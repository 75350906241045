import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Form, Formik } from 'formik';
import { Box } from '@mui/material';
import { FC, ReactNode, useCallback } from 'react';
import { FormikHelpers } from 'formik/dist/types';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Modal } from 'targets/web/components';
import {
  CustomerData,
  CustomerFormFields,
  CustomerSchema,
  CustomerSchemaWithRequiredExternalId,
  initialValues,
} from 'targets/web/modules/configuration/components';
import { Mode } from 'targets/web/modules/configuration/components/CustomerForm/BaseFields';

export enum CustomerModalType {
  Create = 'create',
  Confirm = 'confirm',
}

interface CreateCustomerModalProps {
  open: boolean;
  onSubmit: (values: CustomerData) => Promise<void>;
  onClose: (reason: 'cancel' | 'submit') => void;
  values?: CustomerData;
  title?: ReactNode;
  primaryButtonLabel?: string;
  type: CustomerModalType;
}

export const CreateCustomerModal: FC<CreateCustomerModalProps> = ({
  values,
  title,
  open,
  primaryButtonLabel,
  onSubmit,
  onClose,
  type = CustomerModalType.Create,
}) => {
  const t = useTranslationPrefix('configuration.customers.customer_modal');
  const tGlobal = useTranslationPrefix('global');

  const handleSubmit = useCallback(
    async (values: CustomerData, { resetForm }: FormikHelpers<CustomerData>) =>
      onSubmit(values).finally(() => {
        onClose('submit');
        resetForm();
      }),
    [onClose, onSubmit],
  );

  return (
    <Formik
      initialValues={values ?? initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
      validationSchema={toFormikValidationSchema(
        type === CustomerModalType.Create ? CustomerSchema : CustomerSchemaWithRequiredExternalId,
      )}
    >
      {({ isValid, isSubmitting, submitForm, resetForm }) => (
        <Form autoComplete="off">
          <Modal
            disableBackdropClick
            onClose={() => {
              onClose('cancel');
              resetForm();
            }}
            open={open}
            title={title ?? t('title')}
            maxWidth={false}
            content={
              <Box maxWidth={740}>
                <CustomerFormFields mode={values ? Mode.Edit : Mode.Create} />
              </Box>
            }
            actions={{
              primary: {
                type: 'submit',
                text: primaryButtonLabel ?? tGlobal('confirm'),
                disabled: !isValid || isSubmitting,
                onClick: submitForm,
              },
              secondary: {
                text: tGlobal('cancel'),
              },
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
