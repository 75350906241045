import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { debounce, get } from 'lodash';
import z from 'zod';

import { entityId, ID } from 'domain/types/ID';
import { Entity } from 'domain/types/Entity';
import { Station } from 'domain/entities/Station';
import { useCaseSearchStation } from 'application/stations/useCases/useCaseSearchStation';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Autocomplete } from 'targets/web/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { FormikTextField } from 'targets/web/modules/dashboard/components';

export const associationDetailsSchema = z.object({
  station: z
    .object({
      id: entityId(),
      code: z.string(),
      name: z.string(),
      state: z.string(),
    })
    .nullable(),
  customer: z
    .object({
      id: entityId(),
      code: z.string().optional(),
      name: z.string().optional(),
    })
    .nullable(),
});

export type AssociationDetails = z.infer<typeof associationDetailsSchema>;

const isValueFreeSolo = (value: AssociationDetails['station']): boolean =>
  !!value?.id && !!value?.name && value?.id === value?.name;

const blankStation = { name: 'All', code: '', state: '', id: 'all' as ID };

export const StationAssociationAutocomplete: FC<{
  disabled?: boolean;
  stationEditable?: boolean;
  optional?: boolean;
}> = ({ disabled, stationEditable, optional }) => {
  const { isOffline } = useNetworkStatus();

  const { values, errors, touched, initialValues, setFieldValue } =
    useFormikContext<AssociationDetails>();
  const t = useTranslationPrefix('jobs.details_form.primary_details');

  const [stationPhrase, setStationPhrase] = useState('');
  const { items = [], isLoading } = useCaseSearchStation({ phrase: stationPhrase });

  const stations = useMemo(() => {
    if (!initialValues.station?.id) {
      return [blankStation, ...items];
    }

    if (!items.find(({ id }) => id === initialValues.station?.id)) {
      return [initialValues.station].concat(items);
    }

    return [blankStation, ...items];
  }, [initialValues.station, items]);

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setStationPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<Entity<Station>, false, false, boolean>
      label={t('station_label')}
      loading={isLoading}
      disabled={disabled || !stationEditable || values.station === null}
      freeSolo={isOffline}
      autoSelect={isOffline}
      options={stations}
      value={
        values.station === null
          ? stations.find((station) => station.id === blankStation.id)
          : values.station?.id
          ? values.station
          : ''
      }
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      getOptionDisabled={(option) => option.id === blankStation.id}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      data-testname="station"
      onChange={(_, value) =>
        setFieldValue('station', typeof value === 'string' ? { name: value, id: value } : value)
      }
      renderInput={(params) => {
        const isFreeSolo = values.station && isValueFreeSolo(values.station);
        const hasError = !!get(errors, 'station') && (!!get(touched, 'station') || isFreeSolo);

        return (
          <FormikTextField
            {...params}
            name="station"
            inputProps={{
              ...params.inputProps,
              required: !optional,
            }}
            error={hasError}
            helperText={
              hasError ? (isFreeSolo && isOffline ? t('error_free_solo') : t('error')) : undefined
            }
            label={t('station_label')}
            required={!optional}
          />
        );
      }}
    />
  );
};
