import { FC, useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import { get } from 'lodash';

import { NumberCounter, NumberCounterProps } from 'targets/web/components';

export interface FormikNumberCounter extends NumberCounterProps {
  name: string;
  onChange?: (value: number) => void;
}

export const FormikNumberCounter: FC<FormikNumberCounter> = (props) => {
  return (
    <Field name={props.name} required={props.required}>
      {({ field, form: { touched, errors, setFieldTouched } }: FieldProps) => {
        const hasError = !!get(errors, field.name) && !!get(touched, field.name);

        const handleChange = useCallback(
          (value: number) => {
            setFieldTouched(field.name);

            field.onChange({
              target: { value, name: props.name, type: 'number' },
            });
          },
          [field, setFieldTouched],
        );

        return (
          <NumberCounter
            {...field}
            {...props}
            data-testid={props.name}
            onChange={props.onChange || handleChange}
            error={hasError}
            helperText={hasError ? get(errors, field.name)?.toString() : undefined}
          />
        );
      }}
    </Field>
  );
};
