import { styled, alpha } from '@mui/material';

export const AbsoluteLoaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: alpha(theme.palette.background.default, theme.palette.action.activatedOpacity),
}));
