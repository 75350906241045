import { RadioGroup } from '@mui/material';
import { ChangeEvent, FC, useCallback, useState } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Modal from 'targets/web/components/Modal';
import Radio from 'targets/web/components/Radio';

interface ModalProps {
  open: boolean;
  onConfirm: (billable: boolean) => void;
  onClose: () => void;
}

type HoursType = 'billable' | 'non-billable';

export const BeforeYouStartModal: FC<ModalProps> = ({ onClose, onConfirm, open }) => {
  const t = useTranslationPrefix('components.beforeYouStartModal');

  const [selectedValue, setSelectedValue] = useState<HoursType>('billable');
  const handleRadioChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value as HoursType);
  }, []);

  const handleConfirmation = useCallback(() => {
    onConfirm(selectedValue === 'billable');
  }, [onConfirm, selectedValue]);

  return (
    <Modal
      title={t('title')}
      description={t('description')}
      content={
        <RadioGroup row name="radioGroup" value={selectedValue} onChange={handleRadioChange}>
          <Radio label={t('billableRadio')} value="billable" />
          <Radio label={t('nonBillableRadio')} value="non-billable" />
        </RadioGroup>
      }
      open={open}
      onClose={onClose}
      actions={{
        primary: { text: t('confirm'), onClick: handleConfirmation },
        secondary: { text: t('cancel'), onClick: onClose },
      }}
    />
  );
};
