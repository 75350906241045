import { Stack, styled } from '@mui/material';

export const ImageWrapper = styled(Stack)(({ theme }) => ({
  width: 'auto',
  position: 'relative',
  '& > .MuiButton-root': {
    zIndex: 5,
    opacity: 0,
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.standard,
    }),
  },
  '&:hover > .MuiButton-root': {
    opacity: 1,
  },
}));
