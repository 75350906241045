import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import Link, { LinkProps } from 'targets/web/components/Link';

export interface LinkViewField {
  label: string;
  text: string;
  link: string;
  linkProps?: Partial<LinkProps>;
}

export const LinkViewField: FC<LinkViewField> = ({ label, text, link, ...rest }) => {
  return (
    <Stack gap={2} width="inherit">
      <Typography variant="inputLabel">{label}</Typography>
      <Link {...rest.linkProps} href={link}>
        {text}
      </Link>
    </Stack>
  );
};

export default LinkViewField;
