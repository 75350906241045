import { useQuery, useQueryClient } from '@tanstack/react-query';
import Pagination from 'common/types/Pagination';
import { useCallback } from 'react';
import SortingOptions from 'common/types/SortingOptions';

import { UnapprovedJobStatusCount } from 'domain/entities/UnapprovedJobStatusCount';
import { SearchJobRequestsParams } from 'domain/entities/SearchJobRequestsParams';
import { JobRequestRepository } from 'domain/repositories/IJobRequestRepository';
import { useHookInjection } from 'application/hooks';

const placeholderData: UnapprovedJobStatusCount = {
  total: 0,
  accepted: 0,
  denied: 0,
  created: 0,
};

const QUERY_PREFIX = 'JOB_REQUESTS_STATUS_COUNT';

export const useCaseGetJobRequestsCountByStatus = (
  params: Omit<SearchJobRequestsParams, keyof Pagination | keyof SortingOptions> | null,
) => {
  const qClient = useQueryClient();

  const { getJobRequestsCountByStatus } =
    useHookInjection<JobRequestRepository>(JobRequestRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_PREFIX, params] as const,
    queryFn: ({ queryKey }) => getJobRequestsCountByStatus(queryKey[1] ?? {}),
    enabled: !!params,
    staleTime: 10_000,
    refetchInterval: 30_000,
    placeholderData,
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};
