import { useMutation } from '@tanstack/react-query';

import {
  EditPriceListAssociationRequestParams,
  PricesRepository,
} from 'domain/repositories/IPricesRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseEditAssociation = () => {
  const { editAssociation } = useHookInjection<PricesRepository>(PricesRepository);
  const { mutateAsync } = useMutation<void, Error, EditPriceListAssociationRequestParams>({
    mutationFn: editAssociation,
  });
  return {
    editAssociation: mutateAsync,
  };
};
