import { useQuery } from '@tanstack/react-query';
import Pagination from 'common/types/Pagination';

import { JobRepository, SearchJobsParams } from 'domain/repositories/IJobRepository';
import { JobSummaryCount } from 'domain/entities/JobsSummary';
import { useHookInjection } from 'application/hooks';

const initialData: JobSummaryCount = { totalOverdue: 0, totalAtRisk: 0, totalOverbudget: 0 };

export const useCaseGetJobsSummary = (params: Omit<SearchJobsParams, keyof Pagination> | null) => {
  const { getJobsSummary } = useHookInjection<JobRepository>(JobRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['JOBS_SUMMARY_COUNT', params] as const,
    queryFn: ({ queryKey }) => getJobsSummary(queryKey[1] ?? {}),
    enabled: !!params,
    initialData,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};
