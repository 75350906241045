import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import preWorkCommunication from '/assets/pre_work_communication_checklist.pdf?url';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { downloadFile } from 'infrastructure/utils/downloadFile';
import { QuestionBox } from 'targets/web/modules/jobs/components/QuestionBox';
import { Button, IconContainer, Select, ToggleButton } from 'targets/web/components';
import { FormikNumberCounter, FormikTextField } from 'targets/web/modules/dashboard/components';

import { PreWorkQuestionnaireFormData } from './PreWorkQuestionnaireDialog';
import { DamageLine } from './DamageFound';

export const QuestionsFormSection = () => {
  const t = useTranslationPrefix('jobs.pre_work_questionnaire.questions');
  const tGlobal = useTranslationPrefix('global');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { values, errors, setFieldValue } = useFormikContext<PreWorkQuestionnaireFormData>();

  const [damageFound, setDamageFound] = useState(values.damagesFound.length > 0);

  const handleDownloadPreWorkCommunication = useCallback(() => {
    downloadFile(preWorkCommunication, 'pre_work_communication');
  }, []);

  return (
    <Stack gap={4}>
      <QuestionBox>
        <Typography flex={3}>{t('place_of_work')}</Typography>

        <Box flex={2} width={1}>
          <Select
            placeholder={'Select'}
            sx={{ backgroundColor: 'background.default' }}
            value={values.inspectionAreas ?? []}
            items={[
              {
                label: 'Interior',
                value: 'interior',
              },
              {
                label: 'Exterior',
                value: 'exterior',
              },
              {
                label: 'Brightwork / Boots',
                value: 'brightwork-boots',
              },
            ]}
            itemCheckmarks
            multiple
            onChange={({ target }) => setFieldValue('inspectionAreas', target.value)}
          />
        </Box>
      </QuestionBox>

      <QuestionBox>
        <Typography flex={1}>{t('prework_communication')}</Typography>

        <Button
          variant="text"
          startIcon={<RemoveRedEyeOutlinedIcon />}
          onClick={handleDownloadPreWorkCommunication}
        >
          {t('view_button_label')}
        </Button>

        <ToggleButton
          color="primary"
          size="medium"
          value={values.includeCommunication ? 'yes' : 'no'}
          onClick={(e) => setFieldValue('includeCommunication', e.value === 'yes')}
          options={[
            { value: 'yes', label: tGlobal('yes') },
            { value: 'no', label: tGlobal('no') },
          ]}
        />
      </QuestionBox>

      <QuestionBox direction="column">
        <Stack width={1} spacing={4} alignItems="center" direction="row">
          <Typography flex={1}>{t('aircraft_damage')}</Typography>

          <ToggleButton
            color="primary"
            size="medium"
            value={damageFound ? 'yes' : 'no'}
            onClick={(e) => {
              if (e.value === 'no') {
                setFieldValue('damageFound', []);
              }

              setDamageFound(e.value === 'yes');
            }}
            options={[
              { value: 'yes', label: tGlobal('yes') },
              { value: 'no', label: tGlobal('no') },
            ]}
          />
        </Stack>

        <Collapse in={damageFound} sx={{ width: 1 }} unmountOnExit>
          <Stack gap={2}>
            <Stack
              gap={{ xs: 4, md: 6 }}
              alignItems="center"
              divider={isMobile ? <Divider flexItem /> : undefined}
            >
              {values.damagesFound.map((damage, index) => (
                <DamageLine
                  key={`${damage.damage}-${index}`}
                  damageFound={damage}
                  // @ts-expect-error TS2322
                  error={errors.damagesFound?.[index]}
                  onChange={({ damage, description, imageFile }) => {
                    if (damage === '') return;

                    values.damagesFound.splice(index, 1, {
                      damage,
                      description,
                      imageFile,
                    });
                    setFieldValue('damagesFound', values.damagesFound);
                  }}
                  onDelete={() => {
                    values.damagesFound.splice(index, 1);
                    setFieldValue('damagesFound', values.damagesFound);
                  }}
                />
              ))}
            </Stack>

            <Stack
              gap={4}
              width={1}
              alignItems={values.damagesFound.length === 0 ? 'center' : 'start'}
            >
              {values.damagesFound.length === 0 && (
                <Stack gap={2} alignItems="center">
                  <IconContainer>
                    <ErrorIcon />
                  </IconContainer>

                  <Typography color="text.secondary">{t('no_damage_added')}</Typography>
                </Stack>
              )}

              <Button
                sx={{ width: 'fit-content' }}
                startIcon={<AddIcon />}
                variant={values.damagesFound.length === 0 ? 'contained' : 'text'}
                onClick={() =>
                  setFieldValue('damagesFound', values.damagesFound.concat([{ damage: '' }]))
                }
              >
                {tGlobal('add')}
              </Button>
            </Stack>
          </Stack>
        </Collapse>
      </QuestionBox>

      <Collapse in={damageFound} unmountOnExit>
        <QuestionBox>
          <Stack flex={{ xs: 1, md: 3 }} spacing={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.customerContacted}
                  onChange={(e) => setFieldValue('customerContacted', e.target.checked)}
                />
              }
              label={
                <Typography variant="labelLarge">{t('customer_contacted_about_damage')}</Typography>
              }
            />
          </Stack>

          <Box flex={{ xs: 1, md: 2 }} width={1}>
            <FormikTextField
              disabled={!values.customerContacted}
              name="clientDamageCommunication"
              InputProps={{ sx: { backgroundColor: 'background.default' } }}
              label={t('damage_communicated_note')}
              multiline
              minRows={3}
              maxRows={10}
            />
          </Box>
        </QuestionBox>
      </Collapse>

      <QuestionBox>
        <Typography>{t('employees_count')}</Typography>

        <FormikNumberCounter name="employeeCount" sx={{ maxWidth: 160 }} min={0} max={20} />
      </QuestionBox>
    </Stack>
  );
};
