import { useCallback } from 'react';
import contract from 'api/infrastructure/contract/aircraft.route';

import { AircraftRepository, CreateAircraftPayload } from 'domain/repositories/IAircraftRepository';
import useTsRestClient from 'infrastructure/providers/useTsRestClient';

type RepositoryFunctions = ReturnType<AircraftRepository>;

export const useTsRestAircraftRepository: AircraftRepository = () => {
  const client = useTsRestClient(contract);

  const searchAircraft: RepositoryFunctions['searchAircraft'] = useCallback(
    async (params) => {
      const res = await client.searchAircraft({
        query: {
          ...params,
          sortBy:
            params.sortBy === 'customer' || params.sortBy === 'type'
              ? `${params.sortBy}.name`
              : params.sortBy,
        },
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const createAircraft: RepositoryFunctions['createAircraft'] = useCallback(
    async (payload: CreateAircraftPayload) => {
      const response = await client.createAircraft({
        body: {
          typeId: payload.typeId,
          identifiers: {
            code: payload.regNumber ?? '',
            serialNumber: payload.serialNumber ?? '',
          },
        },
      });

      if (response.status === 201) {
        return response.body;
      }

      throw response;
    },
    [client],
  );

  const searchAircraftType: RepositoryFunctions['searchAircraftType'] = useCallback(
    async (params) => {
      const res = await client.searchAircraftTypes({
        query: {
          phrase: params.phrase,
          sortBy: params.sortBy,
          direction: params.direction,
          offset: params.offset,
          limit: params.limit,
        },
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  return {
    searchAircraft,
    createAircraft,
    searchAircraftType,
  };
};
