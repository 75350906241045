import { useQuery } from '@tanstack/react-query';

import { ServiceRepository } from 'domain/repositories/IServiceRepository';
import { ID } from 'domain/types/ID';
import { ServiceStatus } from 'domain/entities/ServiceStatus';
import { useHookInjection } from 'application/hooks';

const QUERY_PREFIX = 'SERVICES_EVENT';

export const useCaseShowServicesSchedule = (params: {
  dateStart: Date;
  dateEnd: Date;
  customerIds?: ID[];
  stationIds?: ID[];
  status?: ServiceStatus;
}) => {
  const { searchServices } = useHookInjection<ServiceRepository>(ServiceRepository);

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_PREFIX, params] as const,
    queryFn: ({ queryKey }) => {
      const [, queryParams] = queryKey;
      return searchServices({
        limit: 100,
        offset: 0,
        dateStart: queryParams.dateStart.toISOString(),
        dateEnd: queryParams.dateEnd.toISOString(),
        customerIds: queryParams.customerIds,
        status: queryParams.status ? [queryParams.status] : undefined,
        stationIds: queryParams.stationIds,
      });
    },
  });

  return {
    services: data,
    isLoading,
  };
};
