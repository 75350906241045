import { FC } from 'react';
import { Route, Routes } from 'react-router';

import { ShowJobActions } from 'domain/types/Roles';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { JobsView } from 'targets/web/modules/jobs/views/Jobs.view';
import { NewJobView } from 'targets/web/modules/jobs/views/NewJob.view';
import { ServiceDetailsView } from 'targets/web/modules/jobs/views/ServiceDetails.view';
import { JobDetailsView } from 'targets/web/modules/jobs/views/JobDetails.view';
import { JobSummaryView } from 'targets/web/modules/jobs/views/JobSummary.view';
import EditJobView from 'targets/web/modules/jobs/views/EditJob.view';
import PageNotFoundView from 'targets/web/modules/dashboard/views/PageNotFound.view';
import { ServiceEditView } from 'targets/web/modules/jobs/views/ServiceEdit.view';
import { RoleLimitedRoute } from 'targets/web/routes/RoleLimitedRoute';
import { PostWorkQuestionnaireView } from 'targets/web/modules/jobs/views/PostWorkQuestionnaire.view';

const JobsRoutes: FC = () => (
  <Routes>
    <Route index element={<JobsView />} />

    <Route
      path={jobsRoutes.internalRoutes.new}
      element={
        <RoleLimitedRoute role={ShowJobActions}>
          <NewJobView />
        </RoleLimitedRoute>
      }
    />
    <Route path={jobsRoutes.internalRoutes.details} element={<JobDetailsView />} />
    <Route path={jobsRoutes.internalRoutes.summary} element={<JobSummaryView />} />
    <Route
      path={jobsRoutes.internalRoutes.edit}
      element={
        <RoleLimitedRoute role={ShowJobActions}>
          <EditJobView />
        </RoleLimitedRoute>
      }
    />
    <Route path={jobsRoutes.internalRoutes.serviceDetails} element={<ServiceDetailsView />} />
    <Route
      path={jobsRoutes.internalRoutes.serviceEdit}
      element={
        <RoleLimitedRoute role={ShowJobActions}>
          <ServiceEditView />
        </RoleLimitedRoute>
      }
    />
    <Route
      path={jobsRoutes.internalRoutes.postWorkQuestionnaire}
      element={<PostWorkQuestionnaireView />}
    />

    <Route path="*" element={<PageNotFoundView />} />
  </Routes>
);

export default JobsRoutes;
