import { FC } from 'react';
import { Divider, Grid, Stack, Typography, styled } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import formatPrice from 'infrastructure/utils/formatPrice';

import { Container, ServiceDetailsField } from './common';

export type BillingServiceDetailsPreviewData = {
  priceList: string;
  type: string;
  amount: number;
  discountPercent: number | null;
};

export interface BillingServiceDetailsPreviewProps {
  data: BillingServiceDetailsPreviewData & { quantity: number | null };
}

const AttachmentIconWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.action.alertBackground,
  color: theme.palette.primary.action.alertContent,
  borderRadius: '100%',
  padding: theme.spacing(2),
  height: 'fit-content',
}));

export const BillingServiceDetailsPreview: FC<BillingServiceDetailsPreviewProps> = ({ data }) => {
  const t = useTranslationPrefix('jobs.service_details.billing');
  return (
    <Container width={1} gap={4}>
      <Typography variant="h6">{t('title')}</Typography>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Stack gap={4}>
            <ServiceDetailsField title={t('price_list_label')} value={data.priceList} />
            <ServiceDetailsField
              title={t('quantity_label')}
              value={data.quantity?.toString() || '-'}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack gap={4}>
            <ServiceDetailsField title={t('amount_label')} value={formatPrice(data.amount)} />
            {data.discountPercent !== null && (
              <Stack direction="row">
                <ServiceDetailsField
                  title={t('discount_percentage_label')}
                  value={data.discountPercent.toLocaleString('en-US', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                />
                <ServiceDetailsField
                  title={t('discount_amount_label')}
                  value={formatPrice(data.amount * data.discountPercent)}
                />
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Typography variant="h6">{t('attachments_title')}</Typography>
      <Stack alignItems="center" justifyContent="center" direction="column" gap={2}>
        <AttachmentIconWrapper>
          <AttachFileIcon />
        </AttachmentIconWrapper>
        <Typography variant="body1">{t('no_attachments')}</Typography>
      </Stack>
    </Container>
  );
};
