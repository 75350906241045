import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BillingItemRepository } from 'domain/repositories/IBillingItemRepository';
import { BillingItem } from 'domain/entities/BillingItem';
import { useHookInjection } from 'application/hooks';
import { useSortingAndPagination } from 'application/common';

export const QUERY_PREFIX = 'BILLING_ITEMS';

export type BillingItemExtraKeys = 'job.name' | 'customer.name' | 'station.name' | 'poNumber';

export const useCaseSearchBillingItems = () => {
  const qClient = useQueryClient();
  const { searchBillingItems } = useHookInjection<BillingItemRepository>(BillingItemRepository);

  const { paging, sorting, ...utils } = useSortingAndPagination<BillingItem, BillingItemExtraKeys>(
    'poNumber',
  );

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_PREFIX, { ...paging, ...sorting }] as const,
    queryFn: ({ queryKey }) => searchBillingItems(queryKey[1]),
    placeholderData: { items: [], total: 0 },
    staleTime: 10_000,
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    ...data,
    refetch,

    paging,
    sorting,
    ...utils,

    isLoading,
    isFetching,
  };
};
