import { ChangeEvent, ReactElement, ReactNode, useMemo } from 'react';
import { InputAdornment, Stack, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';

import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { componentShadows } from 'targets/web/theme/shadows';
import { Table, TableProps, TextField } from 'targets/web/components';

export interface TableWithSearchProps<T> extends TableProps<T> {
  onSearchPhraseChange?: (phrase: string) => void;
  initialSearchPhrase?: string;
  searchPhrasePlaceholder?: string;
  endElement?: ReactNode | ReactNode[];
}

export const TableWithSearch = <T,>({
  onSearchPhraseChange,
  initialSearchPhrase,
  searchPhrasePlaceholder,
  endElement,
  ...props
}: TableWithSearchProps<T>): ReactElement => {
  const theme = useTheme();

  const handleInputChange = useMemo(
    () =>
      debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onSearchPhraseChange && onSearchPhraseChange(event.target.value);
      }, DEFAULT_DEBOUNCE_TIME),
    [onSearchPhraseChange],
  );

  return (
    <Stack sx={{ boxShadow: componentShadows.card, borderRadius: 1 }}>
      <Stack
        direction="row"
        gap={4}
        sx={{
          padding: 4,
          width: 1,
          backgroundColor: 'background.default',
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
        }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={searchPhrasePlaceholder}
          onChange={handleInputChange}
          defaultValue={initialSearchPhrase}
        />

        {endElement}
      </Stack>

      <Table
        {...props}
        ContainerProps={{
          sx: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            boxShadow: 'unset',
          },
        }}
      />
    </Stack>
  );
};
