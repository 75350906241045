import { Grid } from '@mui/material';
import { FC } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { FormikSelectField, FormikTextField } from 'targets/web/modules/dashboard/components';

export enum Mode {
  Create = 'create',
  Edit = 'edit',
}

export const BaseFields: FC<{ disabled?: boolean; mode?: Mode }> = ({
  disabled,
  mode = Mode.Create,
}) => {
  const t = useTranslationPrefix('configuration.customer_details.customer_form');

  return (
    <>
      <Grid item xs={6}>
        <FormikTextField name="name" label={t('name')} disabled={disabled} required={!disabled} />
      </Grid>
      {mode === Mode.Edit && (
        <>
          <Grid item xs={6}>
            <FormikTextField
              name="code"
              label={t('code')}
              disabled={disabled}
              required={!disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="externalId"
              label={t('externalId')}
              disabled={disabled}
              required={!disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikSelectField
              name="paymentTerm"
              label={t('paymentTerm')}
              placeholder={t('paymentTermPlaceholder')}
              disabled={disabled}
              required={!disabled}
              items={[
                {
                  label: t('paymentTermItems.net30'),
                  value: 'net30',
                },
                {
                  label: t('paymentTermItems.creditCard'),
                  value: 'creditCard',
                },
              ]}
            />
          </Grid>
        </>
      )}
    </>
  );
};
