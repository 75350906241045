import React, { FC, useState } from 'react';
import { Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  NavigationItem as INavigationItem,
  NavigationProps,
} from 'targets/web/components/Navigation/Navigation';
import { NavigationItem } from 'targets/web/components/Navigation/NavigationItem/NavigationItem';

import { Accordion, AccordionDetails, AccordionSummary, List, StyledMenu } from './style';

export type NavigationAccordion = INavigationItem &
  Pick<NavigationProps, 'selected' | 'open' | 'onClick'>;

export const NavigationAccordion: FC<NavigationAccordion> = ({
  url,
  selected,
  nested,
  open,
  onClick,
  label,
  icon,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const isChildSelected = nested
    ? Boolean(nested.find((child) => (child.url ? selected.includes(child.url) : false)))
    : false;

  const handleOnClick = (link: INavigationItem, event: React.MouseEvent<HTMLDivElement>) => {
    !open && nested && handleOpen(event);
    !nested && url && onClick && onClick(link);
  };

  return (
    <>
      <Accordion
        defaultExpanded={isChildSelected}
        onClick={(event) => handleOnClick({ label, icon, url, nested }, event)}
      >
        <AccordionSummary
          open={open}
          selected={isChildSelected}
          expandIcon={open && nested && <ChevronRightIcon />}
          id={url || label}
        >
          {icon}
          {open && (
            <Typography fontWeight={600} fontSize={14} color="inherit">
              {label}
            </Typography>
          )}
        </AccordionSummary>
        {nested && open && (
          <AccordionDetails>
            <List>
              {nested.map((childLink) => {
                const { label, icon, url } = childLink;

                return (
                  <NavigationItem
                    key={url}
                    url={url}
                    onClick={onClick}
                    icon={icon}
                    label={label}
                    open={open}
                    selected={selected}
                  />
                );
              })}
            </List>
          </AccordionDetails>
        )}
      </Accordion>
      {!open && nested && (
        <StyledMenu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleClose}
          items={nested.map((childLink) => ({
            label: childLink.label,
            onClick: () => onClick && onClick(childLink),
            selected: childLink.url ? selected.includes(childLink.url) : false,
            icon: childLink.icon,
          }))}
          anchorOrigin={{ vertical: 'top', horizontal: 68 }}
        />
      )}
    </>
  );
};
