import { FC, useCallback, useState } from 'react';
import { InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import IconButton from 'targets/web/components/IconButton';
import TextField, { TextFieldProps } from 'targets/web/components/TextField';

export type PasswordTextFieldProps = Omit<TextFieldProps, 'type'>;

export const PasswordTextField: FC<PasswordTextFieldProps> = (props) => {
  const [passwordPreview, setPasswordPreview] = useState<boolean>(false);
  const togglePasswordPreview = useCallback(() => setPasswordPreview((preview) => !preview), []);

  return (
    <TextField
      {...props}
      type={passwordPreview ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton color="secondary" variant="text" onClick={togglePasswordPreview}>
              {passwordPreview ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordTextField;
