import { useQuery } from '@tanstack/react-query';

import { ResourcePermission } from 'application/auth/providers/IAccessProvider';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import useOptimisticPermission from 'application/auth/hooks/useOptimisticPermission';

const usePermission = (permission?: ResourcePermission, optimistic?: boolean) => {
  const { hasPermission } = useCaseCheckAccess();
  const optimisticResult = useOptimisticPermission(permission);

  const { data, isLoading, error } = useQuery({
    enabled: !optimistic && !!permission,
    queryKey: ['PERMISSION', permission?.resource, permission?.scope] as const,
    queryFn: async ({ queryKey }) => {
      return hasPermission([
        {
          resource: queryKey[1] as ResourcePermission['resource'],
          scope: queryKey[2] as string,
        },
      ]);
    },
  });

  if (optimistic) {
    return optimisticResult;
  }

  return {
    granted: !isLoading && !error ? data : false,
    isLoading,
  };
};

export default usePermission;
