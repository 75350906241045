import { FC } from 'react';
import { Stack } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { PageTitle } from 'targets/web/modules/dashboard/components';
import { BillingItemsTable } from 'targets/web/modules/billing/components';

export const BillingReviewView: FC = () => {
  const t = useTranslationPrefix('billing.billing_review');

  return (
    <>
      <Stack width={1} direction="row" alignItems="center" justifyContent="space-between">
        <PageTitle title={t('title')} />
      </Stack>

      <Stack gap={4} sx={{ width: 1, height: 'auto' }}>
        <BillingItemsTable />
      </Stack>
    </>
  );
};
