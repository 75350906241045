/* eslint-disable */

import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LocalDataTrack, RemoteDataTrack, RemoteVideoTrack } from 'twilio-video';

import { VideoContext } from 'targets/web/components/ConversationModal/VideoProvider';

interface RemoteControlBaseMessage {
  type: string;
}

interface RemoteControlRequest extends RemoteControlBaseMessage {
  type: 'remoteControlRequest';
}

interface RemoteControlResponse extends RemoteControlBaseMessage {
  type: 'remoteControlResponse';
  accepted: boolean;
}

interface RemoteControlEndEvent extends RemoteControlBaseMessage {
  type: 'remoteControlEnd';
}

interface RemoteControlTouchEvent extends RemoteControlBaseMessage {
  type: 'remoteControlTouch';
  x: number;
  y: number;
}

interface RemoteControlSwipeEvent extends RemoteControlBaseMessage {
  type: 'remoteControlSwipe';
  from: { x: number; y: number };
  to: { x: number; y: number };
  time: number;
}

interface RemoteControlMouseMove extends RemoteControlBaseMessage {
  type: 'remoteControlMouseMove';
  x: number;
  y: number;
}

interface RemoteControlHomeEvent extends RemoteControlBaseMessage {
  type: 'remoteControlHome';
}
interface RemoteControlBackEvent extends RemoteControlBaseMessage {
  type: 'remoteControlBack';
}

export type RemoteControlMessage =
  | RemoteControlRequest
  | RemoteControlTouchEvent
  | RemoteControlResponse
  | RemoteControlEndEvent
  | RemoteControlSwipeEvent
  | RemoteControlHomeEvent
  | RemoteControlBackEvent
  | RemoteControlMouseMove;

export class RemoteControlRejectedError extends Error {
  constructor() {
    super('Driver has rejected the remote control request');
    this.name = 'RemoteControlRejectedError';
  }
}

export class RemoteControlTimeoutError extends Error {
  constructor() {
    super('Timeout has occurred when requesting control');
    this.name = 'RemoteControlTimeoutError';
  }
}

export interface IRemoteControlContext {
  loading: boolean;
  active: boolean;
  sendEvent(event: RemoteControlMessage): void;
  requestControl(): Promise<void>;
  stopControl(): void;
}

export const RemoteControlContext = createContext<IRemoteControlContext>({
  active: false,
  loading: false,
  stopControl() {},
  async requestControl(): Promise<void> {},
  sendEvent() {},
});

const REMOTE_CONTROL_REQUEST_TIMEOUT = 30 * 1000;
export const REMOTE_CONTROL_TRACK_NAME = 'remoteControl';

type MessageListener = (message: RemoteControlMessage) => void;

interface DataTrackPair {
  local?: LocalDataTrack;
  remote?: RemoteDataTrack;
  listeners: Set<MessageListener>;
}

export const RemoteControlProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<{ loading: boolean; active: boolean }>({
    loading: false,
    active: false,
  });
  const videoContext = useContext(VideoContext);
  const tracks = useRef<DataTrackPair>({
    listeners: new Set<MessageListener>(),
  });

  async function getRemoteDataTrack(): Promise<RemoteDataTrack> {
    return new Promise((resolve, reject) => {
      if (!videoContext) {
        reject('video context missing');
        return;
      }

      const timeout = setTimeout(() => {
        reject('Timeout');
      }, REMOTE_CONTROL_REQUEST_TIMEOUT);

      const listener = (track: RemoteDataTrack) => {
        if (track.kind === 'data' && track.name === REMOTE_CONTROL_TRACK_NAME) {
          clearTimeout(timeout);
          videoContext.mainParticipant?.removeListener('trackSubscribed', listener);
          resolve(track);
        }
      };

      videoContext.mainParticipant?.addListener('trackSubscribed', listener);
    });
  }

  function announceMessage(msg: RemoteControlMessage): void {
    if (tracks.current.listeners.size === 0) {
      console.warn('RemoteControl: no message listeners registered');
      return;
    }
    tracks.current.listeners.forEach((listener) => {
      try {
        listener(msg);
      } catch (e) {
        console.warn('Could not process remoteTrack message listener', e);
      }
    });
  }

  async function sendMessage(msg: RemoteControlMessage): Promise<void> {
    tracks.current.local?.send(JSON.stringify(msg));
  }

  // ensure correct data tracks state
  useEffect(() => {
    if (!videoContext?.mainParticipant || !videoContext.localParticipant) {
      return;
    }
    const mainParticipant = videoContext.mainParticipant;

    // reuse existing local data track
    const existingDataTrack = [...(videoContext?.localParticipant.dataTracks.values() || [])].find(
      (t) => t.kind === 'data' && t.trackName === REMOTE_CONTROL_TRACK_NAME,
    );

    if (existingDataTrack) {
      tracks.current.local = existingDataTrack.track;
    }

    // find existing remote data track to reuse
    const existingRemoteDataTrack = ([
      ...(videoContext?.mainParticipant.dataTracks.values() || []),
    ].find((t) => t.kind === 'data' && t.trackName === REMOTE_CONTROL_TRACK_NAME)?.track ||
      null) as RemoteDataTrack | null;
    if (existingRemoteDataTrack) {
      tracks.current.remote = existingRemoteDataTrack;
    }

    // listen for remote video or data tracks to show up
    const listener = async (track: RemoteDataTrack | RemoteVideoTrack) => {
      if (track.kind === 'video') {
        // if video track is published then create a data track
        tracks.current.local = await ensureLocalDataTrack();
        return;
      }

      if (track.kind === 'data' && track.name === REMOTE_CONTROL_TRACK_NAME) {
        tracks.current.remote = track;
        track.addListener('message', (data: string) => {
          try {
            announceMessage(JSON.parse(data) as RemoteControlMessage);
          } catch (e) {
            console.warn('Could not process remoteTrack message', data);
          }
        });
      }
    };
    mainParticipant.addListener('trackSubscribed', listener);

    return () => {
      mainParticipant.removeListener('trackSubscribed', listener);
    };
  }, [videoContext?.mainParticipant, videoContext?.localParticipant, ensureLocalDataTrack]);

  useEffect(() => {
    const clientEventsListener = (message: RemoteControlMessage) => {
      if (message.type === 'remoteControlResponse') {
        // client-triggered remote control abort
        if (!message.accepted && state.active) {
          setState({
            active: false,
            loading: false,
          });
        }
        // client-triggered remote control start
        if (message.accepted && !state.active && !state.loading) {
          setState({
            active: true,
            loading: false,
          });
        }
      }
    };

    tracks.current.listeners.add(clientEventsListener);

    return () => {
      tracks.current.listeners.delete(clientEventsListener);
    };
  }, [state.loading, state.active]);

  async function ensureLocalDataTrack(): Promise<LocalDataTrack> {
    if (tracks.current.local) {
      return tracks.current.local;
    }

    const newLocalTrack = new LocalDataTrack({
      name: REMOTE_CONTROL_TRACK_NAME,
      ordered: true,
    });

    await videoContext?.localParticipant?.publishTrack(newLocalTrack);

    return newLocalTrack;
  }

  async function ensureRemoteDataTrack(): Promise<RemoteDataTrack> {
    if (tracks.current.remote) {
      return tracks.current.remote;
    }

    return await getRemoteDataTrack();
  }

  async function getRemoteControlRequestResponse(): Promise<boolean> {
    await sendMessage({
      type: 'remoteControlRequest',
    });
    return new Promise((resolve, reject) => {
      const listener = (message: RemoteControlMessage) => {
        if (message.type === 'remoteControlResponse') {
          resolve(message.accepted);
        }
      };
      setTimeout(() => {
        tracks.current.listeners.delete(listener);
        reject(new RemoteControlTimeoutError());
      }, REMOTE_CONTROL_REQUEST_TIMEOUT);
      tracks.current.listeners.add(listener);
    });
  }

  async function startRemoteControl() {
    tracks.current.local = await ensureLocalDataTrack();
    tracks.current.remote = await ensureRemoteDataTrack();

    const response = await getRemoteControlRequestResponse();
    if (!response) {
      throw new RemoteControlRejectedError();
    }
  }

  function sendEvent(event: RemoteControlMessage) {
    const dataTrack = [...(videoContext?.localParticipant?.dataTracks.values() || [])].find(
      (t) => t.trackName === REMOTE_CONTROL_TRACK_NAME,
    );
    if (!dataTrack) {
      return;
    }
    dataTrack.track.send(JSON.stringify(event));
  }

  return (
    <RemoteControlContext.Provider
      value={{
        ...state,
        async requestControl(): Promise<void> {
          setState({
            active: false,
            loading: true,
          });

          return startRemoteControl()
            .then(() => {
              setState({
                active: true,
                loading: false,
              });
            })
            .catch((e) => {
              setState({
                active: false,
                loading: false,
              });
              throw e;
            });
        },
        stopControl() {
          sendEvent({
            type: 'remoteControlEnd',
          });
          setState({
            active: false,
            loading: false,
          });
        },
        sendEvent,
      }}
    >
      {children}
    </RemoteControlContext.Provider>
  );
};
