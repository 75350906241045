import { Stack } from '@mui/material';
import { FC, useMemo, useState } from 'react';

import { ServiceType } from 'domain/entities/ServiceType';
import { useCaseSearchServiceTypes } from 'application/services/useCases/useCaseSearchServiceTypes';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Breadcrumbs, TableColumn, TableWithSearch } from 'targets/web/components';
import { OfflinePlaceholder, PageTitle } from 'targets/web/modules/dashboard/components';
import { configurationRoutes } from 'targets/web/modules/configuration/navigation/configurationRoutes';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export const ServiceTypesView: FC = () => {
  const t = useTranslationPrefix('configuration.service_types');
  const tBreadcrumbs = useTranslationPrefix('configuration.breadcrumbs');
  const { isOffline } = useNetworkStatus();

  const [phrase, setPhrase] = useState('');
  const {
    items = [],
    total,
    isLoading,
    isFetching,

    paging,
    changePage,
    changePageSize,

    sorting,
    changeSort,
  } = useCaseSearchServiceTypes({ phrase });

  const columns: TableColumn<ServiceType>[] = useMemo(
    () => [
      {
        headerName: t('column_header.name'),
        field: 'name',
        sortable: true,
        width: '33%',
        renderCell: ({ name }) => name,
      },
      {
        headerName: t('column_header.code'),
        field: 'code',
        sortable: true,
        width: '33%',
        renderCell: ({ code }) => code || '-',
      },
      {
        headerName: t('column_header.description'),
        width: '33%',
        renderCell: ({ description }) => description || '-',
      },
    ],
    [t],
  );

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('configuration'),
                url: configurationRoutes.configuration,
              },
            ]}
          />

          <PageTitle title={t('title')} />
        </Stack>
      </Stack>

      <Stack width={1}>
        {isOffline && !items.length ? (
          <OfflinePlaceholder />
        ) : (
          <TableWithSearch
            onSearchPhraseChange={setPhrase}
            columns={columns}
            rows={items}
            count={total}
            rowsPerPageOptions={[10, 50, 100]}
            initialRowsPerPage={10}
            orderBy={sorting.sortBy}
            orderDirection={sorting.direction}
            initialPage={Math.round(paging.offset / paging.limit)}
            isLoading={isLoading || isFetching}
            searchPhrasePlaceholder={t('placeholder')}
            onRowsPerPageChange={changePageSize}
            onPageChange={changePage}
            onSortChange={changeSort}
          />
        )}
      </Stack>
    </>
  );
};
