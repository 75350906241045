import { atom, useAtom } from 'jotai';
import { endOfMonth, startOfMonth } from 'date-fns';

import { OperationFilters } from 'targets/web/modules/operations/components/OperationFiltersContainer/OperationFiltersContainer';
import { localTimeToUTC } from 'targets/web/modules/jobs/utils';

const filtersAtom = atom<OperationFilters>({
  dateStart: localTimeToUTC(startOfMonth(Date.now())).toISOString(),
  dateEnd: localTimeToUTC(endOfMonth(Date.now())).toISOString(),
});

export type ViewTab = '1' | '2' | '3';

const viewTabAtom = atom<ViewTab>('1');

export const useOperationViewFilters = () => {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [viewTab, setViewTab] = useAtom(viewTabAtom);

  return {
    filters,
    setFilters,
    viewTab,
    setViewTab,
  };
};
