import { FC } from 'react';
import { Radio as MuiRadio, RadioProps as MuiRadioProps, FormControlLabel } from '@mui/material';

export type RadioProps = MuiRadioProps & {
  /**
   * Text to be used in an enclosing label element.
   * If not defined, no label element will be added.
   */
  label?: string;
};

const Radio: FC<RadioProps> = ({ label, ...rest }) =>
  label ? (
    <FormControlLabel control={<MuiRadio {...rest} />} label={label} />
  ) : (
    <MuiRadio {...rest} />
  );

export default Radio;
