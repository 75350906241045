import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SortingDirection } from 'common/types/SortingOptions';
import { useCallback, useState } from 'react';

import {
  AttachmentsParams,
  AttachmentsRepository,
} from 'domain/repositories/IAttachmentsRepository';
import { Attachment } from 'domain/entities/Attachment';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { useHookInjection } from 'application/hooks';

const QUERY_PREFIX = 'ATTACHMENTS';

export const useCaseGetAttachmentsPerJobs = (params: Pick<AttachmentsParams, 'jobId' | 'type'>) => {
  const qClient = useQueryClient();
  const { searchAttachments } = useHookInjection<AttachmentsRepository>(AttachmentsRepository);

  const [filters, setFilters] = useState({
    sortBy: 'createdAt',
    limit: 10,
    offset: 0,
    direction: SortingDirection.Descending,
  });

  const { data, isLoading, isFetching, refetch } = useQuery<
    unknown,
    Error,
    PaginatedResult<Attachment>,
    [string, AttachmentsParams]
  >({
    queryKey: [QUERY_PREFIX, { ...params, ...filters }],
    enabled: !!params.jobId,
    queryFn: ({ queryKey }) => searchAttachments(queryKey[1]),
    placeholderData: { items: [], total: 0 },
    staleTime: 10_000,
  });

  const refetchAll = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    ...data,
    filters,
    setFilters,
    isLoading,
    isFetching,
    refetch,
    refetchAll,
  };
};
