import { useCallback } from 'react';
import contract from 'api/infrastructure/contract/jobs.route';

import { DeclineJobRequestReason } from 'domain/entities/DeclineJobRequestReason';
import { JobRequestRepository } from 'domain/repositories/IJobRequestRepository';
import { ID } from 'domain/types/ID';
import useTsRestClient from 'infrastructure/providers/useTsRestClient';
import { useDeclinedOptions } from 'targets/web/modules/approvals/hooks/useDeclinedOptions';

type RepositoryFunctions = ReturnType<JobRequestRepository>;

export const useTsRestJobRequestRepository: JobRequestRepository = () => {
  const translatedReasons = useDeclinedOptions();

  const client = useTsRestClient(contract);

  const searchJobRequests: RepositoryFunctions['searchJobRequests'] = useCallback(
    async (params, abortSignal) => {
      const res = await client.searchUnapprovedJobs({
        query: params,
        // ts-rest doesn't allow to pass signal on the type level
        // https://github.com/ts-rest/ts-rest/issues/478
        // @ts-expect-error TS2345
        signal: abortSignal,
      });

      if (res.status === 200) {
        return {
          ...res.body,
          items: res.body.items
            .map(({ item }) => ({ ...item }))
            .map((jobRequest) => ({
              ...jobRequest,
              due: new Date(jobRequest.due),
              deniedReason: jobRequest.deniedReason as DeclineJobRequestReason,
              submittedAt: new Date(jobRequest.submittedAt),
              reviewedAt: jobRequest.reviewedAt && new Date(jobRequest.reviewedAt),
            })),
        };
      }

      throw res;
    },
    [client],
  );

  const getJobRequestsCountByStatus: RepositoryFunctions['getJobRequestsCountByStatus'] =
    useCallback(
      async (params) => {
        const res = await client.getUnapprovedJobsCountByStatus({
          query: params,
        });

        if (res.status === 200) {
          return res.body;
        }

        throw res;
      },
      [client],
    );

  const getJobRequest: RepositoryFunctions['getJobRequest'] = useCallback(
    async (params) => {
      if (!params.id) throw new Error('Missing job request id');

      const response = await client.getJobRequest({
        params: { jobRequestId: ID(params.id) },
      });

      if (response.status === 200) {
        return {
          ...response.body,
          arrivalDate: new Date(response.body.arrivalDate),
          departureDate: new Date(response.body.departureDate),
        };
      }

      throw response;
    },
    [client],
  );

  const reviewJob: RepositoryFunctions['reviewJob'] = useCallback(
    async (params) => {
      const { jobId, ...review } = params;
      const response = await client.reviewJob({
        params: { jobId },
        body: { review },
      });

      if (response.status !== 204) {
        throw response;
      }

      return;
    },
    [client],
  );

  const declineJobRequest: RepositoryFunctions['declineJobRequest'] = useCallback(
    async (params) => {
      if ('jobId' in params) {
        const { jobId, ...review } = params;
        const reviewToSend = review.accepted
          ? review
          : { ...review, reason: translatedReasons[review.reason] };

        const response = await client.reviewJob({
          params: { jobId },
          body: { review: reviewToSend },
        });

        if (response.status !== 204) {
          throw response;
        }

        return;
      }

      const { jobRequestId, ...review } = params;
      const response = await client.denyJobRequest({
        params: { jobRequestId },
        body: review,
      });

      if (response.status !== 200) {
        throw response;
      }
    },
    [client, translatedReasons],
  );

  return {
    searchJobRequests,
    getJobRequestsCountByStatus,

    getJobRequest,
    reviewJob,
    declineJobRequest,
  };
};
