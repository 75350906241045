import { useCallback } from 'react';
import contract from 'api/infrastructure/contract/services.route';

import { ServiceRepository } from 'domain/repositories/IServiceRepository';
import { ID } from 'domain/types/ID';
import useTsRestClient from 'infrastructure/providers/useTsRestClient';

type RepositoryFunctions = ReturnType<ServiceRepository>;

export const useTsRestServiceRepository: ServiceRepository = () => {
  const client = useTsRestClient(contract);

  const completeService: RepositoryFunctions['completeService'] = useCallback(
    async (params) => {
      const res = await client.complete({
        params: { serviceId: params.serviceId },
        body: {
          form900: params.form900,
          fodAreas: params.fodAreas,
        },
      });

      if (res.status !== 204) {
        throw res;
      }
    },
    [client],
  );

  const searchServices: RepositoryFunctions['searchServices'] = useCallback(
    async (params) => {
      const res = await client.searchServices({
        query: params,
      });

      if (res.status === 200) {
        return {
          ...res.body,
          items: res.body.items.map((service) => ({
            ...service,
            scheduledDue: new Date(service.scheduledDue),
            scheduledStart: new Date(service.scheduledStart),
            completedAt: service.completedAt && new Date(service.completedAt),
            workLogs: service.workLogs.map((worklog) => ({
              ...worklog,
              startedAt: new Date(worklog.startedAt),
              endedAt: worklog.endedAt && new Date(worklog.endedAt),
            })),
          })),
        };
      }

      throw res;
    },
    [client],
  );

  const updateService: RepositoryFunctions['updateService'] = useCallback(
    async ({ serviceId, data }) => {
      const response = await client.updateService({
        params: { serviceId },
        body: data,
      });

      if (response.status !== 204) {
        throw response;
      }
    },
    [client],
  );

  const setBillingStatus: RepositoryFunctions['setBillingStatus'] = useCallback(
    async ({ serviceId, billingStatus }) => {
      const response = await client.setBillingStatus({
        params: { serviceId },
        body: { billingStatus },
      });

      if (response.status !== 204) {
        throw response;
      }
    },
    [client],
  );

  const getCountByStatus: RepositoryFunctions['getCountByStatus'] = useCallback(
    async (params) => {
      const res = await client.getCountServicesStatus({
        query: params,
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const startTimer: RepositoryFunctions['startTimer'] = useCallback(
    async (serviceId: ID, billable: boolean) => {
      const res = await client.startTimer({ params: { serviceId }, body: { billable } });

      if (res.status === 204) {
        return;
      }
      throw res;
    },
    [client],
  );

  const stopTimer: RepositoryFunctions['stopTimer'] = useCallback(
    async (serviceId: ID) => {
      const res = await client.stopTimer({ params: { serviceId } });

      if (res.status === 200) {
        return;
      }
      throw res;
    },
    [client],
  );

  const getAllServiceTypes: RepositoryFunctions['getAllServiceTypes'] = useCallback(async () => {
    const res = await client.getAllServiceTypes();

    if (res.status === 200) {
      return res.body;
    }

    throw res;
  }, [client]);

  const searchServiceTypes: RepositoryFunctions['searchServiceTypes'] = useCallback(
    async (params) => {
      const res = await client.searchServiceTypes({
        query: params,
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const getService: RepositoryFunctions['getService'] = useCallback(
    async ({ serviceId }) => {
      const res = await client.getService({ params: { serviceId } });

      if (res.status === 200) {
        const { body } = res;
        return {
          ...body,
          scheduledDue: new Date(body.scheduledDue),
          scheduledStart: new Date(body.scheduledStart),
          workLogs: body.workLogs.map((worklog) => ({
            ...worklog,
            startedAt: new Date(worklog.startedAt),
            endedAt: worklog.endedAt && new Date(worklog.endedAt),
          })),
        };
      }

      throw res;
    },
    [client],
  );

  const cancelService: RepositoryFunctions['cancelService'] = useCallback(
    async (params) => {
      const res = await client.cancel({
        params: { serviceId: params.serviceId },
        body: params,
      });

      if (res.status !== 204) {
        throw res;
      }
    },
    [client],
  );

  const submitPreWorkQuestionnaire: RepositoryFunctions['submitPreWorkQuestionnaire'] = useCallback(
    async (params) => {
      const res = await client.submitPreWorkQuestionnaire({
        params: { serviceId: params.serviceId },
        body: params,
      });

      if (res.status === 200) {
        return { questionnaireId: res.body.preWorkQuestionnaireAttachmentId };
      }

      throw res;
    },
    [client],
  );

  return {
    completeService,
    getService,
    searchServices,
    updateService,
    setBillingStatus,
    getCountByStatus,
    startTimer,
    stopTimer,
    getAllServiceTypes,
    searchServiceTypes,
    cancelService,
    submitPreWorkQuestionnaire,
  };
};
