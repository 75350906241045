import { styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

const StyledMobileDrawer = styled(MuiDrawer)(({ theme: { spacing } }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    maxWidth: '300px',
    gap: spacing(1),
  },
}));

export default StyledMobileDrawer;
