import { Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { z } from 'zod';
import { useFormikContext } from 'formik';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { CardStack } from 'targets/web/components/CardStack';
import { TextField } from 'targets/web/components';

export const ExternalServiceDetailsSchema = z.object({
  station: z.string(),
  customer: z.string(),
  service: z.string(),
  aircraft: z.string().optional(),
});

export type ExternalServiceDetailsData = z.infer<typeof ExternalServiceDetailsSchema>;

const ExternalServiceDetails: FC = () => {
  const t = useTranslationPrefix('jobs.service_details_form.external');
  const { values, handleChange } = useFormikContext<ExternalServiceDetailsData>();
  return (
    <CardStack gap={4}>
      <Typography variant="h6">{t('title')}</Typography>

      <Grid container spacing={6}>
        <Grid item md={6}>
          <Stack gap={4}>
            <TextField
              label={t('station_label')}
              name="station"
              value={values.station}
              onChange={handleChange('station')}
              fullWidth
              readOnly={true}
              disabled={true}
            />
            <TextField
              label={t('customer_label')}
              name="customer"
              value={values.customer}
              onChange={handleChange('customer')}
              fullWidth
              readOnly={true}
              disabled={true}
            />
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Stack gap={4}>
            <TextField
              label={t('service_label')}
              name="service"
              value={values.service}
              onChange={handleChange('service')}
              fullWidth
              readOnly={true}
              disabled={true}
            />
            <TextField
              label={t('aircraft_label')}
              name="aircraft"
              value={values.aircraft}
              onChange={handleChange('aircraft')}
              fullWidth
              readOnly={true}
              disabled={true}
            />
          </Stack>
        </Grid>
      </Grid>
    </CardStack>
  );
};

export default ExternalServiceDetails;
