import { Grid } from '@mui/material';
import { FC } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { FormikSelectField, FormikTextField } from 'targets/web/modules/dashboard/components';
import { Countries, StatesOfAmerica } from 'targets/web/modules/dashboard/consts';

export const AddressSection: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const t = useTranslationPrefix('configuration.customer_details.customer_form');

  return (
    <>
      <Grid item xs={6}>
        <FormikTextField
          name="address"
          label={t('address')}
          disabled={disabled}
          required={!disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField name="city" label={t('city')} disabled={disabled} required={!disabled} />
      </Grid>
      <Grid item xs={4}>
        <FormikSelectField
          name="state"
          label={t('state')}
          items={StatesOfAmerica}
          disabled={disabled}
          required={!disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="postalCode"
          label={t('postalCode')}
          disabled={disabled}
          required={!disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikSelectField
          name="country"
          label={t('country')}
          items={Countries}
          disabled={disabled}
          required={!disabled}
        />
      </Grid>
    </>
  );
};
