import { FC } from 'react';
import { Route, Routes } from 'react-router';

import { ApprovalsView } from 'targets/web/modules/approvals/views/Approvals.view';
import { PageNotFoundView } from 'targets/web/modules/dashboard/views';
import { ConfirmJobView } from 'targets/web/modules/approvals/views/ConfirmJob.view';

import { approvalsRoutesConfig } from './approvalsRoutesConfig';

export const ApprovalsRoutes: FC = () => (
  <Routes>
    <Route index element={<ApprovalsView />} />
    <Route path={approvalsRoutesConfig.internalRoutes.confirmJob} element={<ConfirmJobView />} />
    <Route
      path={approvalsRoutesConfig.internalRoutes.confirmJobRequest}
      element={<ConfirmJobView />}
    />

    <Route path="*" element={<PageNotFoundView />} />
  </Routes>
);
