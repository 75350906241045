import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';

export const Wrapper = styled(Card)(() => ({
  height: '100%',
  position: 'relative',
}));

export const DraggableIconWrapper = styled('div')(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
  position: 'absolute',
  left: theme.spacing(4),
  top: theme.spacing(4),
  cursor: 'move',
  zIndex: '2',
  color: '#fff',
}));
