import { PopulatedServicePrice, ServicePrice } from 'api/domain/entities/ServicePrice';
import {
  PopulatedPriceListAssociation,
  PriceListAssociation,
} from 'api/domain/entities/PriceListAssociation';
import Pagination from 'common/types/Pagination';
import SortingOptions from 'common/types/SortingOptions';
import { PriceList, PriceListSummary } from 'api/domain/entities/PriceList';

import { Entity } from 'domain/types/Entity';
import { ID } from 'domain/types/ID';
import { InjectableHook } from 'domain/types/InjectableHook';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { ServiceType } from 'domain/entities/ServiceType';

export type SearchPricesParams = Partial<
  Pagination &
    SortingOptions & {
      phrase: string;
      aircraftTypeId: ID;
      serviceTypeId: ID;
    }
> & { priceListId: ID };

export type SearchAssociationsParams = Partial<
  Pagination &
    SortingOptions & {
      phrase: string;
      priceListId: ID;
      stationId: ID;
      customerId: ID;
    }
>;

export type SearchPriceListsParams = Partial<
  Pagination &
    SortingOptions & {
      phrase?: string;
    }
>;

export interface GetMatchedServiceTypesParams {
  customerId: ID;
  stationId: ID;
  aircraftTypeId: ID;
}

export type MatchedServicePrice = Entity<PriceList> & {
  servicePrices: Entity<ServicePrice & { serviceType: Entity<ServiceType> }>[];
};

export type EditPriceListAssociationRequestParams = PriceListAssociation & { associationId: ID };

export const PricesRepository = Symbol('PricesRepository');

export type PricesRepository = InjectableHook<{
  searchPriceLists(
    params: SearchPriceListsParams,
  ): Promise<PaginatedResult<Entity<PriceListSummary>>>;
  getMatchedServicePrices(params: GetMatchedServiceTypesParams): Promise<MatchedServicePrice[]>;
  getPriceList(id: ID): Promise<Entity<PriceList> | null>;
  getServicePriceByService(id: ID): Promise<Entity<PopulatedServicePrice> | null>;
  searchPrices(params: SearchPricesParams): Promise<PaginatedResult<Entity<PopulatedServicePrice>>>;
  createAssociation(params: PriceListAssociation): Promise<void>;
  editAssociation(params: EditPriceListAssociationRequestParams): Promise<void>;
  searchAssociations(
    params: SearchAssociationsParams,
  ): Promise<PaginatedResult<Entity<PopulatedPriceListAssociation>>>;
}>;
