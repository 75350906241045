import { IconButton, Stack, Theme, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router';
import { useState } from 'react';

import { ID } from 'domain/types/ID';
import { ManageAssociations } from 'domain/types/Roles';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import { useCaseGetPriceList } from 'application/prices/useCases/useCaseGetPriceList';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Breadcrumbs, Tabs } from 'targets/web/components';
import { OfflinePlaceholder, PageTitle } from 'targets/web/modules/dashboard/components';
import {
  AddAssociationButton,
  PriceListAssociations,
  SpecificPriceListTable,
} from 'targets/web/modules/configuration/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export const PriceListView = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const t = useTranslationPrefix('configuration.price_list_details');
  const { isOffline } = useNetworkStatus();

  const { priceListId } = useParams<{ priceListId: ID }>();
  const { data: priceList } = useCaseGetPriceList(priceListId);
  const [tab, setTab] = useState<'price_list' | 'associations'>('price_list');
  const { hasRole } = useCaseCheckAccess();
  const canManageAssociations = hasRole(ManageAssociations);

  if (!priceListId || !priceList) {
    if (isOffline) {
      return <OfflinePlaceholder />;
    }

    return null;
  }

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <IconButton onClick={() => navigate('/configuration/price-lists')} color="primary">
          <ArrowBackIcon />
        </IconButton>
        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: t('breadcrumbs.configuration'),
              },
              {
                label: t('breadcrumbs.price_lists'),
                url: '/configuration/price-lists',
              },
            ]}
          />

          <PageTitle title={priceList?.name ?? ''} />
        </Stack>
        {canManageAssociations && tab === 'associations' && !isOffline && (
          <AddAssociationButton priceListId={priceListId} />
        )}
      </Stack>

      <Stack width={1} gap={4}>
        <Tabs
          variant={isMobile ? 'scrollable' : 'standard'}
          onChange={(_, val) => setTab(val)}
          value={tab}
          items={[
            {
              label: 'Price List',
              value: 'price_list',
            },
            {
              label: 'Associations',
              value: 'associations',
            },
          ]}
        />
        {tab === 'price_list' && <SpecificPriceListTable priceListId={priceListId} />}
        {tab === 'associations' && <PriceListAssociations priceListId={priceListId} />}
      </Stack>
    </>
  );
};
