import { useCallback } from 'react';
import contract from 'api/infrastructure/contract/priceLists.route';

import { MatchedServicePrice, PricesRepository } from 'domain/repositories/IPricesRepository';
import useTsRestClient from 'infrastructure/providers/useTsRestClient';

type RepositoryFunctions = ReturnType<PricesRepository>;

export const useTsRestPricesRepository: PricesRepository = () => {
  const client = useTsRestClient(contract);

  const getPriceList: RepositoryFunctions['getPriceList'] = useCallback(
    async (params) => {
      const result = await client.getPriceList({
        params: {
          priceListId: params,
        },
      });
      if (result.status !== 200) {
        throw new Error("Couldn't fetch price list");
      }
      return result.body;
    },
    [client],
  );

  const getMatchedServicePrices: RepositoryFunctions['getMatchedServicePrices'] = useCallback(
    async (params) => {
      const res = await client.getMatchedServicePrices({
        query: params,
      });

      if (res.status === 200) {
        return res.body.matched as MatchedServicePrice[];
      }

      throw res;
    },
    [client],
  );

  const getServicePriceByService: RepositoryFunctions['getServicePriceByService'] = useCallback(
    async (serviceId) => {
      const result = await client.getServicePriceByService({
        params: { serviceId },
      });
      if (result.status !== 200) {
        throw result;
      }
      return result.body;
    },
    [client],
  );

  const searchPriceLists: RepositoryFunctions['searchPriceLists'] = useCallback(
    async (params) => {
      const result = await client.searchPriceLists({
        query: {
          phrase: params.phrase,
          sortBy: params.sortBy,
          direction: params.direction,
          offset: params.offset,
          limit: params.limit,
        },
      });
      if (result.status === 200) {
        return result.body;
      }
      throw new Error("Couldn't fetch price lists");
    },
    [client],
  );

  const searchPrices: RepositoryFunctions['searchPrices'] = useCallback(
    async (params) => {
      const result = await client.searchServicePrices({
        params: {
          priceListId: params.priceListId,
        },
        query: {
          phrase: params.phrase,
          aircraftTypeId: params.aircraftTypeId,
          serviceTypeId: params.serviceTypeId,
          sortBy: params.sortBy,
          direction: params.direction,
          offset: params.offset,
          limit: params.limit,
        },
      });
      if (result.status === 200) {
        return result.body;
      }
      throw new Error("Couldn't fetch prices");
    },
    [client],
  );

  const createAssociation: RepositoryFunctions['createAssociation'] = useCallback(
    async (params) => {
      const result = await client.createAssociation({
        body: params,
      });
      if (result.status !== 201) {
        throw new Error('Unable to create association');
      }
    },
    [client],
  );

  const editAssociation: RepositoryFunctions['editAssociation'] = useCallback(
    async ({ associationId, ...rest }) => {
      const result = await client.editAssociation({
        body: rest,
        params: {
          associationId,
        },
      });
      if (result.status !== 200) {
        throw new Error('Unable to edit association');
      }
    },
    [client],
  );

  const searchAssociations: RepositoryFunctions['searchAssociations'] = useCallback(
    async (params) => {
      const result = await client.getAssociations({
        query: {
          phrase: params.phrase,
          priceListId: params.priceListId,
          stationId: params.stationId,
          customerId: params.customerId,
          sortBy:
            params.sortBy && ['priceList', 'customer', 'station'].includes(params.sortBy)
              ? `${params.sortBy}.name`
              : params.sortBy,
          direction: params.direction,
          offset: params.offset,
          limit: params.limit,
        },
      });

      if (result.status === 200) {
        return result.body;
      }

      throw new Error("Couldn't fetch associations");
    },
    [client],
  );

  return {
    searchPriceLists,
    getMatchedServicePrices,
    getPriceList,
    getServicePriceByService,
    searchAssociations,
    searchPrices,
    createAssociation,
    editAssociation,
  };
};
