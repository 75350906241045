import { IRepositoryAvailabilityHook } from 'domain/repositories/IRepositoryAvailabilityHook';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

const useBrowserRepositoryAvailability: IRepositoryAvailabilityHook = () => {
  const { isOffline } = useNetworkStatus();

  return {
    isAvailable: !isOffline,
  };
};

export default useBrowserRepositoryAvailability;
