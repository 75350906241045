import { FC, useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import z from 'zod';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Theme, useMediaQuery } from '@mui/material';

import { Service } from 'domain/entities/Service';
import { useCaseUpdateService } from 'application/services/useCases/useCaseUpdateService';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Modal } from 'targets/web/components';

import { EmployeeForm } from './EmployeeForm';

export const ResumeServiceFormSchema = z.object({
  employeeCount: z.number().gte(0),
});

export type ResumeServiceFormData = z.infer<typeof ResumeServiceFormSchema>;

export interface ResumeServiceDialogProps {
  onSubmit: (values: ResumeServiceFormData) => void | Promise<void>;
  onClose: () => void;
  open: boolean;
  service: Pick<Service, 'id' | 'employeeCount'>;
}

export const ResumeServiceDialog: FC<ResumeServiceDialogProps> = ({
  onSubmit,
  onClose,
  open,
  service,
}) => {
  const t = useTranslationPrefix('jobs.resume_service_dialog');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { updateService } = useCaseUpdateService();

  const initialValues: ResumeServiceFormData = useMemo(
    () => ({
      employeeCount: service?.employeeCount ?? 0,
    }),
    [service?.employeeCount],
  );

  const handleSubmit = useCallback(
    async (values: ResumeServiceFormData) => {
      await updateService({
        serviceId: service.id,
        data: {
          employeeCount: values.employeeCount,
        },
      });

      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit, service.id, updateService],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
      validationSchema={toFormikValidationSchema(ResumeServiceFormSchema)}
    >
      {({ isValid, isSubmitting, submitForm }) => (
        <Form>
          <Modal
            disableBackdropClick
            onClose={onClose}
            open={open}
            title={t('title')}
            maxWidth="sm"
            fullWidth
            fullScreen={isMobile}
            onClick={(e) => e.stopPropagation()}
            content={<EmployeeForm />}
            actions={{
              primary: {
                size: 'large',
                type: 'submit',
                startIcon: <PlayArrowIcon />,
                text: t('buttons.resume'),
                disabled: !isValid || isSubmitting,
                onClick: submitForm,
                loading: isSubmitting,
              },
              secondary: {
                size: 'large',
                text: t('buttons.cancel'),
              },
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
