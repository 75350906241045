import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import CheckIcon from '@mui/icons-material/Check';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { ToggleButton } from 'targets/web/components';

export interface QuestionnaireBoolQuestionProps {
  value: boolean;
  onChange: (value: boolean) => void;
  title: string;
  displayConfirmation?: boolean;
  readOnly?: boolean;
}

export const QuestionnaireBoolQuestion: FC<QuestionnaireBoolQuestionProps> = ({
  title,
  value,
  onChange,
  displayConfirmation,
  readOnly,
}) => {
  const t = useTranslationPrefix('global');

  return (
    <Stack
      direction="row"
      sx={{ borderRadius: 2, backgroundColor: 'background.neutral', padding: 4 }}
      alignItems="center"
      gap={4}
    >
      <Typography variant="labelLarge" flex={1}>
        {title}
      </Typography>
      <ToggleButton
        color="primary"
        value={String(value)}
        onClick={({ value }) => !readOnly && onChange(value === 'true')}
        options={[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'N/A' },
        ]}
      />
      {displayConfirmation && value && (
        <Stack direction="row" gap={2}>
          <CheckIcon sx={{ color: 'success.main' }} />
          <Typography sx={{ color: 'success.main' }}>{t('yes')}</Typography>
        </Stack>
      )}
    </Stack>
  );
};
