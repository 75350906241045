import { FC, useCallback, useMemo, useState } from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { BillingItem } from 'domain/entities/BillingItem';
import { useCaseEditCustomer } from 'application/customers/useCases/useCaseEditCustomer';
import { useCaseGetCustomer } from 'application/customers/useCases/useCaseGetCustomer';
import { useCaseConfirmCustomer } from 'application/customers/useCases/useCaseConfirmCustomer';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';
import { CreateCustomerModal } from 'targets/web/modules/configuration/components/CreateCustomerModal';
import { mapCustomerToCustomerData } from 'targets/web/modules/configuration/utils/mapCustomerToCustomerData';
import { Button, Modal } from 'targets/web/components';
import { CustomerData } from 'targets/web/modules/configuration/components';
import { CustomerModalType } from 'targets/web/modules/configuration/components/CreateCustomerModal/CreateCustomerModal';

interface ConfirmCustomerFlow {
  billingItem: BillingItem | null;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmCustomerFlow: FC<ConfirmCustomerFlow> = ({
  billingItem,
  open,
  onConfirm,
  onClose,
}) => {
  const t = useTranslationPrefix('billing.confirm_customer_flow');
  const tGlobal = useTranslationPrefix('global');
  const notify = useSnackbar();

  const { editCustomer } = useCaseEditCustomer();
  const { confirmCustomer } = useCaseConfirmCustomer();
  const { data: customer } = useCaseGetCustomer({ customerId: billingItem?.customer.id });

  const [state, setState] = useState<'editCustomer' | 'confirmCustomer'>('editCustomer');
  const [editedCustomerValues, setEditedCustomerValues] = useState<CustomerData | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isOpen = useMemo(() => open && !!billingItem && !!customer, [billingItem, customer, open]);

  const handleClose = useCallback(() => {
    setState('editCustomer');
    setEditedCustomerValues(null);
    onClose();
  }, [onClose]);

  const handleConfirmCustomerModalClose = useCallback(
    (reason: 'cancel' | 'submit') => {
      if (reason === 'cancel') {
        handleClose();
      }
    },
    [handleClose],
  );

  const handleConfirmModal = useCallback(() => {
    if (!editedCustomerValues || !billingItem) return;
    setIsSubmitting(true);
    editCustomer({
      ...editedCustomerValues,
      id: billingItem.customer.id,
      ...(editedCustomerValues.mailingAddressSameAsPhysical
        ? {
            mailingAddress: {
              address: editedCustomerValues.address,
              city: editedCustomerValues.city,
              state: editedCustomerValues.state,
              postalCode: editedCustomerValues.postalCode,
              country: editedCustomerValues.country,
            },
          }
        : {}),
    })
      .then(
        () => confirmCustomer({ id: billingItem.customer.id }),
        () => {
          notify(t('snackbar.edit_error'), {
            variant: 'error',
          });
        },
      )
      .then(
        () => {
          notify(t('snackbar.confirm_success'), {
            variant: 'success',
          });

          onConfirm();
        },
        () => {
          notify(t('snackbar.confirm_error'), {
            variant: 'error',
          });
        },
      )
      .finally(() => setIsSubmitting(false));
  }, [billingItem, confirmCustomer, editCustomer, editedCustomerValues, notify, onConfirm, t]);

  return (
    <>
      <CreateCustomerModal
        values={(editedCustomerValues as CustomerData) ?? mapCustomerToCustomerData(customer)}
        open={isOpen && state === 'editCustomer'}
        primaryButtonLabel={tGlobal('next')}
        type={CustomerModalType.Confirm}
        title={
          <Stack gap={2} direction="row" alignItems="center">
            <Typography variant="h5">{t('edit_customer_title')}</Typography>

            <Chip label={t('edit_customer_chip')} size="small" color="success" />
          </Stack>
        }
        onSubmit={async (values) => {
          if (!billingItem) return;

          setEditedCustomerValues(values);
          setState('confirmCustomer');
        }}
        onClose={handleConfirmCustomerModalClose}
      />
      <Modal
        disableBackdropClick
        onClose={onClose}
        open={isOpen && state === 'confirmCustomer'}
        maxWidth={false}
        title={t('confirm_customer_title')}
        content={
          <Box maxWidth={430}>
            <Typography color="text.secondary">{t('confirm_customer_description')}</Typography>
          </Box>
        }
        actions={
          <Stack width={1} direction="row" alignItems="center" justifyContent="space-between">
            <Button color="secondary" variant="text" onClick={handleClose}>
              {tGlobal('cancel')}
            </Button>

            <Stack gap={2} direction="row" alignItems="center">
              <Button color="secondary" variant="outlined" onClick={() => setState('editCustomer')}>
                {tGlobal('back')}
              </Button>

              <LoadingButton
                type="submit"
                variant="contained"
                onClick={handleConfirmModal}
                loading={isSubmitting}
              >
                {tGlobal('confirm')}
              </LoadingButton>
            </Stack>
          </Stack>
        }
      />
    </>
  );
};
