import { Box, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { DashboardNavigation, DashboardToolbar } from 'targets/web/modules/dashboard/components';

import { ErrorView } from './Error.view';

export const DashboardView = () => (
  <Box height="100%" minHeight="100vh" width="100%" display="flex">
    <DashboardNavigation />

    <Stack flex="1" overflow="hidden" sx={{ paddingTop: 16 }}>
      <DashboardToolbar />

      <Stack
        gap={4}
        sx={{
          flex: 1,
          alignItems: 'center',
          width: 1,
          overflowX: 'auto',
          overflowY: 'hidden',
          padding: 4,
          backgroundColor: 'background.neutral',
          // Limit the content width on wider screens
          '& > *': {
            maxWidth: {
              xs: 1,
              xl: 0.85,
              xxl: 0.7,
              xxxl: 0.55,
              x4k: 0.4,
            },
          },
        }}
      >
        <Sentry.ErrorBoundary
          fallback={({ resetError }) => <ErrorView onGoBack={resetError} />}
          showDialog
        >
          <Outlet />
        </Sentry.ErrorBoundary>
      </Stack>
    </Stack>
  </Box>
);

export default DashboardView;
