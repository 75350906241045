import { FC, useState } from 'react';
import { Entity } from 'api/domain/types';

import { ID } from 'domain/types/ID';
import { ManageAssociations } from 'domain/types/Roles';
import { PopulatedPriceListAssociation } from 'domain/entities/PopulatedPriceListAssociation';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import { useCaseSearchAssociations } from 'application/prices/useCases/useCaseSearchAssociations';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { TableColumn } from 'targets/web/components';
import { TableWithSearch } from 'targets/web/components/TableWithSearch';

import EditAssociationButton from './EditAssociationButton';

export const PriceListAssociations: FC<{ priceListId: ID }> = ({ priceListId }) => {
  const t = useTranslationPrefix('configuration.price_list_details.associations_table');
  const { hasRole } = useCaseCheckAccess();
  const canManageAssociations = hasRole(ManageAssociations);

  const [phrase, setPhrase] = useState('');
  const {
    items = [],
    total,
    isLoading,
    isFetching,

    paging,
    changePage,
    changePageSize,

    sorting,
    changeSort,
  } = useCaseSearchAssociations({
    priceListId,
    phrase: phrase.length < 2 ? undefined : phrase,
  });

  const columns: TableColumn<Entity<PopulatedPriceListAssociation>>[] = [
    {
      headerName: t('price_list_name_column'),
      field: 'priceList',
      sortable: true,
      renderCell: ({ priceList }) => priceList.name,
    },
    {
      headerName: t('station_column'),
      field: 'station',
      sortable: true,
      renderCell: ({ station }) => station?.name ?? t('all_stations'),
    },
    {
      headerName: t('customer_column'),
      field: 'customer',
      sortable: true,
      renderCell: ({ customer, station }) => station !== null && customer?.name,
    },
    {
      type: 'actions',
      width: 64,
      sortable: false,
      renderCell: (association) =>
        canManageAssociations && (
          <EditAssociationButton priceListId={priceListId} association={association} />
        ),
    },
  ];

  return (
    <TableWithSearch
      columns={columns}
      searchPhrasePlaceholder={t('search_placeholder')}
      rowsPerPageOptions={[10, 50, 100]}
      rows={items}
      count={total}
      orderBy={sorting.sortBy}
      orderDirection={sorting.direction}
      initialPage={Math.round(paging.offset / paging.limit)}
      isLoading={isLoading || isFetching}
      onSearchPhraseChange={setPhrase}
      onRowsPerPageChange={changePageSize}
      onPageChange={changePage}
      onSortChange={changeSort}
    />
  );
};
