import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { CustomerRepository } from 'domain/repositories/ICustomerRepository';
import { ID } from 'domain/types/ID';
import { useHookInjection } from 'application/hooks';

const QUERY_PREFIX = 'FETCH_JOB';

export const useCaseGetCustomer = (params: { customerId?: string }) => {
  const qClient = useQueryClient();
  const { getCustomer } = useHookInjection<CustomerRepository>(CustomerRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_PREFIX, params] as const,
    queryFn: async ({ queryKey }) =>
      queryKey[1].customerId ? getCustomer({ id: ID(queryKey[1].customerId) }) : null,
    enabled: !!params.customerId,
    staleTime: 10_000,
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};
