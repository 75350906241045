import { useCallback } from 'react';
import { del, get, set } from 'idb-keyval';

export const usePersistedValues = (name: string) => {
  const setPersistedValues = useCallback(<T>(value: T) => set(name, value), [name]);
  const getPersistedValues = useCallback(() => get(name), [name]);
  const delPersistedValues = useCallback(() => del(name), [name]);

  return {
    setPersistedValues,
    getPersistedValues,
    delPersistedValues,
  };
};
