import { FC, ReactNode } from 'react';
import { ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

import Button from 'targets/web/components/Button';
import IconButton from 'targets/web/components/Button';

export type ToggleButtonOption = {
  value: string | number;
  disabled?: boolean;
} & ({ label: string | number; icon?: never } | { label?: never; icon: ReactNode }); // Either label or icon

export interface ToggleButtonProps extends Omit<ToggleButtonGroupProps, 'onClick' | 'children'> {
  /**
   * Array of options to show in the `ToggleButton` element.
   *
   * **Note:** either `options` or `children` needs to be provided.
   */
  options?: ToggleButtonOption[];
  /**
   * Callback fired when one of the options is clicked.
   *
   * @param {ToggleButtonOption} option The clicked option.
   */
  onClick?: (option: ToggleButtonOption) => void;

  btnSx?: ToggleButtonGroupProps['sx'];
}

export const ToggleButton: FC<ToggleButtonProps> = ({
  options,
  onClick,
  value,
  btnSx,
  ...props
}) => {
  const handleOnClick = (option: ToggleButtonOption) => onClick?.(option);

  return (
    <ToggleButtonGroup exclusive={!Array.isArray(value)} value={value} {...props}>
      {options?.map((option, index) => {
        const isSelected =
          (Array.isArray(value) && value.includes(option.value)) || option.value === value;

        const ButtonElement = option.icon ? IconButton : Button;

        return (
          <ButtonElement
            key={index}
            variant={isSelected ? 'contained' : 'text'}
            color={
              isSelected ? (props.color === 'standard' ? 'primary' : props.color) : 'secondary'
            }
            sx={{ minWidth: option.icon ? 'auto' : undefined, ...{ ...btnSx } }}
            value={option.value}
            onClick={() => handleOnClick(option)}
            disabled={option.disabled}
          >
            {option.icon}
            {option.label}
          </ButtonElement>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default ToggleButton;
