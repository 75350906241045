import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { Typography } from '@mui/material';

import { ID } from 'domain/types/ID';
import { Entity } from 'domain/types/Entity';
import { Customer } from 'domain/entities/Customer';
import { useCaseSearchCustomer } from 'application/customers/useCases/useCaseSearchCustomer';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { Autocomplete, TextField } from 'targets/web/components';

export const CustomerAutocomplete: FC<{ onChange: (id: ID[] | undefined) => void }> = ({
  onChange,
}) => {
  const t = useTranslationPrefix('components.filters_container');

  const [selectedValues, setSelectedValues] = useState<Entity<Customer>[]>([]);
  const [customerPhrase, setCustomerPhrase] = useState('');
  const { items: customers = [], isLoading } = useCaseSearchCustomer({ phrase: customerPhrase });

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setCustomerPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<Entity<Customer>, true, false, false>
      multiple
      label={t('customer_label')}
      loading={isLoading}
      options={customers}
      value={selectedValues}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      disableCloseOnSelect
      onInputChange={handleInputChange}
      onChange={(_, value) => {
        setSelectedValues(value);
        onChange(value && value.length > 0 ? value.map((s) => ID(s.id)) : undefined);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('customer_label')}
          placeholder={
            selectedValues.length === 0
              ? params.inputProps['aria-expanded']
                ? t('select_items')
                : t('all')
              : undefined
          }
        />
      )}
      renderTags={(value) => <Typography>{value.map((v) => v.name).join(', ')}</Typography>}
    />
  );
};
