import React, { useEffect, useContext, PropsWithChildren, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopContext = React.createContext<() => void>(() => {});

export const ScrollToTopProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();

  const scrollToTop = useCallback(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    scrollToTop();
  }, [pathname, scrollToTop]);

  return <ScrollToTopContext.Provider value={scrollToTop}>{children}</ScrollToTopContext.Provider>;
};

export const useScrollToTop = () => {
  return useContext(ScrollToTopContext);
};
