import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { ServiceRepository } from 'domain/repositories/IServiceRepository';
import { ID } from 'domain/types/ID';
import { useHookInjection } from 'application/hooks';
import { usePrefetchItems } from 'application/common/usePrefetchItems';

const QUERY_PREFIX = 'SERVICE';
const ServiceKey = (serviceId: ID): [string, ID] => [QUERY_PREFIX, serviceId];

export const usePrefetchServices = (servicesToPrefetch: ID[]) => {
  const { getService } = useHookInjection<ServiceRepository>(ServiceRepository);

  usePrefetchItems(
    servicesToPrefetch,
    ({ queryKey }) => queryKey[1] && getService({ serviceId: queryKey[1] }),
    ServiceKey,
  );
};

export const useCaseGetService = (serviceId?: ID) => {
  const qClient = useQueryClient();
  const { getService } = useHookInjection<ServiceRepository>(ServiceRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ServiceKey(serviceId ?? ID('')),
    queryFn: ({ queryKey }) => queryKey[1] && getService({ serviceId: queryKey[1] }),
    enabled: !!serviceId,
    staleTime: 10_000,
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};
