import { IconButton, Stack } from '@mui/material';
import { useMemo } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { format, formatDuration } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';

import { ID } from 'domain/types/ID';
import { isEntityLocked } from 'domain/entities/BillingStatus';
import { useCaseGetService } from 'application/services/useCases/useCaseGetService';
import usePermission from 'application/auth/hooks/usePermission';
import { JobResourceType } from 'application/auth/utils/resources';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Breadcrumbs from 'targets/web/components/Breadcrumbs';
import { PageTitle } from 'targets/web/modules/dashboard/components/PageTitle/PageTitle';
import {
  FooterServiceDetailsPreview,
  ServiceDetailsPreview,
} from 'targets/web/modules/jobs/components';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import {
  mapWorkLogsToDuration,
  utcToLocalTime,
  workLogDateComparator,
} from 'targets/web/modules/jobs/utils';
import { Button } from 'targets/web/components';
import { OfflinePlaceholder } from 'targets/web/modules/dashboard/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export const ServiceDetailsView = () => {
  const navigate = useNavigate();
  const { jobId, serviceId } = useParams();
  const { isOffline } = useNetworkStatus();
  const { data } = useCaseGetService(serviceId ? ID(serviceId) : undefined);
  const { granted: editAccessGranted } = usePermission(
    jobId
      ? {
          resource: `${JobResourceType}:${ID(jobId)}`,
          scope: 'update-service',
        }
      : undefined,
  );

  const tBreadcrumbs = useTranslationPrefix('jobs.breadcrumbs');
  const t = useTranslationPrefix('jobs.service_details');
  const billableLogs = useMemo(
    () => (data?.workLogs ?? []).filter((log) => log.billable).sort(workLogDateComparator),
    [data?.workLogs],
  );
  const nonBillableLogs = useMemo(
    () => (data?.workLogs ?? []).filter((log) => !log.billable).sort(workLogDateComparator),
    [data?.workLogs],
  );

  const billableDuration = useMemo(() => mapWorkLogsToDuration(billableLogs), [billableLogs]);
  const nonBillableDuration = useMemo(
    () => mapWorkLogsToDuration(nonBillableLogs),
    [nonBillableLogs],
  );

  const isServiceEditable = useMemo(
    () => data && !isEntityLocked(data) && !isEntityLocked(data.job) && editAccessGranted,
    [data, editAccessGranted],
  );

  const billingAmount = useMemo(() => (data?.rate ? Number(data.rate) : null), [data?.rate]);

  if (!data) {
    if (isOffline) return <OfflinePlaceholder />;

    return null;
  }

  const billableTime = formatDuration(billableDuration);
  const nonBillableTime = formatDuration(nonBillableDuration);

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <IconButton onClick={() => navigate(`/jobs/${jobId}/summary`)} color="primary">
          <ArrowBackIcon />
        </IconButton>

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('jobs_and_services'),
                url: jobsRoutes.jobs,
              },
              {
                label: tBreadcrumbs('job_details'),
              },
            ]}
          />

          <PageTitle title={t('title')} />
        </Stack>

        {isServiceEditable && (
          <Button
            size="large"
            startIcon={<EditIcon />}
            onClick={() => navigate(`/jobs/${jobId}/services/${serviceId}/edit`)}
          >
            {t('edit_service_button')}
          </Button>
        )}
      </Stack>
      <ServiceDetailsPreview
        data={{
          basic: {
            service: data.serviceType.name,
            scheduled: format(utcToLocalTime(data.scheduledStart) ?? new Date(), 'MMM dd, yyyy'),
            dueDate: format(utcToLocalTime(data.scheduledDue), 'MMM dd, yyyy'),
            recurringType: 'Recurring Type',
            contractType: 'Contract Type',
            serviceLocation: data.job.station.name,
            employeeCount: data.employeeCount ?? 0,
            estimatedTime: data.estimatedTime?.toString() ?? '',
            quantity: data.quantity ?? 1,
          },
          timeTracking: {
            adjustedLoggedTimeBillable: String(data.adjustedLoggedTimeBillable ?? ''),
            adjustedLoggedTimeNonBillable: String(data.adjustedLoggedTimeNonBillable ?? ''),
            realLoggedTimeBillable: billableTime,
            realLoggedTimeNonBillable: nonBillableTime,
          },
          billing: {
            discountPercent: data.discountPercentage,
            priceList: data.priceListName ?? '-',
            type: 'Type',
            amount: billingAmount ?? 0,
          },
          external: {
            station: data.job.station.name,
            customer: data.job.customer.name,
            service: data.serviceType.name,
            aircraftType: data.job.aircraft.code ?? data.job.aircraft.serialNumber ?? '',
          },
          notes: {
            notes: data.notes,
          },
        }}
        footer={<FooterServiceDetailsPreview serviceId={data.id} />}
      />
    </>
  );
};
