import { useAtom } from 'jotai';

import { atomWithToggleAndStorage } from 'infrastructure/utils/atomWithToggleAndStorage';

const darkModeAtom = atomWithToggleAndStorage('isDarkMode', false);

export const useColorMode = () => {
  const [isDarkMode, toggle] = useAtom(darkModeAtom);

  return {
    mode: isDarkMode ? 'dark' : 'light',
    isDarkMode,
    toggle,
  };
};
