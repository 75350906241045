import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { PageNotFoundView } from 'targets/web/modules/dashboard/views';

import AuthorizedRoutes from './AuthorizedRoutes';
import PublicRoutes from './PublicRoutes';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {PublicRoutes}
      {AuthorizedRoutes}
      <Route path="*" element={<PageNotFoundView />} />
    </>,
  ),
);

export const Router = () => <RouterProvider router={router} />;

export default Router;
