import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { PreviewBox } from 'targets/web/components';

import { TasksFormSection } from './TasksFormSection';
import { ToolsUsedFormSection } from './ToolsUsedFormSection';
import { QuestionsFormSection } from './QuestionsFormSection';

export interface PreWorkQuestionnaireFormProps {
  details: {
    job: string;
    service: string;
    customer: string;
    aircraft: string;
  };
}

export const PreWorkQuestionnaireForm: FC<PreWorkQuestionnaireFormProps> = ({ details }) => {
  const t = useTranslationPrefix('jobs.pre_work_questionnaire');

  return (
    <Stack gap={4}>
      <Stack direction="row" gap={4}>
        <PreviewBox
          flex={1}
          size="regular"
          header={t('details.job')}
          subheader={details.job}
          alignItems="start"
        />

        <PreviewBox
          flex={1}
          size="regular"
          header={t('details.service')}
          direction="column"
          alignItems="start"
          spacing={1}
        >
          <Typography noWrap={false} variant="labelMedium">
            {details.service}
          </Typography>
        </PreviewBox>

        <PreviewBox
          flex={1}
          size="regular"
          header={t('details.customer')}
          subheader={details.customer}
          alignItems="start"
        />

        <PreviewBox
          flex={1}
          size="regular"
          header={t('details.aircraft_type')}
          direction="column"
          alignItems="start"
          spacing={1}
        >
          <Typography noWrap={false} variant="labelMedium">
            {details.aircraft}
          </Typography>
        </PreviewBox>
      </Stack>

      <Stack direction="row" gap={4}>
        <Stack gap={2}>
          <Typography variant="h6">{t('tasks.title')}</Typography>
          <TasksFormSection />
        </Stack>

        <Stack gap={2}>
          <Typography variant="h6">{t('tools.label')}</Typography>

          <Stack
            gap={4}
            sx={{
              width: 224,
              borderRadius: 1,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'divider',
              padding: 4,
            }}
          >
            <ToolsUsedFormSection />
          </Stack>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <Typography variant="h6">{t('questions.label')}</Typography>
        <QuestionsFormSection />
      </Stack>
    </Stack>
  );
};
