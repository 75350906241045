import { FC } from 'react';
import { Route, Routes } from 'react-router';

import { ViewCustomersDashboard, ViewPriceLists } from 'domain/types/Roles';
import { configurationRoutes } from 'targets/web/modules/configuration/navigation/configurationRoutes';
import {
  AircraftConfigurationView,
  AircraftTypesView,
  CustomerDetailsView,
  CustomersView,
  PriceListsView,
  PriceListView,
  ServiceTypesView,
  StationDetailsView,
  StationsView,
} from 'targets/web/modules/configuration/views';
import { PageNotFoundView } from 'targets/web/modules/dashboard/views';
import { RoleLimitedRoute } from 'targets/web/routes/RoleLimitedRoute';

const ConfigurationRoutes: FC = () => (
  <Routes>
    <Route
      path={configurationRoutes.internalRoutes.aircraft}
      element={<AircraftConfigurationView />}
    />

    <Route
      path={configurationRoutes.internalRoutes.aircraftTypes}
      element={<AircraftTypesView />}
    />

    <Route
      path={configurationRoutes.internalRoutes.customers}
      element={
        <RoleLimitedRoute role={ViewCustomersDashboard}>
          <CustomersView />
        </RoleLimitedRoute>
      }
    />
    <Route
      path={configurationRoutes.internalRoutes.customerDetails}
      element={<CustomerDetailsView />}
    />

    <Route path={configurationRoutes.internalRoutes.serviceTypes} element={<ServiceTypesView />} />

    <Route path={configurationRoutes.internalRoutes.stations} element={<StationsView />} />
    <Route
      path={configurationRoutes.internalRoutes.stationDetails}
      element={<StationDetailsView />}
    />

    <Route
      path={configurationRoutes.internalRoutes.priceLists}
      element={
        <RoleLimitedRoute role={ViewPriceLists}>
          <PriceListsView />
        </RoleLimitedRoute>
      }
    />
    <Route path={configurationRoutes.internalRoutes.priceListDetails} element={<PriceListView />} />

    <Route path="*" element={<PageNotFoundView />} />
  </Routes>
);

export default ConfigurationRoutes;
