import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router';
import { format, formatDuration } from 'date-fns';
import { Decimal } from 'common/types/Decimal';

import { ID } from 'domain/types/ID';
import { isEntityLocked } from 'domain/entities/BillingStatus';
import { useCaseGetService } from 'application/services/useCases/useCaseGetService';
import { useCaseUpdateService } from 'application/services/useCases/useCaseUpdateService';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import {
  FormikCancelSaveFooter,
  OfflinePlaceholder,
  PageTitle,
  PersistFormikValues,
} from 'targets/web/modules/dashboard/components';
import { ConfirmationModal } from 'targets/web/components/ConfirmationModal';
import Breadcrumbs from 'targets/web/components/Breadcrumbs';
import { ServiceDetailsForm } from 'targets/web/modules/jobs/components/ServiceDetailsForm';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { ServiceDetailsFormData } from 'targets/web/modules/jobs/components/ServiceDetailsForm/ServiceDetailsForm';
import { aggregateWorkLogs } from 'targets/web/modules/jobs/utils';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { usePersistedValues } from 'targets/web/modules/dashboard/hooks/usePersistedValues';

const discountPercentages = [
  { value: 0, name: '0%' },
  { value: 0.05, name: '5%' },
  { value: 0.1, name: '10%' },
  { value: 0.11, name: '11%' },
  { value: 0.12, name: '12%' },
  { value: 0.13, name: '13%' },
  { value: 0.14, name: '14%' },
  { value: 0.15, name: '15%' },
  { value: 0.16, name: '16%' },
  { value: 0.17, name: '17%' },
  { value: 0.18, name: '18%' },
  { value: 0.19, name: '19%' },
  { value: 0.2, name: '20%' },
];

export const ServiceEditView: FC = () => {
  const tBreadcrumbs = useTranslationPrefix('jobs.breadcrumbs');
  const t = useTranslationPrefix('jobs.service_details');
  const navigate = useNavigate();
  const { isOffline } = useNetworkStatus();

  const { jobId, serviceId } = useParams();
  const { data } = useCaseGetService(serviceId ? ID(serviceId) : undefined);
  const { updateService } = useCaseUpdateService();

  const persistFormKey = `edit-service-${jobId}`;
  const { getPersistedValues, delPersistedValues } = usePersistedValues(persistFormKey);

  const handleSubmit = useCallback(
    async (formData: ServiceDetailsFormData) => {
      if (!serviceId) return;

      await updateService({
        data: {
          scheduledStart: new Date(formData.scheduled),
          scheduledDue: new Date(formData.dueDate),
          estimatedTime: formData.estimatedTime,
          quantity: formData.quantity,
          adjustedLoggedTimeBillable: formData.adjustedLoggedTimeBillable,
          adjustedLoggedTimeNonBillable: formData.adjustedLoggedTimeNonBillable,
          notes: formData.notes,
          waitTime: formData.waitTime,
          waitReason: formData.waitTimeReason ?? undefined,
          waitNotes: formData.waitTimeNotes,
          discountPercentage: formData.discountPercentage,
          rate:
            formData.amount !== data?.rate ? (formData.amount as Decimal) ?? undefined : undefined,
          employeeCount: formData.employeeCount,
        },
        serviceId: ID(serviceId),
      });

      navigate(`/jobs/${jobId}/services/${serviceId}/details`);
      delPersistedValues();
    },
    [delPersistedValues, data, jobId, navigate, serviceId, updateService],
  );

  const navigateBack = useCallback(
    () => navigate(`/jobs/${jobId}/services/${serviceId}/details`),
    [jobId, navigate, serviceId],
  );

  const { billable, nonBillable } = useMemo(
    () => aggregateWorkLogs(data?.workLogs ?? []),
    [data?.workLogs],
  );

  useEffect(() => {
    if (data && (isEntityLocked(data) || isEntityLocked(data.job))) {
      navigate(`/jobs/${jobId}/services/${serviceId}/details`);
    }
  }, [data, data?.billingStatus, data?.id, data?.job.billingStatus, jobId, navigate, serviceId]);

  const billingAmount = useMemo(() => (data?.rate ? Number(data.rate) : null), [data?.rate]);

  const [effectiveInitialValues, setEffectiveInitialValues] = useState<ServiceDetailsFormData>();

  useEffect(() => {
    getPersistedValues().then((values) => {
      if (values) {
        setEffectiveInitialValues(values);
      }
    });
  }, [getPersistedValues]);

  useEffect(() => {
    if (effectiveInitialValues) return;
    if (!data) return;

    setEffectiveInitialValues({
      service: data.serviceType.name,
      serviceLocation: data.job.station.name ?? '',
      scheduled: format(data.scheduledStart, 'MMM dd, yyyy'),
      estimatedTime: data.estimatedTime ?? 0,
      quantity: data.quantity ?? 1,
      dueDate: format(data.scheduledDue, 'MMM dd, yyyy'),
      recurringType: 'Monthly',
      employeeCount: data.employeeCount ?? 0,
      contractType: 'Flat Fee (FF)',
      type: '',
      priceList: data.priceListName ?? '-',
      amount: billingAmount?.toFixed(2) ?? '0.00',
      discountPercentage: data.discountPercentage,
      waitTime: data.waitTime ?? 0,
      waitTimeReason: data.waitReason ?? '',
      station: data.job.station.name ?? '',
      customer: data.job.customer.name ?? '',
      aircraft: data.job.aircraft.code ?? data.job.aircraft.serialNumber ?? '',
      waitTimeNotes: data.waitNotes ?? '',
      adjustedLoggedTimeBillable: data.adjustedLoggedTimeBillable ?? undefined,
      adjustedLoggedTimeNonBillable: data.adjustedLoggedTimeNonBillable ?? undefined,
      loggedTimeBillable: formatDuration(billable) || '-',
      loggedTimeNonBillable: formatDuration(nonBillable) || '-',
    });
  }, [billable, billingAmount, data, effectiveInitialValues, nonBillable]);

  if (!data || !effectiveInitialValues) {
    if (isOffline) return <OfflinePlaceholder />;

    return null;
  }
  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <ConfirmationModal
          title={t('back_modal.title')}
          subtitle={t('back_modal.subtitle')}
          confirmText={t('back_modal.confirm')}
          cancelText={t('back_modal.cancel')}
          onConfirm={navigateBack}
          triggerButton={(onClick: () => void) => (
            <IconButton onClick={isOffline ? navigateBack : onClick} color="primary">
              <ArrowBackIcon />
            </IconButton>
          )}
        />

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('jobs_and_services'),
                url: jobsRoutes.jobs,
              },
              {
                label: tBreadcrumbs('job_details'),
              },
            ]}
          />

          <PageTitle title={t('title')} />
        </Stack>
      </Stack>
      <ServiceDetailsForm
        footer={
          <>
            {data && isOffline && <PersistFormikValues persistInvalid name={persistFormKey} />}
            <FormikCancelSaveFooter
              onCancel={() => navigate(`/jobs/${jobId}/services/${serviceId}/details`)}
            />
          </>
        }
        types={[]}
        discountPercentages={discountPercentages}
        quantity={data.quantity ?? 1}
        waitTimeReasons={[]}
        disabled={false}
        initialValues={effectiveInitialValues}
        onSubmit={handleSubmit}
      />
    </>
  );
};
