import { Box, Collapse, Grid, Stack, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { FC, useCallback } from 'react';
import { useAtom } from 'jotai';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';

import { ID } from 'domain/types/ID';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { atomWithToggleAndStorage } from 'infrastructure/utils/atomWithToggleAndStorage';
import { componentShadows } from 'targets/web/theme/shadows';
import { Button, DatePicker } from 'targets/web/components';
import { localTimeToUTC, utcToLocalTime } from 'targets/web/modules/jobs/utils';
import { StationAutocomplete } from 'targets/web/modules/dashboard/components/FiltersContainer/StationAutocomplete';
import { FilterCustomerAutocomplete } from 'targets/web/modules/dashboard/components';

export interface OperationFilters {
  dateStart: string;
  dateEnd: string;
  customerId?: ID;
  stationIds?: ID[];
}

interface FiltersContainerProps {
  initialValues?: OperationFilters;
  onFiltersChange: (values: Partial<OperationFilters>) => void;
}

const filtersCollapsedAtom = atomWithToggleAndStorage('isOperationFilterContainerCollapsed', false);

export const OperationFiltersContainer: FC<FiltersContainerProps> = ({
  initialValues,
  onFiltersChange,
}) => {
  const t = useTranslationPrefix('components.filters_container');

  const [filtersCollapsed, toggleFilters] = useAtom(filtersCollapsedAtom);

  const onClickHandler = useCallback(() => toggleFilters(), [toggleFilters]);

  return (
    <Stack
      width={1}
      sx={{
        padding: 6,
        borderRadius: 1,
        backgroundColor: 'background.default',
        boxShadow: componentShadows.card,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{t('header')}</Typography>
        <Button
          size="small"
          variant="text"
          endIcon={filtersCollapsed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          onClick={onClickHandler}
        >
          {filtersCollapsed ? t('hide_filters') : t('show_filters')}
        </Button>
      </Stack>

      <Collapse in={filtersCollapsed}>
        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <StationAutocomplete
                onChange={(stations) => {
                  onFiltersChange({ stationIds: stations.map((station) => station.id) });
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <FilterCustomerAutocomplete
                label={t('customer_label')}
                onChange={(customerId) => {
                  onFiltersChange({ customerId });
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <DatePicker
                defaultValue={utcToLocalTime(
                  initialValues?.dateStart
                    ? new Date(initialValues.dateStart)
                    : startOfMonth(Date.now()),
                )}
                label={t('date_from_label')}
                format="MMM dd yyyy"
                onChange={(value) =>
                  onFiltersChange({
                    dateStart: localTimeToUTC(startOfDay(value!)).toISOString(),
                  })
                }
                slotProps={{ field: { clearable: false } }}
              />
            </Grid>

            <Grid item xs={3}>
              <DatePicker
                defaultValue={utcToLocalTime(
                  initialValues?.dateEnd ? new Date(initialValues.dateEnd) : endOfMonth(Date.now()),
                )}
                label={t('date_to_label')}
                format="MMM dd yyyy"
                onChange={(value) => {
                  onFiltersChange({ dateEnd: localTimeToUTC(endOfDay(value!)).toISOString() });
                }}
                slotProps={{ field: { clearable: false } }}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Stack>
  );
};
