import { FC, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { CircularProgress, Stack, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button, CardStack } from 'targets/web/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export interface FormikCancelSaveFooterProps {
  disabled?: boolean;
  onCancel: () => void;
  onBack?: () => void;
  submitMode?: 'save' | 'confirm' | 'continue';
  isLoading?: boolean;
}

export const FormikCancelSaveFooter: FC<FormikCancelSaveFooterProps> = ({
  disabled,
  onCancel,
  onBack,
  submitMode = 'save',
  isLoading,
}) => {
  const t = useTranslationPrefix('global');
  const theme = useTheme();
  const { isOffline } = useNetworkStatus();

  const { isValid, isSubmitting } = useFormikContext();

  const isPrimaryButtonDisabled = disabled || !isValid || isSubmitting || isOffline;

  const primaryButtonText = useMemo(() => {
    if (submitMode === 'confirm') {
      return t('confirm');
    }

    if (submitMode === 'continue') {
      return t('continue');
    }

    return t('save');
  }, [submitMode, t]);

  return (
    <CardStack
      justifyContent={onBack ? 'space-between' : 'flex-end'}
      direction="row"
      gap={4}
      sx={{
        width: 1,
        left: 0,
        bottom: 0,
        padding: 4,
        position: 'sticky',
        zIndex: theme.zIndex.appBar,
      }}
    >
      <Button
        disabled={disabled}
        onClick={onCancel}
        variant={onBack ? 'text' : 'outlined'}
        color="secondary"
      >
        {t('cancel')}
      </Button>

      <Stack direction="row" gap={2}>
        {onBack && (
          <Button disabled={disabled} onClick={onBack} variant="outlined" color="secondary">
            {t('back')}
          </Button>
        )}

        <LoadingButton
          disabled={isPrimaryButtonDisabled}
          type="submit"
          startIcon={submitMode === 'confirm' ? <CheckIcon /> : undefined}
          endIcon={isSubmitting ? <CircularProgress size={14} color="inherit" /> : null}
          loading={isLoading || isSubmitting}
          variant="contained"
        >
          {primaryButtonText}
        </LoadingButton>
      </Stack>
    </CardStack>
  );
};

export default FormikCancelSaveFooter;
