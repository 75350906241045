import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { FC, useState } from 'react';
import { Entity, ID } from 'api/domain/types';

import { PopulatedPriceListAssociation } from 'domain/entities/PopulatedPriceListAssociation';
import { useCaseEditAssociation } from 'application/prices/useCases/useCaseEditAssociation';
import { useCaseSearchAssociations } from 'application/prices/useCases/useCaseSearchAssociations';
import { useCaseGetPriceList } from 'application/prices/useCases/useCaseGetPriceList';
import { Modal } from 'targets/web/components';

import AssociationForm, { AssociationFormData } from './AssociationForm';

const EditAssociationButton: FC<{
  priceListId: ID;
  association: Entity<PopulatedPriceListAssociation>;
}> = ({ priceListId, association }) => {
  const { data: priceList } = useCaseGetPriceList(priceListId);
  const { editAssociation } = useCaseEditAssociation();
  const { refetch } = useCaseSearchAssociations();
  const [open, setOpen] = useState<boolean>(false);
  const handleSubmit = async (data: AssociationFormData) => {
    await editAssociation({
      associationId: association.id,
      priceListId,
      stationId: data.station === null ? null : data.station?.id ?? undefined,
      customerId: data.customer?.id ?? undefined,
    });
    refetch();
    setOpen(false);
  };
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <EditIcon />
      </IconButton>
      <Modal
        onClose={() => setOpen(false)}
        open={open}
        fullWidth
        content={
          <AssociationForm
            type="edit"
            priceListName={priceList?.name ?? ''}
            initialValues={{
              station: association.station || undefined,
              customer: association.customer,
            }}
            onSubmit={handleSubmit}
            onCancel={() => setOpen(false)}
          />
        }
      />
    </>
  );
};

export default EditAssociationButton;
