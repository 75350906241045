import { useQuery } from '@tanstack/react-query';

import { SearchStationParams, StationRepository } from 'domain/repositories/IStationRepository';
import { Entity } from 'domain/types/Entity';
import { Station } from 'domain/entities/Station';
import { useSortingAndPagination } from 'application/common';
import { useHookInjection } from 'application/hooks';

export const useCaseSearchStation = (params: SearchStationParams) => {
  const { searchStation } = useHookInjection<StationRepository>(StationRepository);

  const { paging, sorting, ...utils } = useSortingAndPagination<Entity<Station>>('name');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['STATION', { ...params, ...paging, ...sorting }] as const,
    queryFn: ({ queryKey }) => searchStation(queryKey[1]),
    initialData: { items: [], total: 0 },
  });

  return {
    ...data,

    paging,
    sorting,
    ...utils,

    isLoading: isLoading || isFetching,
  };
};
