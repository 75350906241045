import { IconButton, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router';

import { ID } from 'domain/types/ID';
import { useCaseGetStation } from 'application/stations/useCases/useCaseGetStation';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Breadcrumbs, Loader } from 'targets/web/components';
import { OfflinePlaceholder, PageTitle } from 'targets/web/modules/dashboard/components';
import { configurationRoutes } from 'targets/web/modules/configuration/navigation/configurationRoutes';
import { AssociationsTable } from 'targets/web/modules/configuration/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export const StationDetailsView = () => {
  const tBreadcrumbs = useTranslationPrefix('configuration.breadcrumbs');
  const { isOffline } = useNetworkStatus();

  const navigate = useNavigate();
  const { stationId } = useParams<{ stationId: ID }>();

  const { data, isLoading } = useCaseGetStation({ stationId });

  if (isLoading)
    return (
      <Stack height={1} justifyContent="center">
        <Loader />
      </Stack>
    );
  if (!data) {
    if (isOffline) {
      return <OfflinePlaceholder />;
    }

    return null;
  }

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <IconButton onClick={() => navigate('/configuration/stations')} color="primary">
          <ArrowBackIcon />
        </IconButton>

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('configuration'),
                url: configurationRoutes.configuration,
              },
              {
                label: tBreadcrumbs('stations'),
                url: `${configurationRoutes.configuration}/${configurationRoutes.internalRoutes.stations}`,
              },
            ]}
          />

          <PageTitle title={data?.name} />
        </Stack>
      </Stack>

      <Stack width={1}>
        <AssociationsTable stationId={stationId} />
      </Stack>
    </>
  );
};
