import { FC, useState } from 'react';
import { Stack } from '@mui/material';

import { PageTitle } from 'targets/web/modules/dashboard/components/PageTitle/PageTitle';
import { ServiceCalendar } from 'targets/web/modules/schedule/components/ServiceCalendar';
import { ScheduleFiltersContainer } from 'targets/web/modules/schedule/components/ScheduleFiltersContainer';
import { ScheduleFilters } from 'targets/web/modules/schedule/components/ScheduleFiltersContainer/ScheduleFiltersContainer';

export const ScheduleView: FC = () => {
  const [filters, setFilters] = useState<ScheduleFilters>({});

  return (
    <>
      <Stack width={1} spacing={6}>
        <PageTitle title={'Schedule'} />
      </Stack>
      <ScheduleFiltersContainer
        onFiltersChange={(filters) => {
          setFilters((oldFilters) => {
            return { ...oldFilters, ...filters };
          });
        }}
      />
      <ServiceCalendar
        status={filters.status}
        customerIds={filters.customerIds}
        stationIds={filters.stationIds}
      />
    </>
  );
};
