import { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Stack, Theme, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { partition } from 'lodash';
import { add, differenceInSeconds } from 'date-fns';

import { ID } from 'domain/types/ID';
import { WorkLog } from 'domain/entities/Worklog';
import { useCaseGetJobDetails } from 'application/jobs/useCases/useCaseGetJobDetails';
import usePermission from 'application/auth/hooks/usePermission';
import { JobResourceType } from 'application/auth/utils/resources';
import { useCaseCancelJob } from 'application/jobs/useCases/useCaseCancelJob';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { isNonNullish } from 'infrastructure/utils/isNonNullish';
import { Breadcrumbs, Loader, Tabs, Button } from 'targets/web/components';
import { OfflinePlaceholder, PageTitle } from 'targets/web/modules/dashboard/components';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import {
  AttachmentsTable,
  DocumentsTable,
  JobLogCard,
  JobServices,
} from 'targets/web/modules/jobs/components';
import { mapWorkLogsToDuration } from 'targets/web/modules/jobs/utils';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';
import { CancelJobModal } from 'targets/web/modules/jobs/components/CancelJobModal';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { OnboardingJobSummaryModal } from 'targets/web/modules/jobs/components/OnboardingJobSummaryModal';

const emptyWorkLogTimeSummary = {
  billable: 0,
  nonBillable: 0,
};

export const JobSummaryView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useSnackbar();
  const t = useTranslationPrefix('jobs.summary_view');
  const tBreadcrumbs = useTranslationPrefix('jobs.breadcrumbs');
  const tSnackbar = useTranslationPrefix('jobs.job_details.cancel_job_modal.snackbar');
  const isSmallMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(800));
  const hasHistory = location.key !== 'default';
  const backNavigationAllowed =
    hasHistory && location.state && !location.state.from.includes('/jobs');

  const { isOffline } = useNetworkStatus();

  const { jobId = '' } = useParams();

  const [tab, setTab] = useState<'overview' | 'services' | 'attachments' | 'documents'>(
    isSmallMobile ? 'overview' : 'services',
  );

  const { data: job, isLoading } = useCaseGetJobDetails({ id: jobId });

  const { cancelJob } = useCaseCancelJob();

  const { granted: attachmentsAccess } = usePermission(
    jobId
      ? {
          resource: `${JobResourceType}:${ID(jobId)}`,
          scope: 'view-attachments',
        }
      : undefined,
  );

  const totalServices = useMemo(
    () =>
      job?.services.reduce((acc, service) => acc + (service.status === 'completed' ? 1 : 0), 0) ??
      0,
    [job],
  );

  const workLogTimeSummary = useMemo(
    () =>
      job?.services.reduce(
        (summary, service) => {
          const [billableLogs, nonBillableLogs] = partition(service.workLogs, ['billable', true]);

          const calculateAdjustedTime = (
            adjustedLoggedTime: number | undefined,
            logs: WorkLog[],
          ) => {
            if (typeof adjustedLoggedTime === 'number') {
              return adjustedLoggedTime;
            } else {
              return Math.abs(
                differenceInSeconds(0, add(0, mapWorkLogsToDuration(logs)), {
                  roundingMethod: 'floor',
                }),
              );
            }
          };

          summary.billable += calculateAdjustedTime(
            service.adjustedLoggedTimeBillable,
            billableLogs,
          );
          summary.nonBillable += calculateAdjustedTime(
            service.adjustedLoggedTimeNonBillable,
            nonBillableLogs,
          );

          return summary;
        },
        { ...emptyWorkLogTimeSummary },
      ) ?? emptyWorkLogTimeSummary,
    [job?.services],
  );

  useEffect(() => {
    if (!isSmallMobile && tab === 'overview') {
      setTab('services');
    }
  }, [isSmallMobile, tab]);

  useEffect(() => {
    if (job?.status === 'submitted') {
      navigate(`/approvals/${job.id}/confirm/job`);
    }
  }, [job, navigate]);

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [onboardingModalVisible, setOnboardingModalVisible] = useState(false);

  const tabsOptions = useMemo(
    () =>
      [
        isSmallMobile
          ? {
              label: 'Overview',
              value: 'overview',
            }
          : null,
        {
          label: 'Services',
          value: 'services',
        },
        ...(attachmentsAccess || isOffline
          ? [
              {
                label: 'Attachments',
                value: 'attachments',
              },
              {
                label: 'Documents',
                value: 'documents',
              },
            ]
          : []),
      ].filter(isNonNullish),
    [isSmallMobile, attachmentsAccess, isOffline],
  );

  if (isLoading && !isOffline) {
    return (
      <Stack height={1} justifyContent="center">
        <Loader />
      </Stack>
    );
  }

  if (!job) {
    if (isOffline) return <OfflinePlaceholder />;

    return null;
  }

  const JobLogCardElement = (
    <JobLogCard
      totalServices={job.services.length}
      completedServices={totalServices}
      workLogTimeSummary={workLogTimeSummary}
      isPreviewDetailsVisible
      onViewDetailsClick={() => navigate(`/jobs/${job.id}/details`)}
      onCancelClick={
        job.billingStatus !== 'sent_to_sage' && !isOffline
          ? () => setCancelModalOpen(true)
          : undefined
      }
      {...job}
    />
  );

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <IconButton
          onClick={() => (backNavigationAllowed ? navigate(-1) : navigate('/jobs'))}
          color="primary"
        >
          <ArrowBackIcon />
        </IconButton>

        <Stack alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('jobs_and_services'),
                url: jobsRoutes.jobs,
              },
              {
                label: tBreadcrumbs('job_details'),
              },
            ]}
          />

          <PageTitle title={t('title', { job: job.name })} />
        </Stack>

        <Stack>
          <Button size="large" variant="outlined" onClick={() => setOnboardingModalVisible(true)}>
            <QuestionMarkIcon />
          </Button>
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="flex-start" width="100%">
        <Tabs items={tabsOptions} value={tab} onChange={(_, value) => setTab(value)} />
      </Stack>

      <Stack direction="row" alignItems="flex-start" width={1} height={1} gap={4}>
        {!isSmallMobile && <Box maxWidth={{ xs: 270, md: 360 }}>{JobLogCardElement}</Box>}

        <Stack width={1}>
          {tab === 'overview' && (
            <Stack width={1} height={1} alignItems="center">
              {JobLogCardElement}
            </Stack>
          )}
          {tab === 'services' && <JobServices jobId={job.id} />}
          {tab === 'attachments' && <AttachmentsTable jobId={job.id} />}
          {tab === 'documents' && <DocumentsTable jobId={job.id} />}
        </Stack>

        <CancelJobModal
          type={'job'}
          open={cancelModalOpen}
          onClose={() => setCancelModalOpen(false)}
          onSubmit={async (values) => {
            cancelJob({
              jobId: ID(jobId),
              cancel: {
                reason: values.reason,
                notes: values.reason === 'other' ? values.notes : '',
              },
            }).then(
              () => {
                notify(tSnackbar('cancel_success'), {
                  variant: 'secondary',
                });
              },
              () => {
                notify(tSnackbar('cancel_error'), {
                  variant: 'error',
                });
              },
            );
          }}
        />
      </Stack>

      <OnboardingJobSummaryModal
        open={onboardingModalVisible}
        onClose={() => setOnboardingModalVisible(false)}
      />
    </>
  );
};
