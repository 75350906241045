import { atom, useAtom } from 'jotai';

import { JobRequestStatus } from 'domain/entities/JobRequestStatus';
import { Filters } from 'targets/web/modules/dashboard/components/FiltersContainer/FiltersContainer';

const filtersAtom = atom<Partial<Filters>>({
  dateStart: undefined,
  dateEnd: undefined,
});

const jobRequestStatusTabAtom = atom<JobRequestStatus>('created');

export const useAcceptDeclineViewFilters = () => {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [statusTab, setStatusTab] = useAtom(jobRequestStatusTabAtom);

  return {
    filters,
    setFilters,
    statusTab,
    setStatusTab,
  };
};
