import { Stack, Typography } from '@mui/material';
import { FC, useEffect, useRef } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { theme } from 'targets/web/theme/theme';
import { useSnackbar } from 'targets/web/modules/dashboard/hooks/useSnackbar';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export const OfflineModeBanner: FC = () => {
  const notify = useSnackbar();
  const t = useTranslationPrefix('components.offlineMode');

  const { isOffline } = useNetworkStatus();
  const prevStatus = useRef(isOffline);

  useEffect(() => {
    if (isOffline === prevStatus.current) return;

    if (isOffline) {
      notify(t('snackbar.connection_lost'), {
        variant: 'secondary',
        preventDuplicate: true,
      });
    } else {
      notify(t('snackbar.connection_restored'), {
        variant: 'success',
        preventDuplicate: true,
      });
    }

    prevStatus.current = isOffline;
  }, [isOffline, notify, t]);

  return isOffline ? (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
        paddingBlock: 0.5,
        paddingInline: 4,
      }}
    >
      <Typography variant="caption" color="info.contrastText">
        {t('banner')}
      </Typography>
    </Stack>
  ) : undefined;
};
