import z from 'zod';

import { stringLimited } from 'infrastructure/utils/stringLimited';

const AddressSchema = z.object({
  address: z.string(),
  city: z.string(),
  state: z.string(),
  postalCode: stringLimited(8)
    .min(5, 'Postal code should contain at least 5 characters')
    .regex(/^[\d-]+$/),
  country: z.string(),
});

const ContactSchema = z.object({
  name: z.string(),
  phoneNumber: z
    .string()
    .length(10)
    .regex(/^[\d- ]+$/),
  email: z.string().email(),
});

const CustomerContactsSchema = z.object({
  operationsContact: ContactSchema.partial().optional(),
  billingContact: ContactSchema,
});

const BaseCustomerSchema = z
  .object({
    name: z.string(),
    code: z.string().default(''),
    externalId: z.string().optional(),
    paymentTerm: z.enum(['net30', 'creditCard']),
  })
  .merge(AddressSchema)
  .merge(CustomerContactsSchema);

export const CustomerSchema = z.discriminatedUnion('mailingAddressSameAsPhysical', [
  z
    .object({
      mailingAddressSameAsPhysical: z.literal(true),
    })
    .merge(BaseCustomerSchema),
  z
    .object({
      mailingAddressSameAsPhysical: z.literal(false),
      mailingAddress: AddressSchema,
    })
    .merge(BaseCustomerSchema),
]);

export type CustomerData = z.infer<typeof CustomerSchema>;

// Extend the BaseCustomerSchema to require externalId
const BaseCustomerSchemaWithRequiredExternalId = BaseCustomerSchema.extend({
  externalId: z.string(),
});

export const CustomerSchemaWithRequiredExternalId = z.discriminatedUnion(
  'mailingAddressSameAsPhysical',
  [
    z
      .object({
        mailingAddressSameAsPhysical: z.literal(true),
      })
      .merge(BaseCustomerSchemaWithRequiredExternalId),
    z
      .object({
        mailingAddressSameAsPhysical: z.literal(false),
        mailingAddress: AddressSchema,
      })
      .merge(BaseCustomerSchemaWithRequiredExternalId),
  ],
);
