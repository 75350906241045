import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiList from '@mui/material/List';

import { NavigationProps } from 'targets/web/components/Navigation';
import Menu from 'targets/web/components/Menu';

export const Accordion = styled(MuiAccordion)(() => ({
  boxShadow: 'none',
  '::before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}));

export const AccordionSummary = styled(MuiAccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'selected',
})<Pick<NavigationProps, 'open'> & { selected: boolean }>(({ theme, open, selected }) => ({
  padding: open ? theme.spacing(0, 4) : 0,
  marginTop: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: selected && !open ? theme.palette.primary.action?.selected : 'transparent',
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.secondary,

  '&:hover': {
    background: theme.palette.primary.action?.hover,
  },

  '&.Mui-expanded': {
    minHeight: '48px',
  },
  '.MuiAccordionSummary-content': {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: !open && 'center',
    gap: open && theme.spacing(4),
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.secondary.action.hover,
  borderRadius: theme.shape.borderRadius,
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '.MuiMenu-paper': {
    minWidth: '200px',
    borderRadius: theme.shape.borderRadius,
  },
  '.MuiMenuItem-root.Mui-selected': {
    background: theme.palette.action.selected,
  },
}));

export const List = styled(MuiList)(() => ({
  padding: 0,
}));
