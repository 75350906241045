import { useInjection } from 'inversify-react';
import { interfaces } from 'inversify';

import { HookResult, InjectableHook } from 'domain/types/InjectableHook';

import ServiceIdentifier = interfaces.ServiceIdentifier;

export function useHookInjection<T extends InjectableHook<HookResult>>(
  identifier: ServiceIdentifier<T>,
): ReturnType<T> {
  return useInjection<T>(identifier)();
}
