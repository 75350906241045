export const jobsRoutes = {
  jobs: `/jobs`,
  internalRoutes: {
    new: 'new',
    details: ':jobId/details',
    summary: ':jobId/summary',
    edit: ':jobId/edit',
    serviceDetails: ':jobId/services/:serviceId/details',
    serviceEdit: ':jobId/services/:serviceId/edit',
    postWorkQuestionnaire: ':jobId/services/:serviceId/post-work-questionnaire',
  },
} as const;
