import { DrawerProps } from '@mui/material/Drawer';
import { FC, ReactNode } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

import { MobileDrawer } from './MobileDrawer';
import { DesktopNavigation } from './DesktopNavigation';

export interface NavigationLink {
  url?: string;
  icon?: ReactNode;
  label: string;
}

export interface NavigationItem extends NavigationLink {
  bottom?: boolean;
  nested?: NavigationLink[];
}

export interface NavigationProps extends Omit<DrawerProps, 'onClick'> {
  toggleDrawer?: () => void;
  logo?: ReactNode;
  logoCollapsed?: ReactNode;
  links: NavigationItem[];
  selected: string;
  onClick?: (link: NavigationItem) => void;
}

export const Navigation: FC<NavigationProps> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return isMobile ? <MobileDrawer {...props} /> : <DesktopNavigation {...props} />;
};

export default Navigation;
