import { ContainerModule } from 'inversify';

import { IRepositoryAvailabilityHook } from 'domain/repositories/IRepositoryAvailabilityHook';

import useBrowserRepositoryAvailability from './hooks/useBrowserRepositoryAvailability';

const dashboardContainerModule = new ContainerModule((bind) => {
  bind(IRepositoryAvailabilityHook).toFunction(useBrowserRepositoryAvailability);
});

export default dashboardContainerModule;
