import * as React from 'react';
import { ServiceSummary } from 'api/domain/entities/Service';
import { differenceInSeconds, format } from 'date-fns';
import { useNavigate } from 'react-router';
import {
  Close as CloseIcon,
  PeopleOutline as CustomerIcon,
  HomeOutlined as StationIcon,
  CalendarMonthOutlined as ScheduledIcon,
  EventOutlined as DueIcon,
  AttachMoneyOutlined as BillableHoursIcon,
  DescriptionOutlined as NoteIcon,
} from '@mui/icons-material';
import { Stack, Typography, styled } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { StatusChip } from 'targets/web/modules/jobs/components';
import { Button, IconButton } from 'targets/web/components';
import { toUTC } from 'targets/web/modules/jobs/utils';

interface Props {
  service: ServiceSummary;
  onClose: () => void;
  dueDateColor?: string;
}

const StyledLabelTypography = styled(Typography)(({ theme, color }) => ({
  color: (color as string) || theme.palette.text.secondary,
}));
const StyledValueTypography = styled(Typography)(({ theme, color }) => ({
  color: (color as string) || theme.palette.text.primary,
}));

const DataRow: React.FC<{
  label: string;
  value: string;
  icon: React.ReactNode;
  color?: string;
}> = ({ value, label, icon, color }) => {
  return (
    <Stack direction="row">
      <Stack direction="row" flex={1} spacing={2} alignItems="center">
        {icon}
        <StyledLabelTypography color={color}>{label}</StyledLabelTypography>
      </Stack>
      <StyledValueTypography
        flex={1}
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        textAlign={'end'}
        color={color}
      >
        {value}
      </StyledValueTypography>
    </Stack>
  );
};

const ServiceSummaryStack: React.FC<Props> = ({ service, onClose, dueDateColor }) => {
  const t = useTranslationPrefix('jobs.schedule.calendar');
  const navigate = useNavigate();
  const billableHours = Math.ceil(
    service.workLogs
      .filter((wl) => wl.billable && wl.endedAt)
      .reduce((sum, wl) => sum + differenceInSeconds(wl.endedAt || new Date(), wl.startedAt), 0) /
      3600,
  );

  return (
    <Stack color="text.secondary" minWidth={340} spacing={2}>
      <Stack width={1} direction="row" alignItems="flex-end">
        <Stack flex={1}>
          <Typography variant="h6">{service.job.name}</Typography>
          <Typography color="text.secondary">{service.code}</Typography>
        </Stack>
        <Stack alignSelf="flex-start">
          <IconButton onClick={onClose} size="small" variant="text" color="secondary">
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack paddingY={1} flexDirection={'row'} justifyContent={'space-between'}>
        <StatusChip status={service.status} dueDate={service.scheduledDue} editable={false} />
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<RemoveRedEyeOutlinedIcon />}
          onClick={() => navigate(`/jobs/${service.jobId}/details`)}
        >
          {t('view_details')}
        </Button>
      </Stack>
      <DataRow
        label={t('customer_label')}
        value={service.job.customer.name}
        icon={<CustomerIcon />}
      />
      <DataRow label={t('station_label')} value={service.job.station.name} icon={<StationIcon />} />
      <DataRow
        label={t('scheduled_label')}
        value={format(toUTC(service.scheduledStart), 'M/d/yy')}
        icon={<ScheduledIcon />}
      />
      <DataRow
        label={t('due_label')}
        value={format(toUTC(service.scheduledDue), 'M/d/yy')}
        icon={<DueIcon />}
        color={dueDateColor}
      />
      <DataRow
        label={t('billable_label')}
        value={String(billableHours)}
        icon={<BillableHoursIcon />}
      />
      {service.notes && (
        <Stack direction="row" flex={1} spacing={2} alignItems="center">
          <NoteIcon />
          <StyledLabelTypography>{service.notes}</StyledLabelTypography>
        </Stack>
      )}
    </Stack>
  );
};

export default ServiceSummaryStack;
