import { useMutation } from '@tanstack/react-query';

import { CompleteJobParams, JobRepository } from 'domain/repositories/IJobRepository';
import { useHookInjection } from 'application/hooks';
import { useCaseGetJobDetails } from 'application/jobs/useCases/useCaseGetJobDetails';

export const useCaseCompleteJob = () => {
  const { completeJob } = useHookInjection<JobRepository>(JobRepository);
  const { refetch: refetchJob } = useCaseGetJobDetails({});

  const { error, mutateAsync, isPending, isSuccess } = useMutation<void, Error, CompleteJobParams>({
    mutationFn: (params) => completeJob(params),
    onSuccess: () => {
      refetchJob();
    },
  });
  return {
    isSuccess,
    isPending,
    error,
    completeJob: mutateAsync,
  };
};
