import { FC, useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { FODInspectionAreas } from 'api/domain/entities/documents/FODInspection';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { CardStack } from 'targets/web/components';

import { QuestionnaireBoolQuestion } from './QuestionnaireBoolQuestion';

export type QuestionnaireType = 'exterior' | 'interior' | 'work_area';

export interface BoolQuestionnaireProps {
  type: QuestionnaireType;
  displayConfirmation?: boolean;
  readOnly?: boolean;
  areas: FODInspectionAreas[];
}

const BoolQuestionnaireSection: FC<BoolQuestionnaireProps> = ({
  type,
  displayConfirmation,
  readOnly,
  areas,
}) => {
  const t = useTranslationPrefix(`jobs.post_work_questionnaire`);

  const { values, setFieldValue } = useFormikContext<Record<FODInspectionAreas, boolean>>();

  const handleChange = useCallback(
    (field: FODInspectionAreas) => (val: boolean) => setFieldValue(field, val),
    [setFieldValue],
  );

  return (
    <CardStack gap={4}>
      <Stack direction="row" gap={2}>
        <Typography variant="h5">{t(`${type}.title`)}</Typography>
      </Stack>

      {areas.map((val, index) => (
        <QuestionnaireBoolQuestion
          key={index}
          value={values[val]}
          onChange={handleChange(val)}
          title={`${index + 1}. ${t(`questions.${val}`)}`}
          displayConfirmation={displayConfirmation}
          readOnly={readOnly}
        />
      ))}
    </CardStack>
  );
};

export default BoolQuestionnaireSection;
