import Pagination from 'common/types/Pagination';
import SortingOptions from 'common/types/SortingOptions';

import { InjectableHook } from 'domain/types/InjectableHook';
import { ID } from 'domain/types/ID';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { Aircraft, AircraftType } from 'domain/entities/Aircraft';
import { Entity } from 'domain/types/Entity';

export type SearchAircraftParams = Partial<
  Pagination &
    SortingOptions & {
      phrase: string;
      typeId: ID;
      customerId: ID;
    }
>;

export type SearchAircraftTypeParams = Partial<
  Pagination &
    SortingOptions & {
      phrase: string;
    }
>;

export interface CreateAircraftPayload {
  typeId: ID;
  regNumber?: string;
  serialNumber?: string;
}

export const AircraftRepository = Symbol('AircraftRepository');
export type AircraftRepository = InjectableHook<{
  searchAircraft(params: SearchAircraftParams): Promise<PaginatedResult<Entity<Aircraft>>>;
  createAircraft(params: CreateAircraftPayload): Promise<{ id: ID }>;

  searchAircraftType(
    params: SearchAircraftTypeParams,
  ): Promise<PaginatedResult<Entity<AircraftType>>>;
}>;
