import KeyboardDoubleArrowUpOutlined from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import UserIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { endOfDay, format, isAfter } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { JobApprovalItem } from 'domain/entities/JobApprovalItem';
import { JobRequestStatus } from 'domain/entities/JobRequestStatus';
import { useCaseDeclineJobRequest } from 'application/approvals/useCases/useCaseDeclineJobRequest';
import { useCaseSearchJobRequests } from 'application/approvals/useCases/useCaseSearchJobRequests';
import { JobResourceType } from 'application/auth/utils/resources';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import useOptimisticPermission from 'application/auth/hooks/useOptimisticPermission';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { isNonNullish } from 'infrastructure/utils/isNonNullish';
import {
  Chip,
  IconButton,
  Loader,
  Table,
  TableColumn,
  Tooltip,
  TripleArrowUp,
} from 'targets/web/components';
import { DeclineJobRequestModal } from 'targets/web/modules/approvals/components';
import { useAcceptDeclineViewFilters } from 'targets/web/modules/approvals/hooks/useAcceptDeclineViewFilters';
import { useDeclinedOptions } from 'targets/web/modules/approvals/hooks/useDeclinedOptions';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';
import { differenceToNowInDaysRoundedUp, utcToLocalTime } from 'targets/web/modules/jobs/utils';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { OfflinePlaceholder } from 'targets/web/modules/dashboard/components';

import { ServicesInfoModal } from './ServicesInfoModal';

const ApproveDeclineTableActionCell: FC<{
  jobApproval: JobApprovalItem;
  onApprove: () => void;
}> = ({ jobApproval, onApprove }) => {
  const navigate = useNavigate();
  const { hasRole } = useCaseCheckAccess();
  const { granted: jobReviewGranted, isLoading: jobReviewAccessLoading } = useOptimisticPermission(
    jobApproval.type === 'job'
      ? {
          resource: `${JobResourceType}:${jobApproval.job.id}`,
          scope: 'review',
        }
      : undefined,
  );

  const showReviewButtons = useMemo(
    () =>
      !jobReviewAccessLoading &&
      (jobApproval.type === 'job' ? jobReviewGranted : hasRole('job-requests-review')),
    [jobApproval.type, jobReviewAccessLoading, jobReviewGranted, hasRole],
  );

  if (jobReviewAccessLoading)
    return (
      <Stack alignItems={'center'} justifyContent={'center'}>
        <Loader size={18} />
      </Stack>
    );

  if (!showReviewButtons) {
    return null;
  }

  return (
    <Stack width="auto" gap={8} direction="row" justifyContent="center">
      {jobApproval.status !== JobRequestStatus.Values.denied && (
        <IconButton
          onClick={() =>
            jobApproval.type === 'job'
              ? navigate(`./${jobApproval.job.id}/confirm/job`)
              : navigate(`./${jobApproval.jobRequest.id}/confirm/job-request`)
          }
          variant={
            jobApproval.status === JobRequestStatus.Values.accepted ? 'contained' : 'outlined'
          }
          disabled={jobApproval.status === JobRequestStatus.Values.accepted}
          size="small"
          color="success"
          data-testname={`accept-${jobApproval.id}`}
        >
          <CheckIcon />
        </IconButton>
      )}
      {jobApproval.status !== JobRequestStatus.Values.accepted && (
        <IconButton
          onClick={onApprove}
          variant={jobApproval.status === JobRequestStatus.Values.denied ? 'contained' : 'outlined'}
          disabled={jobApproval.status === JobRequestStatus.Values.denied}
          size="small"
          color="error"
          data-testname={`decline-${jobApproval.id}`}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export const ApproveDeclineTable = () => {
  const notify = useSnackbar();
  const t = useTranslationPrefix('approvals.approvals_view.table');
  const tSnackbar = useTranslationPrefix('approvals.approvals_view.snackbar');
  const isSmallMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(800));
  const { isOffline } = useNetworkStatus();

  const { filters, statusTab: status } = useAcceptDeclineViewFilters();
  const {
    items = [],
    total,
    isLoading,
    isFetching,
    setPaging,
    setSort,
    sort,
  } = useCaseSearchJobRequests({ ...filters, status });

  const { declineJobRequest } = useCaseDeclineJobRequest();

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
    setPaging((current) => ({ ...current, offset: 0 }));
  }, [setPaging, status]);

  const [jobServicesPreview, setJobServicesPreview] = useState<JobApprovalItem | null>(null);
  const [selectedJob, setSelectedJob] = useState<JobApprovalItem | null>(null);

  const modificationDateHeaderName = useMemo(() => {
    switch (status) {
      case 'accepted':
        return t('columns.approved_date');
      case 'denied':
        return t('columns.declined_date');
      case 'created':
        return t('columns.submitted_date');
      default:
        return '';
    }
  }, [status, t]);

  const declinedOptions = useDeclinedOptions();

  const dueDateCellRenderer = useCallback(
    (jobApproval: JobApprovalItem) => {
      let date;
      if (jobApproval.type === 'jobRequest') {
        date = endOfDay(utcToLocalTime(new Date(jobApproval.jobRequest.departureDate)));
      } else {
        date = endOfDay(utcToLocalTime(jobApproval.due));
      }
      const diffInDays = differenceToNowInDaysRoundedUp(date);
      const isPastDate = isAfter(new Date(), date);

      return (
        <Stack gap={2} direction="row">
          <Typography noWrap>{format(date, 'MMM dd, yyyy')}</Typography>
          {(diffInDays < 5 || isPastDate) &&
            jobApproval.status === JobRequestStatus.Values.created && (
              <Chip
                label={t(isPastDate ? 'due_chip.overdue' : 'due_chip.warning', {
                  count: diffInDays,
                })}
                color={isPastDate ? 'error' : 'warning'}
                size="small"
              />
            )}
        </Stack>
      );
    },
    [t],
  );

  const modificationDateCellRenderer = useCallback(
    ({ reviewedByUsername, ...jobApproval }: JobApprovalItem) => {
      const date =
        jobApproval.status === JobRequestStatus.Values.created
          ? jobApproval.submittedAt
          : jobApproval.reviewedAt;

      return (
        <Stack>
          <Typography noWrap>{date ? format(date, 'MMM dd, yyyy') : ''}</Typography>

          {reviewedByUsername && (
            <Tooltip title={!reviewedByUsername ? '' : reviewedByUsername}>
              <Stack gap={1} direction="row" alignItems="center" sx={{ color: 'text.secondary' }}>
                <UserIcon sx={{ fontSize: 20, color: 'inherit' }} />

                <Typography variant="body2" color="secondary" noWrap>
                  {reviewedByUsername}
                </Typography>
              </Stack>
            </Tooltip>
          )}
        </Stack>
      );
    },
    [],
  );

  const columns: TableColumn<JobApprovalItem>[] = useMemo(
    () =>
      [
        {
          headerName: t('columns.job_name'),
          field: 'name' as const,
          width: isSmallMobile ? '25%' : 'auto',
          sortable: true,
          renderCell: ({ name, type, createdByDisplayName, id }: JobApprovalItem) => (
            <Stack>
              <Typography variant="body1" noWrap data-testname={`job-${id}`}>
                {name}
              </Typography>

              <Tooltip title={type === 'jobRequest' ? '' : createdByDisplayName}>
                <Stack
                  gap={1}
                  direction="row"
                  alignItems="center"
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: 'text.secondary' }}
                >
                  {type === 'job' && !isSmallMobile && (
                    <UserIcon sx={{ fontSize: 20, color: 'inherit' }} />
                  )}

                  <Typography variant="body2" color="secondary" noWrap>
                    {type === 'jobRequest' ? t('services_chip.website') : createdByDisplayName}
                  </Typography>
                </Stack>
              </Tooltip>
            </Stack>
          ),
        },
        ...(isSmallMobile
          ? [
              {
                width: '30%',
                headerName: (
                  <Stack>
                    <Typography variant="tableHeader" color="text.secondary" noWrap>{`${t(
                      'columns.due_date',
                    )} / ${modificationDateHeaderName}`}</Typography>
                  </Stack>
                ),
                renderCell: (jobApproval: JobApprovalItem) => {
                  return (
                    <Stack gap={2}>
                      {dueDateCellRenderer(jobApproval)}
                      {modificationDateCellRenderer(jobApproval)}
                    </Stack>
                  );
                },
              },
            ]
          : [
              {
                headerName: t('columns.due_date'),
                field: 'due' as const,
                sortable: true,
                width: '20%',
                renderCell: dueDateCellRenderer,
              },
              {
                headerName: modificationDateHeaderName,
                field:
                  status === JobRequestStatus.Values.created
                    ? ('submittedAt' as const)
                    : ('reviewedAt' as const),
                sortable: true,
                width: 'auto',
                renderCell: modificationDateCellRenderer,
              },
            ]),
        status !== JobRequestStatus.Values.denied
          ? isSmallMobile
            ? null
            : {
                headerName: t('columns.services'),
                width: '10%',
                renderCell: (jobApproval: JobApprovalItem) => {
                  const services =
                    jobApproval.type === 'jobRequest'
                      ? jobApproval.jobRequest.requestedServices
                      : jobApproval.job.services;
                  return (
                    <Chip
                      clickable={services.length > 0}
                      onClick={
                        services.length > 0 ? () => setJobServicesPreview(jobApproval) : undefined
                      }
                      label={t('services_chip.label', { count: services.length })}
                      color="info"
                      size="small"
                    />
                  );
                },
              }
          : {
              headerName: t('columns.reason'),
              width: 'auto',
              renderCell: (jobApproval: JobApprovalItem) => {
                const reasonLabel = jobApproval.deniedReason
                  ? declinedOptions[jobApproval.deniedReason] ?? jobApproval.deniedReason // We changed from enums to translated strings
                  : '';

                const reason = (
                  <Typography variant="body1" noWrap>
                    {reasonLabel}
                  </Typography>
                );

                if (
                  jobApproval.deniedReason === 'other' ||
                  jobApproval.deniedReason === declinedOptions.other
                ) {
                  return <Tooltip title={jobApproval.reviewNotes}>{reason}</Tooltip>;
                }

                return reason;
              },
            },
        {
          headerName: (
            <Stack>
              <Typography variant="tableHeader" color="text.secondary" noWrap>
                {t('columns.customer_station')}
              </Typography>
            </Stack>
          ),
          width: isSmallMobile ? '20%' : 'auto',
          renderCell: (jobApproval: JobApprovalItem) => {
            const customerCode =
              jobApproval.type === 'jobRequest'
                ? jobApproval.jobRequest.company
                : jobApproval.job.customer.name;

            const stationCode = jobApproval.station.name;

            return (
              <Stack>
                <Tooltip title={customerCode}>
                  <Typography variant="body1" noWrap>
                    {customerCode}
                  </Typography>
                </Tooltip>

                <Typography variant="body2" color="secondary" noWrap>
                  {stationCode}
                </Typography>
              </Stack>
            );
          },
        },
        isSmallMobile
          ? null
          : {
              headerName: t('columns.expected_profit'),
              width: '10%',
              renderCell: (jobApproval: JobApprovalItem) => {
                const profit =
                  jobApproval.type === 'job' ? Number(jobApproval.expectedProfit) : null;
                return (
                  <Stack alignItems="center">
                    {profit === null && '-'}
                    {profit !== null && profit <= 2000 && (
                      <ExpandLessOutlinedIcon fontSize="small" sx={{ color: 'success.light' }} />
                    )}
                    {profit !== null && profit > 2000 && profit <= 9999 && (
                      <KeyboardDoubleArrowUpOutlined fontSize="small" color="success" />
                    )}
                    {profit !== null && profit > 9999 && <TripleArrowUp color="success.dark" />}
                  </Stack>
                );
              },
            },
        {
          type: 'actions' as const,
          width: isSmallMobile ? '20%' : '13%',
          renderCell: (jobApproval: JobApprovalItem) => (
            <ApproveDeclineTableActionCell
              jobApproval={jobApproval}
              onApprove={() => setSelectedJob(jobApproval)}
            />
          ),
        },
      ].filter(isNonNullish),
    [
      modificationDateHeaderName,
      declinedOptions,
      dueDateCellRenderer,
      isSmallMobile,
      modificationDateCellRenderer,
      status,
      t,
    ],
  );

  const shouldRenderOfflineElement = isOffline && !items.length && !(isLoading || isFetching);

  return (
    <>
      {shouldRenderOfflineElement ? (
        <OfflinePlaceholder />
      ) : (
        <Table
          size={isSmallMobile ? 'small' : 'medium'}
          sx={{
            width: 1,
            tableLayout: isSmallMobile ? 'fixed' : 'auto',
          }}
          columns={columns}
          rows={items}
          rowsPerPageOptions={[10, 50, 100]}
          initialRowsPerPage={10}
          count={total}
          orderDirection={sort.direction}
          orderBy={sort.sortBy}
          onRowsPerPageChange={(rowsPerPage) => {
            setPaging({ limit: rowsPerPage, offset: 0 });
            setCurrentPage(0);
          }}
          onPageChange={(pageNumber) => {
            setPaging((curr) => ({ limit: curr.limit, offset: pageNumber * curr.limit }));
            setCurrentPage(pageNumber);
          }}
          onSortChange={(sortBy, direction) => {
            setSort({ sortBy, direction });
            setPaging((currentPaging) => ({ ...currentPaging, offset: 0 }));
            setCurrentPage(0);
          }}
          initialPage={currentPage}
          isLoading={isLoading || isFetching}
        />
      )}

      <ServicesInfoModal
        jobApprovalItem={jobServicesPreview}
        onClose={() => setJobServicesPreview(null)}
      />

      <DeclineJobRequestModal
        open={Boolean(selectedJob)}
        onClose={() => setSelectedJob(null)}
        onSubmit={async (values) => {
          if (!selectedJob) return;

          const declinePromise =
            selectedJob?.type === 'job'
              ? declineJobRequest({
                  jobId: selectedJob.id,
                  accepted: false,
                  ...values,
                })
              : declineJobRequest({ jobRequestId: selectedJob.id, ...values });

          return declinePromise.then(
            () => {
              notify(tSnackbar('decline_success'), {
                variant: 'secondary',
              });
            },
            () => {
              notify(tSnackbar('decline_error'), {
                variant: 'error',
              });
            },
          );
        }}
      />
    </>
  );
};
