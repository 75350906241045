import { Part } from 'api/domain/entities/documents/PostWorkQuestionnaire';

import { InspectionArea } from 'domain/entities/InspectionArea';

export const sectionsMap: Record<InspectionArea, Part[][]> = {
  interior: [
    ['cockpit', 'galley', 'headliner', 'windows', 'tables', 'cupHoldersSideLedges', 'wood'],
    [
      'compartmentsMagazineRacks',
      'mediaCenter',
      'cabinMonitors',
      'seats',
      'seatBelts',
      'seatTracks',
      'sidePanels',
    ],
    [
      'mirrors',
      'trash',
      'lavatory',
      'cockpitCarpet',
      'cabinCarpet',
      'baggageCompartment',
      'meStepsDoor',
    ],
  ],
  exterior: [
    [
      'lhNose',
      'lhFuselage',
      'lhWing',
      'lhUnderWing',
      'lhLeadingEdge',
      'lhPxWindows',
      'lhUnderEngine',
      'lhTopEngine',
      'lhTail',
      'lhBottomHoriz',
      'lhTopHorizontal',
    ],

    [
      'rhNose',
      'rhFuselage',
      'rhWing',
      'rhUnderWing',
      'rhLeadingEdge',
      'rhPxWindows',
      'rhUnderEngine',
      'rhTopEngine',
      'rhTail',
      'rhBottomHoriz',
      'rhTopHorizontal',
    ],

    [
      'topOfFuselage',
      'belly',
      'engineInlets',
      'rhMainGear',
      'lhMainGear',
      'noseGear',
      'rhMainGearWell',
      'lhMainGearWell',
      'noseGearWell',
      'rhFlapWell',
      'lhFlapWell',
    ],
  ],
  'brightwork-boots': [
    [
      'lhLeadingEdge2',
      'lhEngineInlet',
      'lhHorizontal',
      'lhThrustRev',
      'slgEngLhSpinner',
      'verticalStab',
    ],
    [
      'rhLeadingEdge2',
      'rhEngineInlet',
      'rhHorizontal',
      'rhThrustRev',
      'rhSpinner',
      'windshieldRetain',
    ],
    ['lhWingBoot', 'rhWingBoot', 'rhHorizBoot', 'lhHorizBoot', 'verticalBoot', 'propsBoots'],
  ],
};
