import { styled } from '@mui/material/styles';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';

import { NavigationProps } from 'targets/web/components/Navigation/Navigation';

export const List = styled(MuiList)(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

export const ListItem = styled(MuiListItem)(({ theme }) => ({
  display: 'block',

  '& .MuiTouchRipple-child': {
    backgroundColor: `${theme.palette.primary.action?.ripple} !important`,
  },
}));

export const ListItemButton = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== 'open',
})<Pick<NavigationProps, 'open'>>(({ theme, open }) => ({
  height: theme.spacing(12),
  width: open ? 'initial' : theme.spacing(12),
  px: open ? theme.spacing(4) : 0,
  py: open ? theme.spacing(2) : 0,
  justifyContent: open ? 'flex-start' : 'center',
  borderRadius: theme.shape.borderRadius,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.action?.focus,
  },

  '&:hover': {
    background: theme.palette.primary.action?.hover,
  },
}));

export const ListItemIcon = styled(MuiListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'selected',
})<Pick<NavigationProps, 'open'> & { selected?: boolean }>(({ theme, open, selected }) => ({
  minWidth: 0,
  justifyContent: 'center',
  marginRight: open ? theme.spacing(4) : 0,
  color: selected ? theme.palette.primary.dark : theme.palette.text.secondary,
}));

export const ListItemText = styled(MuiListItemText)(() => ({
  overflow: 'hidden',
}));
