import { FC, useState } from 'react';
import z from 'zod';
import { Box, Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Decimal, decimal } from 'common/types/Decimal';

import { entityId, ID } from 'domain/types/ID';
import { MatchedServicePrice } from 'domain/repositories/IPricesRepository';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import formatPrice from 'infrastructure/utils/formatPrice';
import { Autocomplete, IconButton, Select, TextField } from 'targets/web/components';
import { FormikNumberCounter } from 'targets/web/modules/dashboard/components';
import Switch from 'targets/web/components/Switch';

const discountPercentages = [
  { value: 0, name: '0%' },
  { value: 0.05, name: '5%' },
  { value: 0.1, name: '10%' },
  { value: 0.11, name: '11%' },
  { value: 0.12, name: '12%' },
  { value: 0.13, name: '13%' },
  { value: 0.14, name: '14%' },
  { value: 0.15, name: '15%' },
  { value: 0.16, name: '16%' },
  { value: 0.17, name: '17%' },
  { value: 0.18, name: '18%' },
  { value: 0.19, name: '19%' },
  { value: 0.2, name: '20%' },
];

export const serviceFormCardSchema = z.object({
  serviceType: z.object({
    id: entityId(),
    name: z.string(),
  }),
  priceList: z.object({
    id: entityId(),
    name: z.string(),
  }),
  name: z.string().optional(),
  price: decimal().optional(),
  estimatedTime: z.number().optional(),
  quantity: z.number().optional(),
  notes: z.string().optional(),
  discountPercentage: z.number().nullable(),
  order: z.number().optional(),
});

export type ServiceFormCardSchema = z.infer<typeof serviceFormCardSchema>;

interface Props {
  service: ServiceFormCardSchema;
  onRemove: () => void;
  onChange: (changedValues: Partial<ServiceFormCardSchema>) => void;
  disabled?: boolean;
  enableEstimates?: boolean;
  enableBilling?: boolean;
  isLoading: boolean;
  priceLists: MatchedServicePrice[];
  hideServiceTypesIDs: Set<ID>;
}

const ServiceFormCard: FC<Props> = ({
  service,
  disabled,
  onChange,
  onRemove,
  enableEstimates,
  enableBilling,
  isLoading,
  priceLists,
  hideServiceTypesIDs,
}) => {
  const t = useTranslationPrefix('jobs.details_form.services_details');
  const theme = useTheme();
  const [formattedAmount, setFormattedAmount] = useState<string | null>(null);

  const availableServicePrices =
    priceLists.find((pL) => pL.id === service.priceList.id)?.servicePrices || [];
  const availableServiceTypes = availableServicePrices.map((s) => s.serviceType);

  const totalPrice =
    service.price ??
    availableServicePrices.find((s) => s.serviceType.id === service.serviceType.id)?.rate ??
    '0.00';

  const finalPrice = (
    Number(totalPrice) *
    (1 - (service?.discountPercentage || 0)) *
    (service?.quantity || 1)
  ).toFixed(2);

  return (
    <Stack gap={4} direction="row" justifyContent="flex-end">
      <Box border={1} borderRadius={1} borderColor="outline" padding={4}>
        <Grid container flex={1} spacing={4}>
          <Grid item xs={6}>
            <Autocomplete<ServiceFormCardSchema['priceList'], false, false, false>
              disablePortal
              disabled={disabled || isLoading}
              options={priceLists.map((pL) => ({
                id: pL.id,
                name: pL.name,
              }))}
              getOptionLabel={(option) => option.name}
              data-testname="priceList"
              value={service.priceList}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, priceList) => {
                if (!priceList) {
                  return;
                }
                onChange({
                  priceList,
                  serviceType: (priceLists
                    .find((pL) => pL.id === priceList.id)
                    ?.servicePrices?.filter((s) => !hideServiceTypesIDs.has(s.serviceTypeId)) ||
                    [])[0]?.serviceType || {
                    id: ID(''),
                    name: '',
                  },
                  price: undefined,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label={t('price_list_label')} fullWidth required />
              )}
              style={{ flex: 2 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete<ServiceFormCardSchema['serviceType'], false, false, false>
              disablePortal
              disabled={disabled || isLoading}
              options={availableServiceTypes}
              getOptionLabel={(option) => option.name}
              data-testname="service"
              value={service.serviceType}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, serviceType) => {
                if (!serviceType) {
                  return;
                }
                onChange({
                  serviceType,
                  price: undefined,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label={t('service_name_label')} fullWidth required />
              )}
              style={{ flex: 2 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="number"
              name="estimatedTime"
              label={t('estimated_hours_label')}
              disabled={disabled || !enableEstimates}
              InputProps={{ inputProps: { min: 1 }, endAdornment: 'h' }}
              value={service?.estimatedTime}
              onChange={(e) => onChange({ estimatedTime: Number(e.target.value) })}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikNumberCounter
              name="quantity"
              label={t('quantity')}
              min={1}
              max={20}
              disabled={disabled}
              value={service?.quantity}
              onChange={(quantity) => onChange({ quantity })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('service_notes_label')}
              placeholder={t('service_notes_placeholder')}
              name="notes"
              value={service?.notes}
              fullWidth
              multiline
              rows={1}
              disabled={disabled}
              onChange={(e) => onChange({ notes: e.target.value })}
            />
          </Grid>
          {enableBilling && (
            <Grid item xs={12}>
              <Stack
                style={{
                  backgroundColor: theme.palette.primary.action.hover,
                }}
                borderRadius={1}
                padding={4}
                gap={4}
                direction="column"
              >
                <Stack direction="row" alignItems="end" gap={4}>
                  <TextField
                    type="text"
                    label={t('service_price_label')}
                    name="price"
                    disabled={disabled}
                    placeholder={totalPrice}
                    value={formattedAmount}
                    InputProps={{
                      startAdornment: '$',
                      style: { background: theme.palette.background.default },
                    }}
                    onChange={(e) => setFormattedAmount(e.target.value)}
                    onBlur={() => {
                      const numberValue = Number(formattedAmount);
                      const decimalValue = Decimal(
                        isNaN(numberValue) ? '0.00' : numberValue.toFixed(2),
                      );
                      onChange({ price: decimalValue });
                      setFormattedAmount(decimalValue);
                    }}
                    fullWidth
                    style={{ flex: 1 }}
                  />
                  <Switch
                    label={t('discount_label')}
                    name="apply_discount"
                    disabled={disabled}
                    checked={typeof service.discountPercentage === 'number'}
                    value={service.discountPercentage}
                    onChange={(_, val) =>
                      onChange({
                        discountPercentage: val ? 0 : null,
                      })
                    }
                  />
                </Stack>
                {typeof service.discountPercentage === 'number' && (
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Stack direction="row" width={1} gap={4}>
                        <Select
                          label={t('discount_percent_label')}
                          name="type"
                          value={service.discountPercentage.toString()}
                          fullWidth
                          style={{ background: theme.palette.background.default }}
                          disabled={disabled}
                          onChange={(e) => onChange({ discountPercentage: Number(e.target.value) })}
                          items={discountPercentages.map(({ value, name }) => ({
                            label: name,
                            value: value.toString(),
                          }))}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        type="text"
                        label={t('discount_amount_label')}
                        name="discount_amount"
                        InputProps={{
                          style: { background: theme.palette.background.default },
                        }}
                        value={formatPrice(Number(totalPrice) * (service?.discountPercentage || 0))}
                        fullWidth
                        readOnly
                      />
                    </Grid>
                  </Grid>
                )}

                <Divider />
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="labelLarge">{t('final_price_label')}</Typography>
                  <Typography variant="labelLarge">${finalPrice}</Typography>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
      {!disabled && (
        <IconButton
          style={{ alignSelf: 'stretch' }}
          onClick={() => onRemove()}
          variant="outlined"
          color="error"
        >
          <DeleteOutlineIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default ServiceFormCard;
