import { FC, useMemo } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

import { ID } from 'domain/types/ID';
import { Service } from 'domain/entities/Service';
import { useCaseGetJobDetails } from 'application/jobs/useCases/useCaseGetJobDetails';
import { useCaseGetAttachmentsPerJobs } from 'application/attachments/useCases/useCaseGetAttachmentsPerJob';
import { VirtualizedGrid } from 'targets/web/components';
import { ServiceLogCard } from 'targets/web/modules/jobs/components';

export interface JobServicesProps {
  jobId: ID;
}

function sortServices(data: Omit<Service, 'job'>[]): Omit<Service, 'job'>[] {
  const active = data.filter((el) => el.workLogs.some((el) => !el.endedAt));
  const inactive = data.filter((el) => el.workLogs.every((el) => !!el.endedAt));
  return [...active, ...inactive];
}

export const JobServices: FC<JobServicesProps> = ({ jobId }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const { data: job, refetch } = useCaseGetJobDetails({ id: jobId });
  const { refetchAll } = useCaseGetAttachmentsPerJobs({
    jobId,
    type: [],
  });

  const data = useMemo(() => sortServices(job?.services ?? []), [job?.services]);

  const virtualisedGridProps = useMemo(
    () => ({
      useWindowScroll: true,
      spacing: 4,
      minColumnWidth: isMobile ? 450 : 800,
      overscan: 500,
    }),
    [isMobile],
  );

  if (!job) return null;

  return (
    <VirtualizedGrid
      {...virtualisedGridProps}
      data={data}
      computeItemKey={(_index, element) => element.id}
      itemContent={(_index, el) => (
        <ServiceLogCard
          {...el}
          job={job}
          jobBillingStatus={job.billingStatus}
          refetch={() => {
            refetch();
            refetchAll();
          }}
        />
      )}
    />
  );
};

export default JobServices;
