import { Box, Grid, Stack, Typography } from '@mui/material';
import DollarIcon from '@mui/icons-material/AttachMoneyOutlined';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import MoneyOffIcon from '@mui/icons-material/MoneyOffCsredOutlined';
import { Close, VisibilityOutlined } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonthOutlined';
import { FC, useCallback, useMemo, useState } from 'react';
import CalendarDateIcon from '@mui/icons-material/EventOutlined';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Job } from 'api/domain/entities/Job';
import { ExcludedServiceCodesFromPostWorkQuestionnaire } from 'common/data/service';

import { ID } from 'domain/types/ID';
import { Service } from 'domain/entities/Service';
import { ServiceStatus } from 'domain/entities/ServiceStatus';
import { isEntityLocked } from 'domain/entities/BillingStatus';
import { useCaseCompleteService } from 'application/services/useCases/useCaseCompleteService';
import { useCaseStartService } from 'application/services/useCases/useCaseStartService';
import { useCaseStopService } from 'application/services/useCases/useCaseStopService';
import usePermission from 'application/auth/hooks/usePermission';
import { JobResourceType } from 'application/auth/utils/resources';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import durationToSeconds from 'infrastructure/utils/durationToSeconds';
import { componentShadows, shadows } from 'targets/web/theme/shadows';
import { Button, Loader, PreviewBox, Switch } from 'targets/web/components';
import {
  PreWorkQuestionnaireDialog,
  ResumeServiceDialog,
  ServiceBillingStatusChip,
  ServiceStatusChip,
  TimerButton,
} from 'targets/web/modules/jobs/components';
import {
  aggregateWorkLogs,
  formatDuration,
  getLastWorkLog,
  isWorkLogExceedTTL,
  toDuration,
  toUTC,
} from 'targets/web/modules/jobs/utils';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export type ServiceLogCardProps = Pick<
  Service,
  | 'id'
  | 'jobId'
  | 'job'
  | 'name'
  | 'code'
  | 'status'
  | 'scheduledDue'
  | 'scheduledStart'
  | 'workLogs'
  | 'billingStatus'
  | 'adjustedLoggedTimeBillable'
  | 'adjustedLoggedTimeNonBillable'
  | 'employeeCount'
  | 'notes'
> & {
  jobBillingStatus: Job['billingStatus'];
  refetch: () => void;
  optimisticPermissions?: boolean;
};

export const ServiceLogCard: FC<ServiceLogCardProps> = ({
  id,
  jobId,
  job,
  code,
  name,
  status,
  scheduledDue,
  scheduledStart,
  adjustedLoggedTimeBillable,
  adjustedLoggedTimeNonBillable,
  workLogs,
  billingStatus,
  jobBillingStatus,
  employeeCount,
  notes,
  refetch,
  optimisticPermissions,
}) => {
  const notify = useSnackbar();
  const navigate = useNavigate();
  const timerT = useTranslationPrefix('jobs.timers');
  const t = useTranslationPrefix('components.cards');
  const { isOffline } = useNetworkStatus();

  const { completeService, isPending } = useCaseCompleteService();
  const { startService, isPending: startIsPending } = useCaseStartService((e) => {
    if (
      'status' in e &&
      e.status === 409 &&
      e.body?.message === 'pre_work_questionnaire_required'
    ) {
      setIsPreWorkQuestionnaireModalOpen(true);
      return;
    }

    notify(
      timerT('error_start', { error: ('body' in e ? e.body?.message : e.message) || 'unknown' }),
      {
        variant: 'error',
      },
    );
  });
  const { stopService, isPending: stopIsPending } = useCaseStopService();

  const isLocked = useMemo(
    () =>
      isEntityLocked({ billingStatus, status }) ||
      isEntityLocked({ billingStatus: jobBillingStatus, status }),
    [billingStatus, jobBillingStatus, status],
  );
  const timeTrackAvailable =
    (status === 'pending' || status === 'in_progress') &&
    (job.status === 'scheduled' || job.status === 'in_progress') &&
    !isLocked;

  const { granted: trackTimeAccess, isLoading: accessLoading } = usePermission(
    timeTrackAvailable
      ? {
          resource: `${JobResourceType}:${jobId}`,
          scope: 'record-time',
        }
      : undefined,
    optimisticPermissions,
  );

  const { billable: billableDuration, nonBillable: nonBillableDuration } = useMemo(() => {
    const result = aggregateWorkLogs(workLogs);
    return {
      billable: adjustedLoggedTimeBillable
        ? toDuration(adjustedLoggedTimeBillable)
        : result.billable,
      nonBillable: adjustedLoggedTimeNonBillable
        ? toDuration(adjustedLoggedTimeNonBillable)
        : result.nonBillable,
    };
  }, [adjustedLoggedTimeBillable, adjustedLoggedTimeNonBillable, workLogs]);

  const billableTime = useMemo(() => formatDuration(billableDuration), [billableDuration]);
  const nonBillableTime = useMemo(() => formatDuration(nonBillableDuration), [nonBillableDuration]);
  const billableSeconds = durationToSeconds(billableDuration);

  const lastLog = useMemo(
    () =>
      workLogs.length > 0
        ? workLogs.reduce((acc, curr) =>
            acc.startedAt.getTime() > curr.startedAt.getTime() ? acc : curr,
          )
        : undefined,
    [workLogs],
  );
  const isRunning = typeof lastLog !== 'undefined' && !lastLog.endedAt;
  const defaultIsNonBillable = typeof lastLog !== 'undefined' ? !lastLog.billable : false;

  const [trackNonBillable, setTrackNonBillable] = useState(defaultIsNonBillable);
  const [isPreWorkQuestionnaireModalOpen, setIsPreWorkQuestionnaireModalOpen] = useState(false);
  const [isResumeServiceModalOpen, setIsResumeServiceModalOpen] = useState(false);

  const buttonText = useMemo(() => {
    switch (status) {
      case 'pending':
      case 'in_progress':
        return t('serviceCard.done');
      case 'completed':
        return t('serviceCard.completed');
      case 'canceled':
        return t('serviceCard.canceled');
    }
  }, [status, t]);

  const outlineColor = useMemo(() => {
    if (!isRunning) {
      return 'background.default';
    }

    if (!lastLog?.billable) {
      return 'secondary.main';
    }

    return 'primary.main';
  }, [isRunning, lastLog?.billable]);

  const isServiceSubmitDisabled = useMemo(
    () => isRunning || stopIsPending || startIsPending || isLocked,
    [isRunning, stopIsPending, startIsPending, isLocked],
  );

  const handleViewClick = useCallback(
    () => navigate(`/jobs/${jobId}/services/${id}/details`),
    [id, jobId, navigate],
  );

  const handleSubmitService = useCallback(() => {
    if (jobId && code) {
      if (ExcludedServiceCodesFromPostWorkQuestionnaire.includes(code)) {
        completeService({
          serviceId: ID(id),
        });
      } else {
        navigate(`/jobs/${jobId}/services/${id}/post-work-questionnaire`);
      }
    }
  }, [id, navigate, jobId, code, completeService]);

  const onStart = useCallback(() => {
    startService({ serviceId: id, billable: !trackNonBillable }).then(refetch);
  }, [id, refetch, startService, trackNonBillable]);

  const onResume = () => {
    const lastLog = getLastWorkLog(workLogs);

    if (
      !lastLog ||
      isWorkLogExceedTTL(lastLog) ||
      ExcludedServiceCodesFromPostWorkQuestionnaire.includes(code)
    ) {
      return onStart();
    }
    setIsResumeServiceModalOpen(true);
  };

  const onStop = useCallback(() => {
    stopService({ serviceId: id })
      .then(refetch)
      .catch((e) => {
        notify(timerT('error_stop', { error: e.data?.message || e.message || 'unknown' }), {
          variant: 'error',
        });
      });
  }, [notify, id, refetch, stopService, timerT]);

  const completedTransitionAvailable = useMemo(
    () =>
      !isServiceSubmitDisabled &&
      (billableSeconds > 0 || status === ServiceStatus.Values.completed),
    [billableSeconds, isServiceSubmitDisabled, status],
  );

  const { granted: completedTransitionAccess } = usePermission(
    completedTransitionAvailable
      ? {
          resource: `${JobResourceType}:${jobId}`,
          scope: 'complete-service',
        }
      : undefined,
    optimisticPermissions,
  );

  return (
    <Stack
      gap={4}
      data-testname="serviceCard"
      sx={{
        padding: 6,
        borderRadius: 1,
        borderWidth: 2,
        backgroundColor: 'background.default',
        borderStyle: 'solid',
        borderColor: outlineColor,
        boxShadow: componentShadows.card,
        cursor: 'pointer',
        '&:hover': {
          boxShadow: shadows[16],
        },
      }}
      onClick={() => navigate(`/jobs/${jobId}/summary`)}
    >
      <Stack gap={4}>
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">{[job?.name || code, name].join(' - ')}</Typography>
            {completedTransitionAvailable && completedTransitionAccess && (
              <Button
                size="large"
                variant="contained"
                disabled={(status !== 'pending' && status !== 'in_progress') || isPending}
                color={status !== 'canceled' ? 'success' : 'error'}
                startIcon={status !== 'canceled' ? <CheckIcon /> : <Close />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSubmitService();
                }}
              >
                {buttonText}
              </Button>
            )}
          </Stack>

          <Stack gap={2} direction="row" flexWrap="wrap">
            <ServiceStatusChip
              id={id}
              jobId={jobId}
              status={status}
              billingStatus={billingStatus}
              jobBillingStatus={jobBillingStatus}
              scheduledDue={toUTC(scheduledDue)}
              onChange={refetch}
              code={code}
              availableStatusChanges={[
                'in_progress',
                'pending',
                ...(completedTransitionAvailable ? (['completed'] as const) : []),
              ]}
              optimisticPermissions={optimisticPermissions}
            />

            <ServiceBillingStatusChip
              id={id}
              jobId={jobId}
              status={status}
              billingStatus={billingStatus}
              jobBillingStatus={jobBillingStatus}
              onChange={refetch}
              optimisticPermissions={optimisticPermissions}
            />
          </Stack>
        </Stack>

        <Box>
          <Grid container spacing={4}>
            <Grid item xs={3} lg>
              <PreviewBox
                flex={1}
                icon={<CalendarMonthIcon />}
                header={t('scheduled')}
                subheader={scheduledStart ? format(toUTC(scheduledStart), 'MMM dd, yyyy') : 'N/A'}
              />
            </Grid>

            <Grid item xs={3} lg>
              <PreviewBox
                flex={1}
                icon={<CalendarDateIcon />}
                header={t('dueDate')}
                subheader={format(toUTC(scheduledDue), 'MMM dd, yyyy')}
              />
            </Grid>

            <Grid item xs={3} lg>
              <PreviewBox
                flex={1}
                color="success"
                icon={<DollarIcon color="success" />}
                header={t('billable')}
                subheader={billableTime}
              />
            </Grid>

            <Grid item xs={3} lg>
              <PreviewBox
                flex={1}
                color="secondary"
                icon={<MoneyOffIcon color="secondary" />}
                header={t('nonBillable')}
                subheader={nonBillableTime}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} lg>
              <PreviewBox flex={1} color="secondary" header={t('notes')} subheader={notes} />
            </Grid>
          </Grid>
        </Box>

        <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
          <Button
            data-testname={`service-${id}`}
            variant="outlined"
            size="large"
            startIcon={<VisibilityOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleViewClick();
            }}
          >
            {t('view')}
          </Button>

          {accessLoading ? (
            <Loader />
          ) : (
            timeTrackAvailable &&
            trackTimeAccess && (
              <Stack direction="row" flexWrap="wrap">
                <Switch
                  checked={isRunning ? !lastLog?.billable : trackNonBillable}
                  label={t('serviceCard.trackNonBillable')}
                  disabled={isRunning || stopIsPending || startIsPending}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => {
                    setTrackNonBillable((prev) => !prev);
                  }}
                />

                <TimerButton
                  inProgress={stopIsPending || startIsPending || isPending}
                  onStart={onStart}
                  onResume={onResume}
                  onStop={onStop}
                  workLogs={workLogs}
                  disabled={isOffline}
                />
                <PreWorkQuestionnaireDialog
                  open={isPreWorkQuestionnaireModalOpen}
                  service={{ id, jobId, name, status, workLogs }}
                  onSubmit={onStart}
                  onClose={() => setIsPreWorkQuestionnaireModalOpen(false)}
                />
                <ResumeServiceDialog
                  open={isResumeServiceModalOpen}
                  service={{ id, employeeCount }}
                  onSubmit={onStart}
                  onClose={() => setIsResumeServiceModalOpen(false)}
                />
              </Stack>
            )
          )}
        </Stack>

        {timeTrackAvailable && trackTimeAccess && isOffline && (
          <Stack direction="row" justifyContent="flex-end" flexWrap="wrap">
            <Typography variant="caption">{timerT('timer_btn_active_when_online')}</Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
