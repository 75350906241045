import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  width: '160px',
  height: '120px',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.action.active,

  video: {
    borderRadius: theme.shape.borderRadius,
    transform: 'rotateY(180deg)',
  },

  '.MuiSvgIcon-root': {
    color: theme.palette.primary.contrastText,
  },
}));
