import { Grid } from '@mui/material';
import { FC } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { FormikTextField } from 'targets/web/modules/dashboard/components';

export const ContactFields: FC<{
  fieldPrefix: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
}> = ({ fieldPrefix, label, required, disabled }) => {
  const t = useTranslationPrefix('configuration.customer_details.customer_form');

  return (
    <>
      <Grid item xs={4}>
        <FormikTextField
          name={`${fieldPrefix}.name`}
          label={label}
          disabled={disabled}
          required={required}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name={`${fieldPrefix}.phoneNumber`}
          label={t('phoneNumber')}
          disabled={disabled}
          required={required}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name={`${fieldPrefix}.email`}
          label={t('email')}
          disabled={disabled}
          required={required}
        />
      </Grid>
    </>
  );
};
