import { FC, useEffect, useState } from 'react';
import { Alert, Stack } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { NavigationItem } from 'targets/web/components/Navigation/NavigationItem/NavigationItem';
import { NavigationAccordion } from 'targets/web/components/Navigation/NavigationAccordion/NavigationAccordion';
import {
  NavigationItem as INavigationItem,
  NavigationProps,
} from 'targets/web/components/Navigation';

import { List, ListItem, StyledDesktopDrawer, ToggleButton } from './style';

export const DesktopNavigation: FC<NavigationProps> = ({
  open,
  toggleDrawer,
  links,
  selected,
  onClick,
  ...rest
}) => {
  const t = useTranslationPrefix('components.navigation');
  const [sections, setSections] = useState<{
    top: INavigationItem[];
    bottom: INavigationItem[] | undefined;
  }>({ top: [], bottom: undefined });

  useEffect(() => {
    const top = links.filter((link) => !link.bottom);
    const bottom = links.filter((link) => link.bottom);

    setSections({ top, bottom });
  }, [links]);

  const handleToggleDrawer = () => toggleDrawer && toggleDrawer();

  const getLinks = (section: INavigationItem[]) =>
    section.map((parentLink) => {
      const { label, icon, url, nested } = parentLink;

      if (!nested && url) {
        return (
          <NavigationItem
            key={url}
            url={url}
            onClick={onClick}
            icon={icon}
            label={label}
            open={open}
            selected={selected}
            isParent={!nested}
          />
        );
      }

      if (nested?.length === 1) {
        const firstNestedItem = nested[0];

        return (
          <NavigationItem
            key={firstNestedItem.url}
            url={firstNestedItem.url}
            onClick={onClick}
            icon={icon}
            label={label}
            open={open}
            selected={selected}
            isParent={true}
          />
        );
      }

      return (
        <ListItem key={url || label}>
          <NavigationAccordion
            icon={icon}
            label={label}
            selected={selected}
            onClick={onClick}
            url={url}
            nested={nested}
            open={open}
          />
        </ListItem>
      );
    });

  return (
    <StyledDesktopDrawer variant="permanent" open={open} {...rest}>
      <Stack spacing={16} justifyContent="space-between" flex={1}>
        <Stack spacing={4}>
          {sections.top.length ? (
            <List>{getLinks(sections.top)}</List>
          ) : (
            <Alert severity="warning" variant="outlined">
              {t('no_apps_active')}
            </Alert>
          )}
        </Stack>

        {sections.bottom && <List>{getLinks(sections.bottom)}</List>}
      </Stack>

      <ToggleButton size="large" variant="text" color="secondary" onClick={handleToggleDrawer}>
        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </ToggleButton>
    </StyledDesktopDrawer>
  );
};
