import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { FC } from 'react';

import useCaseLogout from 'application/auth/useCases/useCaseLogout';

export const LogoutButton: FC = () => {
  const { logout } = useCaseLogout();

  return (
    <Button variant="text" color="primary" startIcon={<ExitToAppIcon />} onClick={logout}>
      Logout
    </Button>
  );
};
