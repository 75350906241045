import { JobRecurring } from 'domain/entities/JobRecurring';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

// @ts-expect-error: Vite treats vars as string
const isEvery15MinEnabled = import.meta.env.VITE_RECURRING_TYPE_15MIN_INTERVAL_ENABLED === 'true';

export const getRecurringOptions = () => {
  const t = useTranslationPrefix('jobs.details_form.availability_details');
  const recurringOptions = [
    {
      label: t('recurring_options.none'),
      value: JobRecurring.Values.none,
    },
    {
      label: t('recurring_options.every_15_minutes'),
      value: JobRecurring.Values['15_min'],
    },
    {
      label: t('recurring_options.weekly'),
      value: JobRecurring.Values.weekly,
    },
    {
      label: t('recurring_options.monthly'),
      value: JobRecurring.Values.monthly,
    },
    {
      label: t('recurring_options.1st_of_the_month'),
      value: JobRecurring.Values['1st_of_month'],
    },
    {
      label: t('recurring_options.15th_of_the_month'),
      value: JobRecurring.Values['15th_of_month'],
    },
    {
      label: t('recurring_options.25th_of_the_month'),
      value: JobRecurring.Values['25th_of_month'],
    },
  ];

  if (!isEvery15MinEnabled) {
    return recurringOptions.filter((option) => option.value !== JobRecurring.Values['15_min']);
  }

  return recurringOptions;
};

export const shouldDisableRecurringDate = (recurringType: JobRecurring, day: Date) => {
  switch (recurringType) {
    case JobRecurring.Values['1st_of_month']:
      return day.getDate() !== 1;
    case JobRecurring.Values['15th_of_month']:
      return day.getDate() !== 15;
    case JobRecurring.Values['25th_of_month']:
      return day.getDate() !== 25;
    default:
      return false;
  }
};
