import { Divider, Grid } from '@mui/material';
import { FC } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import { BaseFields, Mode } from './BaseFields';
import { AddressSection } from './AddressSection';
import { MailingAddressSection } from './MailingAddressSection';
import { ContactFields } from './ContactFields';

export const CustomerFormFields: FC<{ disabled?: boolean; mode?: Mode }> = ({ disabled, mode }) => {
  const t = useTranslationPrefix('configuration.customer_details.customer_form');

  return (
    <Grid container spacing={4}>
      <BaseFields disabled={disabled} mode={mode} />

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <AddressSection disabled={disabled} />

      <MailingAddressSection disabled={disabled} />

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <ContactFields
        fieldPrefix={'operationsContact'}
        label={t('operationsContact')}
        disabled={disabled}
      />

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <ContactFields
        fieldPrefix={'billingContact'}
        label={t('billingContact')}
        disabled={disabled}
        required={!disabled}
      />
    </Grid>
  );
};
