import { z } from 'zod';
import { BillingStatus as APIBillingStatus } from 'api/domain/enums/BillingStatus';
import { JobStatus as APIJobStatus } from 'api/domain/entities/Job';
import { ServiceStatus as APIServiceStatus } from 'api/domain/entities/Service';

export const BillingStatus = APIBillingStatus;
export type BillingStatus = z.infer<typeof BillingStatus>;

export const isEntityLocked = <
  T extends { billingStatus: BillingStatus; status: APIJobStatus | APIServiceStatus },
>(
  entity: T,
): boolean => {
  return (
    entity.billingStatus === BillingStatus.Values.billable ||
    entity.status === APIJobStatus.Values.canceled
  );
};
