import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AttachmentsRepository } from 'domain/repositories/IAttachmentsRepository';
import { ID } from 'domain/types/ID';
import { AttachmentContentType } from 'domain/entities/Attachment';
import { useHookInjection } from 'application/hooks';

export type UploadFileParams = {
  jobId: ID;
  file: File;
  refersTo?: ID;
};

export const useCaseUploadFile = () => {
  const queryClient = useQueryClient();
  const { requestUploadUrl, uploadFile, createAttachment } =
    useHookInjection<AttachmentsRepository>(AttachmentsRepository);

  const { mutateAsync, isPending } = useMutation<void, Error, UploadFileParams>({
    mutationFn: async ({ jobId, file, refersTo }) => {
      const { uri, url } = await requestUploadUrl({
        jobId,
        name: file.name,
        contentType: file.type as AttachmentContentType,
      });

      await uploadFile({ url, file });

      await createAttachment({
        jobId,
        data: {
          type: 'custom',
          name: file.name,
          uri,
          contentType: file.type as AttachmentContentType,
          refersToId: refersTo,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['ATTACHMENTS'] });
    },
  });

  return {
    uploadFile: mutateAsync,
    isPending,
  };
};
