import { FC } from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
} from '@mui/material';

export type CheckboxProps = MuiCheckboxProps & {
  /**
   * Text to be used in an enclosing label element.
   * If not defined, no label element will be added.
   */
  label?: string;
};

export const Checkbox: FC<CheckboxProps> = ({ label, ...rest }) =>
  label ? (
    <FormControlLabel control={<MuiCheckbox {...rest} />} label={label} />
  ) : (
    <MuiCheckbox {...rest} />
  );

export default Checkbox;
