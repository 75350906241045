import { useQuery } from '@tanstack/react-query';

import { PricesRepository } from 'domain/repositories/IPricesRepository';
import { ID } from 'domain/types/ID';
import { useHookInjection } from 'application/hooks';

export const useCaseGetPriceList = (id?: ID) => {
  const { getPriceList } = useHookInjection<PricesRepository>(PricesRepository);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['PRICE_LIST', { priceListId: id }] as const,
    queryFn: ({ queryKey }) => queryKey[1].priceListId && getPriceList(queryKey[1].priceListId),
    enabled: !!id,
  });
  return {
    data,
    isLoading,
    isFetching,
  };
};
