import { useFormikContext } from 'formik';
import { SyntheticEvent, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { Box } from '@mui/material';

import { Entity } from 'domain/types/Entity';
import { AircraftType } from 'domain/entities/Aircraft';
import { ID } from 'domain/types/ID';
import { useCaseSearchAircraftType } from 'application/aircraft/useCases/useCaseSearchAircraftType';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Autocomplete } from 'targets/web/components';

export const FormAircraftTypeAutocomplete = () => {
  const t = useTranslationPrefix('components.form_aircraft_type_autocomplete');
  const { values, initialValues, setFieldValue } = useFormikContext<{
    type: { id: ID; name: string; code: string };
  }>();

  const [phrase, setPhrase] = useState('');
  const { items = [], isLoading } = useCaseSearchAircraftType({ phrase });

  const aircraftTypes = useMemo(() => {
    if (!initialValues.type?.code) {
      return items;
    }

    if (!items.find(({ id }) => id === initialValues.type.id)) {
      return [initialValues.type].concat(items);
    }

    return items;
  }, [initialValues.type, items]);

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<Entity<AircraftType>, false, false, false>
      label={t('label')}
      loading={isLoading}
      options={aircraftTypes ?? []}
      value={values.type?.code ? values.type : null}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={handleInputChange}
      onChange={(_, value) => setFieldValue('type', value)}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.name}
        </Box>
      )}
    />
  );
};
