import { addSeconds, differenceInSeconds, intervalToDuration, Duration } from 'date-fns';

import { WorkLog } from 'domain/entities/Worklog';

export const mapWorkLogsToDuration = (workLogs: WorkLog[]): Duration => {
  return intervalToDuration({
    start: 0,
    end: workLogs
      .filter((log) => Boolean(log.endedAt))
      .map((log) =>
        differenceInSeconds(log.endedAt ?? Date.now(), log.startedAt, { roundingMethod: 'floor' }),
      )
      .reduce((durationSum, duration) => addSeconds(durationSum, duration).getTime(), 0),
  });
};
