import { useQuery } from '@tanstack/react-query';

import {
  GetMatchedServiceTypesParams,
  PricesRepository,
} from 'domain/repositories/IPricesRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseGetMatchedServicePrices = (params: GetMatchedServiceTypesParams) => {
  const { getMatchedServicePrices } = useHookInjection<PricesRepository>(PricesRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['ASSOCIATED_SERVICE_TYPES', { ...params }] as const,
    queryFn: ({ queryKey }) => getMatchedServicePrices(queryKey[1]),
    staleTime: Infinity,
    enabled: !!params.aircraftTypeId && !!params.customerId && !!params.stationId,
  });

  return {
    priceLists: data,
    isLoading,
    isFetching,
  };
};
