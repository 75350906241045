import { atom, useAtom } from 'jotai/index';
import { useCallback } from 'react';

const postWorkQuestionnaireSteps = ['form900-1', 'form900-2', 'formFOD-1', 'formFOD-2'] as const;
const stepsAtom = atom<number>(0);

export const usePostWorkQuestionnaireSteps = () => {
  const [step, setStep] = useAtom(stepsAtom);

  const nextStep = useCallback(
    () => setStep((step) => (step < postWorkQuestionnaireSteps.length - 1 ? step + 1 : step)),
    [setStep],
  );

  const previousStep = useCallback(
    () => setStep((step) => (step > 0 ? step - 1 : step)),
    [setStep],
  );

  const reset = useCallback(() => setStep(0), [setStep]);

  return {
    step: postWorkQuestionnaireSteps[step],
    reset,
    nextStep,
    previousStep,
  };
};
