import { styled } from '@mui/material/styles';

const touchCursor =
  'url(data:image/svg+xml;base64,' +
  'PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Im' +
  'h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxOSIgaGVpZ2h0PSI' +
  'xOSIgcng9IjkuNSIgZmlsbD0iIzA5NkREOSIvPgo8cmVjdCB4PSIwLjUiIHk9IjAuNSIgd2lkdGg9IjE5IiBoZWlnaHQ9' +
  'IjE5IiByeD0iOS41IiBzdHJva2U9IndoaXRlIi8+Cjwvc3ZnPgo=) 12 12, pointer';

export const Wrapper = styled('div')<{ mirrored?: boolean; remoteControl?: boolean }>(
  ({ theme, mirrored = true, remoteControl = false }) => ({
    height: '100%',
    width: '100%',

    cursor: remoteControl ? touchCursor : undefined,

    video: {
      transform: mirrored ? 'rotateY(180deg)' : '',
    },

    '.MuiSvgIcon-root': {
      fontSize: '64px',
      color: theme.palette.primary.contrastText,
    },
  }),
);
