import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    minWidth: '200px',
    border: `1px solid ${theme.palette?.outline}`,
  },
}));

export const SearchWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 0),
  width: '100%',
  '.MuiInputAdornment-root': {
    marginRight: 0,
  },
}));
