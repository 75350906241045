import { useMutation, DefaultError } from '@tanstack/react-query';

import { ServiceRepository } from 'domain/repositories/IServiceRepository';
import { CancelServiceRequest } from 'domain/entities/CancelServiceRequest';
import { useHookInjection } from 'application/hooks';

import { useCaseSearchServices } from './useCaseSearchServices';
import { useCaseGetService } from './useCaseGetService';
import { useCaseGetServicesCountByStatus } from './useCaseGetServicesCountByStatus';

export const useCaseCancelService = () => {
  const { cancelService } = useHookInjection<ServiceRepository>(ServiceRepository);

  const { refetch: refetchServiceList } = useCaseSearchServices(null);
  const { refetch: refetchServiceCount } = useCaseGetServicesCountByStatus(null);
  const { refetch: refetchService } = useCaseGetService();

  const { mutateAsync } = useMutation<void, DefaultError, CancelServiceRequest>({
    mutationFn: cancelService,
    onSuccess: () => {
      refetchService();
      refetchServiceList();
      refetchServiceCount();
    },
  });

  return {
    cancelService: mutateAsync,
  };
};
