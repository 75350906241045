import { useMutation } from '@tanstack/react-query';

import { ServiceRepository } from 'domain/repositories/IServiceRepository';
import { ID } from 'domain/types/ID';
import { useHookInjection } from 'application/hooks';
import { useCaseGetServicesCountByStatus } from 'application/services/useCases/useCaseGetServicesCountByStatus';

export const useCaseStopService = () => {
  const { stopTimer } = useHookInjection<ServiceRepository>(ServiceRepository);

  const { refetch: refetchServicesCount } = useCaseGetServicesCountByStatus(null);

  const { error, isPending, mutateAsync } = useMutation<void, Error, { serviceId: ID }>({
    mutationFn: ({ serviceId }) => stopTimer(serviceId),
    onSuccess: () => {
      refetchServicesCount();
    },
  });

  return {
    error,
    isPending,
    stopService: mutateAsync,
  };
};
