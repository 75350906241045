import { useMutation } from '@tanstack/react-query';

import { ID } from 'domain/types/ID';
import { CustomerRepository } from 'domain/repositories/ICustomerRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseConfirmCustomer = () => {
  const { confirmCustomer } = useHookInjection<CustomerRepository>(CustomerRepository);

  const { mutateAsync } = useMutation<void, Error, { id: ID }>({
    mutationFn: confirmCustomer,
  });

  return {
    confirmCustomer: mutateAsync,
  };
};
