import { Provider } from 'inversify-react';
import * as Sentry from '@sentry/react';

import InfrastructureProvider from 'infrastructure/contexts/InfrastructureProvider';
import OIDCProvider from 'infrastructure/keycloak/OIDCProvider';

import container from './container';
import Router from './routes/Router';
import { ThemeProvider } from './modules/dashboard/components/ThemeProvider';
import SnackbarProvider from './contexts/SnackbarProvider';

export default function App() {
  return (
    <Sentry.ErrorBoundary showDialog>
      <Provider container={container}>
        <InfrastructureProvider>
          <OIDCProvider>
            <ThemeProvider>
              <SnackbarProvider>
                <Router />
              </SnackbarProvider>
            </ThemeProvider>
          </OIDCProvider>
        </InfrastructureProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}
