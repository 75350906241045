import { Grid, Typography } from '@mui/material';
import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import z from 'zod';
import { debounce, get } from 'lodash';

import { entityId } from 'domain/types/ID';
import { Entity } from 'domain/types/Entity';
import { Aircraft } from 'domain/entities/Aircraft';
import { useCaseSearchAircraft } from 'application/aircraft/useCases/useCaseSearchAircraft';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { Autocomplete, TextField } from 'targets/web/components';
import { getAircraftOptionName } from 'targets/web/modules/jobs/utils';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { FormikTextField } from 'targets/web/modules/dashboard/components';

import { Container } from './common';

const isValueFreeSolo = (value: AircraftJobDetailsData['aircraft']): boolean =>
  !!value.id && !!value.type?.name && value.id === value.type?.name;

export const AircraftJobDetailsSchema = z.object({
  aircraft: z.object({
    id: entityId(),
    code: z.string(),
    type: z.object({
      name: z.string(),
      code: z.string(),
    }),
    typeId: entityId(),
    serialNumber: z.string().optional().nullable(),
  }),
  payment: z.object({
    poNumber: z.string().max(20).optional(),
    woNumber: z.string().max(20).optional(),
    squawkNumber: z.string().max(20).optional(),
  }),
});

export type AircraftJobDetailsData = z.infer<typeof AircraftJobDetailsSchema>;

export interface AircraftJobDetailsProps {
  disabled?: boolean;
  paymentDisabled?: boolean;
  aircraftEditable?: boolean;
}

export const AircraftAutocomplete: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const t = useTranslationPrefix('jobs.details_form.aircraft_details');
  const { isOffline } = useNetworkStatus();
  const { values, errors, touched, initialValues, setFieldValue } =
    useFormikContext<AircraftJobDetailsData>();

  const [aircraftPhrase, setAircraftPhrase] = useState('');
  const { items = [], isLoading } = useCaseSearchAircraft({
    phrase: aircraftPhrase,
  });

  const aircraftItems = useMemo(() => {
    if (!initialValues.aircraft?.id) {
      return items;
    }

    if (!items.find(({ id }) => id === initialValues.aircraft.id)) {
      return [initialValues.aircraft].concat(items);
    }

    return items;
  }, [initialValues.aircraft, items]);

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setAircraftPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<
      Entity<Omit<Aircraft, 'typeId' | 'customerId' | 'customer'>>,
      false,
      false,
      boolean
    >
      label={t('aircraft_label')}
      disabled={disabled}
      loading={isLoading}
      freeSolo={isOffline}
      autoSelect={isOffline}
      options={aircraftItems ?? []}
      data-testname="aircraft"
      value={values.aircraft?.id ? values.aircraft : null}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : getAircraftOptionName(option)
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      onChange={(_, value) =>
        setFieldValue(
          'aircraft',
          typeof value === 'string' ? { code: '', id: value, type: { name: value } } : value,
        )
      }
      renderInput={(params) => {
        const isFreeSolo = values.aircraft && isValueFreeSolo(values.aircraft);
        const hasError = !!get(errors, 'aircraft') && (isFreeSolo || !!get(touched, 'aircraft'));

        return (
          <FormikTextField
            {...params}
            name="aircraft"
            inputProps={{
              ...params.inputProps,
            }}
            error={hasError}
            helperText={
              hasError ? (isFreeSolo && isOffline ? t('error_free_solo') : t('error')) : undefined
            }
            label={t('aircraft_label')}
            required
          />
        );
      }}
    />
  );
};

export const AircraftJobDetails: FC<AircraftJobDetailsProps> = ({
  disabled,
  paymentDisabled,
  aircraftEditable,
}) => {
  const t = useTranslationPrefix('jobs.details_form.aircraft_details');

  const { values, handleChange } = useFormikContext<AircraftJobDetailsData>();

  const selectedAircraft = useMemo(() => {
    if (!values.aircraft || !values.aircraft.id) {
      return null;
    }

    return {
      name: getAircraftOptionName(values.aircraft),
      id: values.aircraft.id,
      type: values.aircraft.type.name,
      regNumber: values.aircraft.code,
      serialNumber: values.aircraft.serialNumber ?? '',
    };
  }, [values.aircraft]);

  return (
    <Container gap={4} sx={{ padding: 4 }}>
      <Typography variant="h6">{t('title')}</Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AircraftAutocomplete disabled={disabled || !aircraftEditable} />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label={t('reg_label')}
            name="reg"
            readOnly={true}
            disabled={disabled || !aircraftEditable}
            value={selectedAircraft?.regNumber ?? ''}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('serial_label')}
            name="serial"
            value={selectedAircraft?.serialNumber ?? ''}
            fullWidth
            readOnly={true}
            disabled={disabled || !aircraftEditable}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('type_label')}
            name="type"
            value={selectedAircraft?.type ?? ''}
            fullWidth
            readOnly={true}
            disabled={disabled || !aircraftEditable}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t('po_number_label')}
            name="po"
            disabled={paymentDisabled}
            onChange={handleChange('payment.poNumber')}
            value={values.payment?.poNumber ?? ''}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t('wo_number_label')}
            name="wo"
            disabled={paymentDisabled}
            onChange={handleChange('payment.woNumber')}
            value={values.payment?.woNumber ?? ''}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t('squawk_number_label')}
            name="squawk"
            disabled={paymentDisabled}
            onChange={handleChange('payment.squawkNumber')}
            value={values.payment?.squawkNumber ?? ''}
            fullWidth
          />
        </Grid>
      </Grid>
    </Container>
  );
};
