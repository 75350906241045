import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const StyledTextInput = styled(TextField)(({ theme }) => ({
  input: {
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
  },
  '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.outline,
  },
}));
