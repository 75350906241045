import { Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { Decimal } from 'common/types/Decimal';

import { ID } from 'domain/types/ID';
import { useCaseSearchPrices } from 'application/prices/useCases/useCaseSearchPrices';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import formatPrice from 'infrastructure/utils/formatPrice';
import { TableColumn } from 'targets/web/components';
import { TableWithSearch } from 'targets/web/components/TableWithSearch';
import { usePriceListFilters } from 'targets/web/modules/configuration/hooks/usePriceListFilters';

export type PriceListEntry = {
  id: string;
  serviceName: string;
  aircraftType?: string;
  rate: Decimal;
};

function getSort(sortBy: string): string {
  switch (sortBy) {
    case 'serviceName':
      return 'serviceType.name';
    case 'aircraftType':
      return 'aircraftType.name';
    default:
      return sortBy;
  }
}

export const SpecificPriceListTable: FC<{ priceListId: ID }> = ({ priceListId }) => {
  const { sort, setSort, filters, setFilters, setSearchPhrase } = usePriceListFilters();
  const [currentPage, setCurrentPage] = useState(0);
  const t = useTranslationPrefix('configuration.price_list_details.price_list_table');
  const { pages, total, isLoading, isFetching, fetchNextPage } = useCaseSearchPrices({
    priceListId,
    sortBy: sort.sortBy,
    direction: sort.direction,
    ...filters,
  });
  const columns: TableColumn<PriceListEntry>[] = [
    {
      headerName: t('service_name_column'),
      field: 'serviceName',
      sortable: true,
      renderCell: ({ serviceName }: PriceListEntry) => (
        <Typography variant="body1">{serviceName}</Typography>
      ),
    },
    {
      headerName: t('aircraft_type_column'),
      field: 'aircraftType',
      sortable: true,
      renderCell: ({ aircraftType }: PriceListEntry) => (
        <Typography variant="body1">{aircraftType}</Typography>
      ),
    },
    {
      headerName: t('rate_column'),
      field: 'rate',
      sortable: true,
      renderCell: ({ rate }: PriceListEntry) => (
        <Typography variant="body1">{formatPrice(Number(rate))}</Typography>
      ),
    },
  ];
  const rows = useMemo(
    () =>
      (pages[currentPage]?.items ?? []).map((el) => ({
        id: el.id,
        serviceName: el.serviceType.name,
        aircraftType: el.aircraftType?.name,
        rate: el.rate,
      })),
    [pages, currentPage],
  );
  return (
    <TableWithSearch
      onSearchPhraseChange={setSearchPhrase}
      columns={columns}
      rows={rows}
      rowsPerPageOptions={[10, 50, 100]}
      initialRowsPerPage={filters?.limit ?? 10}
      count={total}
      onRowsPerPageChange={(rowsPerPage) => {
        setFilters((current) => ({ ...current, limit: rowsPerPage }));
        setCurrentPage(0);
      }}
      onPageChange={async (pageNumber) => {
        if (!pages[pageNumber]) {
          await fetchNextPage();
        }

        setCurrentPage(pageNumber);
      }}
      onSortChange={(sortBy, direction) => {
        setSort({
          sortBy: getSort(sortBy),
          direction,
        });
        setCurrentPage(0);
      }}
      initialPage={currentPage}
      isLoading={isLoading || isFetching}
      searchPhrasePlaceholder={t('search_placeholder')}
    />
  );
};

export default SpecificPriceListTable;
