import { useMutation } from '@tanstack/react-query';
import { PriceListAssociation } from 'api/domain/entities/PriceListAssociation';

import { PricesRepository } from 'domain/repositories/IPricesRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseCreateAssociation = () => {
  const { createAssociation } = useHookInjection<PricesRepository>(PricesRepository);
  const { mutateAsync } = useMutation<void, Error, PriceListAssociation>({
    mutationFn: createAssociation,
  });

  return {
    createAssociation: mutateAsync,
  };
};
