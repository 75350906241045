import { CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';

import { useColorMode } from 'targets/web/modules/dashboard/hooks/useColorMode';
import { theme } from 'targets/web/theme/theme';

export const ThemeProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { mode } = useColorMode();

  const defaultTheme = useMemo(
    () => ({
      ...theme,
      palette: {
        ...theme.palette,
        mode: mode,
      },
    }),
    [mode],
  );

  return (
    <MUIThemeProvider theme={defaultTheme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
};
