import Pagination from 'common/types/Pagination';
import SortingOptions from 'common/types/SortingOptions';

import { Entity } from 'domain/types/Entity';
import { InjectableHook } from 'domain/types/InjectableHook';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import {
  Attachment,
  RequestUploadUrlParams,
  UploadResourceUrlData,
  CreateAttachmentParams,
  CreateAttachmentData,
  UploadFileParams,
  DeleteAttachmentParams,
} from 'domain/entities/Attachment';
import { ID } from 'domain/types/ID';
import { AttachmentType } from 'domain/entities/AttachmentType';

export type AttachmentsParams = Partial<Pagination & SortingOptions> & {
  jobId: ID;
  type: AttachmentType[];
};

export const AttachmentsRepository = Symbol('AttachmentsRepository');

export type AttachmentsRepository = InjectableHook<{
  searchAttachments(params: AttachmentsParams): Promise<PaginatedResult<Entity<Attachment>>>;

  requestUploadUrl(params: RequestUploadUrlParams): Promise<UploadResourceUrlData>;

  createAttachment(params: CreateAttachmentParams): Promise<CreateAttachmentData>;

  uploadFile(params: UploadFileParams): Promise<void>;

  deleteAttachment(params: DeleteAttachmentParams): Promise<void>;
}>;
