import { FC, ReactElement, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import Loader from 'targets/web/components/Loader';

interface ProtectedRouteProps {
  children: ReactElement;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading) {
      auth
        .signinRedirect({
          redirect_uri: location.href,
        })
        .catch(console.error);
    }
  }, [auth]);

  if (!auth.isAuthenticated) {
    return <Loader absolute />;
  }

  return children;
};

export default ProtectedRoute;
