import z from 'zod';

import { PrimaryJobDetailsSchema } from './PrimaryJobDetails';
import { AircraftJobDetailsSchema } from './AircraftJobDetails';
import { AvailabilityJobDetailsSchema } from './AvailabilityJobDetails';
import { NotesJobDetailsSchema } from './NotesJobDetails';
import { ServicesJobDetailsSchema } from './ServicesJobDetails';

export const JobDetailsDataSchema = PrimaryJobDetailsSchema.merge(
  AircraftJobDetailsSchema.merge(
    AvailabilityJobDetailsSchema.merge(NotesJobDetailsSchema.merge(ServicesJobDetailsSchema)),
  ),
);

export type JobDetailsData = z.infer<typeof JobDetailsDataSchema>;
