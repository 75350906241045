import { IconButton, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Part } from 'api/domain/entities/documents/PostWorkQuestionnaire';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { FODInspectionAreas } from 'api/domain/entities/documents/FODInspection';

import { Form900 } from 'domain/entities/Form900';
import { ID } from 'domain/types/ID';
import { InspectionArea } from 'domain/entities/InspectionArea';
import { useCaseGetJobDetails } from 'application/jobs/useCases/useCaseGetJobDetails';
import { useCaseCompleteService } from 'application/services/useCases/useCaseCompleteService';
import { useCaseGetService } from 'application/services/useCases/useCaseGetService';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { PageTitle } from 'targets/web/modules/dashboard/components';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { Breadcrumbs, Button, Loader } from 'targets/web/components';
import {
  CustomerSignatureModal,
  Step1PostWorkQuestionnaire,
  Step2PostWorkQuestionnaire,
} from 'targets/web/modules/jobs/components/PostWorkQuestionnaire';
import { getAircraftOptionName } from 'targets/web/modules/jobs/utils';
import { usePostWorkQuestionnaireSteps } from 'targets/web/modules/jobs/hooks/usePostWorkQuestionnaireSteps';
import { usePersistedValues } from 'targets/web/modules/dashboard/hooks/usePersistedValues';

export const PostWorkQuestionnaireView: FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const t = useTranslationPrefix('jobs.post_work_questionnaire');
  const { jobId = '', serviceId = '' } = useParams();
  const tBreadcrumbs = useTranslationPrefix('jobs.breadcrumbs');

  const { data, isLoading } = useCaseGetService(ID(serviceId));
  const { data: jobData, isLoading: isLoadingJob } = useCaseGetJobDetails({ id: ID(jobId) });

  const { completeService, isPending } = useCaseCompleteService();

  const [form900, setForm900] = useState<Pick<Form900, 'parts'> | null>(null);
  const { step, reset, previousStep, nextStep } = usePostWorkQuestionnaireSteps();
  const [customerSignatureModalOpen, setCustomerSignatureModalOpen] = useState(false);
  const [customerSignature, setCustomerSignature] = useState<string | undefined>('');

  const persistForm900Key = `form900-${jobId}`;
  const persistFormFODKey = `formFOD-${jobId}`;
  const { delPersistedValues: clearForm900 } = usePersistedValues(persistForm900Key);
  const { delPersistedValues: clearFormFOD } = usePersistedValues(persistFormFODKey);

  const inspectionAreas = useMemo(
    () =>
      data ? (data.inspectionAreas.length > 0 ? data.inspectionAreas : InspectionArea.options) : [],
    [data],
  );

  useEffect(() => {
    reset();
  }, [reset]);

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const handleStep1Submit = useCallback(
    (
      values: Record<
        Part,
        Partial<{
          performedBy: string;
          checkedBy: string;
        }>
      >,
    ) => {
      setForm900({
        parts: Object.entries(values)
          .filter(([, value]) => !!value)
          .map(([key, value]) => ({
            part: key as Part,
            performedBy: value.performedBy || '',
            checkedBy: value.checkedBy || '',
          })),
      });
      nextStep();
    },
    [nextStep],
  );

  const handleSubmitPostWorkQuestionnaire = useCallback(
    async (fodAreas: FODInspectionAreas[]) => {
      if (!(form900 && jobId && serviceId)) {
        return;
      }

      return completeService({
        serviceId: ID(serviceId),
        form900: {
          ...form900,
          customerSignature,
        },
        fodAreas,
      })
        .then(() => {
          navigate(`/jobs/${jobId}/summary`, {
            replace: true,
          });

          if (data?.scheduledStart && data?.name) {
            enqueueSnackbar(
              t('post_work_questionnaire_complete_success', {
                service: data.name,
                date: format(data.scheduledStart, 'MM/dd/yyyy'),
              }),
              {
                variant: 'success',
              },
            );
          }
        })
        .then(() => {
          clearForm900();
          clearFormFOD();
          reset();
        })
        .catch((e) => {
          enqueueSnackbar(
            t('complete_error', {
              message: (e as { body?: { message?: string } }).body?.message || 'unknown',
            }),
            {
              variant: 'error',
            },
          );
        });
    },
    [
      clearForm900,
      clearFormFOD,
      completeService,
      serviceId,
      customerSignature,
      data?.name,
      data?.scheduledStart,
      enqueueSnackbar,
      form900,
      jobId,
      navigate,
      reset,
      t,
    ],
  );

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <IconButton
          onClick={() => {
            if (step !== 'form900-1') {
              previousStep();
              window.scrollTo({ top: 0, behavior: 'instant' });
              return;
            }

            goBack();
          }}
          color="primary"
        >
          <ArrowBackIcon />
        </IconButton>

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between">
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('jobs_and_services'),
                url: jobsRoutes.jobs,
              },
              {
                label: tBreadcrumbs('job_details'),
              },
            ]}
          />

          <PageTitle title={t('title')} />
        </Stack>

        <Stack flex={1} />
        {step === 'form900-2' && (
          <Button size="large" onClick={() => setCustomerSignatureModalOpen(true)}>
            {t('customer_signature_btn')}
          </Button>
        )}
      </Stack>
      {isLoadingJob && isLoading ? (
        <Stack height="40vh" alignItems="center" justifyContent="center">
          <Loader />
        </Stack>
      ) : (
        <>
          <CustomerSignatureModal
            onClose={() => setCustomerSignatureModalOpen(false)}
            open={customerSignatureModalOpen}
            signature={customerSignature || ''}
            onSave={(signature) => {
              setCustomerSignature(signature.length > 0 ? signature : undefined);
              setCustomerSignatureModalOpen(false);
            }}
          />

          {(step === 'form900-1' || step === 'form900-2') && data && inspectionAreas.length > 0 && (
            <Step1PostWorkQuestionnaire
              inspectionAreas={inspectionAreas}
              persistFormKey={persistForm900Key}
              isLoading={isPending}
              onCancel={goBack}
              onSubmit={handleStep1Submit}
            />
          )}
          {(step === 'formFOD-1' || step === 'formFOD-2') && (
            <Step2PostWorkQuestionnaire
              inspectionAreas={inspectionAreas}
              persistFormKey={persistFormFODKey}
              aircraftName={jobData ? getAircraftOptionName(jobData?.aircraft) : ''}
              service={data ? data.name : ''}
              onSubmit={handleSubmitPostWorkQuestionnaire}
              onCancel={goBack}
              isLoading={isPending}
            />
          )}
        </>
      )}
    </>
  );
};
