import { FC, SyntheticEvent, UIEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { debounce, get } from 'lodash';
import { Box, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';

import { Entity } from 'domain/types/Entity';
import { Customer } from 'domain/entities/Customer';
import { Station } from 'domain/entities/Station';
import { useCaseSearchCustomer } from 'application/customers/useCases/useCaseSearchCustomer';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Autocomplete, Button } from 'targets/web/components';
import { configurationRoutes } from 'targets/web/modules/configuration/navigation/configurationRoutes';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { FormikTextField } from 'targets/web/modules/dashboard/components/FormikTextField/FormikTextField';

const LISTBOX_SCROLL_THRESHOLD = 20;

const isValueFreeSolo = (value: Entity<Customer>): boolean =>
  !!value.id && !!value.name && value.id === value.name;

export const FormCustomerAutocomplete: FC<{
  disabled?: boolean;
  customerEditable?: boolean;
  optional?: boolean;
}> = ({ disabled, customerEditable, optional }) => {
  const { isOffline } = useNetworkStatus();

  const t = useTranslationPrefix('components.form_customer_autocomplete');
  const navigate = useNavigate();

  const { values, errors, touched, setFieldValue } = useFormikContext<{
    station?: Entity<Station>;
    customer?: Entity<Customer>;
  }>();

  const [customerPhrase, setCustomerPhrase] = useState('');
  const [page, setPage] = useState(0);
  const [fetchedCustomers, setFetchedCustomers] = useState<Entity<Customer>[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const {
    items = [],
    isLoading,
    isFetching,
    changePage,
  } = useCaseSearchCustomer({
    phrase: customerPhrase,
    // stationIds: values.station?.id ? [values.station.id] : undefined,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      return;
    }

    if (page === 0) {
      setFetchedCustomers(items);
      return;
    }

    setFetchedCustomers((prevCustomers) => [
      ...prevCustomers,
      ...items.filter((nc) => !prevCustomers.some((pc) => pc.id === nc.id)),
    ]);
  }, [items, page, isLoading, isFetching]);

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setCustomerPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const handleAddCustomerButton = useCallback(
    () =>
      navigate(
        `${configurationRoutes.configuration}/${configurationRoutes.internalRoutes.customers}`,
        { state: { shouldOpenCreateCustomerModal: true } },
      ),
    [navigate],
  );

  const handleScroll = useMemo(
    () =>
      debounce((event: UIEvent<HTMLUListElement>) => {
        const listNode = event.target as EventTarget & HTMLUListElement;

        if (
          listNode.scrollTop + listNode.clientHeight >=
            listNode.scrollHeight - LISTBOX_SCROLL_THRESHOLD &&
          !isLoading &&
          hasMore
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      }, DEFAULT_DEBOUNCE_TIME),
    [hasMore, isLoading],
  );

  useEffect(() => {
    changePage(page);
  }, [page, changePage]);

  useEffect(() => {
    setHasMore(items.length >= 0);
  }, [items]);

  useEffect(() => {
    setPage(0);
  }, [customerPhrase, setPage]);

  return (
    <Autocomplete<Entity<Customer>, false, false, boolean>
      label={t('label')}
      loading={isLoading}
      disabled={disabled || !customerEditable || values.station === null}
      freeSolo={isOffline}
      autoSelect={isOffline}
      options={fetchedCustomers}
      value={values.station === null ? null : values.customer?.id ? values.customer : null}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      data-testname="customer"
      noOptionsText={
        <Stack alignItems="start" sx={{ marginInline: -4, marginBlock: -2 }}>
          <Box p={3}>
            <Typography color="text.secondary" variant="menuItem">
              {t('no_options')}
            </Typography>
          </Box>
          <Button variant="text" startIcon={<AddIcon />} onClick={handleAddCustomerButton}>
            {t('add_customer_button_label')}
          </Button>
        </Stack>
      }
      onInputChange={handleInputChange}
      onChange={(_, value) =>
        setFieldValue('customer', typeof value === 'string' ? { name: value, id: value } : value)
      }
      ListboxProps={{ onScroll: handleScroll }}
      renderInput={(params) => {
        const isFreeSolo = values.customer && isValueFreeSolo(values.customer);
        const hasError = !!get(errors, 'customer') && (!!get(touched, 'customer') || isFreeSolo);

        return (
          <FormikTextField
            {...params}
            name="customer"
            label={t('label')}
            inputProps={{
              ...params.inputProps,
              required: !optional,
            }}
            error={hasError}
            helperText={
              hasError ? (isFreeSolo && isOffline ? t('error_free_solo') : t('error')) : undefined
            }
            required={!optional}
          />
        );
      }}
    />
  );
};
