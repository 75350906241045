import { Fade, Paper, PopperProps as MuiPopperProps, styled } from '@mui/material';
import { ClickAwayListener, ClickAwayListenerProps } from '@mui/base';
import { FC, useState } from 'react';

import { Popper } from './Popper';

interface PopoverProps extends MuiPopperProps {
  onClose: ClickAwayListenerProps['onClickAway'];
  arrow?: boolean;
}

const Arrow = styled('div')({
  position: 'absolute',
  fontSize: 8,
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
});

export const Popover: FC<PopoverProps> = ({ onClose, arrow, children, ...props }) => {
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  return (
    <Popper
      placement="top"
      transition
      arrow={arrow ?? true}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      {...props}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClose}>
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ padding: 4 }}>
              <Arrow ref={setArrowRef} className="MuiPopper-arrow" />
              <>{children}</>
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};
