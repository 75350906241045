import { Customer as APICustomer } from 'api/domain/entities/Customer';

import { Entity } from 'domain/types/Entity';

export type Customer = Entity<APICustomer>;

export const areAddressesEqual = (customer: Customer): boolean => {
  return (
    customer.mailingAddressStreet === customer.physicalAddressStreet ||
    customer.mailingAddressCity === customer.physicalAddressCity ||
    customer.mailingAddressState === customer.physicalAddressState ||
    customer.mailingAddressZip === customer.physicalAddressZip ||
    customer.mailingAddressCountry === customer.physicalAddressCountry
  );
};
