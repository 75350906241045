import * as React from 'react';
import { useEffect, useState } from 'react';
import { Part } from 'api/domain/entities/documents/PostWorkQuestionnaire';
import { FormikProvider, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Modal } from 'targets/web/components';
import { FormikTextField } from 'targets/web/modules/dashboard/components';

interface Props {
  part?: Part[];
  onClose: () => void;
  onSave: (name: Part[], confirmedBy: string) => void;
}

const ConfirmedByModal: React.FC<Props> = ({ part, onClose, onSave }) => {
  const t = useTranslationPrefix('jobs.post_work_questionnaire');

  const [input, setInput] = useState<HTMLInputElement | null>(null);

  const formik = useFormik({
    initialValues: {
      confirmedBy: '',
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        confirmedBy: z.string().max(30).min(1),
      }),
    ),
    validateOnBlur: false,
    onSubmit: ({ confirmedBy }) => {
      if (!part?.length) {
        return;
      }
      onSave(part, confirmedBy);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (input) {
      input.focus();
    }
  }, [input]);

  return (
    <FormikProvider value={formik}>
      <Modal
        data-testname="confirmedByModal"
        open={!!part?.length}
        onClose={onClose}
        disableBackdropClick
        maxWidth="sm"
        fullWidth
        actions={{
          primary: {
            disabled: !(formik.isValid && formik.dirty),
            text: t('save'),
            onClick: () => {
              if (!part?.length) {
                return;
              }
              formik.handleSubmit();
            },
          },
          secondary: {
            text: t('cancel'),
            onClick: () => {
              formik.resetForm();
              onClose();
            },
          },
        }}
        title={t('confirmed_by_title')}
        content={
          <FormikTextField
            inputRef={setInput}
            resetOnUnmount={false}
            name="confirmedBy"
            label={t('confirmed_by_label')}
          />
        }
      />
    </FormikProvider>
  );
};

export default ConfirmedByModal;
