import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import {
  ViewTab,
  useOperationViewFilters,
} from 'targets/web/modules/operations/hooks/useOperationViewFilters';
import { Tabs } from 'targets/web/components';

export const OperationViewTabs = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const t = useTranslationPrefix('operations.operations_view');

  const { viewTab, setViewTab } = useOperationViewFilters();

  return (
    <Stack direction="row" gap={4} alignItems="center" justifyContent="space-between" width={1}>
      <Tabs
        variant={isMobile ? 'scrollable' : 'standard'}
        onChange={(_, val) => setViewTab(val)}
        value={viewTab ?? '1'}
        items={[
          {
            label: (
              <Typography color="inherit" variant="labelMedium">
                {t('summary_tab')}
              </Typography>
            ),
            value: '1' satisfies ViewTab,
          },
          {
            label: (
              <Typography color="inherit" variant="labelMedium">
                {t('job_status_tab')}
              </Typography>
            ),
            value: '2' satisfies ViewTab,
          },
          {
            label: (
              <Typography color="inherit" variant="labelMedium">
                {t('job_revenue_tab')}
              </Typography>
            ),
            value: '3' satisfies ViewTab,
          },
        ]}
      />
    </Stack>
  );
};
