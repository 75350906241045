import { Stack, StackOwnProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface QuestionBoxProps extends StackOwnProps {
  size?: 'small' | 'regular';
  children?: ReactNode | ReactNode[];
}

export const QuestionBox: FC<QuestionBoxProps> = ({ size = 'regular', children, ...props }) => {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      justifyContent="space-between"
      gap={4}
      width={1}
      {...props}
      sx={{
        padding: size === 'small' ? 2 : 4,
        borderRadius: 2,
        backgroundColor: 'background.neutral',
        ...props.sx,
      }}
    >
      {children}
    </Stack>
  );
};
