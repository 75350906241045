import { FC, useCallback, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import PrintIcon from '@mui/icons-material/Print';

import { AttachmentType } from 'domain/entities/AttachmentType';
import { Attachment } from 'domain/entities/Attachment';
import { ID } from 'domain/types/ID';
import { useCaseGetAttachmentsPerJobs } from 'application/attachments/useCases/useCaseGetAttachmentsPerJob';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import IconButton from 'targets/web/components/IconButton';
import Table, { TableColumn } from 'targets/web/components/Table';
import Link from 'targets/web/components/Link';
import { IconContainer } from 'targets/web/components';

interface DocumentsTableProps {
  jobId: ID;
}

const attachmentsTypes: AttachmentType[] = [
  'preworkCommunication',
  'aircraftInspection',
  'form900',
  'fodInspection',
];

export const DocumentsTable: FC<DocumentsTableProps> = ({ jobId }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const t = useTranslationPrefix('jobs.job_details.documents');

  const {
    isLoading,
    isFetching,
    filters,
    setFilters,
    items = [],
    total,
  } = useCaseGetAttachmentsPerJobs({
    jobId,
    type: attachmentsTypes,
  });

  const mapName = useCallback(
    (type: Omit<AttachmentType, 'custom'>) => {
      switch (type) {
        case 'preworkCommunication':
          return t('preworkCommunication');
        case 'aircraftInspection':
          return t('aircraftInspection');
        case 'form900':
          return t('form900');
        case 'fodInspection':
          return t('fodInspection');
      }
    },
    [t],
  );

  const columns: TableColumn<Attachment>[] = useMemo(
    () => [
      {
        headerName: 'Document',
        field: 'type',
        renderCell: ({ type, url }) => (
          <Link underline="none" href={url} target="_blank">
            {mapName(type)}
          </Link>
        ),
      },
      {
        headerName: 'Date',
        field: 'createdAt',
        sortable: true,
        renderCell: ({ createdAt }) => (
          <Stack direction="row" alignItems="center" spacing={2}>
            {new Date(createdAt).toLocaleDateString('en-US')}
          </Stack>
        ),
      },
      {
        headerName: 'User',
        field: 'user',
        renderCell: ({ user }) =>
          user ? (
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{
                maxWidth: {
                  xs: '220px',
                  sm: 1,
                },
              }}
            >
              <Typography noWrap>{user.username}</Typography>
            </Stack>
          ) : (
            ''
          ),
      },
      {
        type: 'actions',
        headerName: 'Print',
        width: '5%',
        renderCell: ({ url }) => (
          <Link href={url} target="_blank">
            <IconButton size="small" variant="text" color="secondary">
              <PrintIcon />
            </IconButton>
          </Link>
        ),
      },
    ],
    [mapName],
  );

  return (
    <Table
      columns={columns}
      rows={items}
      rowsPerPageOptions={[10, 50, 100]}
      initialRowsPerPage={filters.limit}
      onRowsPerPageChange={(rowsPerPage) => {
        setFilters((current) => ({ ...current, limit: rowsPerPage }));
        setCurrentPage(0);
      }}
      count={total}
      onPageChange={(pageNumber) => {
        setFilters((current) => ({ ...current, offset: pageNumber * current.limit }));
        setCurrentPage(pageNumber);
      }}
      onSortChange={(sortBy, direction) => {
        setFilters((current) => ({ ...current, sortBy: sortBy, direction: direction, offset: 0 }));
        setCurrentPage(0);
      }}
      noDataOptions={{
        icon: (
          <IconContainer padding={6}>
            <InsertDriveFileOutlined />
          </IconContainer>
        ),
        text: t('noDocuments'),
      }}
      initialPage={currentPage}
      isLoading={isLoading || isFetching}
    />
  );
};
