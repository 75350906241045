import { FormikContextType } from 'formik';
import { useEffect, useMemo } from 'react';

import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

import { JobDetailsData } from './JobDetailsDataSchema';

export const useAddJobSelectDisabledLogic = ({
  values,
  setFieldValue,
}: Pick<FormikContextType<JobDetailsData>, 'values' | 'setFieldValue'>) => {
  const { isOffline } = useNetworkStatus();

  const customerSelectEnabled = useMemo(
    () => Boolean(values.station?.id) || isOffline,
    [isOffline, values.station?.id],
  );

  const aircraftSelectEnabled = useMemo(
    () => Boolean(values.station?.id && values.customer?.id) || isOffline,
    [values.station?.id, values.customer?.id, isOffline],
  );

  const serviceSelectEnabled = useMemo(
    () => Boolean(values.station?.id && values.customer?.id && values.aircraft?.id) || isOffline,
    [values.station?.id, values.customer?.id, values.aircraft?.id, isOffline],
  );

  useEffect(() => {
    if (!values.station?.id) {
      setFieldValue('customer', null);
    }
  }, [setFieldValue, values.station]);

  useEffect(() => {
    if (!values.station?.id || !values.customer?.id) {
      setFieldValue('aircraft', null);
    }
  }, [setFieldValue, values.station, values.customer]);

  useEffect(() => {
    if (!values.station?.id || !values.customer?.id || !values.aircraft?.id) {
      setFieldValue('services', []);
    }
  }, [setFieldValue, values.station, values.customer, values.aircraft, isOffline]);

  return {
    customerSelectEnabled,
    aircraftSelectEnabled,
    serviceSelectEnabled,
  };
};
