import { FC } from 'react';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { Toolbar } from 'targets/web/components/Toolbar';
import IconButton from 'targets/web/components/IconButton';
import { PrimeFlightIcon } from 'targets/web/components/Icons';
import { useNavigationDrawer } from 'targets/web/modules/dashboard/hooks/useNavigationDrawer';
import { LogoutButton, OfflineModeBanner } from 'targets/web/modules/dashboard/components';

export const DashboardToolbar: FC = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const theme = useTheme();
  const { toggle } = useNavigationDrawer();

  return (
    <>
      <Toolbar sx={{ zIndex: isDesktop ? theme.zIndex.drawer + 1 : undefined }}>
        {!isDesktop && (
          <IconButton variant="text" size="medium" onClick={() => toggle()}>
            <MenuIcon />
          </IconButton>
        )}
        <PrimeFlightIcon />
        <Box sx={{ flexGrow: 1 }} />
        <LogoutButton />
      </Toolbar>
      <OfflineModeBanner />
    </>
  );
};
