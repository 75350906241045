import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { FetchJobParams, JobRepository } from 'domain/repositories/IJobRepository';
import { ID } from 'domain/types/ID';
import { useHookInjection } from 'application/hooks';
import { usePrefetchItems } from 'application/common/usePrefetchItems';

export const QUERY_PREFIX = 'FETCH_JOB';
const JobKey = (id: ID): [string, { id: ID }] => [QUERY_PREFIX, { id }];

export const usePrefetchJobs = (jobsToPrefetch: ID[]) => {
  const { fetchJob } = useHookInjection<JobRepository>(JobRepository);

  usePrefetchItems(jobsToPrefetch, ({ queryKey }) => queryKey[1] && fetchJob(queryKey[1]), JobKey);
};

export const useCaseGetJobDetails = (params: FetchJobParams) => {
  const qClient = useQueryClient();
  const { fetchJob } = useHookInjection<JobRepository>(JobRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_PREFIX, params] as const,
    queryFn: async ({ queryKey }) => (queryKey[1].id ? fetchJob(queryKey[1]) : null),
    enabled: !!params.id,
    staleTime: 10_000,
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};
