import { Box, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { FODInspectionAreas } from 'api/domain/entities/documents/FODInspection';

import { InspectionArea } from 'domain/entities/InspectionArea';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { PreviewBox } from 'targets/web/components';
import {
  FormikCancelSaveFooter,
  PersistFormikValues,
} from 'targets/web/modules/dashboard/components';
import { usePersistedValues } from 'targets/web/modules/dashboard/hooks/usePersistedValues';
import { usePostWorkQuestionnaireSteps } from 'targets/web/modules/jobs/hooks/usePostWorkQuestionnaireSteps';

import BoolQuestionnaireSection from './BoolQuestionnaireSection';

export enum InspectionPart {
  Exterior = 'exterior',
  Interior = 'interior',
  WorkArea = 'work_area',
}

export const inspectionPartAreas: Record<InspectionPart, FODInspectionAreas[]> = {
  [InspectionPart.Interior]: [
    'interior_cockpit_seats',
    'interior_cockpit_pedals',
    'interior_galley',
    'interior_lavatory',
    'interior_forward_cabin',
    'interior_aft_cabin',
    'interior_baggage_area',
  ],
  [InspectionPart.Exterior]: [
    'exterior_full_front_view',
    'exterior_nose_landing_gear',
    'exterior_lh_main_landing_gear',
    'exterior_rh_main_landing_gear',
    'exterior_lh_engine_inlet',
    'exterior_lh_side',
    'exterior_rh_side',
    'exterior_center_engine',
    'exterior_tubes_ports',
    'exterior_lh_rh_engine_pylons',
    'exterior_rh_lh_flap_wells',
  ],
  [InspectionPart.WorkArea]: ['work_area_hangar_ramp', 'work_area_scissor_lift'],
};

export interface Step2PostWorkQuestionnaireProps {
  inspectionAreas: InspectionArea[];
  persistFormKey: string;
  onSubmit: (fodAreas: FODInspectionAreas[]) => void;
  onCancel: () => void;
  isLoading: boolean;
  aircraftName: string;
  service: string;
}

export const Step2PostWorkQuestionnaire: FC<Step2PostWorkQuestionnaireProps> = ({
  onSubmit,
  onCancel,
  isLoading,
  aircraftName,
  service,
  persistFormKey,
  inspectionAreas,
}) => {
  const t = useTranslationPrefix('jobs.post_work_questionnaire');
  const { step, previousStep, nextStep } = usePostWorkQuestionnaireSteps();

  const hasInteriorSection = inspectionAreas.includes('interior');
  const hasExteriorSection =
    inspectionAreas.includes('exterior') || inspectionAreas.includes('brightwork-boots');

  const hasLimitedWorkArea = hasInteriorSection && inspectionAreas.length === 1;

  const emptyInitialData = useMemo(
    () => ({
      ...(hasExteriorSection
        ? inspectionPartAreas[InspectionPart.Exterior].reduce(
            (acc, a) => ({ ...acc, [a]: false }),
            {},
          )
        : {}),
      ...(hasInteriorSection
        ? inspectionPartAreas[InspectionPart.Interior].reduce(
            (acc, a) => ({ ...acc, [a]: false }),
            {},
          )
        : {}),
      ...(hasLimitedWorkArea
        ? {
            [FODInspectionAreas.Values.work_area_hangar_ramp]: false,
          }
        : {
            [FODInspectionAreas.Values.work_area_hangar_ramp]: false,
            [FODInspectionAreas.Values.work_area_scissor_lift]: false,
          }),
    }),
    [hasExteriorSection, hasInteriorSection, hasLimitedWorkArea],
  );

  const { getPersistedValues } = usePersistedValues(persistFormKey);
  const [effectiveInitialValues, setEffectiveInitialValues] = useState(emptyInitialData);

  const schema = useMemo(
    () =>
      z.object({
        ...(hasExteriorSection
          ? inspectionPartAreas[InspectionPart.Exterior].reduce(
              (acc, a) => ({ ...acc, [a]: z.boolean() }),
              {},
            )
          : {}),
        ...(hasInteriorSection
          ? inspectionPartAreas[InspectionPart.Interior].reduce(
              (acc, a) => ({ ...acc, [a]: z.boolean() }),
              {},
            )
          : {}),
        ...(hasLimitedWorkArea
          ? {
              [FODInspectionAreas.Values.work_area_hangar_ramp]: z.boolean(),
            }
          : {
              [FODInspectionAreas.Values.work_area_hangar_ramp]: z.boolean(),
              [FODInspectionAreas.Values.work_area_scissor_lift]: z.boolean(),
            }),
      }),
    [hasExteriorSection, hasInteriorSection, hasLimitedWorkArea],
  );

  useEffect(() => {
    getPersistedValues().then((values) => {
      if (values) {
        setEffectiveInitialValues(values);
      }
    });
  }, [getPersistedValues]);

  const handleSubmit = useCallback(
    async (values: Partial<Record<FODInspectionAreas, boolean>>) => {
      if (step === 'formFOD-1') {
        nextStep();
        return;
      }

      onSubmit(
        Object.entries(values)
          .filter(([, val]) => val)
          .map(([key]) => key as FODInspectionAreas),
      );
    },
    [nextStep, onSubmit, step],
  );

  const isSummary = step === 'formFOD-2';

  return (
    <Box width={{ xs: 1, md: 0.6 }}>
      <Formik
        validationSchema={toFormikValidationSchema(schema)}
        onSubmit={handleSubmit}
        initialValues={effectiveInitialValues}
        enableReinitialize
        validateOnMount
      >
        <Form>
          <PersistFormikValues persistInvalid name={persistFormKey} />
          <Stack sx={{ gap: 4, alignItems: 'stretch' }}>
            <Stack direction={{ xs: 'column', md: 'row' }} gap={4} sx={{ alignItems: 'center' }}>
              <PreviewBox
                flex={1}
                width={1}
                size="regular"
                header={t('aircraft_badge_title')}
                subheader={aircraftName}
                backgroundColor="background.default"
              />
              <PreviewBox
                flex={1}
                width={1}
                size="regular"
                header={t('service_badge_title')}
                backgroundColor="background.default"
                direction="column"
                alignItems="start"
                spacing={1}
              >
                <Typography noWrap={false} sx={{ breakWord: 'break-all' }} variant="labelMedium">
                  {service}
                </Typography>
              </PreviewBox>
            </Stack>
            {hasExteriorSection && (
              <BoolQuestionnaireSection
                type="exterior"
                displayConfirmation={isSummary}
                readOnly={isSummary}
                areas={inspectionPartAreas[InspectionPart.Exterior]}
              />
            )}
            {hasInteriorSection && (
              <BoolQuestionnaireSection
                type="interior"
                displayConfirmation={isSummary}
                readOnly={isSummary}
                areas={inspectionPartAreas[InspectionPart.Interior]}
              />
            )}
            <BoolQuestionnaireSection
              type="work_area"
              displayConfirmation={isSummary}
              readOnly={isSummary}
              areas={
                hasLimitedWorkArea
                  ? [FODInspectionAreas.Values.work_area_hangar_ramp]
                  : [
                      FODInspectionAreas.Values.work_area_hangar_ramp,
                      FODInspectionAreas.Values.work_area_scissor_lift,
                    ]
              }
            />

            <FormikCancelSaveFooter
              disabled={isLoading}
              onBack={() => {
                previousStep();
                window.scrollTo({ top: 0, behavior: 'instant' });
              }}
              onCancel={onCancel}
              submitMode={isSummary ? 'confirm' : 'save'}
            />
          </Stack>
        </Form>
      </Formik>
    </Box>
  );
};
