import { styled } from '@mui/material/styles';

import Button, { ButtonProps } from 'targets/web/components/Button';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'open',
})<ButtonProps & { open?: boolean }>(({ theme, open }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.text.primary,
  padding: theme.spacing(1, 2),
  backgroundColor: open ? theme.palette.primary?.action?.selected : 'transparent',
  '& hover': {
    backgroundColor: theme.palette.primary?.action?.hover,
  },
}));
