import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC, ReactNode } from 'react';
import UserIcon from '@mui/icons-material/PersonOutlineOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import OnboardingModal from 'targets/web/modules/jobs/components/OnboardingModal';
import Tooltip from 'targets/web/components/Tooltip';
import Chip from 'targets/web/components/Chip';
import { DoubleArrowUpIcon } from 'targets/web/components';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export const OnboardingAcceptAndDeclineModal: FC<ModalProps> = ({ onClose, open }) => {
  const t = useTranslationPrefix('jobs.jobs_view.job_accept_and_decline_onboarding_modal');
  const isSmallMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(800));

  const options: { main: ReactNode; description: ReactNode }[] = [
    {
      main: (
        <Stack direction="row" justifyContent="center">
          <Stack alignContent="center">
            <Typography variant="body1" noWrap>
              {'N7777QX-0001'}
            </Typography>

            <Tooltip title={'john.doe@gmail.com'}>
              <Stack
                gap={1}
                direction="row"
                alignItems="center"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: 'text.secondary' }}
              >
                {!isSmallMobile && <UserIcon sx={{ fontSize: 20, color: 'inherit' }} />}

                <Typography variant="body2" color="secondary" noWrap>
                  {'john.doe@gmail.com'}
                </Typography>
              </Stack>
            </Tooltip>
          </Stack>
        </Stack>
      ),
      description: (
        <>
          <Typography variant="body2">{t('author.description_p1')}</Typography>
          <Typography variant="body2">{t('author.description_p2')}</Typography>
        </>
      ),
    },
    {
      main: (
        <Stack flexDirection="row" justifyContent="center">
          <Stack gap={2} direction="row">
            <Typography noWrap>{'Aug 31, 2023'}</Typography>
            <Chip label={'5 Days'} color={'error'} size="small" />
          </Stack>
        </Stack>
      ),
      description: <Typography variant="body2">{t('due.description')}</Typography>,
    },
    {
      main: (
        <Stack flexDirection="row" justifyContent="center">
          <Chip clickable={true} label={'3 Services'} color="info" size="small" />
        </Stack>
      ),
      description: <Typography variant="body2">{t('services.description')}</Typography>,
    },
    {
      main: [
        <Stack
          key="double-arrow"
          sx={{
            alignItems: 'center',
          }}
        >
          <DoubleArrowUpIcon />
        </Stack>,
        <Stack
          key="arrow-upward"
          sx={{
            alignItems: 'center',
          }}
        >
          <ArrowUpwardOutlinedIcon />
        </Stack>,
        <Stack
          key="expand-less"
          sx={{
            alignItems: 'center',
          }}
        >
          <ExpandLessOutlinedIcon />
        </Stack>,
      ],
      description: <Typography variant="body2">{t('profit.description')}</Typography>,
    },
  ];

  return <OnboardingModal title={t('title')} options={options} open={open} onClose={onClose} />;
};
