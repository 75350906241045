import { useAtom } from 'jotai';

import { atomWithToggleAndStorage } from 'infrastructure/utils/atomWithToggleAndStorage';

const drawerAtom = atomWithToggleAndStorage('isNavigationDrawerOpen', false);

export const useNavigationDrawer = () => {
  const [isOpen, toggle] = useAtom(drawerAtom);

  return {
    isOpen,
    toggle,
  };
};
