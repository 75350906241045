import { Stack, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Button from 'targets/web/components/Button';

export const ErrorView: FC<{ onGoBack: () => void }> = ({ onGoBack }) => {
  const navigate = useNavigate();
  const t = useTranslationPrefix('global');

  const goBack = useCallback(() => {
    navigate('/jobs');
    onGoBack();
  }, [navigate, onGoBack]);

  return (
    <Stack height={1} spacing={6} alignItems="center" justifyContent="center">
      <Typography variant="h1">Error</Typography>

      <Stack spacing={4} textAlign="center">
        <Typography variant="h4">Oops! Something went wrong.</Typography>

        <Typography variant="body1">
          {"We're sorry, but an unexpected error occurred while processing your request."}
        </Typography>
        <Typography variant="body1">
          {
            'Our team has been notified, and we are working to fix the issue. Please try again later.'
          }
        </Typography>
      </Stack>

      <Button onClick={goBack}>{t('go_back')}</Button>
    </Stack>
  );
};
