import { Theme, useMediaQuery } from '@mui/material';
import { FC, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Navigation, NavigationLink } from 'targets/web/components/Navigation';
import { useAvailableNavigationLinks } from 'targets/web/modules/dashboard/hooks/useAvailableNavigationLinks';
import { useNavigationDrawer } from 'targets/web/modules/dashboard/hooks/useNavigationDrawer';

export const DashboardNavigation: FC = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const accessibleLinks = useAvailableNavigationLinks();

  const { isOpen, toggle } = useNavigationDrawer();

  const handleOnClick = useCallback(
    (link: NavigationLink) => {
      if (link.url) {
        navigate(link.url);
      }

      if (!isDesktop) {
        toggle();
      }
    },
    [isDesktop, navigate, toggle],
  );

  return (
    <Navigation
      sx={{ [`& .MuiDrawer-paper`]: { marginTop: isDesktop ? 16 : 0 } }}
      links={accessibleLinks}
      selected={pathname}
      open={isOpen}
      toggleDrawer={() => toggle()}
      onClick={handleOnClick}
    />
  );
};
