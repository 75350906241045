import { FC, useId } from 'react';
import {
  InputLabel,
  OutlinedTextFieldProps,
  Stack,
  TextField as MuiOutlinedInput,
  TextFieldVariants,
  useTheme,
} from '@mui/material';

export interface TextFieldProps extends Omit<OutlinedTextFieldProps, 'variant' | 'ref'> {
  /**
   * If `true`, the label is shrunk.
   * @default false
   */
  shrinkLabel?: boolean;
  /**
   * If `true`, the input will be in read-only mode,
   * @default false
   */
  readOnly?: boolean;
  variant?: TextFieldVariants;
  /**
   * If `true`, the label is displayed inside input.
   * @default false
   */
  embeddedLabel?: boolean;
}

export const TextField: FC<TextFieldProps> = ({
  shrinkLabel,
  embeddedLabel,
  label,
  readOnly,
  ...rest
}) => {
  const id = useId();
  const theme = useTheme();

  return (
    <Stack gap={2} width="inherit">
      {label && !embeddedLabel && (
        <InputLabel
          {...rest.InputLabelProps}
          disabled={rest.disabled ?? readOnly}
          required={rest.required}
          focused={rest.focused}
          sx={{ ...theme.typography.inputLabel }}
          htmlFor={id}
        >
          {label}
        </InputLabel>
      )}
      <MuiOutlinedInput
        id={id}
        {...rest}
        sx={{
          '.MuiFormHelperText-root': {
            marginLeft: !embeddedLabel ? 0 : undefined,
            marginRight: !embeddedLabel ? 0 : undefined,
          },
          ...rest.sx,
        }}
        label={embeddedLabel ? label : undefined}
        InputLabelProps={{
          ...rest.InputLabelProps,
          ...(shrinkLabel && { shrink: true }),
        }}
        InputProps={{
          ...rest.InputProps,
          ...(readOnly && { readOnly: true }),
        }}
      />
    </Stack>
  );
};

export default TextField;
