import { WorkLog } from 'domain/entities/Worklog';

import { mapWorkLogsToDuration } from './mapWorkLogsToDuration';
import { workLogDateComparator } from './workLogDateComparator';

export const aggregateWorkLogs = (
  workLogs: WorkLog[],
): { billable: Duration; nonBillable: Duration } => {
  const billable = workLogs.filter((log) => log.billable).sort(workLogDateComparator);
  const nonBillable = workLogs.filter((log) => !log.billable).sort(workLogDateComparator);
  return {
    billable: mapWorkLogsToDuration(billable),
    nonBillable: mapWorkLogsToDuration(nonBillable),
  };
};
