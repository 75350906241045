import { FC, useState } from 'react';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

import { ID } from 'domain/types/ID';
import { isEntityLocked } from 'domain/entities/BillingStatus';
import { useCaseGetService } from 'application/services/useCases/useCaseGetService';
import { useCaseCancelService } from 'application/services/useCases/useCaseCancelService';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button } from 'targets/web/components';
import { Timer } from 'targets/web/modules/jobs/components';
import { CancelJobModal } from 'targets/web/modules/jobs/components/CancelJobModal';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';

import { Container } from './common';

export interface FooterServiceDetailsPreviewProps {
  serviceId: ID;
}

export const FooterServiceDetailsPreview: FC<FooterServiceDetailsPreviewProps> = ({
  serviceId,
}) => {
  const t = useTranslationPrefix('jobs.service_details.footer');
  const tSnackbar = useTranslationPrefix('jobs.service_details.cancel_service_modal.snackbar');
  const notify = useSnackbar();
  const { data, refetch } = useCaseGetService(serviceId ? ID(serviceId) : undefined);
  const { cancelService } = useCaseCancelService();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  if (!data) {
    return null;
  }

  if (data && (data.status === 'completed' || isEntityLocked(data) || isEntityLocked(data.job))) {
    return null;
  }

  return (
    <Container
      justifyContent="flex-end"
      direction="row"
      gap={2}
      left={0}
      padding={4}
      position="sticky"
      width="100%"
      bottom={0}
    >
      <Button
        onClick={() => setCancelModalOpen(true)}
        variant="outlined"
        color="error"
        startIcon={<CloseOutlined />}
      >
        {t('cancel_service')}
      </Button>
      {data && <Timer service={data} refresh={refetch} />}

      <CancelJobModal
        type={'service'}
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        onSubmit={(values) => {
          return cancelService({
            serviceId: ID(data.id),
            cancel: {
              reason: values.reason,
              notes: values.reason === 'other' ? values.notes : '',
            },
          }).then(
            () => {
              notify(tSnackbar('cancel_success'), {
                variant: 'secondary',
              });
            },
            () => {
              notify(tSnackbar('cancel_error'), {
                variant: 'error',
              });
            },
          );
        }}
      />
    </Container>
  );
};
