import { useEffect } from 'react';
import { useAtom } from 'jotai';

import { atomWithToggle } from 'infrastructure/utils/atomWithToggle';

const networkStatusAtom = atomWithToggle(!window.navigator.onLine);

export const useNetworkStatus = () => {
  const [isOffline, setIsOffline] = useAtom(networkStatusAtom);

  useEffect(() => {
    const handleNetworkStatusChange = (e: Event) => {
      setIsOffline(e.type === 'offline');
    };

    window.addEventListener('online', handleNetworkStatusChange);
    window.addEventListener('offline', handleNetworkStatusChange);

    return () => {
      window.removeEventListener('offline', handleNetworkStatusChange);
      window.removeEventListener('online', handleNetworkStatusChange);
    };
  }, [setIsOffline]);

  return { isOffline };
};
