import { Typography } from '@mui/material';
import { FC } from 'react';
import { useFormikContext } from 'formik';
import z from 'zod';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { TextField } from 'targets/web/components';

import { Container } from './common';

export const NotesJobDetailsSchema = z.object({
  notes: z.string().optional(),
});

export type NotesJobDetailsData = z.infer<typeof NotesJobDetailsSchema>;

export interface NotesJobDetailsProps {
  disabled?: boolean;
}

export const NotesJobDetails: FC<NotesJobDetailsProps> = ({ disabled }) => {
  const { values, handleChange } = useFormikContext<NotesJobDetailsData>();
  const t = useTranslationPrefix('jobs.details_form.notes_details');
  return (
    <Container gap={4} sx={{ padding: 4 }}>
      <Typography variant="h6">{t('title')}</Typography>
      <TextField
        label={t('notes_label')}
        placeholder={t('notes_placeholder')}
        name="notes"
        value={values.notes}
        fullWidth
        multiline
        rows={4}
        disabled={disabled}
        onChange={handleChange('notes')}
      />
    </Container>
  );
};
