import { FC, useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { addDays, addMonths, endOfDay, startOfDay } from 'date-fns';
import { format } from 'date-fns-tz';
import { EventInput } from '@fullcalendar/core';
import { ServiceSummary } from 'api/domain/entities/Service';
import { Stack, styled, Typography } from '@mui/material';
import { HandymanOutlined as ServiceCountIcon } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { differenceInDays } from 'date-fns/fp';

import { ID } from 'domain/types/ID';
import { Service } from 'domain/entities/Service';
import { ServiceStatus } from 'domain/entities/ServiceStatus';
import { useCaseShowServicesSchedule } from 'application/schedule/useCaseShowServicesSchedule';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { getLabelAndColor, getTodaysDate, toUTC } from 'targets/web/modules/jobs/utils';
import { Loader, Popover } from 'targets/web/components';
import { StyledCalendarContainer } from 'targets/web/modules/schedule/components/ServiceCalendar/StyledCalendarContainer';

import ServiceSummaryStack from './ServiceSummaryStack';
import { ServiceEventContainer } from './ServiceEventContainer';

interface Props {
  customerIds?: ID[];
  stationIds?: ID[];
  status?: ServiceStatus;
}

type SelectedService = {
  service: ServiceSummary;
  isOpen: boolean;
  element: HTMLElement;
};

const StyledPopover = styled(Popover)({
  zIndex: 9999 + 10,
});

export const ServiceCalendar: FC<Props> = ({ customerIds, stationIds, status }) => {
  const t = useTranslationPrefix('jobs.schedule.calendar');
  const calendarRef = useRef<FullCalendar | null>(null);
  const [selectedRange, setSelectedRange] = useState<{ start: Date; end: Date }>({
    // todo: handle out-of-range services on backend
    start: addMonths(new Date(), -1),
    end: addMonths(new Date(), 2),
  });
  const [selectedServices, setSelectedServices] = useState<SelectedService[]>([]);

  const { services, isLoading } = useCaseShowServicesSchedule({
    dateStart: selectedRange.start,
    dateEnd: selectedRange.end,
    customerIds,
    stationIds,
    status,
  });

  // creates a map that maps each day within selectedRange start and end Date with a number of services in that day
  const dayMap = useMemo(() => {
    const map = new Map<string, number>();
    services?.items.forEach((service) => {
      const days = differenceInDays(
        startOfDay(service.scheduledStart),
        startOfDay(service.scheduledDue),
      );
      for (let i = 0; i <= days; i++) {
        const date = addDays(service.scheduledStart, i);
        const key = format(date, 'yyyy-MM-dd');
        map.set(key, (map.get(key) || 0) + 1);
      }
    });
    return map;
  }, [services]);

  const eventMap: Map<ID, Service> = useMemo(() => {
    const map = new Map<ID, Service>();
    services?.items.forEach((s) => {
      map.set(s.id, s);
    });
    return map;
  }, [services]);

  const events: EventInput[] = useMemo(
    () =>
      services?.items.map((s) => {
        const service = eventMap.get(s.id);

        const style = getLabelAndColor(
          service?.status || 'pending',
          service?.scheduledDue || getTodaysDate(),
        );
        return {
          id: s.id,
          title: s.name,
          start: startOfDay(toUTC(s.scheduledStart)),
          end: endOfDay(toUTC(s.scheduledDue)),
          allDay: false,
          interactive: true,
          editable: true,
          className: `event-color-${style.color}`,
        };
      }) || [],
    [services?.items, eventMap],
  );

  useEffect(() => {
    const api = calendarRef.current?.getApi();
    setTimeout(() => {
      api?.updateSize();
    }, 0);
  }, [services]);

  const toggleSelectedService = (item: SelectedService) => {
    setSelectedServices((prevServices) => {
      if (prevServices.some((prevService) => prevService.service.id === item.service.id)) {
        return prevServices.filter((prevService) => prevService.service.id !== item.service.id);
      } else {
        return [...prevServices, item];
      }
    });
  };

  return (
    <StyledCalendarContainer width={1}>
      <Stack position="relative">
        <FullCalendar
          ref={(cal) => (calendarRef.current = cal)}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            start: 'today prev,next title',
            end: 'dayGridMonth,dayGridWeek',
          }}
          buttonText={{
            today: t('today'),
            month: t('monthly'),
            week: t('weekly'),
          }}
          events={events}
          eventOverlap={false}
          datesSet={(range) => {
            setSelectedRange({
              start: addMonths(range.start, -1),
              end: addMonths(range.end, 2),
            });
          }}
          timeZone="local"
          dayHeaderContent={({ date, view }) => {
            if (view.type === 'dayGridMonth') {
              return format(toUTC(date), 'eee');
            }
            return (
              <Stack
                className="header-service-count"
                direction="row"
                justifyContent="space-between"
                paddingY={3}
                paddingX={2}
              >
                <Typography variant="labelLarge">{format(date, 'eeeeee d')}</Typography>
                <Stack direction="row" alignItems="center">
                  <ServiceCountIcon />
                  <Typography variant="tooltip">
                    {dayMap.get(format(date, 'yyyy-MM-dd')) || 0}
                  </Typography>
                </Stack>
              </Stack>
            );
          }}
          views={{
            dayGridMonth: {
              dayMaxEventRows: 4,
            },
          }}
          dayCellContent={({ date }) => {
            return (
              <Stack className="day-service-count" direction="row" justifyContent="space-between">
                <Typography variant="body2">{format(date, 'd')}</Typography>
                <Stack direction="row" alignItems="center">
                  <ServiceCountIcon />
                  <Typography variant="tooltip">
                    {dayMap.get(format(date, 'yyyy-MM-dd')) || 0}
                  </Typography>
                </Stack>
              </Stack>
            );
          }}
          eventClick={({ event, el }) => {
            const service = eventMap.get(ID(event.id));
            if (!service) {
              return;
            }
            toggleSelectedService({
              service,
              isOpen: true,
              element: el,
            });
          }}
          eventContent={({ event }) => {
            const service = eventMap.get(ID(event.id));
            if (!service) {
              return null;
            }
            const style = getLabelAndColor(service.status, service.scheduledDue);

            return <ServiceEventContainer style={style} service={service} />;
          }}
        />
        {isLoading && <Loader absolute />}
      </Stack>
      {selectedServices.map((selectedService) => {
        const { color, isAfterDueDate, isDueCloseEnough } = getLabelAndColor(
          selectedService.service.status,
          selectedService.service.scheduledDue,
        );

        return (
          <ClickAwayListener
            onClickAway={() => toggleSelectedService(selectedService)}
            key={selectedService.service.id}
          >
            <StyledPopover
              disablePortal
              onClose={() => toggleSelectedService(selectedService)}
              open={Boolean(selectedService && selectedService.isOpen)}
              anchorEl={selectedService?.element}
            >
              {selectedService && (
                <ServiceSummaryStack
                  service={selectedService.service}
                  onClose={() => toggleSelectedService(selectedService)}
                  dueDateColor={isAfterDueDate || isDueCloseEnough ? color : undefined}
                />
              )}
            </StyledPopover>
          </ClickAwayListener>
        );
      })}
    </StyledCalendarContainer>
  );
};
