import { Stack, styled, Divider } from '@mui/material';
import { FC, ReactNode } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Modal from 'targets/web/components/Modal';

const StyledStack = styled(Stack)(() => ({
  backgroundColor: 'rgba(95, 99, 104, 0.08)',
  padding: '16px',
  borderRadius: '8px',
}));

interface ModalProps {
  title: string;
  options: { main: ReactNode | ReactNode[]; description: ReactNode }[];
  open: boolean;
  onClose: () => void;
}

export const OnboardingModal: FC<ModalProps> = ({ title, options, onClose, open }) => {
  const t = useTranslationPrefix('global');

  return (
    <Modal
      title={title}
      content={
        <Stack gap={8} sx={{ maxWidth: '592px' }} divider={<Divider />}>
          {options.map((opt, idx1) => (
            <Stack gap={4} key={`onboarding${idx1}`}>
              {Array.isArray(opt.main) ? (
                <Stack flexDirection="row" gap={4} justifyContent={'space-between'}>
                  {opt.main.map((r, idx2) => (
                    <StyledStack key={`onboarding${idx1}${idx2}`} gap={8} flex={1}>
                      {r}
                    </StyledStack>
                  ))}
                </Stack>
              ) : (
                <StyledStack gap={8}>{opt.main}</StyledStack>
              )}
              {opt.description}
            </Stack>
          ))}
        </Stack>
      }
      open={open}
      onClose={onClose}
      actions={{
        primary: { text: t('close'), onClick: onClose },
      }}
    />
  );
};
