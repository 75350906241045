import { FC } from 'react';
import { MenuItem } from '@mui/material';

import { ServiceStatus } from 'domain/entities/ServiceStatus';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Select } from 'targets/web/components';

interface Props {
  onChange: (status?: ServiceStatus) => unknown;
}
const StatusPicker: FC<Props> = ({ onChange }) => {
  const t = useTranslationPrefix('components.filters_container');

  return (
    <Select
      onChange={(event) => {
        onChange(event.target.value !== 'all' ? (event.target.value as ServiceStatus) : undefined);
      }}
      defaultValue="all"
      label={t('status_label')}
      renderValue={(value) => (value === 'all' ? t('all') : t(`service_status.${value}`))}
    >
      <MenuItem value="all">{t('all')}</MenuItem>
      <MenuItem value={ServiceStatus.Enum.pending}>{t('service_status.pending')}</MenuItem>
      <MenuItem value={ServiceStatus.Enum.in_progress}>{t('service_status.in_progress')}</MenuItem>
      <MenuItem value={ServiceStatus.Enum.completed}>{t('service_status.completed')}</MenuItem>
      <MenuItem value={ServiceStatus.Enum.canceled}>{t('service_status.canceled')}</MenuItem>
    </Select>
  );
};

export default StatusPicker;
