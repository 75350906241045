import { FONT_SIZE_HTML, FONT_SIZE_BODY } from './consts';

export const pxToRem = (px: number | string): string =>
  `${((typeof px === 'number' ? px : parseFloat(px)) / FONT_SIZE_HTML) * (FONT_SIZE_BODY / 14)}rem`;

export const remToPx = (rem: number | string): string =>
  `${
    ((typeof rem === 'number' ? rem : parseFloat(rem)) * FONT_SIZE_HTML) / (FONT_SIZE_BODY / 14)
  }px`;

export const lineHeightPxToUnitless = (lineHeightPx: number, fontSizePx: number) =>
  lineHeightPx / fontSizePx;

export const lineHeightUnitlessToPx = (lineHeight: number, fontSizePx: number | string) =>
  `${lineHeight * (typeof fontSizePx === 'number' ? fontSizePx : parseFloat(fontSizePx))}px`;

export const getValueAndUnit = (value: string | number): [number, string | undefined] => {
  if (typeof value === 'number') {
    return [value, ''];
  }
  const matchedValue = value.match(/^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/);
  if (matchedValue) {
    return [parseFloat(value), matchedValue[2]];
  }
  return [parseFloat(value), undefined];
};

export const roundDecimals = (value: number, decimalPlaces = 2): number =>
  Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

export const rgbaToRgb = (color: string, background?: string) => {
  const parsedBackground = background?.startsWith('rgb')
    ? background
        .match(/[.?\d]+/g)
        ?.slice(0, 3)
        .map(Number) ?? []
    : [];
  const backgroundRgb = [255, 255, 255].map(
    (defaultValue, index) => parsedBackground[index] || defaultValue,
  );
  if (color.startsWith('rgb')) {
    const parsedColor =
      color
        .match(/[.?\d]+/g)
        ?.slice(0, 4)
        .map(Number) ?? [];
    const rgba = [0, 0, 0, 0.5].map((defaultValue, index) => parsedColor[index] || defaultValue);
    const red = Math.round((1 - rgba[3]) * backgroundRgb[0] + rgba[3] * rgba[0]);
    const green = Math.round((1 - rgba[3]) * backgroundRgb[1] + rgba[3] * rgba[1]);
    const blue = Math.round((1 - rgba[3]) * backgroundRgb[2] + rgba[3] * rgba[2]);
    return '#' + ((1 << 24) | (red << 16) | (green << 8) | blue).toString(16).slice(1);
  }
  return color;
};

export const hexToRgbArray = (hex: string): [number, number, number] => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : [0, 0, 0];
};

export const createShadow = (
  offsetX = 0,
  offsetY = 0,
  blurRadius = 0,
  spreadRadius = 0,
  color: [number, number, number] | string = [0, 0, 0],
  colorOpacity = 1,
) =>
  `${offsetX}px ${offsetY}px ${blurRadius}px ${spreadRadius}px rgba(${(typeof color === 'string'
    ? hexToRgbArray(color)
    : color
  ).join(',')}, ${colorOpacity})`;
