import { FC } from 'react';
import { AttachFileOutlined } from '@mui/icons-material';
import { Stack, styled, Typography } from '@mui/material';

import { ID } from 'domain/types/ID';
import { useCaseGetAttachmentsPerJobs } from 'application/attachments/useCases/useCaseGetAttachmentsPerJob';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { IconContainer, Chip, Loader } from 'targets/web/components';
import { componentShadows } from 'targets/web/theme/shadows';

import { AddAttachmentButton } from './AddAttachmentButton';
import { AttachmentCard } from './AttachmentCard';

interface AttachmentsTableProps {
  jobId: ID;
}

const Container = styled(Stack)(({ theme }) => ({
  flex: '1 0 0',
  alignSelf: 'stretch',
  padding: theme.spacing(6),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  boxShadow: componentShadows.card,
}));

export const AttachmentsTable: FC<AttachmentsTableProps> = ({ jobId }) => {
  const t = useTranslationPrefix('jobs.job_details.attachments');

  const {
    isLoading,
    isFetching,
    items = [],
    total,
  } = useCaseGetAttachmentsPerJobs({
    jobId,
    type: ['custom'],
  });

  if (isLoading || (total === 0 && isFetching)) {
    return (
      <Container justifyContent="center" alignItems="center" minHeight={400}>
        <Loader />
      </Container>
    );
  }

  if (total === 0 && !isLoading) {
    return (
      <Container alignItems="center" justifyContent="center" gap={6}>
        <IconContainer padding={6}>
          <AttachFileOutlined sx={{ fontSize: '48px' }} />
        </IconContainer>

        <Typography variant="h5">{t('noAttachments')}</Typography>

        <AddAttachmentButton jobId={jobId} variant="contained" size="large" />
      </Container>
    );
  }

  return (
    <Container gap={6} sx={{ opacity: isFetching ? 0.6 : 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h5">{t('title')}</Typography>
          <Chip color="primary" size="small" label={total} sx={{ borderRadius: 4 }} />
        </Stack>

        <AddAttachmentButton jobId={jobId} size="small" />
      </Stack>

      <Stack gap={4}>
        {items.map((attachment) => (
          <AttachmentCard key={attachment.id} attachment={attachment} />
        ))}
      </Stack>
    </Container>
  );
};
