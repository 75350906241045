import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { ID } from 'domain/types/ID';
import { Entity } from 'domain/types/Entity';
import { Customer } from 'domain/entities/Customer';
import { useCaseSearchCustomer } from 'application/customers/useCases/useCaseSearchCustomer';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Autocomplete } from 'targets/web/components';

export const FilterCustomerAutocomplete: FC<{
  onChange: (id: ID | undefined) => void;
  label?: string;
  allOptionsLabel?: string;
}> = ({ onChange, label, allOptionsLabel }) => {
  const t = useTranslationPrefix('components.filters_container');

  const [customerPhrase, setCustomerPhrase] = useState('');
  const { items = [], isLoading } = useCaseSearchCustomer({ phrase: customerPhrase });

  const customers = useMemo(
    () => [{ id: ID('all'), name: allOptionsLabel ?? t('all') } as Entity<Customer>].concat(items),
    [allOptionsLabel, items, t],
  );

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setCustomerPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<Entity<Customer>, false, false, false>
      label={label}
      loading={isLoading}
      options={customers}
      defaultValue={customers[0]}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      data-testname="customerFilter"
      onChange={(_, value) => {
        onChange(value && value.id !== 'all' ? ID(value.id) : undefined);
      }}
    />
  );
};
