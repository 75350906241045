import { Pause, PlayArrow } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FC, useCallback, useEffect, useState } from 'react';
import { addSeconds, differenceInSeconds, intervalToDuration } from 'date-fns';

import { WorkLog } from 'domain/entities/Worklog';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

interface TimerProps {
  workLogs: WorkLog[];
  inProgress: boolean;
  onStart: () => void;
  onResume: () => void;
  onStop: () => void;
  disabled: boolean;
}

const formatDuration = (duration: Duration) => {
  return [
    duration.hours?.toString() || '0',
    (duration.minutes || 0).toString().padStart(2, '0'),
    (duration.seconds || 0).toString().padStart(2, '0'),
  ].join(':');
};

export const TimerButton: FC<TimerProps> = ({
  onStart,
  onResume,
  onStop,
  inProgress,
  workLogs,
  disabled,
}) => {
  const t = useTranslationPrefix('jobs.timers');

  const currentWorkLog = workLogs.find((wl) => !wl.endedAt);
  const isRunning = Boolean(currentWorkLog);
  const isBillable = Boolean(currentWorkLog?.billable);

  const getTotalTime = useCallback(() => {
    return workLogs
      .filter((wl) => {
        return wl.billable === isBillable;
      })
      .reduce((acc, wl) => {
        return differenceInSeconds(wl.endedAt || new Date(), wl.startedAt) + acc;
      }, 0);
  }, [workLogs, isBillable]);
  const [time, setTime] = useState(getTotalTime());

  useEffect(() => {
    if (!currentWorkLog) {
      return;
    }

    const refreshTime = () => {
      const newTime = getTotalTime();
      setTime(newTime);
    };
    refreshTime();
    const refreshInterval = setInterval(() => {
      refreshTime();
    }, 1000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [workLogs, currentWorkLog, getTotalTime]);

  const duration = intervalToDuration({ start: new Date(0), end: addSeconds(new Date(0), time) });

  return (
    <>
      {!isRunning && workLogs.length === 0 && (
        <LoadingButton
          variant="contained"
          loading={inProgress}
          color="success"
          startIcon={<PlayArrow />}
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            onStart();
          }}
        >
          {t('btn_start')}
        </LoadingButton>
      )}
      {isRunning && (
        <LoadingButton
          variant="contained"
          loading={inProgress}
          color={isBillable ? 'primary' : 'secondary'}
          startIcon={<Pause />}
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            onStop();
          }}
        >
          {formatDuration(duration)}
        </LoadingButton>
      )}
      {!isRunning && workLogs.length > 0 && (
        <LoadingButton
          variant="contained"
          loading={inProgress}
          startIcon={<PlayArrow />}
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            onResume();
          }}
        >
          {t('btn_resume')}
        </LoadingButton>
      )}
    </>
  );
};
