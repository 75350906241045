import { useAuth } from 'react-oidc-context';
import jwtDecode from 'jwt-decode';

import { IAuthContextRepositoryHook } from 'domain/repositories/IAuthContextRepositoryHook';
import { AuthContext } from 'domain/entities/AuthContext';
import { Role } from 'domain/types/Roles';

const useOIDCAuthContextRepository: IAuthContextRepositoryHook = () => {
  const auth = useAuth();
  return {
    useAuthContext(): AuthContext | undefined {
      if (!auth.isAuthenticated || !auth.user) {
        return undefined;
      }

      const { realm_access } = jwtDecode<{ realm_access: { roles: Role[] } }>(
        auth.user.access_token,
      );

      return {
        accessToken: auth.user.access_token,
        refreshToken: auth.user.refresh_token || '',
        roles: realm_access.roles,
      };
    },

    setAuthContext() {
      throw new Error("OIDC can't set auth context manually");
    },
  };
};

export default useOIDCAuthContextRepository;
