import { Stack, StackOwnProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import { IconContainer } from 'targets/web/components/IconContainer';
import { Colors } from 'targets/web/types/theme';

interface PreviewBoxProps extends StackOwnProps {
  header?: string;
  subheader?: string;
  size?: 'small' | 'regular';
  color?: Colors;
  icon?: ReactNode;
  children?: ReactNode | ReactNode[];
  backgroundColor?: string;
}

export const PreviewBox: FC<PreviewBoxProps> = ({
  size = 'small',
  icon,
  children,
  header,
  subheader,
  color,
  backgroundColor,
  ...props
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      {...props}
      sx={{
        padding: size === 'small' ? 2 : 4,
        borderRadius: 1,
        backgroundColor: backgroundColor ?? 'background.neutral',
        ...props.sx,
      }}
    >
      {icon && (
        <IconContainer padding={1} color={color ?? 'secondary'}>
          {icon}
        </IconContainer>
      )}

      {(header || subheader) && (
        <Stack gap={1}>
          {header && (
            <Typography noWrap variant="inputLabel">
              {header}
            </Typography>
          )}
          {subheader && (
            <Typography noWrap variant={size === 'small' ? 'labelSmall' : 'labelMedium'}>
              {subheader}
            </Typography>
          )}
        </Stack>
      )}

      {children}
    </Stack>
  );
};
