import { z } from 'zod';
import { FC } from 'react';
import { Form, Formik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Stack } from '@mui/material';

import { CancelJobReasonSchema } from 'domain/entities/CancelJobReason';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { stringLimited } from 'infrastructure/utils/stringLimited';
import { Modal } from 'targets/web/components';
import { FormikSelectField, FormikTextField } from 'targets/web/modules/dashboard/components';
import { useCanceledOptions } from 'targets/web/modules/jobs/hooks/useCanceledOptions';

import { CancelJobModalActions } from './CancelJobModalActions';

const CancelJobSchema = z.discriminatedUnion('reason', [
  z.object({
    reason: CancelJobReasonSchema.exclude(['other']),
  }),
  z.object({
    reason: z.literal('other'),
    notes: stringLimited(500),
  }),
]);
export type CancelJob = z.infer<typeof CancelJobSchema>;

const CancelJobFormSchema = z.discriminatedUnion('reason', [
  ...CancelJobSchema.options,
  z.object({
    reason: z.literal(''),
  }),
]);
type CancelJobFormSchema = z.infer<typeof CancelJobFormSchema>;

export interface CancelJobModalProps {
  onClose: () => void;
  onSubmit: (values: CancelJob) => Promise<void>;
  open: boolean;
  type: 'job' | 'service';
}

export const CancelJobModal: FC<CancelJobModalProps> = ({ open, onClose, onSubmit, type }) => {
  const t =
    type === 'job'
      ? useTranslationPrefix('jobs.job_details.cancel_job_modal')
      : useTranslationPrefix('jobs.service_details.cancel_service_modal');

  const selectOptions = Object.entries(useCanceledOptions()).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <Formik<CancelJobFormSchema>
      validateOnBlur={false}
      initialValues={{ reason: '' }}
      validationSchema={toFormikValidationSchema(CancelJobFormSchema)}
      onSubmit={(values, { resetForm }) => {
        if (values.reason === '') return;

        return onSubmit(values).finally(() => {
          onClose();
          resetForm();
        });
      }}
    >
      {({ values, resetForm }) => (
        <Form>
          <Modal
            fullWidth
            maxWidth="xs"
            onClose={() => {
              onClose();
              resetForm();
            }}
            open={open}
            title={t('title')}
            description={t('subtitle')}
            content={
              <Stack gap={6} width={1}>
                <FormikSelectField
                  name="reason"
                  label={t('reason_select.label')}
                  placeholder={t('reason_select.placeholder')}
                  defaultValue={''}
                  items={selectOptions}
                />

                {values.reason === 'other' ? (
                  <FormikTextField
                    name="notes"
                    label={t('notes.label')}
                    placeholder={t('notes.placeholder')}
                    multiline
                    resetOnUnmount
                    minRows={3}
                    maxRows={10}
                  />
                ) : null}
              </Stack>
            }
            actions={<CancelJobModalActions onClose={onClose} />}
          />
        </Form>
      )}
    </Formik>
  );
};
