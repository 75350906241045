import * as React from 'react';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { GlobalStyles } from '@mui/material';

import NotistackSnackbar from 'targets/web/components/NotistackSnackbar';

declare module 'notistack' {
  interface VariantOverrides {
    secondary: true;
  }
}

const SnackbarProvider: React.FC<React.PropsWithChildren> = ({ children }) => (
  <>
    <GlobalStyles styles={{ '.notistack-root-snackbar': { minWidth: 'inherit' } }} />
    <NotistackSnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      autoHideDuration={5000}
      Components={{
        error: NotistackSnackbar,
        success: NotistackSnackbar,
        info: NotistackSnackbar,
        secondary: NotistackSnackbar,
        warning: NotistackSnackbar,
      }}
      classes={{
        root: 'notistack-root-snackbar',
      }}
    >
      {children}
    </NotistackSnackbarProvider>
  </>
);

export default SnackbarProvider;
