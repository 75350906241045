import { FC } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import UserIcon from '@mui/icons-material/PersonOutlineOutlined';
import { format } from 'date-fns';

import { Job } from 'domain/entities/GetJobResponse';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import { Container } from './common';

export interface JobInformationProps {
  job: Job;
}

export const JobInformation: FC<JobInformationProps> = ({ job }) => {
  const t = useTranslationPrefix('jobs.details_form.job_information');
  const theme = useTheme();
  const createdBy = [job.creator?.firstName, job.creator?.lastName].filter(Boolean).join(' ');

  return (
    <Container gap={4} sx={{ padding: 4 }}>
      <Typography variant="h6">{t('title')}</Typography>

      <Stack flexDirection="row" gap={4}>
        <Stack
          flex={1}
          alignContent="center"
          gap={2}
          sx={{
            padding: 3,
            borderRadius: 1,
            background: theme.palette.background.neutral,
          }}
        >
          <Typography variant="inputLabel" color="text.secondary">
            {t('created_by_label')}
          </Typography>
          <Stack
            gap={2}
            direction="row"
            alignItems="center"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: 'text.secondary' }}
          >
            <UserIcon sx={{ fontSize: 20, color: 'inherit' }} />

            <Typography variant="input" color="text.primary">
              {createdBy
                ? job.createdAt
                  ? t('created_by_value', {
                      author: createdBy,
                      date: format(job.createdAt, 'MM/dd/yy'),
                    })
                  : createdBy
                : format(job.createdAt, 'MM/dd/yy')}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          flex={1}
          alignContent="center"
          gap={2}
          sx={{
            padding: 3,
            borderRadius: 1,
            background: job.completedAt ? '#0C81450A' : theme.palette.background.neutral,
          }}
        >
          <Typography variant="inputLabel" color="text.secondary">
            {t('completed_by_label')}
          </Typography>
          <Stack
            gap={2}
            direction="row"
            alignItems="center"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'text.secondary',
            }}
          >
            {job.completedAt && (
              <>
                <UserIcon sx={{ fontSize: 20, color: theme.palette.success.main }} />
                <Typography variant="labelLarge" sx={{ color: theme.palette.success.main }}>
                  {job.completer
                    ? job.completedAt
                      ? t('completed_by_value', {
                          author: `${job.completer?.firstName} ${job.completer?.lastName}`,
                          date: format(new Date(job.completedAt), 'MM/dd/yy'),
                        })
                      : `${job.completer?.firstName} ${job.completer?.lastName}`
                    : 'N/A'}
                </Typography>
              </>
            )}
            {!job.completedAt && (
              <Typography variant="labelLarge" color="text.primary">
                {t('not_completed_yet')}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};
