import { useFormikContext } from 'formik';
import { Stack } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { CheckboxCard } from 'targets/web/components';

import { PreWorkQuestionnaireFormData } from './PreWorkQuestionnaireDialog';

export const TasksFormSection = () => {
  const t = useTranslationPrefix('jobs.pre_work_questionnaire.tasks');
  const { values, setFieldValue } = useFormikContext<PreWorkQuestionnaireFormData>();

  return (
    <Stack gap={4}>
      <CheckboxCard
        label={t('aircraft_verification_title')}
        description={t('aircraft_verification_description')}
        checked={values.availabilityVerified}
        onChange={(e) => setFieldValue('availabilityVerified', e.target.checked)}
      />
      <CheckboxCard
        label={t('conduct_walkaround_title')}
        description={t('conduct_walkaround_description')}
        checked={values.walkaroundConducted}
        onChange={(e) => setFieldValue('walkaroundConducted', e.target.checked)}
      />
      <CheckboxCard
        label={t('check_hangar_title')}
        checked={values.hangarChecked}
        onChange={(e) => setFieldValue('hangarChecked', e.target.checked)}
      />
    </Stack>
  );
};
