import { styled } from '@mui/material/styles';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(4),
}));

export const DrawerContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 0),
}));
