import { Stack } from '@mui/material';
import { FC } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Breadcrumbs } from 'targets/web/components';
import { PageTitle } from 'targets/web/modules/dashboard/components';
import { PriceListsTable } from 'targets/web/modules/configuration/components';
import { configurationRoutes } from 'targets/web/modules/configuration/navigation/configurationRoutes';

export const PriceListsView: FC = () => {
  const t = useTranslationPrefix('configuration.price_lists');
  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: t('breadcrumbs.configuration'),
                url: configurationRoutes.configuration,
              },
            ]}
          />

          <PageTitle title={t('title')} />
        </Stack>
      </Stack>

      <Stack width={1}>
        <PriceListsTable />
      </Stack>
    </>
  );
};
