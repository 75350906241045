import { useCallback, useState } from 'react';
import SortingOptions, { SortingDirection } from 'common/types/SortingOptions';

export const useSortingAndPagination = <T, K extends string = never>(
  defaultSortBy: keyof T | K,
  defaultDirection?: SortingDirection,
) => {
  const [paging, setPaging] = useState({
    limit: 10,
    offset: 0,
  });

  const [sorting, setSorting] = useState<SortingOptions<T & Record<K, unknown>>>({
    sortBy: defaultSortBy,
    direction: defaultDirection ?? SortingDirection.Ascending,
  });

  const changePage = useCallback(
    (page: number) => {
      setPaging((currentPaging) => ({
        ...currentPaging,
        offset: page * currentPaging.limit,
      }));
    },
    [setPaging],
  );

  const changePageSize = useCallback(
    (pageSize: number) => {
      setPaging({
        offset: 0,
        limit: pageSize,
      });
    },
    [setPaging],
  );

  const changeSort = useCallback(
    (sortBy: keyof T | K, direction: SortingDirection) => {
      setSorting({ sortBy, direction });
      setPaging((current) => ({ ...current, offset: 0 }));
    },
    [setPaging, setSorting],
  );

  return {
    paging,
    changePage,
    changePageSize,

    sorting,
    changeSort,
  };
};
