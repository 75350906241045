import { z } from 'zod';
import { FC } from 'react';
import { Form, Formik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Stack } from '@mui/material';

import { DeclineJobRequestReasonSchema } from 'domain/entities/DeclineJobRequestReason';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { stringLimited } from 'infrastructure/utils/stringLimited';
import { Modal } from 'targets/web/components';
import { FormikSelectField, FormikTextField } from 'targets/web/modules/dashboard/components';
import { useDeclinedOptions } from 'targets/web/modules/approvals/hooks/useDeclinedOptions';

import { DeclineJobRequestModalActions } from './DeclineJobRequestModalActions';

const DeclineJobRequestSchema = z.discriminatedUnion('reason', [
  z.object({
    reason: DeclineJobRequestReasonSchema,
  }),
  z.object({
    reason: z.literal('other'),
    reviewNotes: stringLimited(500),
  }),
]);
export type DeclineJobRequest = z.infer<typeof DeclineJobRequestSchema>;

const DeclineJobRequestFormSchema = z.discriminatedUnion('reason', [
  ...DeclineJobRequestSchema.options,
  z.object({
    reason: z.literal(''),
  }),
]);
type DeclineJobRequestFormSchema = z.infer<typeof DeclineJobRequestFormSchema>;

export interface DeclineJobRequestModalProps {
  onClose: () => void;
  onSubmit: (values: DeclineJobRequest) => Promise<void>;
  open: boolean;
}

export const DeclineJobRequestModal: FC<DeclineJobRequestModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const t = useTranslationPrefix('approvals.approvals_view.decline_job_request_modal');

  const selectOptions = Object.entries(useDeclinedOptions()).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <Formik<DeclineJobRequestFormSchema>
      validateOnBlur={false}
      initialValues={{ reason: '' }}
      validationSchema={toFormikValidationSchema(DeclineJobRequestFormSchema)}
      onSubmit={(values, { resetForm }) => {
        if (values.reason === '') return;

        return onSubmit(values).finally(() => {
          onClose();
          resetForm();
        });
      }}
    >
      {({ values }) => (
        <Form>
          <Modal
            fullWidth
            maxWidth="xs"
            onClose={onClose}
            open={open}
            title={t('title')}
            description={t('subtitle')}
            content={
              <Stack gap={6} width={1}>
                <FormikSelectField
                  name="reason"
                  label={t('reason_select.label')}
                  placeholder={t('reason_select.placeholder')}
                  defaultValue={''}
                  items={selectOptions}
                />

                {values.reason === 'other' ? (
                  <FormikTextField
                    name="reviewNotes"
                    label={t('notes.label')}
                    placeholder={t('notes.placeholder')}
                    multiline
                    resetOnUnmount
                    minRows={3}
                    maxRows={10}
                  />
                ) : null}
              </Stack>
            }
            actions={<DeclineJobRequestModalActions onClose={onClose} />}
          />
        </Form>
      )}
    </Formik>
  );
};
