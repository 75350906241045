import {
  DefaultError,
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import Pagination from 'common/types/Pagination';
import { useCallback, useMemo } from 'react';

import { PaginatedResult } from 'domain/types/PaginatedResult';
import { SearchServicesParams, ServiceRepository } from 'domain/repositories/IServiceRepository';
import { Service } from 'domain/entities/Service';
import { useHookInjection } from 'application/hooks';

const QUERY_PREFIX = 'SERVICES';

export const useCaseSearchServices = (params: Omit<SearchServicesParams, 'offset'> | null) => {
  const qClient = useQueryClient();

  const { searchServices } = useHookInjection<ServiceRepository>(ServiceRepository);

  const initialPageParam: Pagination = useMemo(
    () => ({
      limit: params?.limit ?? 10,
      offset: 0,
    }),
    [params?.limit],
  );

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery<
      PaginatedResult<Service>,
      DefaultError,
      InfiniteData<PaginatedResult<Service>>,
      [string, typeof params],
      Pagination
    >({
      queryKey: [QUERY_PREFIX, params],
      enabled: !!params,
      queryFn: ({ queryKey, pageParam }) => searchServices({ ...queryKey[1], ...pageParam }),
      getNextPageParam: (lastPage, _pages, lastPageParam) => {
        if (lastPageParam.offset + lastPageParam.limit < lastPage.total) {
          return {
            ...lastPageParam,
            offset: lastPageParam.offset + lastPageParam.limit,
          };
        }

        return null;
      },
      initialPageParam,
      maxPages: 100,
      staleTime: 10_000,
      refetchInterval: 30_000,
      placeholderData: {
        pages: [{ items: [], total: 0 }],
        pageParams: [initialPageParam],
      },
    });

  const allPages = useMemo(
    () => data?.pages.map((page) => page.items).flat(1) ?? [],
    [data?.pages],
  );

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    pages: data?.pages ?? [],
    allPages,
    total: data?.pages[0]?.total ?? 0,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
  };
};
