import { ContainerModule } from 'inversify';

import { AuthContextRepository } from 'domain/repositories/IAuthContextRepositoryHook';
import { LogoutAdapter } from 'application/auth/useCases/useCaseLogout';
import { AccessProvider } from 'application/auth/providers/IAccessProvider';

import useOIDCAuthContextRepository from './OIDCAuthContextRepository';
import OIDCLogoutAdapter from './OIDCLogoutAdapter';
import { useOIDCAccessProvider } from './OIDCAccessProvider';

export default new ContainerModule((bind) => {
  bind(AuthContextRepository).toConstantValue(useOIDCAuthContextRepository);
  bind(AccessProvider).toFunction(useOIDCAccessProvider);
  bind(LogoutAdapter).to(OIDCLogoutAdapter);
});
