import { FC, PropsWithChildren } from 'react';

import { Role } from 'domain/types/Roles';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import ForbiddenView from 'targets/web/modules/dashboard/views/Forbidden.view';

type RoleLimitedRouteProps = PropsWithChildren<{
  role: Role;
}>;

export const RoleLimitedRoute: FC<RoleLimitedRouteProps> = ({ role, children }) => {
  const { hasRole } = useCaseCheckAccess();

  if (!hasRole(role)) {
    return <ForbiddenView />;
  }

  return <>{children}</>;
};
