import { FC, ReactNode } from 'react';
import { AppBar, Toolbar as MuiToolbar, AppBarProps } from '@mui/material';

export interface ToolbarProps extends AppBarProps {
  children?: ReactNode;
}

export const Toolbar: FC<ToolbarProps> = ({ children, ...rest }) => (
  <AppBar {...rest}>
    <MuiToolbar>{children}</MuiToolbar>
  </AppBar>
);

export default Toolbar;
