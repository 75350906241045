import { useMemo } from 'react';

import { DeclineJobRequestReason } from 'domain/entities/DeclineJobRequestReason';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

export const useCanceledOptions = (): Record<DeclineJobRequestReason, string> => {
  const tOptions = useTranslationPrefix('jobs.job_details.cancel_job_modal.reason_select.options');

  return useMemo(
    () => ({
      'ac-schedule-change': tOptions('ac_schedule_change'),
      duplicate: tOptions('duplicate'),
      'unable-to': tOptions('unable_to'),
      'labor-unavailable': tOptions('labor_unavailable'),
      'lack-of-hangar-space': tOptions('lack_of_hangar_space'),
      'maintenance-conflict': tOptions('maintenance_conflict'),
      weather: tOptions('weather'),
      other: tOptions('other'),
    }),
    [tOptions],
  );
};
