import { styled } from '@mui/material/styles';

export const ModalChip = styled('div')(({ theme }) => ({
  height: '24px',
  borderRadius: '32px',
  backgroundColor: theme.palette.action.active,
  display: 'flex',
  gap: '4px',
  padding: '4px 8px',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.primary.contrastText,

  '.MuiSvgIcon-root': {
    fontSize: '16px',
  },

  '.MuiTypography-root': {
    fontSize: '13px',
  },
}));

export default ModalChip;
