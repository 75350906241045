import { Stack, styled } from '@mui/material';
import { Form, Formik } from 'formik';
import { FC, ReactElement } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import BasicServiceDetails, { BasicServiceDetailsSchema } from './BasicServiceDetails';
import TimeTrackingServiceDetails, {
  TimeTrackingServiceDetailsSchema,
} from './TimeTrackingServiceDetails';
import BillingServiceDetails, {
  BillingServiceDetailsProps,
  BillingServiceDetailsSchema,
} from './BillingServiceDetails';
import ExternalServiceDetails, { ExternalServiceDetailsSchema } from './ExternalServiceDetails';
import NotesServiceDetails, { NotesServiceDetailsSchema } from './NotesServiceDetails';
import WaitTimeServiceDetails, {
  WaitTimeServiceDetailsProps,
  WaitTimeServiceDetailsSchema,
} from './WaitTimeServiceDetails';

export const ServiceDetailsFormSchema = BasicServiceDetailsSchema.merge(
  BillingServiceDetailsSchema.merge(
    WaitTimeServiceDetailsSchema.merge(
      ExternalServiceDetailsSchema.merge(
        TimeTrackingServiceDetailsSchema.merge(NotesServiceDetailsSchema),
      ),
    ),
  ),
);

export type ServiceDetailsFormData = z.infer<typeof ServiceDetailsFormSchema>;

export interface ServiceDetailsFormProps {
  footer?: ReactElement;
  types: BillingServiceDetailsProps['types'];
  waitTimeReasons: WaitTimeServiceDetailsProps['waitTimeReasons'];
  discountPercentages: BillingServiceDetailsProps['discountPercentages'];
  quantity: BillingServiceDetailsProps['quantity'];
  disabled: boolean;
  initialValues: ServiceDetailsFormData;
  onSubmit: (data: ServiceDetailsFormData) => void;
}

const FullWidthForm = styled(Form)(() => ({
  width: '100%',
}));

const ServiceDetailsForm: FC<ServiceDetailsFormProps> = ({
  footer,
  types,
  waitTimeReasons,
  discountPercentages,
  quantity,
  disabled,
  initialValues,
  onSubmit,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validateOnMount
    enableReinitialize
    validationSchema={toFormikValidationSchema(ServiceDetailsFormSchema)}
  >
    <FullWidthForm>
      <Stack gap={4}>
        <BasicServiceDetails disabled={disabled} />
        <TimeTrackingServiceDetails disabled={disabled} />
        <BillingServiceDetails
          disabled={disabled}
          types={types}
          discountPercentages={discountPercentages}
          quantity={quantity}
        />
        <ExternalServiceDetails />
        <NotesServiceDetails disabled={disabled} />
        <WaitTimeServiceDetails disabled={disabled} waitTimeReasons={waitTimeReasons} />
        {footer}
      </Stack>
    </FullWidthForm>
  </Formik>
);

export default ServiceDetailsForm;
