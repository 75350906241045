import { ChangeEvent, useCallback, useRef } from 'react';
import { AttachFileOutlined } from '@mui/icons-material';
import { closeSnackbar } from 'notistack';

import { ID } from 'domain/types/ID';
import { useCaseUploadFile } from 'application/attachments/useCases/useCaseUploadFile';
import usePermission from 'application/auth/hooks/usePermission';
import { JobResourceType } from 'application/auth/utils/resources';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button, ButtonProps, Loader } from 'targets/web/components';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';

import { ACCEPTED_FILE_EXT } from './constants';

const acceptedFiles = ACCEPTED_FILE_EXT.join(', ');

export const AddAttachmentButton = ({ jobId, ...props }: ButtonProps & { jobId: ID }) => {
  const t = useTranslationPrefix('jobs.job_details.attachments');
  const notify = useSnackbar();

  const ref = useRef<HTMLInputElement | null>(null);

  const { uploadFile } = useCaseUploadFile();

  const { granted, isLoading: accessLoading } = usePermission({
    resource: `${JobResourceType}:${jobId}`,
    scope: 'create-attachment',
  });

  const handleInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || !event.target.files[0]) {
        return;
      }

      const loadingSnackbarKey = notify(t('snackbar.uploading'), {
        variant: 'secondary',
      });

      const file = event.target.files[0];
      await uploadFile({ jobId, file }).catch((e) => {
        closeSnackbar(loadingSnackbarKey);
        notify(t('snackbar.error'), {
          variant: 'error',
        });

        throw e;
      });

      closeSnackbar(loadingSnackbarKey);
      notify(t('snackbar.success'), {
        variant: 'success',
      });

      event.target.value = '';
    },
    [jobId, notify, t, uploadFile],
  );

  const handleButtonClick = useCallback(() => {
    ref.current?.click();
  }, [ref]);

  if (accessLoading) {
    return <Loader size={24} />;
  }

  if (!granted) {
    return null;
  }

  return (
    <>
      <input type="file" accept={acceptedFiles} hidden onChange={handleInputChange} ref={ref} />
      <Button
        variant="outlined"
        startIcon={<AttachFileOutlined />}
        onClick={handleButtonClick}
        {...props}
      >
        {t('addAttachments')}
      </Button>
    </>
  );
};
