import { FC, useCallback, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import DollarIcon from '@mui/icons-material/AttachMoneyOutlined';
import { Box, Chip, MenuItem, Typography } from '@mui/material';

import { BillingStatus } from 'domain/entities/BillingStatus';
import { ID } from 'domain/types/ID';
import usePermission from 'application/auth/hooks/usePermission';
import { JobResourceType } from 'application/auth/utils/resources';
import { Menu } from 'targets/web/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

interface BillableStatusChipProps {
  billingStatus: BillingStatus;
  editable?: boolean;
  onSelect: (status: BillingStatus) => void;
  jobId: ID;
  optimisticPermissions?: boolean;
}

const chipBillingStatusMap: Record<
  BillingStatus,
  { label: string; color: 'default' | 'success' | 'error' }
> = {
  non_billable: { color: 'default', label: 'Not Ready' },
  billable: { color: 'success', label: 'Ready' },
  sent_to_sage: {
    color: 'success',
    label: 'Sent to Sage',
  },
};

export const BillingStatusChip: FC<BillableStatusChipProps> = ({
  billingStatus,
  editable = true,
  onSelect,
  jobId,
  optimisticPermissions,
}) => {
  const { isOffline } = useNetworkStatus();
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { granted: viewGranted } = usePermission(
    {
      resource: `${JobResourceType}:${jobId}`,
      scope: 'view-billing-status',
    },
    optimisticPermissions,
  );

  const isClickable = billingStatus !== BillingStatus.Values.sent_to_sage && editable && !isOffline;
  const { granted: editGranted } = usePermission(
    isClickable
      ? {
          resource: `${JobResourceType}:${jobId}`,
          scope: 'set-billing-status',
        }
      : undefined,
    optimisticPermissions,
  );

  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);
  const closeMenu = useCallback(() => setIsMenuOpen(false), []);

  if (!viewGranted) {
    return null;
  }

  return (
    <Box
      data-testname="billingStatusChip"
      onClick={(e) => e.stopPropagation()}
      white-space="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
    >
      <Chip
        ref={setMenuRef}
        label={chipBillingStatusMap[billingStatus].label}
        color={chipBillingStatusMap[billingStatus].color}
        size="small"
        icon={<DollarIcon />}
        clickable={isClickable}
        onClick={isClickable && editGranted ? openMenu : undefined}
        onDelete={isClickable && editGranted ? openMenu : undefined}
        deleteIcon={<ArrowDropDownIcon />}
      />

      <Menu
        anchorEl={menuRef}
        slotProps={{
          paper: {
            sx: {
              marginTop: 2,
            },
          },
        }}
        sx={{
          '.MuiList-root.MuiMenu-list': {
            padding: 0,
          },
        }}
        open={isMenuOpen}
        onClose={closeMenu}
      >
        <Box padding={4}>
          <Typography variant="labelMedium">Select Billing Status</Typography>
        </Box>
        {[
          {
            ...chipBillingStatusMap.non_billable,
            onClick: () => onSelect(BillingStatus.Values.non_billable),
          },
          {
            ...chipBillingStatusMap.billable,
            onClick: () => onSelect(BillingStatus.Values.billable),
          },
        ].map(({ label, color, onClick }, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onClick();
              closeMenu();
            }}
            sx={{ padding: 4 }}
          >
            <Chip label={label} color={color} icon={<DollarIcon />} size="small" />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
