import { useMutation } from '@tanstack/react-query';

import { DeclineJobRequestParams, ReviewJobParams } from 'domain/entities/DeclineJobRequestParams';
import { JobRequestRepository } from 'domain/repositories/IJobRequestRepository';
import { useHookInjection } from 'application/hooks';

import { useCaseSearchJobRequests } from './useCaseSearchJobRequests';
import { useCaseGetJobRequestsCountByStatus } from './useCaseGetJobRequestsCountByStatus';

export const useCaseDeclineJobRequest = () => {
  const { declineJobRequest } = useHookInjection<JobRequestRepository>(JobRequestRepository);

  const { refetch: refetchJobRequestList } = useCaseSearchJobRequests(null);
  const { refetch: refetchJobRequestCount } = useCaseGetJobRequestsCountByStatus(null);

  const { mutateAsync } = useMutation<void, Error, DeclineJobRequestParams | ReviewJobParams>({
    mutationFn: declineJobRequest,
    onSuccess: () => {
      refetchJobRequestList();
      refetchJobRequestCount();
    },
  });

  return {
    declineJobRequest: mutateAsync,
  };
};
