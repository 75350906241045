import { FC, useMemo, useState } from 'react';

import { SearchAssociationsParams } from 'domain/repositories/IPricesRepository';
import { PopulatedPriceListAssociation } from 'domain/entities/PopulatedPriceListAssociation';
import { useCaseSearchAssociations } from 'application/prices/useCases/useCaseSearchAssociations';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { TableColumn } from 'targets/web/components';
import { TableWithSearch } from 'targets/web/components/TableWithSearch';

export const AssociationsTable: FC<
  Pick<SearchAssociationsParams, 'priceListId' | 'stationId' | 'customerId'>
> = (params) => {
  const tTable = useTranslationPrefix('configuration.price_list_details.associations_table');

  const [phrase, setPhrase] = useState('');
  const {
    items = [],
    total,
    isLoading,
    isFetching,

    paging,
    changePage,
    changePageSize,

    sorting,
    changeSort,
  } = useCaseSearchAssociations({ ...params, phrase });

  const columns: TableColumn<PopulatedPriceListAssociation>[] = useMemo(
    () => [
      {
        headerName: tTable('price_list_name_column'),
        field: 'priceList',
        sortable: true,
        renderCell: ({ priceList }) => priceList.name,
      },
      {
        headerName: tTable('station_column'),
        field: 'station',
        sortable: true,
        renderCell: ({ station }) => station?.name ?? tTable('all_stations'),
      },
      {
        headerName: tTable('customer_column'),
        field: 'customer',
        sortable: true,
        renderCell: ({ customer }) => customer?.name,
      },
    ],
    [tTable],
  );

  return (
    <TableWithSearch
      columns={columns}
      searchPhrasePlaceholder={tTable('search_placeholder')}
      rowsPerPageOptions={[10, 50, 100]}
      rows={items}
      count={total}
      orderBy={sorting.sortBy}
      orderDirection={sorting.direction}
      initialPage={Math.round(paging.offset / paging.limit)}
      isLoading={isLoading || isFetching}
      onSearchPhraseChange={setPhrase}
      onRowsPerPageChange={changePageSize}
      onPageChange={changePage}
      onSortChange={changeSort}
    />
  );
};
