import { Stack, Typography, Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import DatePicker from 'targets/web/components/DatePicker';
import Select from 'targets/web/components/Select';
import TextField from 'targets/web/components/TextField';
import OnboardingModal from 'targets/web/modules/jobs/components/OnboardingModal';
import Autocomplete from 'targets/web/components/Autocomplete';
import IconButton from 'targets/web/components/IconButton';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export const OnboardingAddAndConfirmModal: FC<ModalProps> = ({ onClose, open }) => {
  const t = useTranslationPrefix('jobs.jobs_view.job_add_and_confirm_onboarding_modal');

  const options: { main: ReactNode; description: ReactNode }[] = [
    {
      main: (
        <Stack gap={4}>
          <Autocomplete<[{ name: string }][number], false, false, false>
            disablePortal
            disabled={false}
            options={[{ name: 'ICT-SVC' }]}
            value={{ name: 'ICT-SVC' }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Station" fullWidth required />}
          />
          <Autocomplete<[{ name: string }][number], false, false, false>
            disablePortal
            disabled={false}
            options={[{ name: 'NET JETS' }]}
            value={{ name: 'NET JETS' }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Customer" fullWidth required />}
          />
        </Stack>
      ),
      description: <Typography variant="body2">{t('primary_details.description')}</Typography>,
    },
    {
      main: <DatePicker label={'Job Start'} value={null} />,
      description: <Typography variant="body2">{t('scheduled.description')}</Typography>,
    },
    {
      main: (
        <Select
          label={'Recurring Type'}
          fullWidth
          disabled={true}
          items={[{ label: 'None', value: 'none' }]}
          value={'none'}
        />
      ),
      description: <Typography variant="body2">{t('recurring_billing.description')}</Typography>,
    },
    {
      main: (
        <TextField label={'Notes'} placeholder={'Type something...'} fullWidth multiline rows={4} />
      ),
      description: <Typography variant="body2">{t('notes.description')}</Typography>,
    },
    {
      main: (
        <Stack flex={1} direction={'row'} alignItems={'end'} gap={2}>
          <Autocomplete<[{ name: string }][number], false, false, false>
            disablePortal
            disabled={false}
            options={[{ name: 'Brightwork' }]}
            value={{ name: 'Brightwork' }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Service Name" fullWidth required />
            )}
            sx={{ flex: 3 }}
          />
          <Box flex={3}>
            <TextField
              type="number"
              label="Estimate"
              disabled={false}
              InputProps={{ inputProps: { min: 1 }, endAdornment: 'h' }}
            />
          </Box>
          <Box flex={1} textAlign={'right'}>
            <IconButton variant="text" color="error">
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        </Stack>
      ),
      description: <Typography variant="body2">{t('service_estimation.description')}</Typography>,
    },
  ];

  return <OnboardingModal title={t('title')} options={options} open={open} onClose={onClose} />;
};
