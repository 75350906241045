import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useQueryClient } from '@tanstack/react-query';

import { IRepositoryAvailabilityHook } from 'domain/repositories/IRepositoryAvailabilityHook';
import { useHookInjection } from 'application/hooks';
import axiosInstance from 'infrastructure/api';

const OIDCAxiosHandler: FC<PropsWithChildren> = ({ children }) => {
  const { signinSilent, signinRedirect, user } = useAuth();
  const { isAvailable } = useHookInjection<IRepositoryAvailabilityHook>(
    IRepositoryAvailabilityHook,
  );
  const queryClient = useQueryClient();
  const prevIsAvailable = useRef<boolean>();
  const haveRefreshedTokenAfterBeingOnlineRef = useRef(false);
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const userAccessToken = user?.access_token;

  useEffect(() => {
    if (triggerRefetch) {
      setTriggerRefetch(false);
      queryClient.invalidateQueries().catch(console.warn);
    }
  }, [triggerRefetch, queryClient]);

  useEffect(() => {
    if (userAccessToken && haveRefreshedTokenAfterBeingOnlineRef.current) {
      // trigger refetch in another lifecycle to make sure all clients are updated with new access tokens
      haveRefreshedTokenAfterBeingOnlineRef.current = false;
      setTimeout(() => {
        setTriggerRefetch(true);
      }, 0);
    }
  }, [userAccessToken]);

  useEffect(() => {
    if (!userAccessToken) {
      return;
    }
    const accessToken = userAccessToken;
    axiosInstance.interceptors.request.clear();
    const accessTokenInterceptor = axiosInstance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
    return () => {
      axiosInstance.interceptors.request.eject(accessTokenInterceptor);
    };
  }, [queryClient, userAccessToken]);

  useEffect(() => {
    // went back online
    if (isAvailable && prevIsAvailable.current === false) {
      signinSilent()
        .catch(() => {
          console.warn('Could not sign in silently after going back online');
          return signinRedirect().catch(console.error);
        })
        .then(() => {
          haveRefreshedTokenAfterBeingOnlineRef.current = true;
        });
    }
    prevIsAvailable.current = isAvailable;
  }, [queryClient, isAvailable, signinSilent, signinRedirect]);

  return children;
};

export default OIDCAxiosHandler;
