import { Typography } from '@mui/material';
import { FC } from 'react';
import z from 'zod';

import { entityId } from 'domain/types/ID';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { FormCustomerAutocomplete } from 'targets/web/modules/dashboard/components';

import { Container } from './common';
import { StationAutocomplete } from './StationAutocomplete';

export const PrimaryJobDetailsSchema = z.object({
  station: z.object({
    id: entityId(),
    code: z.string(),
    name: z.string(),
    state: z.string(),
  }),
  customer: z.object({
    id: entityId(),
    code: z.string().optional(),
    name: z.string().optional(),
  }),
});

export type PrimaryJobDetailsData = z.infer<typeof PrimaryJobDetailsSchema>;

export interface PrimaryJobDetailsProps {
  disabled?: boolean;
  stationEditable?: boolean;
  customerEditable?: boolean;
}

export const PrimaryJobDetails: FC<PrimaryJobDetailsProps> = ({
  disabled,
  stationEditable,
  customerEditable,
}) => {
  const t = useTranslationPrefix('jobs.details_form.primary_details');

  return (
    <Container gap={4} sx={{ padding: 4 }}>
      <Typography variant="h6">{t('title')}</Typography>

      <StationAutocomplete disabled={disabled} stationEditable={stationEditable} />

      <FormCustomerAutocomplete disabled={disabled} customerEditable={customerEditable} />
    </Container>
  );
};
