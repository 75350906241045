import { Stack, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Button from 'targets/web/components/Button';

export const PageForbiddenView: FC = () => {
  const navigate = useNavigate();
  const t = useTranslationPrefix('global');

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <Stack height={1} spacing={6} alignItems="center" justifyContent="center">
      <Typography variant="h1">403</Typography>

      <Stack spacing={4} textAlign="center">
        <Typography variant="h4">{t('forbidden.title')}</Typography>

        <Typography variant="body1">{t('forbidden.subtitle')}</Typography>
      </Stack>

      <Button onClick={goBack}>{t('go_back')}</Button>
    </Stack>
  );
};

export default PageForbiddenView;
