import { Part } from 'api/domain/entities/documents/PostWorkQuestionnaire';
import { z, ZodString } from 'zod';

export const Form900Schema = (parts: Part[]) =>
  z.object(
    parts.reduce(
      (obj, key: Part) => ({
        ...obj,
        [key]: z.object({
          performedBy: z.string().optional(),
          checkedBy: z.string().optional(),
        }),
      }),
      {} as Record<
        Part,
        z.ZodObject<{
          performedBy: z.ZodOptional<ZodString>;
          checkedBy: z.ZodOptional<ZodString>;
        }>
      >,
    ),
  );
