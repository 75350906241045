import { differenceInDays, isAfter, isSameDay } from 'date-fns';
import { ChipProps } from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  HandymanOutlined as ToolsIcon,
  HourglassEmpty as HourglassEmptyIcon,
} from '@mui/icons-material';
import { ReactElement } from 'react';

import { ChipStatus } from 'targets/web/modules/jobs/components';
import { toUTC } from 'targets/web/modules/jobs/utils';

export const chipJobStatusMap = {
  submitted: {
    color: 'default' as const,
    label: 'Not Started',
  },
  pending: {
    color: 'default' as const,
    label: 'Not Started',
  },
  canceled: {
    color: 'error' as const,
    label: 'Canceled',
  },
  declined: {
    color: 'error' as const,
    label: 'Declined',
  },
  completed: {
    color: 'success' as const,
    label: 'Completed',
  },
};
const WARNING_THRESHOLD_IN_DAYS = 5;
export const getLabelAndColor = (
  status: ChipStatus,
  dueDate: Date,
): Required<
  Pick<ChipProps, 'color' | 'label'> & { isAfterDueDate: boolean; isDueCloseEnough: boolean }
> => {
  // we use day-to-day granularity to calculate the difference
  const isAfterDueDate =
    isAfter(toUTC(new Date()), toUTC(dueDate)) && !isSameDay(toUTC(new Date()), toUTC(dueDate));
  const daysDifference = differenceInDays(toUTC(new Date()), toUTC(dueDate));
  const isDueCloseEnough = daysDifference >= -WARNING_THRESHOLD_IN_DAYS;

  // not started for both Jobs and Services
  if (status === 'scheduled' || status === 'pending') {
    /*
     * -1 -> 1 day
     * 0  -> 0 days
     * 3  -> 3 days
     * 1  -> 1 day
     * etc.
     * */
    const daysLabel = `${Math.abs(daysDifference)} ${
      Math.abs(daysDifference) === 1 ? 'Day' : 'Days'
    }`;

    /*
     * overdue -> error
     * today = due -> warning
     * due in less or equal than 5 days -> warning
     * due in more than 5 days -> default
     * */
    const color = isAfterDueDate ? 'error' : isDueCloseEnough ? 'warning' : 'default';

    /*
     * overdue -> N day(s) Overdue
     * today = due -> 0 days Left
     * due in less or equal than 5 days -> N day(s) Left
     * due in more than 5 days -> just display the status
     * */
    const label = isAfterDueDate
      ? `${daysLabel} Overdue`
      : isDueCloseEnough
      ? `${daysLabel} Left`
      : chipJobStatusMap.submitted.label;

    return {
      color,
      label,
      isAfterDueDate,
      isDueCloseEnough,
    };
  }

  if (status === 'in_progress') {
    return {
      color: isAfterDueDate ? 'error' : isDueCloseEnough ? 'warning' : 'primary',
      label: `In Progress${
        isAfterDueDate || isDueCloseEnough ? ` (${Math.abs(daysDifference)})` : ''
      }`,
      isAfterDueDate,
      isDueCloseEnough,
    };
  }

  return { ...chipJobStatusMap[status], isAfterDueDate, isDueCloseEnough };
};
export const iconsMap: Record<ChipStatus, ReactElement> = {
  submitted: <HourglassEmptyIcon />,
  pending: <HourglassEmptyIcon />,
  scheduled: <HourglassEmptyIcon />,
  in_progress: <ToolsIcon />,
  declined: <CloseIcon />,
  canceled: <CloseIcon />,
  completed: <CheckIcon />,
};
