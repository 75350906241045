import { Part } from 'api/domain/entities/documents/PostWorkQuestionnaire';
import { FC } from 'react';
import { Grid, Typography } from '@mui/material';

import { InspectionArea } from 'domain/entities/InspectionArea';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import { QuestionnairePart } from './QuestionnairePart';
import { sectionsMap } from './sectionsMap';

export const InspectionQuestionnaireSection: FC<{
  inspectionPart: InspectionArea;
  onPerformedByClick: (parts: Part[]) => void;
  onConfirmedByClick: (parts: Part[]) => void;
}> = ({ inspectionPart, onPerformedByClick, onConfirmedByClick }) => {
  const t = useTranslationPrefix('jobs.post_work_questionnaire');

  return (
    <>
      <Typography variant="h4">{t(`${inspectionPart}.title`)}</Typography>
      <Grid container spacing={4} data-testname={`${inspectionPart}`}>
        {sectionsMap[inspectionPart].map((parts, index) => (
          <QuestionnairePart
            key={index}
            onPerformedByClick={onPerformedByClick}
            onConfirmedByClick={onConfirmedByClick}
            parts={parts}
          />
        ))}
      </Grid>
    </>
  );
};
