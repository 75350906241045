import { useMutation } from '@tanstack/react-query';

import { CustomerRepository, EditCustomerData } from 'domain/repositories/ICustomerRepository';
import { useHookInjection } from 'application/hooks';

import { useCaseSearchCustomer } from './useCaseSearchCustomer';

export const useCaseEditCustomer = () => {
  const { editCustomer } = useHookInjection<CustomerRepository>(CustomerRepository);

  const { refetch } = useCaseSearchCustomer(null);

  const { mutateAsync, isPending } = useMutation<void, Error, EditCustomerData>({
    mutationFn: editCustomer,
    onSuccess: () => {
      refetch();
    },
  });

  return {
    editCustomer: mutateAsync,
    isPending,
  };
};
