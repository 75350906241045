import { FC, ReactNode } from 'react';
import MuiBreadcrumbs, { BreadcrumbsProps as MuiBreadcrumbsProps } from '@mui/material/Breadcrumbs';
import { Link, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';

export interface Breadcrumb {
  label: string;
  url?: string;
}

export interface BreadcrumbsProps extends Omit<MuiBreadcrumbsProps, 'onClick'> {
  items?: Breadcrumb[];
  children?: ReactNode;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ items, children, ...props }) => {
  const itemsLinks = items?.map((breadcrumb, index) =>
    breadcrumb.url ? (
      <Link
        key={index}
        variant="body2"
        color="text.secondary"
        underline="none"
        component={RouterLink}
        to={breadcrumb.url}
      >
        {breadcrumb.label}
      </Link>
    ) : (
      <Typography key={index} color="text.secondary" variant="body2">
        {breadcrumb.label}
      </Typography>
    ),
  );

  const breadcrumbs = children || itemsLinks;

  return (
    <MuiBreadcrumbs
      {...props}
      sx={{
        alignItems: 'center',
        ...props.sx,
      }}
      separator={<ChevronRightIcon color="action" fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
