import { Stack, styled } from '@mui/material';

import { componentShadows } from 'targets/web/theme/shadows';

export const CardStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  boxShadow: componentShadows.card,
}));

export default CardStack;
