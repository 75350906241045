import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { JobApprovalItem } from 'domain/entities/JobApprovalItem';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Modal from 'targets/web/components/Modal';

export const ServicesInfoModal: FC<{
  onClose: () => void;
  jobApprovalItem: JobApprovalItem | null;
}> = ({ onClose, jobApprovalItem }) => {
  const t = useTranslationPrefix('approvals.approvals_view.services_info_modal');

  const services =
    (jobApprovalItem?.type === 'jobRequest'
      ? jobApprovalItem?.jobRequest?.requestedServices
      : jobApprovalItem?.job.services) || [];

  return (
    <Modal
      onClose={onClose}
      open={Boolean(jobApprovalItem)}
      title={t('title', { name: jobApprovalItem?.name ?? '' })}
      content={
        <Stack component="ul" sx={{ paddingLeft: 6, margin: 0 }}>
          {services.map((service) => (
            <Box component="li" key={service.id}>
              <Typography>
                {[service.code, service.code !== service.name ? service.name : undefined]
                  .filter(Boolean)
                  .join(' - ')}
              </Typography>
            </Box>
          ))}
        </Stack>
      }
      actions={{
        primary: {
          text: t('close'),
          onClick: onClose,
        },
      }}
    />
  );
};
