import { FC, useCallback, useMemo } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Form, Formik } from 'formik';
import z, { IssueData } from 'zod';
import { Box, Grid } from '@mui/material';
import { FormikHelpers } from 'formik/dist/types';

import { entityId, ID } from 'domain/types/ID';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { stringLimited } from 'infrastructure/utils/stringLimited';
import { Modal } from 'targets/web/components';
import {
  FormAircraftTypeAutocomplete,
  FormikTextField,
} from 'targets/web/modules/dashboard/components';

const numberValuesRegExp = /^[a-zA-Z0-9-]*$/;
export const CreateAircraftSchema = z.object({
  type: z.object({
    id: entityId(),
    name: z.string(),
    code: z.string(),
  }),
  regNumber: stringLimited(15).min(1),
  serialNumber: stringLimited(15).min(1),
});

export type CreateAircraftData = z.infer<typeof CreateAircraftSchema>;

interface CreateAircraftModalProps {
  onSubmit: (values: CreateAircraftData) => Promise<void>;
  onClose: () => void;
  open: boolean;
}

const initialValues: CreateAircraftData = {
  regNumber: '',
  serialNumber: '',
  type: { id: ID(''), name: '', code: '' },
};

export const CreateAircraftModal: FC<CreateAircraftModalProps> = ({ open, onSubmit, onClose }) => {
  const t = useTranslationPrefix('configuration.aircraft.create_aircraft_modal');
  const tGlobal = useTranslationPrefix('global');

  const validationSchema = useMemo(() => {
    return CreateAircraftSchema.extend({
      regNumber: CreateAircraftSchema.shape.regNumber
        .regex(numberValuesRegExp, t('validation_error.reg_exp'))
        .optional(),
      serialNumber: CreateAircraftSchema.shape.serialNumber
        .regex(numberValuesRegExp, t('validation_error.reg_exp'))
        .optional(),
    }).superRefine((values, ctx) => {
      if (!values.regNumber && !values.serialNumber) {
        const issue: IssueData = {
          fatal: true,
          code: z.ZodIssueCode.custom,
          path: ['regNumber'],
          message: t('validation_error.reg_num_or_serial_num'),
        };

        ctx.addIssue({
          ...issue,
          path: ['regNumber'],
        });
        ctx.addIssue({
          ...issue,
          path: ['serialNumber'],
        });
      }
    });
  }, [t]);

  const handleSubmit = useCallback(
    async (values: CreateAircraftData, { resetForm }: FormikHelpers<CreateAircraftData>) =>
      onSubmit(values).finally(() => {
        onClose();
        resetForm();
      }),
    [onClose, onSubmit],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
      validationSchema={toFormikValidationSchema(validationSchema)}
    >
      {({ isValid, isSubmitting, submitForm, resetForm }) => (
        <Form>
          <Modal
            disableBackdropClick
            onClose={() => {
              onClose();
              resetForm();
            }}
            open={open}
            title={t('title')}
            maxWidth="sm"
            fullWidth
            content={
              <Box>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FormikTextField label={t('reg_number_label')} name="regNumber" />
                  </Grid>

                  <Grid item xs={6}>
                    <FormikTextField label={t('serial_number_label')} name="serialNumber" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormAircraftTypeAutocomplete />
                  </Grid>
                </Grid>
              </Box>
            }
            actions={{
              primary: {
                type: 'submit',
                text: tGlobal('confirm'),
                disabled: !isValid || isSubmitting,
                onClick: submitForm,
              },
              secondary: {
                text: tGlobal('cancel'),
              },
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
