import { FC, useCallback, useMemo, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Box, Chip, ChipProps, MenuItem, Typography } from '@mui/material';

import { Menu } from 'targets/web/components';
import { chipJobStatusMap, getLabelAndColor, iconsMap } from 'targets/web/modules/jobs/utils';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export type ChipStatus =
  | 'in_progress'
  | 'completed'
  | 'submitted'
  | 'pending'
  | 'scheduled'
  | 'declined'
  | 'canceled';

interface StatusChipProps {
  status: ChipStatus;
  availableStatusChanges?: ChipStatus[];
  editable?: boolean;
  dueDate: Date;
  onSelect?: (status: 'submitted' | 'in_progress' | 'completed') => void;
}

type MenuItem = {
  status: ChipStatus;
  onClick: () => void;
} & Required<Pick<ChipProps, 'color' | 'label'>>;

export const StatusChip: FC<StatusChipProps> = ({
  status,
  editable: editableProps = true,
  dueDate,
  availableStatusChanges,
  onSelect,
}) => {
  const { isOffline } = useNetworkStatus();
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);
  const closeMenu = useCallback(() => setIsMenuOpen(false), []);

  const MenuItems: MenuItem[] = useMemo(
    () =>
      [
        {
          status: 'scheduled' as const,
          ...getLabelAndColor('scheduled', dueDate),
          label: 'Not Started',
          onClick: () => onSelect?.('submitted'),
        },
        {
          status: 'pending' as const,
          ...getLabelAndColor('scheduled', dueDate),
          label: 'Not Started',
          onClick: () => onSelect?.('submitted'),
        },
        {
          status: 'in_progress' as const,
          ...getLabelAndColor('in_progress', dueDate),
          label: 'In Progress',
          onClick: () => onSelect?.('in_progress'),
        },
        {
          status: 'completed' as const,
          ...chipJobStatusMap.completed,
          onClick: () => onSelect?.('completed'),
        },
      ].filter(
        ({ status }) =>
          !availableStatusChanges || availableStatusChanges.includes(status as ChipStatus),
      ),
    [dueDate, onSelect, availableStatusChanges],
  );

  const { label, color } = getLabelAndColor(status, dueDate);

  const editable = editableProps && !isOffline;

  return (
    <Box onClick={(e) => e.stopPropagation()} data-testname="statusChip">
      <Chip
        ref={setMenuRef}
        label={label}
        color={color}
        icon={iconsMap[status]}
        size="small"
        clickable={editable}
        onDelete={editable ? openMenu : undefined}
        onClick={editable ? openMenu : undefined}
        deleteIcon={editable ? <ArrowDropDownIcon /> : undefined}
      />

      <Menu
        anchorEl={menuRef}
        slotProps={{
          paper: {
            sx: {
              marginTop: 2,
            },
          },
        }}
        sx={{
          '.MuiList-root.MuiMenu-list': {
            padding: 0,
          },
        }}
        open={isMenuOpen}
        onClose={closeMenu}
      >
        <Box padding={4}>
          <Typography variant="labelMedium">Select Job Status</Typography>
        </Box>

        {MenuItems.map(({ status, onClick, ...rest }, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onClick();
              closeMenu();
            }}
            sx={{ padding: 4 }}
          >
            <Chip {...rest} icon={iconsMap[status]} size="small" />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
