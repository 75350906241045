import { useMutation } from '@tanstack/react-query';

import { CustomerRepository } from 'domain/repositories/ICustomerRepository';
import { useHookInjection } from 'application/hooks';

import { useCaseSearchCustomer } from './useCaseSearchCustomer';

export const useCaseCreateCustomer = () => {
  const { createCustomer } = useHookInjection<CustomerRepository>(CustomerRepository);

  const { refetch } = useCaseSearchCustomer(null);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: createCustomer,
    onSuccess: () => {
      refetch();
    },
  });

  return {
    createCustomer: mutateAsync,
    isPending,
  };
};
