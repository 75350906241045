import { areAddressesEqual, Customer } from 'domain/entities/Customer';
import { CustomerData } from 'targets/web/modules/configuration/components';

export const mapCustomerToCustomerData = (data?: Customer | null): CustomerData | undefined => {
  return data
    ? {
        name: data.name,
        code: data.code,
        externalId: data.externalId,
        paymentTerm: data.paymentTerm,

        address: data.physicalAddressStreet,
        city: data.physicalAddressCity,
        state: data.physicalAddressState,
        postalCode: data.physicalAddressZip,
        country: data.physicalAddressCountry,

        mailingAddressSameAsPhysical: areAddressesEqual(data),
        mailingAddress: {
          address: data.mailingAddressStreet,
          city: data.mailingAddressCity,
          state: data.mailingAddressState,
          postalCode: data.mailingAddressZip,
          country: data.mailingAddressCountry,
        },

        operationsContact: data.operationsName
          ? {
              name: data.operationsName,
              phoneNumber: data.operationsPhone,
              email: data.operationsEmail,
            }
          : undefined,

        billingContact: {
          name: data.billingName,
          phoneNumber: data.billingPhone,
          email: data.billingEmail,
        },
      }
    : undefined;
};
