import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { CardStack } from 'targets/web/components/CardStack';

export const Container = CardStack;

export const ServiceDetailsField: FC<{ title: string; value: string; disabled?: boolean }> = ({
  title,
  value,
  disabled,
}) => (
  <Stack direction="column" flex={1}>
    <Typography variant="body2" color="textSecondary">
      {title}
    </Typography>
    <Typography
      data-testname={`${title}-value`}
      variant="body1"
      color={disabled ? 'textSecondary' : 'textPrimary'}
    >
      {value}
    </Typography>
  </Stack>
);
