import { FC, useMemo } from 'react';

import { ID } from 'domain/types/ID';
import { useCaseGetCustomer } from 'application/customers/useCases/useCaseGetCustomer';
import { useCaseEditCustomer } from 'application/customers/useCases/useCaseEditCustomer';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';
import { FormikCancelSaveFooter } from 'targets/web/modules/dashboard/components';
import {
  CustomerData,
  CustomerDetailsForm,
  initialValues,
} from 'targets/web/modules/configuration/components/CustomerForm';
import { mapCustomerToCustomerData } from 'targets/web/modules/configuration/utils/mapCustomerToCustomerData';
import { Mode } from 'targets/web/modules/configuration/components/CustomerForm/BaseFields';

export const CustomerDetailsGeneralTab: FC<{
  editMode?: boolean;
  onExitEditMode: () => void;
  customerId: ID;
}> = ({ editMode, customerId, onExitEditMode }) => {
  const t = useTranslationPrefix('configuration.customer_details');
  const notify = useSnackbar();

  const { data } = useCaseGetCustomer({ customerId });
  const { editCustomer } = useCaseEditCustomer();

  const values = useMemo(() => mapCustomerToCustomerData(data) ?? initialValues, [data]);

  if (!data) return null;
  return (
    <CustomerDetailsForm
      onSubmit={
        editMode
          ? async (values: CustomerData) => {
              return editCustomer({
                ...values,
                id: data.id,
                ...(values.mailingAddressSameAsPhysical
                  ? {
                      mailingAddress: {
                        address: values.address,
                        city: values.city,
                        state: values.state,
                        postalCode: values.postalCode,
                        country: values.country,
                      },
                    }
                  : {}),
              })
                .then(
                  () => {
                    notify(t('snackbar.edit_success'), {
                      variant: 'success',
                    });
                  },
                  () => {
                    notify(t('snackbar.edit_error'), {
                      variant: 'error',
                    });
                  },
                )
                .finally(onExitEditMode);
            }
          : undefined
      }
      initialValues={values}
      disabled={!editMode}
      footer={editMode ? <FormikCancelSaveFooter onCancel={onExitEditMode} /> : null}
      mode={editMode ? Mode.Edit : Mode.Create}
    />
  );
};
