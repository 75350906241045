import { QueryFunction, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { ID } from 'domain/types/ID';

import { useInterval } from './useInterval';

export const usePrefetchItems = <TData extends { id: ID }, TKey extends readonly [string, unknown]>(
  itemsToPrefetch: ID[],
  fetchFn: QueryFunction<TData, TKey>,
  computeKey: (id: ID) => TKey,
  delay = 1000,
) => {
  const qClient = useQueryClient();

  const [queue, setQueue] = useState<ID[]>([]);

  useEffect(() => {
    const itemsInCache: ID[] = qClient
      .getQueriesData({ queryKey: [computeKey(ID(''))[0]] })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .map(([queryKey]) => ID(queryKey[1]?.id ?? ''));

    setQueue(itemsToPrefetch.filter((jobId) => !itemsInCache.includes(jobId)).reverse());
  }, [computeKey, itemsToPrefetch, qClient]);

  useInterval(
    () => {
      const nextItem = queue.pop();

      if (nextItem && !qClient.getQueryData(computeKey(nextItem))) {
        qClient.prefetchQuery({
          queryKey: computeKey(nextItem),
          queryFn: fetchFn,
          meta: {
            silentError: true,
          },
        });
      }

      setQueue([...queue]);
    },
    queue.length ? delay : null,
  );
};
