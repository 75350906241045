import Pagination from 'common/types/Pagination';
import SortingOptions from 'common/types/SortingOptions';
import { FODInspectionAreas } from 'api/domain/entities/documents/FODInspection';

import { InjectableHook } from 'domain/types/InjectableHook';
import { ID } from 'domain/types/ID';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { JobStatusCount } from 'domain/entities/JobStatusCount';
import { ServiceStatus } from 'domain/entities/ServiceStatus';
import { CommonSearchParams } from 'domain/types/CommonSearchParams';
import { Service } from 'domain/entities/Service';
import { ServiceType } from 'domain/entities/ServiceType';
import { Entity } from 'domain/types/Entity';
import { UpdateServiceRequest } from 'domain/entities/UpdateServiceRequest';
import { GetServiceRequest } from 'domain/entities/GetServiceRequest';
import { GetServiceResponse } from 'domain/entities/GetServiceResponse';
import { BillingStatus } from 'domain/entities/BillingStatus';
import { CancelServiceRequest } from 'domain/entities/CancelServiceRequest';
import { Form900 } from 'domain/entities/Form900';
import { InspectionArea } from 'domain/entities/InspectionArea';
import { DamageFound } from 'domain/entities/DamageFound';

export type SearchServicesParams = Partial<
  CommonSearchParams & {
    jobId: ID;
    serviceTypeId: ID;
    status: ServiceStatus[];
    aircraftId: ID;
    customerIds: ID[];
    phrase: string;
    stationIds: ID[];
  }
>;

export type CompleteServiceParams = {
  serviceId: ID;
  form900?: Pick<Form900, 'parts' | 'customerSignature'>;
  fodAreas?: FODInspectionAreas[];
};

export interface SubmitPreWorkQuestionnaireData {
  serviceId: ID;
  inspectionAreas: InspectionArea[];
  includeCommunication: boolean;
  toolsUsed: {
    bottles: number;
    microfiber: number;
    terrycloth: number;
    cleanKit: number;
    other: number;
  };
  damagesFound: DamageFound[];
  employeeCount: number;
  clientDamageCommunication?: { notes: string };
}

export type SearchServiceTypesParams = Pagination & SortingOptions & { phrase?: string };

export const ServiceRepository = Symbol('ServiceRepository');
export type ServiceRepository = InjectableHook<{
  searchServices(params: SearchServicesParams): Promise<PaginatedResult<Service>>;
  getCountByStatus(params: SearchServicesParams): Promise<JobStatusCount>;

  getService(params: GetServiceRequest): Promise<GetServiceResponse>;
  completeService(params: CompleteServiceParams): Promise<void>;
  startTimer(serviceId: ID, billable: boolean): Promise<void>;
  stopTimer(serviceId: ID): Promise<void>;
  updateService(params: UpdateServiceRequest): Promise<void>;
  setBillingStatus(params: { serviceId: ID; billingStatus: BillingStatus }): Promise<void>;
  submitPreWorkQuestionnaire(
    params: SubmitPreWorkQuestionnaireData,
  ): Promise<{ questionnaireId: ID }>;

  getAllServiceTypes(): Promise<{ serviceTypes: Entity<ServiceType>[] }>;
  searchServiceTypes(
    params: SearchServiceTypesParams,
  ): Promise<PaginatedResult<Entity<ServiceType>>>;
  cancelService(params: CancelServiceRequest): Promise<void>;
}>;
