import { Route } from 'react-router-dom';

import PageNotFoundView from 'targets/web/modules/dashboard/views/PageNotFound.view';
import { WelcomePage } from 'targets/web/modules/dashboard/views/WelcomePage';

const PublicRoutes = (
  <Route>
    <Route path="/welcome" element={<WelcomePage />} />

    <Route path="*" element={<PageNotFoundView />} />
  </Route>
);

export default PublicRoutes;
