import { Area } from './DamageFound';

export const areas: { value: Area; label: string }[] = [
  { value: 'interior-flight_deck', label: 'Flight Deck' },
  { value: 'interior-entrance', label: 'Entrance / Vestibule Area' },
  { value: 'interior-crew_rest', label: 'Crew Rest Area' },
  { value: 'interior-forward', label: 'Forward & Aft Galley Area(s)' },
  { value: 'interior-lavatory', label: 'Forward & Aft Lavatory(s)' },
  { value: 'interior-cabin', label: 'Cabin' },
  { value: 'interior-baggage_area', label: 'Baggage Area' },
  { value: 'exterior', label: 'Exterior Section' },
];
export const inspectionItems: Record<Area, { value: string; label: string }[]> = {
  'interior-flight_deck': [
    { value: 'carpet', label: 'Carpet / Flooring' },
    { value: 'woodwork', label: 'Woodwork' },
    { value: 'doors', label: 'Interior Doors / Curtains' },
    { value: 'seats', label: 'Crew Seats' },
    { value: 'side_panels', label: 'Side Panels' },
    { value: 'pedestal', label: 'Pedestal' },
    { value: 'circuit_breaker_panels', label: 'Circuit Breaker Panels' },
    { value: 'instrument_panels', label: 'Instrument Panels' },
    { value: 'glare_shield', label: 'Glare-Shield' },
    { value: 'glare_windows', label: 'Windows / Windshields' },
    { value: 'glare_headliner', label: 'Headliner' },
  ],
  'interior-entrance': [
    { value: 'carpet', label: 'Carpet / Flooring' },
    { value: 'woodwork', label: 'Woodwork / Countertops / Ledges' },
    { value: 'cabinets', label: 'Cabinets' },
    { value: 'interior_doors', label: 'Interior Doors / Curtains' },
    { value: 'entry_door', label: 'Entry Door' },
    { value: 'flight_attendant_seating', label: 'Flight Attendant Seating' },
    { value: 'sidewalls', label: 'Sidewalls' },
    { value: 'lights', label: 'Lights / Switches' },
    { value: 'windows', label: 'Windows' },
    { value: 'headliner', label: 'Headliner / Upper Sidewalls' },
    { value: 'air_gaspers', label: 'Air Gaspers' },
  ],
  'interior-crew_rest': [
    { value: 'carpet', label: 'Carpet / Flooring' },
    { value: 'woodwork', label: 'Woodwork / Countertops / Ledges' },
    { value: 'cabinets', label: 'Cabinets' },
    { value: 'interior_doors', label: 'Interior Doors / Curtains' },
    { value: 'seats', label: 'Seats / Beds' },
    { value: 'sidewalls', label: 'Sidewalls' },
    { value: 'windows', label: 'Windows' },
    { value: 'lights', label: 'Lights / Switches' },
    { value: 'headliner', label: 'Headliner / Upper Sidewalls' },
    { value: 'air_gaspers', label: 'Air Gaspers' },
    { value: 'video_monitors', label: 'Video Monitors' },
  ],
  'interior-forward': [
    { value: 'carpet', label: 'Carpet / Flooring' },
    { value: 'woodwork', label: 'Woodwork / Countertops / Ledges' },
    { value: 'cabinets', label: 'Cabinets' },
    { value: 'interior_doors', label: 'Interior Doors / Curtains' },
    { value: 'sink', label: 'Sink / Faucets / Drain ' },
    { value: 'glassware', label: 'Glassware / Flatware' },
    { value: 'refrigerator', label: 'Refrigerator / Cold Storage' },
    { value: 'ovens', label: 'Ovens / Cooktops' },
    { value: 'microwave', label: 'Microwave Oven' },
    { value: 'coffee', label: 'Coffee / Espresso Machines' },
    { value: 'sidewalls', label: 'Sidewalls' },
    { value: 'video_monitors', label: 'Video Monitors' },
    { value: 'windows', label: 'Windows' },
    { value: 'mirrors', label: 'Mirrors' },
    { value: 'headliner', label: 'Headliner / Upper Sidewalls' },
    { value: 'air_gaspers', label: 'Air Gaspers' },
    { value: 'lights', label: 'Lights / Switches' },
  ],
  'interior-lavatory': [
    { value: 'carpet', label: 'Carpet / Flooring' },
    { value: 'woodwork', label: 'Woodwork / Countertops / Ledges' },
    { value: 'cabinets', label: 'Cabinets' },
    { value: 'interior_doors', label: 'Interior Doors / Curtains' },
    { value: 'toilet_cabinet_and_fixtures', label: 'Toilet Cabinet and Fixtures' },
    { value: 'vanity', label: 'Vanity Cabinet and Fixtures' },
    { value: 'bidet', label: 'Bidet Cabinet and Fixtures' },
    { value: 'mirrors', label: 'Mirrors' },
    { value: 'sidewalls', label: 'Sidewalls' },
    { value: 'windows', label: 'Windows' },
    { value: 'headline', label: 'Headliner / Upper Sidewalls' },
    { value: 'air_gasper', label: 'Air Gaspers / Lights / Switches' },
  ],
  'interior-cabin': [
    { value: 'carpet', label: 'Carpet / Flooring' },
    { value: 'woodwork', label: 'Woodwork / Countertops / Ledges' },
    { value: 'cabinets', label: 'Cabinets' },
    { value: 'interior_doors', label: 'Interior Doors / Curtains' },
    { value: 'air_gaspers', label: 'Air Gaspers' },
    { value: 'seats', label: 'Seats' },
    { value: 'divans', label: 'Divans' },
    { value: 'tables', label: 'Tables' },
    { value: 'sidewalls', label: 'Sidewalls' },
    { value: 'lights', label: 'Lights / Switches' },
    { value: 'windows', label: 'Windows' },
    { value: 'mirrors', label: 'Mirrors' },
    { value: 'headline', label: 'Headliner / Upper Sidewalls' },
    { value: 'video_monitors', label: 'Video Monitors' },
  ],
  'interior-baggage_area': [
    { value: 'carpet', label: 'Carpet / Flooring' },
    { value: 'interior_doors', label: 'Interior Doors / Curtains' },
    { value: 'baggage_door', label: 'Baggage Door' },
    { value: 'sidewalls', label: 'Sidewalls' },
    { value: 'headliner', label: 'Headliner /Upper Sidewalls' },
    { value: 'air_gaspers', label: 'Air Gaspers / Lights / Switches' },
  ],
  exterior: [
    { value: 'a', label: 'Section A' },
    { value: 'b', label: 'Section B' },
    { value: 'c', label: 'Section C' },
    { value: 'd', label: 'Section D' },
    { value: 'e', label: 'Section E' },
    { value: 'f', label: 'Section F' },
    { value: 'g', label: 'Section G' },
    { value: 'h', label: 'Section H' },
    { value: 'i', label: 'Section I' },
    { value: 'j', label: 'Section J' },
    { value: 'k', label: 'Section K' },
    { value: 'l', label: 'Section L' },
    { value: 'm', label: 'Section M' },
    { value: 'n', label: 'Section N' },
    { value: 'o', label: 'Section O' },
    { value: 'p', label: 'Section P' },
  ],
};
