import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { Typography } from '@mui/material';

import { ID } from 'domain/types/ID';
import { Entity } from 'domain/types/Entity';
import { Station } from 'domain/entities/Station';
import { useCaseSearchStation } from 'application/stations/useCases/useCaseSearchStation';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { Autocomplete, TextField } from 'targets/web/components';

export const StationAutocomplete: FC<{ onChange: (id: ID[] | undefined) => void }> = ({
  onChange,
}) => {
  const [selectedValues, setSelectedValues] = useState<Entity<Station>[]>([]);
  const t = useTranslationPrefix('components.filters_container');

  const [stationPhrase, setStationPhrase] = useState('');
  const { items: stations, isLoading } = useCaseSearchStation({ phrase: stationPhrase });

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setStationPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<Entity<Station>, true, false, false>
      multiple
      label={t('station_label')}
      loading={isLoading}
      options={stations}
      value={selectedValues}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      disableCloseOnSelect
      onInputChange={handleInputChange}
      onChange={(_, value) => {
        setSelectedValues(value);
        onChange(value && value.length > 0 ? value.map((s) => ID(s.id)) : undefined);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('station_label')}
          placeholder={
            selectedValues.length === 0
              ? params.inputProps['aria-expanded']
                ? t('select_items')
                : t('all')
              : undefined
          }
        />
      )}
      renderTags={(value) => <Typography>{value.map((v) => v.name).join(', ')}</Typography>}
    />
  );
};
