import { DefaultError, useMutation } from '@tanstack/react-query';

import { ServiceRepository } from 'domain/repositories/IServiceRepository';
import { ID } from 'domain/types/ID';
import { useHookInjection } from 'application/hooks';
import { useCaseGetServicesCountByStatus } from 'application/services/useCases/useCaseGetServicesCountByStatus';

export const useCaseStartService = (onError?: (error: DefaultError) => void) => {
  const { startTimer } = useHookInjection<ServiceRepository>(ServiceRepository);

  const { refetch: refetchServicesCount } = useCaseGetServicesCountByStatus(null);

  const { error, mutateAsync, isPending, isSuccess } = useMutation<
    void,
    DefaultError,
    { serviceId: ID; billable: boolean }
  >({
    mutationFn: ({ serviceId, billable }) => startTimer(serviceId, billable),
    onSuccess: () => {
      refetchServicesCount();
    },
    onError,
  });

  return {
    isSuccess,
    isPending,
    error,
    startService: mutateAsync,
  };
};
