export const timePattern = /^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;

export const stringTimeToSeconds = (time: string) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const secondsToStringTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formatTime = (time: number): string => (time < 10 ? `0${time}` : `${time}`);

  return `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(remainingSeconds)}`;
};
