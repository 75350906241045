import { FC } from 'react';
import { Typography } from '@mui/material';
import {
  ErrorOutline as OverdueIcon,
  RunningWithErrorsOutlined as AtRiskIcon,
} from '@mui/icons-material';
import { ServiceSummary } from 'api/domain/entities/Service';

import { iconsMap } from 'targets/web/modules/jobs/utils';

export const ServiceEventContainer: FC<{ style: { color: string }; service: ServiceSummary }> = (
  props,
) => (
  <div className="service-container">
    {props.style.color === 'warning' && (
      <div className="service-icon warning">
        <AtRiskIcon />
      </div>
    )}
    {props.style.color === 'error' && (
      <div className="service-icon error">
        <OverdueIcon />
      </div>
    )}
    <div className={`service-label ${props.style.color}`}>
      {iconsMap[props.service?.status || 'pending']}
      <Typography color={props.style.color} variant="caption" fontWeight={'bold'}>
        {props.service?.job.name} - {props.service?.code}
      </Typography>
    </div>
  </div>
);
