import { createContext, FC, ReactNode, useEffect, useMemo, useRef } from 'react';
import { ConnectOptions, LocalParticipant, RemoteParticipant, Room } from 'twilio-video';

import { ConversationModalProps } from 'targets/web/components/ConversationModal';

import useRoom from './useRoom';
import useVideo from './useVideo';

export interface IVideoContext {
  room: Room | undefined;
  isConnecting: boolean;
  connect: (token: string, options: ConnectOptions) => Promise<void>;
  participants: RemoteParticipant[];
  localParticipant?: LocalParticipant;
  mainParticipant?: LocalParticipant | RemoteParticipant;
  videoAvailable: boolean;
  micMuted: boolean;
}

interface VideoProviderProps {
  children: ReactNode;
  token: ConversationModalProps['token'];
  name: ConversationModalProps['name'];
  videoAvailable?: boolean;
  micMuted?: boolean;
  clearData?: () => void;
}

export const VideoContext = createContext<IVideoContext | null>(null);

export const VideoProvider: FC<VideoProviderProps> = ({
  children,
  token,
  name,
  clearData,
  videoAvailable = true,
  micMuted = true,
}) => {
  const { room, isConnecting, connect } = useVideo();
  const { participants, localParticipant, mainParticipant } = useRoom(room, clearData);

  const lastRoomParams = useRef<{ token?: string; name?: string }>({});

  useEffect(() => {
    if (token === lastRoomParams.current.token && name === lastRoomParams.current.name) {
      return;
    }
    if (token && name) {
      lastRoomParams.current = {
        token,
        name,
      };
    }
    if (!token || !name) {
      return;
    }

    connect(token, {
      name,
      audio: { name: 'mic' },
      video: videoAvailable
        ? {
            name: 'camera',
            width: 200,
            height: 200,
          }
        : false,
    }).catch(console.error);
  }, [token, name, connect, videoAvailable]);

  useEffect(() => {
    if (!room) return;

    if (!micMuted) {
      room?.localParticipant.audioTracks.forEach(({ track }) => {
        track.enable();
      });
    }
  }, [micMuted, room]);

  const value = useMemo(
    () => ({
      room,
      isConnecting,
      connect,
      participants,
      localParticipant,
      mainParticipant,
      videoAvailable,
      micMuted,
    }),
    [
      room,
      isConnecting,
      connect,
      participants,
      localParticipant,
      mainParticipant,
      videoAvailable,
      micMuted,
    ],
  );

  return <VideoContext.Provider value={value}>{children}</VideoContext.Provider>;
};
