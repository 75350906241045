import { useMutation } from '@tanstack/react-query';

import { ReviewJobParams } from 'domain/entities/DeclineJobRequestParams';
import { JobRequestRepository } from 'domain/repositories/IJobRequestRepository';
import { useHookInjection } from 'application/hooks';

import { useCaseSearchJobRequests } from './useCaseSearchJobRequests';
import { useCaseGetJobRequestsCountByStatus } from './useCaseGetJobRequestsCountByStatus';

export const useCaseReviewJob = () => {
  const { reviewJob } = useHookInjection<JobRequestRepository>(JobRequestRepository);

  const { refetch: refetchJobRequestList } = useCaseSearchJobRequests(null);
  const { refetch: refetchJobRequestCount } = useCaseGetJobRequestsCountByStatus(null);

  const { mutateAsync } = useMutation<void, Error, ReviewJobParams>({
    mutationFn: reviewJob,
    onSuccess: () => {
      refetchJobRequestList();
      refetchJobRequestCount();
    },
  });

  return {
    reviewJob: mutateAsync,
  };
};
