import { FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

export interface PageTitleProps {
  title?: string;
  children?: ReactNode;
}

export const PageTitle: FC<PageTitleProps> = ({ title, children }) => (
  <Stack justifyContent="space-between" direction="row" alignItems="center">
    <Typography data-testname="pageTitle" variant="h3">
      {title}
    </Typography>
    {children}
  </Stack>
);
