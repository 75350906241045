import { Grid, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { z } from 'zod';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Select, TextField } from 'targets/web/components';
import { CardStack } from 'targets/web/components/CardStack';

export const WaitTimeServiceDetailsSchema = z.object({
  waitTimeNotes: z.string().optional(),
  waitTime: z.number(),
  waitTimeReason: z.string().optional().nullable(),
});

export type WaitTimeServiceDetailsData = z.infer<typeof WaitTimeServiceDetailsSchema>;

export interface WaitTimeServiceDetailsProps {
  disabled: boolean;
  waitTimeReasons: {
    id: string;
    name: string;
  }[];
}

const WaitTimeServiceDetails: FC<WaitTimeServiceDetailsProps> = ({ disabled, waitTimeReasons }) => {
  const { values, setFieldValue, handleChange } = useFormikContext<WaitTimeServiceDetailsData>();
  const t = useTranslationPrefix('jobs.service_details_form.wait_time');
  return (
    <CardStack gap={4}>
      <Typography variant="h6">{t('title')}</Typography>
      <Grid container spacing={6}>
        <Grid item md={6}>
          <Stack gap={4}>
            <TextField
              type="number"
              label={t('wait_time_label')}
              name="wait_time"
              value={values.waitTime}
              onChange={handleChange('waitTime')}
              fullWidth
            />
            <Select
              label={t('reason_label')}
              name="reason"
              value={values.waitTimeReason}
              fullWidth
              disabled={disabled}
              onChange={(e) => setFieldValue('waitTimeReason', e.target.value)}
              items={waitTimeReasons.map(({ id, name }) => ({ label: name, value: id }))}
            />
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Stack gap={4}>
            <TextField
              label={t('wait_time_notes_label')}
              placeholder={t('wait_time_notes_placeholder')}
              name="waitTimeNotes"
              value={values.waitTimeNotes}
              fullWidth
              multiline
              rows={4}
              disabled={disabled}
              onChange={handleChange('waitTimeNotes')}
            />
          </Stack>
        </Grid>
      </Grid>
    </CardStack>
  );
};

export default WaitTimeServiceDetails;
