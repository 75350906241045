import { FC, ReactElement } from 'react';

import {
  BasicServiceDetailsPreview,
  BasicServiceDetailsPreviewData,
} from './BasicServiceDetailsPreview';
import {
  TimeTrackingServiceDetailsPreview,
  TimeTrackingServiceDetailsPreviewData,
} from './TimeTrackingServiceDetailsPreview';
import {
  BillingServiceDetailsPreview,
  BillingServiceDetailsPreviewData,
} from './BillingServiceDetailsPreview';
import {
  ExternalServiceDetailsPreview,
  ExternalServiceDetailsPreviewData,
} from './ExternalServiceDetailsPreview';
import {
  NotesServiceDetailsPreview,
  NotesServiceDetailsPreviewData,
} from './NotesServiceDetailsPreview';

export type ServiceDetailsPreviewData = {
  basic: BasicServiceDetailsPreviewData;
  timeTracking: TimeTrackingServiceDetailsPreviewData;
  billing: BillingServiceDetailsPreviewData;
  external: ExternalServiceDetailsPreviewData;
  notes: NotesServiceDetailsPreviewData;
};

export interface ServiceDetailsPreviewProps {
  data: ServiceDetailsPreviewData;
  footer?: ReactElement;
}

export const ServiceDetailsPreview: FC<ServiceDetailsPreviewProps> = ({ data, footer }) => (
  <>
    <BasicServiceDetailsPreview data={data.basic} />
    <TimeTrackingServiceDetailsPreview data={data.timeTracking} />
    <BillingServiceDetailsPreview data={{ ...data.billing, quantity: data.basic.quantity }} />
    <ExternalServiceDetailsPreview data={data.external} />
    <NotesServiceDetailsPreview data={data.notes} />
    {footer}
  </>
);
