import { FC } from 'react';
import {
  CardHeader,
  CardHeaderProps,
  CardContent,
  CardProps as MuiCardProps,
  Divider,
} from '@mui/material';

import { StyledCard } from './style';

export interface CardProps extends MuiCardProps {
  /**
   * Props applied to the [`MuiCardHeader`](https://mui.com/material-ui/api/card-header/) element.
   */
  HeaderProps?: CardHeaderProps;
}

export const Card: FC<CardProps> = ({ HeaderProps, children, ...props }) => (
  <StyledCard {...props}>
    {HeaderProps && (
      <>
        <CardHeader {...HeaderProps} />
        <Divider variant="middle" />
      </>
    )}
    <CardContent>{children}</CardContent>
  </StyledCard>
);

export default Card;
