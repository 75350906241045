import { Stack, Theme, useMediaQuery } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridViewOutlined';
import ListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { useCaseGetJobCountByStatus } from 'application/jobs/useCases/useCaseGetJobCountByStatus';
import { useCaseGetServicesCountByStatus } from 'application/services/useCases/useCaseGetServicesCountByStatus';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import {
  LogType,
  StatusTab,
  useJobViewFilters,
  ViewType,
} from 'targets/web/modules/jobs/hooks/useJobViewFilters';
import { getLabelWithValue, Tabs, ToggleButton } from 'targets/web/components';

export const JobViewTabs = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const t = useTranslationPrefix('jobs.jobs_view');

  const {
    serviceFilters,
    jobFilters,
    logType,
    setLogType,
    statusTab,
    setStatusTab,
    viewType,
    setViewType,
  } = useJobViewFilters();

  const { data: jobData } = useCaseGetJobCountByStatus(logType === 'job-log' ? jobFilters : null);
  const { data: servicesData } = useCaseGetServicesCountByStatus(
    logType === 'service-log' ? serviceFilters : null,
  );

  return (
    <Stack direction="row" gap={4} alignItems="center" justifyContent="space-between">
      <Tabs
        variant={isMobile ? 'scrollable' : 'standard'}
        onChange={(_, val) => setStatusTab(val)}
        value={statusTab ?? 'all'}
        items={[
          {
            label: getLabelWithValue(
              t('status_filters.all'),
              logType === 'job-log' ? jobData.all : servicesData.all,
            ),
            value: 'all' satisfies StatusTab,
          },
          {
            label: getLabelWithValue(
              t('status_filters.not_started'),
              logType === 'job-log' ? jobData.notStarted : servicesData.notStarted,
            ),
            value: 'scheduled' satisfies StatusTab,
          },
          {
            label: getLabelWithValue(
              t('status_filters.in_progress'),
              logType === 'job-log' ? jobData.inProgress : servicesData.inProgress,
            ),
            value: 'in_progress' satisfies StatusTab,
          },
          {
            label: getLabelWithValue(
              t('status_filters.completed'),
              logType === 'job-log' ? jobData.completed : servicesData.completed,
            ),
            value: 'completed' satisfies StatusTab,
          },
          {
            label: getLabelWithValue(
              t('status_filters.canceled'),
              logType === 'job-log' ? jobData.canceled : servicesData.canceled,
            ),
            value: 'canceled' satisfies StatusTab,
          },
        ]}
      />

      <Stack direction="row" gap={4} alignItems="center">
        <ToggleButton
          color="primary"
          size="small"
          value={logType}
          onClick={({ value }) => setLogType(String(value) as LogType)}
          options={[
            { value: 'job-log', label: t('log_type.job_log') },
            { value: 'service-log', label: t('log_type.service_log') },
          ]}
          btnSx={{
            padding: {
              xs: '4px 4px',
              lg: '12px 4px',
            },
            minWidth: {
              xs: '84px',
              lg: '64px',
            },
          }}
        />

        <ToggleButton
          color="primary"
          size="small"
          value={viewType}
          onClick={({ value }) => setViewType(String(value) as ViewType)}
          options={[
            { value: 'grid-view' as const, icon: <GridViewIcon /> },
            { value: 'table-view', icon: <ListBulletedIcon /> },
          ]}
          btnSx={{
            padding: {
              xs: '4px 4px',
              lg: '12px 4px',
            },
            minWidth: {
              xs: '84px',
              lg: '64px',
            },
          }}
        />
      </Stack>
    </Stack>
  );
};
