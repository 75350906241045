import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { JobRequestRepository } from 'domain/repositories/IJobRequestRepository';
import { useHookInjection } from 'application/hooks';

const QUERY_PREFIX = 'FETCH_JOB_REQUEST';

export const useCaseGetJobRequest = (params: { id?: string }) => {
  const qClient = useQueryClient();
  const { getJobRequest } = useHookInjection<JobRequestRepository>(JobRequestRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_PREFIX, params] as const,
    queryFn: async ({ queryKey }) => (queryKey[1].id ? getJobRequest(queryKey[1]) : null),
    enabled: !!params.id,
    staleTime: 5_000,
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};
