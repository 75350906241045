import { FC, PropsWithChildren } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import * as Sentry from '@sentry/react';

import OIDCAxiosHandler from './OIDCAxiosHandler';

const OIDCClientId = import.meta.env.VITE_OIDC_CLIENT_ID;
const Authority = import.meta.env.VITE_OIDC_AUTHORITY;
const Issuer = import.meta.env.VITE_OIDC_ISSUER;
const AuthorizationEndpoint = import.meta.env.VITE_OIDC_AUTHORIZATION_ENDPOINT;
const UserInfoEndpoint = import.meta.env.VITE_OIDC_USERINFO_ENDPOINT;
const EndSessionEndpoint = import.meta.env.VITE_OIDC_END_SESSION_ENDPOINT;
const TokenEndpoint = import.meta.env.VITE_OIDC_TOKEN_ENDPOINT;

const userStore = new WebStorageStateStore({ store: window.localStorage });
const OIDCProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AuthProvider
      client_id={OIDCClientId}
      authority={Authority}
      response_type="code"
      accessTokenExpiringNotificationTimeInSeconds={5}
      redirect_uri={window.location.origin + '/jobs'}
      metadata={{
        issuer: Issuer,
        authorization_endpoint: AuthorizationEndpoint,
        userinfo_endpoint: UserInfoEndpoint,
        end_session_endpoint: EndSessionEndpoint,
        token_endpoint: TokenEndpoint,
      }}
      onSigninCallback={(user) => {
        if (user) {
          Sentry.setUser({
            id: user.profile.sub,
            email: user.profile.email,
          });
        }
        window.history.replaceState({}, document.title, window.location.pathname);
      }}
      userStore={userStore}
    >
      <OIDCAxiosHandler>{children}</OIDCAxiosHandler>
    </AuthProvider>
  );
};

export default OIDCProvider;
