import { ID } from 'domain/types/ID';

export const JobResourceType = 'job';
export function getJobResourceName(jobId: ID) {
  return `${JobResourceType}:${jobId}` as const;
}
export const ServiceResourceType = 'service';
export function getServiceResourceName(serviceId: ID) {
  return `${ServiceResourceType}:${serviceId}` as const;
}

export type ResourceType = typeof JobResourceType | typeof ServiceResourceType;

export const enum StationResourceScope {
  ListJobs = 'list-jobs',
  ListJobRequests = 'list-job-requests',
  ViewDetails = 'view-details',
  Update = 'update',
}

export const enum JobResourceScope {
  ViewDetails = 'view-details',
  Update = 'update',
  Cancel = 'cancel',
  Review = 'review',
  SetBillingStatus = 'set-billing-status',
  RecordJobTime = 'record-time',
  CompleteService = 'complete-service',
  UpdateEstimate = 'update-estimate',
}
