import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CompleteServiceParams, ServiceRepository } from 'domain/repositories/IServiceRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseCompleteService = () => {
  const queryClient = useQueryClient();
  const { completeService } = useHookInjection<ServiceRepository>(ServiceRepository);

  const { error, mutateAsync, isPending, isSuccess } = useMutation<
    void,
    Error,
    CompleteServiceParams
  >({
    mutationFn: (params) => completeService(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['FETCH_JOB'] });
    },
  });
  return {
    isSuccess,
    isPending,
    error,
    completeService: mutateAsync,
  };
};
