import { useFormikContext } from 'formik';
import { FormControlLabel, Grid, Stack } from '@mui/material';
import { FC } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Checkbox } from 'targets/web/components';
import { FormikSelectField, FormikTextField } from 'targets/web/modules/dashboard/components';
import { Countries, StatesOfAmerica } from 'targets/web/modules/dashboard/consts';

import { CustomerData } from './CustomerData';

export const MailingAddressSection: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const t = useTranslationPrefix('configuration.customer_details.customer_form');
  const { values, setFieldValue } = useFormikContext<CustomerData>();

  return (
    <>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                checked={values.mailingAddressSameAsPhysical}
                onChange={(e) => setFieldValue('mailingAddressSameAsPhysical', e.target.checked)}
              />
            }
            label={t('mailingAddressSameAsPhysical')}
          />
        </Stack>
      </Grid>

      {!values.mailingAddressSameAsPhysical && (
        <>
          <Grid item xs={6}>
            <FormikTextField
              name="mailingAddress.address"
              label={t('mailingAddress')}
              disabled={disabled}
              required={!disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="mailingAddress.city"
              label={t('city')}
              disabled={disabled}
              required={!disabled}
            />
          </Grid>

          <Grid item xs={4}>
            <FormikSelectField
              name="mailingAddress.state"
              label={t('state')}
              items={StatesOfAmerica}
              disabled={disabled}
              required={!disabled}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              name="mailingAddress.postalCode"
              label={t('postalCode')}
              disabled={disabled}
              required={!disabled}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikSelectField
              name="mailingAddress.country"
              label={t('country')}
              items={Countries}
              disabled={disabled}
              required={!disabled}
            />
          </Grid>
        </>
      )}
    </>
  );
};
