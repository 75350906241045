import { FC, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import z from 'zod';
import { decimal } from 'common/types/Decimal';

import { JobRecurring } from 'domain/entities/JobRecurring';
import { Job } from 'domain/entities/GetJobResponse';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { localTimeToUTC, utcToLocalTime } from 'targets/web/modules/jobs/utils';
import { DatePicker, Select, LinkViewField } from 'targets/web/components';

import { getRecurringOptions, shouldDisableRecurringDate } from './AvailabilityJobDetails.utils';
import { Container } from './common';

export const AvailabilityJobDetailsSchema = z.object({
  eta: z.date().optional(),
  etd: z.date().optional(),
  scheduled: z.date().optional(),
  deliverBy: z.date(),
  recurringType: JobRecurring,
  recurringStartsAt: z.date().optional(),
  recurringEndsAt: z.date().optional(),
  flatRate: decimal()
    .superRefine((value, ctx) => {
      if (value && value.includes('e')) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Value must not use scientific notation',
        });
      }
      if (Number(value) < 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Value must be greater than or equal to 1',
        });
      }
      if (Number(value) > 1e8) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Value must be less than or equal to 100000000',
        });
      }
    })
    .optional(),
});

export type AvailabilityJobDetailsData = z.infer<typeof AvailabilityJobDetailsSchema>;

export interface AvailabilityJobDetailsProps {
  disabled?: boolean;
  enableDateEdit?: boolean;
  parent?: Job['parent'];
}

export const AvailabilityJobDetails: FC<AvailabilityJobDetailsProps> = ({
  disabled,
  enableDateEdit,
  parent,
}) => {
  const { values, setFieldValue, validateField, errors } =
    useFormikContext<AvailabilityJobDetailsData>();
  const t = useTranslationPrefix('jobs.details_form.availability_details');
  const recurringOptions = getRecurringOptions();

  useEffect(() => {
    if (values.recurringType === JobRecurring.Values.none) {
      setFieldValue('recurringStartsAt', undefined);
      setFieldValue('recurringEndsAt', undefined);
      setFieldValue('flatRate', undefined);
    }
    validateField('recurringStartsAt');
    validateField('recurringEndsAt');
    validateField('flatRate');
  }, [setFieldValue, values.recurringType, validateField]);

  return (
    <Container gap={4} sx={{ padding: 4 }}>
      <Typography variant="h6">{t('title')}</Typography>

      <DatePicker
        label={t('eta_label')}
        value={values.eta ? utcToLocalTime(new Date(values.eta)) : null}
        disabled={disabled || !enableDateEdit}
        onChange={(e) => setFieldValue('eta', e ? localTimeToUTC(e) : undefined)}
      />
      <DatePicker
        label={t('etd_label')}
        value={values.etd ? utcToLocalTime(new Date(values.etd)) : null}
        disabled={disabled || !enableDateEdit}
        onChange={(e) => setFieldValue('etd', e ? localTimeToUTC(e) : undefined)}
      />
      <DatePicker
        label={t('scheduled_label')}
        value={values.scheduled ? utcToLocalTime(new Date(values.scheduled)) : null}
        disabled={disabled || !enableDateEdit}
        onChange={(e) => setFieldValue('scheduled', e ? localTimeToUTC(e) : undefined)}
      />
      <DatePicker
        label={t('deliver_by_label')}
        value={values.deliverBy ? utcToLocalTime(new Date(values.deliverBy)) : null}
        disabled={disabled || !enableDateEdit}
        onChange={(e) => setFieldValue('deliverBy', e ? localTimeToUTC(e) : undefined)}
      />
      {parent && (
        <LinkViewField
          label={t('recurring_parent_label')}
          text={t('recurring_parent_view_text')}
          link={
            parent.status === 'submitted'
              ? `/approvals/${parent.id}/confirm/job`
              : `/jobs/${parent.id}/details`
          }
          linkProps={{ target: '_blank' }}
        ></LinkViewField>
      )}
      <Select
        label={t('recurring_type_label')}
        name={`recurring_type`}
        value={values.recurringType}
        fullWidth
        disabled={disabled || !!parent}
        items={recurringOptions}
        onChange={(e) => setFieldValue('recurringType', e.target.value)}
      />
      <DatePicker
        label={t('contract_start_label')}
        value={values.recurringStartsAt ? utcToLocalTime(new Date(values.recurringStartsAt)) : null}
        disabled={disabled || values.recurringType === JobRecurring.Values.none || !!parent}
        onChange={(e) => setFieldValue('recurringStartsAt', e ? localTimeToUTC(e) : undefined)}
        shouldDisableDate={(day) => shouldDisableRecurringDate(values.recurringType, day)}
        slotProps={{
          textField: {
            error: !!errors.recurringStartsAt,
            helperText: errors.recurringStartsAt,
          },
        }}
      />
      <DatePicker
        label={t('contract_end_label')}
        value={values.recurringEndsAt ? utcToLocalTime(new Date(values.recurringEndsAt)) : null}
        disabled={disabled || values.recurringType === JobRecurring.Values.none || !!parent}
        onChange={(e) => setFieldValue('recurringEndsAt', e ? localTimeToUTC(e) : undefined)}
        shouldDisableDate={(day) => shouldDisableRecurringDate(values.recurringType, day)}
        slotProps={{
          textField: {
            error: !!errors.recurringEndsAt,
            helperText: errors.recurringEndsAt,
          },
        }}
      />
    </Container>
  );
};
