import { useMemo } from 'react';

import { DeclineJobRequestReason } from 'domain/entities/DeclineJobRequestReason';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

export const useDeclinedOptions = (): Record<DeclineJobRequestReason, string> => {
  const tOptions = useTranslationPrefix(
    'approvals.approvals_view.decline_job_request_modal.reason_select.options',
  );

  return useMemo(
    () => ({
      'ac-schedule-change': tOptions('ac_schedule_change'),
      duplicate: tOptions('duplicate'),
      'unable-to': tOptions('unable_to'),
      'labor-unavailable': tOptions('labor_unavailable'),
      'lack-of-hangar-space': tOptions('lack_of_hangar_space'),
      'maintenance-conflict': tOptions('maintenance_conflict'),
      other: tOptions('other'),
    }),
    [tOptions],
  );
};
