import { Box, Collapse, Grid, Stack, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { FC, useCallback } from 'react';
import { useAtom } from 'jotai';

import { ID } from 'domain/types/ID';
import { ServiceStatus } from 'domain/entities/ServiceStatus';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { atomWithToggleAndStorage } from 'infrastructure/utils/atomWithToggleAndStorage';
import { componentShadows } from 'targets/web/theme/shadows';
import { Button } from 'targets/web/components';
import StatusPicker from 'targets/web/modules/schedule/components/ScheduleFiltersContainer/StatusPicker';

import { CustomerAutocomplete } from './CustomerAutocomplete';
import { StationAutocomplete } from './StationAutocomplete';

export interface ScheduleFilters {
  customerIds?: ID[];
  stationIds?: ID[];
  status?: ServiceStatus;
}

interface FiltersContainerProps {
  onFiltersChange: (values: ScheduleFilters) => void;
}

const filtersCollapsedAtom = atomWithToggleAndStorage('isScheduleFilterContainerCollapsed', false);

export const ScheduleFiltersContainer: FC<FiltersContainerProps> = ({ onFiltersChange }) => {
  const t = useTranslationPrefix('components.filters_container');

  const [filtersCollapsed, toggleFilters] = useAtom(filtersCollapsedAtom);

  const onClickHandler = useCallback(() => toggleFilters(), [toggleFilters]);

  return (
    <Stack
      width={1}
      sx={{
        padding: 6,
        borderRadius: 1,
        backgroundColor: 'background.default',
        boxShadow: componentShadows.card,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{t('header')}</Typography>
        <Button
          size="small"
          variant="text"
          endIcon={filtersCollapsed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          onClick={onClickHandler}
        >
          {filtersCollapsed ? t('hide_filters') : t('show_filters')}
        </Button>
      </Stack>

      <Collapse in={filtersCollapsed}>
        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item xs>
              <StationAutocomplete
                onChange={(stationIds) => {
                  onFiltersChange({ stationIds });
                }}
              />
            </Grid>
            <Grid item xs>
              <CustomerAutocomplete
                onChange={(customerIds) => {
                  onFiltersChange({ customerIds });
                }}
              />
            </Grid>
            <Grid item xs>
              <StatusPicker onChange={(status) => onFiltersChange({ status })} />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Stack>
  );
};
