import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { z } from 'zod';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { CardStack } from 'targets/web/components/CardStack';
import TextField from 'targets/web/components/TextField';

export const NotesServiceDetailsSchema = z.object({
  notes: z.string().optional(),
});

export type NotesServiceDetailsData = z.infer<typeof NotesServiceDetailsSchema>;

export interface NotesServiceDetailsProps {
  disabled: boolean;
}

const NotesServiceDetails: FC<NotesServiceDetailsProps> = ({ disabled }) => {
  const { values, handleChange } = useFormikContext<NotesServiceDetailsData>();
  const t = useTranslationPrefix('jobs.service_details_form.notes');
  return (
    <CardStack spacing={4}>
      <Typography variant="h6">{t('title')}</Typography>
      <TextField
        placeholder={t('notes_placeholder')}
        name="notes"
        value={values.notes}
        fullWidth
        multiline
        rows={4}
        disabled={disabled}
        onChange={handleChange('notes')}
      />
    </CardStack>
  );
};

export default NotesServiceDetails;
