import { useMutation } from '@tanstack/react-query';

import { SubmitPreWorkQuestionnaireData } from 'domain/repositories/IJobRepository';
import { ID } from 'domain/types/ID';
import { ServiceRepository } from 'domain/repositories/IServiceRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseSubmitPreWorkQuestionnaire = () => {
  const { submitPreWorkQuestionnaire } = useHookInjection<ServiceRepository>(ServiceRepository);

  const { mutateAsync } = useMutation<
    { questionnaireId: ID },
    Error,
    SubmitPreWorkQuestionnaireData
  >({
    mutationFn: submitPreWorkQuestionnaire,
  });

  return {
    submitPreWorkQuestionnaire: mutateAsync,
  };
};
