import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { FormikNumberCounter } from 'targets/web/modules/dashboard/components';

export const ToolsUsedFormSection = () => {
  const t = useTranslationPrefix('jobs.pre_work_questionnaire.tools');

  return (
    <>
      <FormikNumberCounter name="toolsUsed.bottles" label={t('bottles')} min={0} max={999} />
      <FormikNumberCounter name="toolsUsed.microfiber" label={t('microfiber')} min={0} max={999} />
      <FormikNumberCounter name="toolsUsed.terrycloth" label={t('terrycloth')} min={0} max={999} />
      <FormikNumberCounter name="toolsUsed.cleanKit" label={t('clean_kit')} min={0} max={999} />
      <FormikNumberCounter name="toolsUsed.other" label={t('other_supplies')} min={0} max={999} />
    </>
  );
};
