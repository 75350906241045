import { Admin, Billing, Director, Dispatch, Lead, Manager, Role } from 'domain/types/Roles';
import { ResourcePermission } from 'application/auth/providers/IAccessProvider';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';

type OptimisticPermissionResolver = (roles: Role[]) => boolean;
type OptimisticResourceTypePermissionResolverMap = Record<string, OptimisticPermissionResolver>;
type OptimisticResolverMap = Record<string, OptimisticResourceTypePermissionResolverMap>;

const resolverMap: OptimisticResolverMap = {
  job: {
    review: (roles) =>
      (<Role[]>[Admin, Director, Manager, Dispatch]).some((role) => roles.includes(role)),
    cancel: (roles) => (<Role[]>[Admin, Director, Manager]).some((role) => roles.includes(role)),
    'update-statuses': (roles) =>
      (<Role[]>[Admin, Lead, Dispatch, Director, Manager]).some((role) => roles.includes(role)),
    'view-billing-status': (roles) =>
      (<Role[]>[Admin, Billing, Director, Manager]).some((role) => roles.includes(role)),
    'set-billing-status': (roles) =>
      (<Role[]>[Admin, Billing, Director, Manager]).some((role) => roles.includes(role)),
    'record-time': (roles) =>
      (<Role[]>[Admin, Lead, Director, Manager]).some((role) => roles.includes(role)),
    'complete-service': (roles) =>
      (<Role[]>[Admin, Lead, Director, Manager]).some((role) => roles.includes(role)),
  },
};

const useOptimisticPermission = (permission?: ResourcePermission) => {
  const { getAllRoles } = useCaseCheckAccess();

  if (!permission) {
    return {
      granted: false,
      isLoading: false,
    };
  }

  const roles = getAllRoles();
  const resourceType = permission.resource.split(':')[0] || 'unknown';

  const resolver = resolverMap[resourceType] && resolverMap[resourceType][permission.scope];

  if (!resolver) {
    return {
      granted: false,
      isLoading: false,
    };
  }

  return {
    granted: resolver(roles),
    isLoading: false,
  };
};

export default useOptimisticPermission;
