export const configurationRoutes = {
  configuration: '/configuration',
  internalRoutes: {
    aircraft: 'aircrafts',
    aircraftTypes: 'aircraft-types',
    customers: 'customers',
    customerDetails: 'customers/:customerId',
    stations: 'stations',
    stationDetails: 'stations/:stationId',
    serviceTypes: 'service-types',
    priceLists: 'price-lists',
    priceListDetails: 'price-lists/:priceListId/details',
  },
} as const;
