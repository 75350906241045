import { useMutation } from '@tanstack/react-query';

import { AircraftRepository, CreateAircraftPayload } from 'domain/repositories/IAircraftRepository';
import { ID } from 'domain/types/ID';
import { useHookInjection } from 'application/hooks';

import { useCaseSearchAircraft } from './useCaseSearchAircraft';

export const useCaseCreateAircraft = () => {
  const { createAircraft } = useHookInjection<AircraftRepository>(AircraftRepository);

  const { refetch } = useCaseSearchAircraft({});

  const { mutateAsync, isPending } = useMutation<{ id: ID }, Error, CreateAircraftPayload>({
    mutationFn: createAircraft,
    onSuccess: () => {
      refetch();
    },
  });

  return {
    createAircraft: mutateAsync,
    isPending,
  };
};
