import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { QuestionBox } from 'targets/web/modules/jobs/components/QuestionBox';
import { FormikNumberCounter } from 'targets/web/modules/dashboard/components';

export const EmployeeForm: FC = () => {
  const t = useTranslationPrefix('jobs.resume_service_dialog.form');

  return (
    <Stack gap={4}>
      <QuestionBox>
        <Typography>{t('employees_count')}</Typography>
        <FormikNumberCounter name="employeeCount" sx={{ maxWidth: 160 }} min={0} max={20} />
      </QuestionBox>
    </Stack>
  );
};
