import { useQuery } from '@tanstack/react-query';

import {
  SearchServiceTypesParams,
  ServiceRepository,
} from 'domain/repositories/IServiceRepository';
import { Entity } from 'domain/types/Entity';
import { ServiceType } from 'domain/entities/ServiceType';
import { useSortingAndPagination } from 'application/common';
import { useHookInjection } from 'application/hooks';

export const useCaseSearchServiceTypes = (
  params: Omit<SearchServiceTypesParams, 'limit' | 'offset' | 'sortBy' | 'direction'>,
) => {
  const { searchServiceTypes } = useHookInjection<ServiceRepository>(ServiceRepository);

  const { paging, sorting, ...utils } = useSortingAndPagination<Entity<ServiceType>>('name');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['SERVICE_TYPES', { ...params, ...paging, ...sorting }] as const,
    queryFn: ({ queryKey }) => searchServiceTypes(queryKey[1]),
    staleTime: 10_000,
  });

  return {
    ...data,

    paging,
    sorting,
    ...utils,

    isLoading,
    isFetching,
  };
};
