import { FC } from 'react';

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from 'targets/web/components/Navigation/NavigationItem/style';
import {
  NavigationItem as INavigationItem,
  NavigationProps,
} from 'targets/web/components/Navigation/Navigation';

export type NavigationItemProps = INavigationItem &
  Pick<NavigationProps, 'selected' | 'open' | 'onClick'> & { isParent?: boolean };

export const NavigationItem: FC<NavigationItemProps> = ({
  url,
  onClick,
  icon,
  label,
  open,
  selected,
  isParent,
}) => {
  const isSelected = url ? selected.includes(url) : false;

  const handleOnClick = (link: INavigationItem) => onClick && onClick(link);

  return (
    <ListItem
      data-testid="navigationMenuItem"
      disablePadding
      onClick={() => handleOnClick({ url, icon, label })}
    >
      <ListItemButton open={open} selected={isSelected}>
        {icon && (
          <ListItemIcon open={open} selected={isSelected}>
            {icon}
          </ListItemIcon>
        )}
        {open && (
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              variant: 'body2',
              color: isSelected ? 'primary.dark' : 'text.secondary',
              fontWeight: isParent ? 'bold' : isSelected ? 'bold' : 'regular',
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};
