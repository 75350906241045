import { Stack, styled, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Button from 'targets/web/components/Button';

export interface BannerProps {
  icon?: ReactNode;
  message: string;
  title: string;
  action?: ReactNode;
  onClose: () => void;
}

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[16],
  padding: theme.spacing(6),
  borderRadius: theme.shape.borderRadius,
  width: '100%',
}));

export const Banner: FC<BannerProps> = ({ message, action, onClose, title, icon }) => {
  const t = useTranslationPrefix('global');

  return (
    <StyledStack direction="row" gap={4} alignItems="center">
      {icon}

      <Stack flex={1}>
        {title && <Typography variant="labelLarge">{title}</Typography>}
        <Typography color="text.secondary">{message}</Typography>
      </Stack>

      <Stack flex="none" direction="row" gap={4}>
        {action}
        <Button variant={'outlined'} onClick={onClose}>
          {t('dismiss')}
        </Button>
      </Stack>
    </StyledStack>
  );
};

export default Banner;
