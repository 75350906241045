import { CSSObject, styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiList from '@mui/material/List';

import {
  DESKTOP_NAVIGATION_WIDTH,
  DESKTOP_NAVIGATION_WIDTH_COLLAPSED,
} from 'targets/web/theme/consts';
import IconButton from 'targets/web/components/IconButton';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DESKTOP_NAVIGATION_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: DESKTOP_NAVIGATION_WIDTH_COLLAPSED,
});

export const StyledDesktopDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      height: 'initial',
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(4),
      boxSizing: 'border-box',
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      height: 'initial',
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(4),
      boxSizing: 'border-box',
    },
  }),
}));

export const ToggleButton = styled(IconButton)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),

  '&:hover': {
    backgroundColor: theme.palette.primary.action?.hover,
  },

  '& .MuiTouchRipple-child': {
    backgroundColor: `${theme.palette.primary.action?.ripple} !important`,
  },
}));

export const List = styled(MuiList)(({ theme }) => ({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const ListItem = styled('li')(() => ({
  position: 'relative',
  textDecoration: 'none',
  width: '100%',
  boxSizing: 'border-box',
  textAlign: 'left',
  display: 'block',
}));
