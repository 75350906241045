import { useSnackbar as useNotistackSnackbar, ProviderContext } from 'notistack';

import { InjectableHook } from 'domain/types/InjectableHook';

export type ISnackbarHook = InjectableHook<ProviderContext['enqueueSnackbar']>;

export const useSnackbar: ISnackbarHook = () => {
  const { enqueueSnackbar } = useNotistackSnackbar();

  return enqueueSnackbar;
};

export default useSnackbar;
