import { Duration } from 'date-fns';

export const formatDuration = (duration: Duration): string => {
  return [
    duration.months ? duration.months + 'mo' : undefined,
    duration.days ? duration.days + 'd' : undefined,
    duration.hours + 'h',
    duration.minutes + 'm',
    duration.seconds + 's',
  ].join(' ');
};
