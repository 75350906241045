import Pagination from 'common/types/Pagination';
import SortingOptions from 'common/types/SortingOptions';

import { InjectableHook } from 'domain/types/InjectableHook';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { Entity } from 'domain/types/Entity';
import { Customer } from 'domain/entities/Customer';
import { ID } from 'domain/types/ID';

export type SearchCustomerParams = Partial<
  Pagination &
    SortingOptions & {
      phrase: string;
      stationIds: string[];
    }
>;

interface Address {
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}
interface Contact {
  name: string;
  phoneNumber: string;
  email: string;
}

export interface CreateCustomerData extends Address {
  name: string;
  code: string;
  externalId?: string;
  paymentTerm: string;
  billingContact: Contact;

  mailingAddress?: Address;
  operationsContact?: Partial<Contact>;
}

export interface EditCustomerData extends CreateCustomerData {
  id: ID;
}

export type CreateCustomerResponse =
  | {
      created: true;
      id: ID;
    }
  | {
      created: false;
      name: string;
      code?: string;
      externalId?: string;
    };

export const CustomerRepository = Symbol('CustomerRepository');
export type CustomerRepository = InjectableHook<{
  searchCustomer(params: SearchCustomerParams): Promise<PaginatedResult<Entity<Customer>>>;

  getCustomer(params: { id: ID }): Promise<Entity<Customer> | null>;
  createCustomer(params: CreateCustomerData): Promise<CreateCustomerResponse>;
  editCustomer(params: EditCustomerData): Promise<void>;
  confirmCustomer(params: { id: ID }): Promise<void>;
}>;
