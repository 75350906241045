import { useMutation, DefaultError } from '@tanstack/react-query';

import { JobRepository } from 'domain/repositories/IJobRepository';
import { CancelJobRequest } from 'domain/entities/CancelJobRequest';
import { useHookInjection } from 'application/hooks';

import { useCaseSearchJobs } from './useCaseSearchJobs';
import { useCaseGetJobDetails } from './useCaseGetJobDetails';
import { useCaseGetJobCountByStatus } from './useCaseGetJobCountByStatus';

export const useCaseCancelJob = () => {
  const { cancelJob } = useHookInjection<JobRepository>(JobRepository);

  const { refetch: refetchJobList } = useCaseSearchJobs(null);
  const { refetch: refetchJobCount } = useCaseGetJobCountByStatus(null);
  const { refetch: refetchJob } = useCaseGetJobDetails({});

  const { mutateAsync } = useMutation<void, DefaultError, CancelJobRequest>({
    mutationFn: cancelJob,
    onSuccess: () => {
      refetchJob();
      refetchJobList();
      refetchJobCount();
    },
  });

  return {
    cancelJob: mutateAsync,
  };
};
