import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import SortingOptions, { SortingDirection } from 'common/types/SortingOptions';
import Pagination from 'common/types/Pagination';

import { JobApprovalItem } from 'domain/entities/JobApprovalItem';
import { SearchJobRequestsParams } from 'domain/entities/SearchJobRequestsParams';
import { JobRequestStatus } from 'domain/entities/JobRequestStatus';
import { JobRequestRepository } from 'domain/repositories/IJobRequestRepository';
import { useHookInjection } from 'application/hooks';

const getDefaultSortForStatus = (
  status: JobRequestStatus | undefined,
): SortingOptions<JobApprovalItem>['sortBy'] => {
  switch (status) {
    case JobRequestStatus.Values.created:
      return 'submittedAt';
    case JobRequestStatus.Values.denied:
      return 'reviewedAt';
    case JobRequestStatus.Values.accepted:
      return 'reviewedAt';
    default:
      return 'due';
  }
};

const QUERY_PREFIX = 'JOB_REQUESTS';

export const useCaseSearchJobRequests = (
  params: Omit<SearchJobRequestsParams, keyof Pagination | keyof SortingOptions> | null,
) => {
  const qClient = useQueryClient();

  const { searchJobRequests } = useHookInjection<JobRequestRepository>(JobRequestRepository);

  const [paging, setPaging] = useState({
    limit: 10,
    offset: 0,
  });

  const [sort, setSort] = useState<SortingOptions<JobApprovalItem>>({
    sortBy: getDefaultSortForStatus(params?.status),
    direction: SortingDirection.Descending,
  });

  useEffect(() => {
    if (params?.status) {
      setSort((currentSort) => ({
        ...currentSort,
        sortBy: getDefaultSortForStatus(params.status),
      }));
    }
  }, [params?.status]);

  const { data, error, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_PREFIX, { ...params, ...paging, ...sort }] as const,
    enabled: !!params,
    queryFn: ({ queryKey, signal }) => searchJobRequests(queryKey[1] ?? {}, signal),
    staleTime: 10_000,
    refetchInterval: 30_000,
    placeholderData: { items: [], total: 0 },
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    ...data,
    error,

    paging,
    setPaging,
    sort,
    setSort,

    isLoading,
    isFetching,
    refetch,
  };
};
