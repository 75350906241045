import { useQuery } from '@tanstack/react-query';

import {
  AircraftRepository,
  SearchAircraftTypeParams,
} from 'domain/repositories/IAircraftRepository';
import { AircraftType } from 'domain/entities/Aircraft';
import { Entity } from 'domain/types/Entity';
import { useHookInjection } from 'application/hooks';
import { useSortingAndPagination } from 'application/common';

export const useCaseSearchAircraftType = (
  params: Omit<SearchAircraftTypeParams, 'limit' | 'offset' | 'sortBy' | 'direction'>,
) => {
  const { searchAircraftType } = useHookInjection<AircraftRepository>(AircraftRepository);

  const { paging, sorting, ...utils } = useSortingAndPagination<Entity<AircraftType>>('code');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['AIRCRAFT_TYPES', { ...params, ...paging, ...sorting }] as const,
    queryFn: ({ queryKey }) => searchAircraftType(queryKey[1]),
    placeholderData: { items: [], total: 0 },
    staleTime: 10_000,
  });

  return {
    ...data,

    paging,
    sorting,
    ...utils,

    isLoading,
    isFetching,
  };
};
