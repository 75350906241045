import { z } from 'zod';

export const DeclineJobRequestReasonSchema = z.enum([
  'ac-schedule-change',
  'duplicate',
  'unable-to',
  'labor-unavailable',
  'lack-of-hangar-space',
  'maintenance-conflict',
]);
export type DeclineJobRequestReason = z.infer<typeof DeclineJobRequestReasonSchema> | 'other';
