import { useMutation } from '@tanstack/react-query';

import { BillingItemRepository } from 'domain/repositories/IBillingItemRepository';
import { ID } from 'domain/types/ID';
import { useHookInjection } from 'application/hooks';

import { useCaseSearchBillingItems } from './useCaseSearchBillingItems';

export const useCaseConfirmBillingItem = () => {
  const { confirmBillingItem } = useHookInjection<BillingItemRepository>(BillingItemRepository);

  const { refetch } = useCaseSearchBillingItems();

  const { mutateAsync } = useMutation<void, Error, { id: ID }>({
    mutationFn: confirmBillingItem,
    onSuccess: () => refetch(),
  });

  return {
    confirmBillingItem: mutateAsync,
  };
};
