import { FC } from 'react';
import { Route, Routes } from 'react-router';

import { PageNotFoundView } from 'targets/web/modules/dashboard/views';
import { BillingReviewView } from 'targets/web/modules/billing/views/BillingReview.view';

export const BillingRoutes: FC = () => (
  <Routes>
    <Route index element={<BillingReviewView />} />

    <Route path="*" element={<PageNotFoundView />} />
  </Routes>
);
