import { FC } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import z from 'zod';
import { Stack, Typography } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { stringLimited } from 'infrastructure/utils/stringLimited';
import Modal from 'targets/web/components/Modal';
import { FormikTextField } from 'targets/web/modules/dashboard/components';

interface Props {
  onClose: () => void;
  open: boolean;
  signature: string;
  onSave: (customerSignature: string) => void;
}

export const CustomerSignatureModal: FC<Props> = ({ signature, open, onSave, onClose }) => {
  const t = useTranslationPrefix('jobs.post_work_questionnaire');
  const gT = useTranslationPrefix('global');

  const formik = useFormik({
    initialValues: {
      signature,
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        signature: stringLimited(256).optional(),
      }),
    ),
    validateOnBlur: false,
    onSubmit: ({ signature }) => {
      onSave(signature);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Modal
        data-testname="customerSignatureModal"
        open={open}
        onClose={onClose}
        disableBackdropClick
        maxWidth="xs"
        fullWidth
        title={t('customer_signature_modal_title')}
        actions={{
          primary: {
            disabled: !formik.isValid,
            text: gT('save'),
            onClick: () => {
              formik.handleSubmit();
            },
          },
          secondary: {
            text: gT('cancel'),
            onClick: () => {
              onClose();
            },
          },
        }}
        content={
          <Stack direction="column" spacing={4}>
            <Typography variant="body2">{t('customer_signature_modal_description')}</Typography>
            <FormikTextField
              name="signature"
              resetOnUnmount={false}
              label={t('customer_signature_modal_field_label')}
            />
          </Stack>
        }
      />
    </FormikProvider>
  );
};
