import { Container } from 'inversify';

import infrastructureModule from 'infrastructure/infrastructure.module';
import keycloakModule from 'infrastructure/keycloak/keycloak.module';

import dashboardContainerModule from './modules/dashboard/dashboard.container';

const container = new Container();

container.load(infrastructureModule);
container.load(keycloakModule);
container.load(dashboardContainerModule);

export default container;
