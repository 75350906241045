import { useCaseGetJobRequestsCountByStatus } from 'application/approvals/useCases/useCaseGetJobRequestsCountByStatus';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { useAcceptDeclineViewFilters } from 'targets/web/modules/approvals/hooks/useAcceptDeclineViewFilters';
import { getLabelWithValue, Tabs } from 'targets/web/components';

export const AcceptDeclineTabs = () => {
  const t = useTranslationPrefix('approvals.approvals_view.tabs');

  const { filters, statusTab, setStatusTab } = useAcceptDeclineViewFilters();
  const { data } = useCaseGetJobRequestsCountByStatus({ ...filters });

  return (
    <Tabs
      onChange={(_, status) => setStatusTab(status)}
      value={statusTab}
      items={[
        {
          label: getLabelWithValue(t('needs_review'), data?.created),
          value: 'created',
        },
        {
          label: getLabelWithValue(t('accepted'), data?.accepted),
          value: 'accepted',
        },
        {
          label: getLabelWithValue(t('declined'), data?.denied),
          value: 'denied',
        },
      ]}
    />
  );
};
