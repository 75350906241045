import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { ID } from 'domain/types/ID';
import { StationRepository } from 'domain/repositories/IStationRepository';
import { useHookInjection } from 'application/hooks';

const QUERY_PREFIX = 'FETCH_STATION';

export const useCaseGetStation = (params: { stationId?: string }) => {
  const qClient = useQueryClient();
  const { getStation } = useHookInjection<StationRepository>(StationRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_PREFIX, params] as const,
    queryFn: async ({ queryKey }) =>
      queryKey[1].stationId ? getStation({ id: ID(queryKey[1].stationId) }) : null,
    enabled: !!params.stationId,
    staleTime: 10_000,
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};
