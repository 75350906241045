import {
  Divider,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  MenuProps as MuiMenuProps,
  Typography,
} from '@mui/material';
import { SystemProps } from '@mui/system';
import { FC, ReactNode, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

import TextField from 'targets/web/components/TextField';

import { SearchWrapper, StyledMenu } from './style';

export interface SimpleMenuItem {
  label: string;
  icon?: ReactNode;
  isDivider?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLLIElement>) => void;
  selected?: boolean;
  disabled?: boolean;
  color?: SystemProps['color'];
}

export interface MenuProps extends Omit<MuiMenuProps, 'onClick'> {
  /**
   * Array of items to populate the menu with. Items will be rendered in `MenuItem` elements.
   *
   * **Note:** either `items` or `children` needs to be provided.
   */
  items?: SimpleMenuItem[];
  searchable?: boolean;
}

export const Menu: FC<MenuProps> = ({ searchable, items, children, open, ...rest }) => {
  const [itemsState, setItemsState] = useState(items);

  const handleOnSearch = (searchValue: string) => {
    if (searchValue) {
      return setItemsState((state) =>
        state?.filter((stateItem) =>
          stateItem.label.toLowerCase().includes(searchValue.toLowerCase()),
        ),
      );
    }

    return setItemsState(items);
  };

  useEffect(() => {
    setItemsState(items);
  }, [items]);

  return (
    <StyledMenu
      open={open}
      onKeyDown={(e) => e.stopPropagation()}
      {...rest}
      onTransitionEnd={() => !open && searchable && setItemsState(items)}
    >
      {searchable && (
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <SearchWrapper>
            <TextField
              fullWidth
              size="small"
              placeholder="Search"
              onChange={(e) => handleOnSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </SearchWrapper>
        </MenuItem>
      )}
      {children ||
        itemsState?.map((item, index) =>
          item.isDivider ? (
            <Divider key={index} />
          ) : (
            <MenuItem
              key={index}
              accessKey={undefined}
              onClick={item.onClick}
              selected={item.selected}
              disabled={item.disabled}
            >
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <Typography variant="menuItem" color={item.color}>
                {item.label}
              </Typography>
            </MenuItem>
          ),
        )}
    </StyledMenu>
  );
};

export default Menu;
