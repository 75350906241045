import { FC } from 'react';
import { Grid, Stack, Typography } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import { Container, ServiceDetailsField } from './common';

export type ExternalServiceDetailsPreviewData = {
  station: string;
  customer: string;
  service: string;
  aircraftType: string;
};

export interface ExternalServiceDetailsPreviewProps {
  data: ExternalServiceDetailsPreviewData;
}

export const ExternalServiceDetailsPreview: FC<ExternalServiceDetailsPreviewProps> = ({ data }) => {
  const t = useTranslationPrefix('jobs.service_details.external');
  return (
    <Container width={1} gap={4}>
      <Typography variant="h6">{t('title')}</Typography>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Stack gap={4}>
            <ServiceDetailsField title={t('station_label')} value={data.station} />
            <ServiceDetailsField title={t('customer_label')} value={data.customer} />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack gap={4}>
            <ServiceDetailsField title={t('service_label')} value={data.service} />
            <ServiceDetailsField title={t('aircraft_type_label')} value={data.aircraftType} />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
