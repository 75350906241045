import { forwardRef, useCallback, ReactNode } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';

import Alert, { AlertProps } from 'targets/web/components/Alert';

interface ReportCompleteProps extends Omit<CustomContentProps, 'variant'> {
  variant?: AlertProps['color'];
  MuiAlertProps?: AlertProps;
}

const NotistackSnackbar = forwardRef<HTMLDivElement, ReportCompleteProps>(
  ({ id, variant = 'info', MuiAlertProps, message, action }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const onClose = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} style={{ minWidth: 'inherit' }}>
        <Alert
          {...MuiAlertProps}
          severity={variant === 'secondary' ? 'info' : variant}
          color={variant === 'secondary' ? variant : undefined}
          onClose={onClose}
          action={action as ReactNode}
          message={message}
        >
          {message}
        </Alert>
      </SnackbarContent>
    );
  },
);

NotistackSnackbar.displayName = 'NotistackSnackbar';

export default NotistackSnackbar;
