import { Button, IconButton, Stack } from '@mui/material';
import { useMemo } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

import { isEntityLocked } from 'domain/entities/BillingStatus';
import { ID } from 'domain/types/ID';
import { useCaseGetJobDetails } from 'application/jobs/useCases/useCaseGetJobDetails';
import { useCaseGetServiceTypes } from 'application/services/useCases/useCaseGetServiceTypes';
import usePermission from 'application/auth/hooks/usePermission';
import { JobResourceType } from 'application/auth/utils/resources';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Breadcrumbs from 'targets/web/components/Breadcrumbs';
import { PageTitle } from 'targets/web/modules/dashboard/components/PageTitle/PageTitle';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { JobDetailsForm } from 'targets/web/modules/jobs/components';
import { Loader } from 'targets/web/components';
import { OfflinePlaceholder } from 'targets/web/modules/dashboard/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export const JobDetailsView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { jobId } = useParams();
  const { isOffline } = useNetworkStatus();

  const hasHistory = location.key !== 'default';
  const backNavigationAllowed = hasHistory && location.state?.from !== `/jobs/${jobId}/edit`;

  const tBreadcrumbs = useTranslationPrefix('jobs.breadcrumbs');
  const t = useTranslationPrefix('jobs.job_details');

  const { isLoading: servicesLoading } = useCaseGetServiceTypes();
  const { data } = useCaseGetJobDetails({ id: jobId });

  const { granted: editGranted } = usePermission(
    jobId
      ? {
          resource: `${JobResourceType}:${ID(jobId)}`,
          scope: 'update',
        }
      : undefined,
  );

  const isEditable = useMemo(
    () => (data && !isEntityLocked(data) && editGranted) || isOffline,
    [data, editGranted, isOffline],
  );

  if (servicesLoading && !isOffline) {
    return (
      <Stack height={1} justifyContent="center">
        <Loader />
      </Stack>
    );
  }
  if (!data) {
    if (isOffline) return <OfflinePlaceholder />;

    return null;
  }

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <IconButton
          onClick={() =>
            backNavigationAllowed ? navigate(-1) : navigate(`/jobs/${jobId}/summary`)
          }
          color="primary"
        >
          <ArrowBackIcon />
        </IconButton>

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('jobs_and_services'),
                url: jobsRoutes.jobs,
              },
              {
                label: tBreadcrumbs('job_details'),
              },
            ]}
          />

          <PageTitle title={t('title', { job: data.name })} />
        </Stack>
        {isEditable && (
          <Button
            size="large"
            startIcon={<EditIcon />}
            onClick={() => navigate(`/jobs/${data.id}/edit`)}
          >
            {t('edit_job_button')}
          </Button>
        )}
      </Stack>
      <JobDetailsForm
        disabled
        job={data}
        initialValues={{
          station: data.station,
          customer: data.customer,
          aircraft: data.aircraft,
          payment: {
            poNumber: data.poNumber,
            woNumber: data.woNumber,
            squawkNumber: data.squawkNumber,
          },
          eta: data.eta,
          scheduled: data.scheduledStart,
          deliverBy: data.due,
          etd: data.etd,
          notes: data.notes,
          services: data.services
            .map(
              ({
                serviceType,
                serviceTypeId,
                priceList,
                estimatedTime,
                quantity,
                rate,
                discountPercentage,
                order,
                notes,
              }) => ({
                serviceType: {
                  id: serviceTypeId,
                  name: serviceType.name,
                },
                priceList: {
                  id: priceList?.id,
                  name: priceList?.name,
                },
                price: rate,
                discountPercentage: discountPercentage ?? null,
                name: serviceType.name,
                estimatedTime,
                quantity,
                order,
                notes,
              }),
            )
            .sort((a, b) => (a.order || 0) - (b.order || 0)),
          recurringType: data.recurringType,
          recurringStartsAt: data.recurringStartsAt,
          recurringEndsAt: data.recurringEndsAt,
          flatRate: data.flatRate,
        }}
      />
    </>
  );
};
