import { Shadows } from '@mui/material';

import { createShadow } from './helpers';

const DEFAULT_COLOR: [number, number, number] = [172, 172, 172];
const DEFAULT_OPACITY = 0.16;

export const componentShadows = {
  card: [
    createShadow(0, 0, 2, 0, DEFAULT_COLOR, 0.2),
    createShadow(0, 12, 24, -4, DEFAULT_COLOR, 0.12),
  ].join(','),
  dialog: createShadow(-40, 40, 80, -8, DEFAULT_COLOR, 0.24),
  dropdown: [
    createShadow(0, 0, 2, 0, DEFAULT_COLOR, 0.24),
    createShadow(-20, 20, 40, -4, DEFAULT_COLOR, 0.24),
  ].join(','),
  switch: [
    createShadow(0, 2, 4, -1, [0, 0, 0], 0.2),
    createShadow(0, 4, 5, 0, [0, 0, 0], 0.14),
    createShadow(0, 1, 10, 0, [0, 0, 0], 0.08),
  ].join(','),
  slider: {
    primary: createShadow(1, 0, 4, 0, [24, 144, 255], 0.25),
    secondary: createShadow(1, 0, 4, 0, [0, 0, 0], 0.25),
  },
};

export const shadows: Shadows = [
  'none',
  createShadow(0, 1, 2, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 2, 4, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 3, 6, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 4, 8, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 5, 10, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 6, 12, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 7, 14, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 8, 16, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 9, 18, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 10, 20, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 11, 22, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 12, 24, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 13, 26, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 14, 28, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 15, 30, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 16, 32, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 17, 34, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 18, 36, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 19, 38, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 20, 40, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 21, 42, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 22, 44, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 23, 46, -4, DEFAULT_COLOR, DEFAULT_OPACITY),
  createShadow(0, 24, 48, 0, DEFAULT_COLOR, DEFAULT_OPACITY),
];
