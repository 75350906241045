import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AttachmentsRepository } from 'domain/repositories/IAttachmentsRepository';
import { DeleteAttachmentParams } from 'domain/entities/Attachment';
import { useHookInjection } from 'application/hooks';

export const useCaseDeleteAttachment = () => {
  const queryClient = useQueryClient();
  const { deleteAttachment } = useHookInjection<AttachmentsRepository>(AttachmentsRepository);

  const { mutateAsync } = useMutation<void, Error, DeleteAttachmentParams>({
    mutationFn: deleteAttachment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['ATTACHMENTS'] });
    },
  });

  return {
    deleteAttachment: mutateAsync,
  };
};
