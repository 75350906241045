import { SortingDirection } from 'common/types/SortingOptions';
import { useMemo, useState } from 'react';

export const usePriceListsFilters = () => {
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [sort, setSort] = useState({
    sortBy: 'name',
    direction: SortingDirection.Ascending,
  });
  const [filters, setFilters] = useState<{ limit?: number }>({});
  const combinedFilters = useMemo(
    () => ({
      phrase: searchPhrase.length < 2 ? undefined : searchPhrase,
      ...sort,
      ...filters,
    }),
    [sort, searchPhrase, filters],
  );
  return {
    setSearchPhrase,
    sort,
    setSort,
    setFilters,
    filters: combinedFilters,
  };
};
