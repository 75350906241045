import { FC, useCallback, useState } from 'react';
import { Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useNavigate } from 'react-router-dom';

import { ShowJobActions } from 'domain/types/Roles';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { Button } from 'targets/web/components';
import { FiltersContainer, PageTitle } from 'targets/web/modules/dashboard/components';
import { AcceptDeclineTabs, ApproveDeclineTable } from 'targets/web/modules/approvals/components';
import { useAcceptDeclineViewFilters } from 'targets/web/modules/approvals/hooks/useAcceptDeclineViewFilters';
import { approvalsRoutesConfig } from 'targets/web/modules/approvals/navigation/approvalsRoutesConfig';
import { OnboardingAcceptAndDeclineModal } from 'targets/web/modules/jobs/components/OnboardingAcceptAndDeclineModal';

export const ApprovalsView: FC = () => {
  const t = useTranslationPrefix('approvals.approvals_view');
  const tBreadcrumbs = useTranslationPrefix('approvals.breadcrumbs');
  const { hasRole } = useCaseCheckAccess();

  const navigate = useNavigate();

  const { filters, setFilters } = useAcceptDeclineViewFilters();

  const [onboardingModalVisible, setOnboardingModalVisible] = useState(false);

  const handleAddJobButton = useCallback(
    () =>
      navigate(`${jobsRoutes.jobs}/${jobsRoutes.internalRoutes.new}`, {
        state: {
          breadcrumbs: [
            {
              label: tBreadcrumbs('approve_and_decline'),
              url: approvalsRoutesConfig.main,
            },
            {
              label: tBreadcrumbs('new_job'),
            },
          ],
        },
      }),
    [navigate, tBreadcrumbs],
  );

  return (
    <>
      <Stack width={1} direction="row" alignItems="center" justifyContent="space-between">
        <PageTitle title={t('title')} />

        <Stack direction="row" spacing={4}>
          <Button size="large" variant="outlined" onClick={() => setOnboardingModalVisible(true)}>
            <QuestionMarkIcon />
          </Button>
          {hasRole(ShowJobActions) && (
            <Button size="large" startIcon={<AddIcon />} onClick={handleAddJobButton}>
              {t('add_job_button')}
            </Button>
          )}
        </Stack>
      </Stack>

      <FiltersContainer
        initialValues={filters}
        onFiltersChange={(newFilters) => {
          setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }));
        }}
      />

      <Stack gap={4} sx={{ width: 1, height: 'auto' }}>
        <AcceptDeclineTabs />

        <ApproveDeclineTable />
      </Stack>

      <OnboardingAcceptAndDeclineModal
        open={onboardingModalVisible}
        onClose={() => setOnboardingModalVisible(false)}
      />
    </>
  );
};
