import z, { ZodType } from 'zod';

export type ID = string & { readonly '': '' };
export const ID = (value: string): ID => value as ID;

export interface Indexed {
  id: ID;
}

export function entityId(): ZodType<ID> {
  return z.string().uuid() as unknown as ZodType<ID>;
}
