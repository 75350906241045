import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { ConversationModalProps } from 'targets/web/components/ConversationModal';
import { DraggableModalProps } from 'targets/web/components/DraggableModal';

export interface IModalContext extends DraggableModalProps {
  handleMaximize: () => void;
  handleMinimize: () => void;
  handleModalClose: () => void;
  modalSize: { width: number; height: number } | undefined;
  modalPosition: { x: number; y: number } | undefined;
  maximized: boolean;
}

interface ModalProviderProps {
  children: ReactNode;
  token: ConversationModalProps['token'];
  name: ConversationModalProps['name'];
  parentSize?: ConversationModalProps['parentSize'];
  onCallEnd?: () => void;
}

const MIN_WIDTH = 490;
const MIN_HEIGHT = 420;
const INITIAL_WIDTH = 520;
const INITIAL_HEIGHT = 480;
const FULL_SIZE_OFFSET = 50;

export const ModalContext = createContext<IModalContext | null>(null);

export const ModalProvider: FC<ModalProviderProps> = ({
  children,
  token,
  name,
  parentSize,
  onCallEnd,
}) => {
  const initialCenterX = (parentSize?.width || window.innerWidth) / 2 - INITIAL_WIDTH / 2;
  const initialCenterY = (parentSize?.height || window.innerHeight) / 2 - INITIAL_HEIGHT / 2;

  const [state, setState] = useState({
    open: !!token && !!name,
    width: INITIAL_WIDTH,
    height: INITIAL_HEIGHT,
    x: initialCenterX,
    y: initialCenterY,
    maximized: false,
  });

  useEffect(() => {
    setState((_state) => ({ ..._state, open: !!token && !!name }));
  }, [token, name]);

  const handleMaximize = useCallback(
    () =>
      setState((_state) => ({
        ..._state,
        maximized: true,
        width: (parentSize?.width || window.innerWidth) - 2 * FULL_SIZE_OFFSET,
        height: (parentSize?.height || window.innerHeight) - 2 * FULL_SIZE_OFFSET,
        x: FULL_SIZE_OFFSET,
        y: FULL_SIZE_OFFSET,
      })),
    [setState, parentSize],
  );

  const handleMinimize = useCallback(
    () =>
      setState((_state) => ({
        ..._state,
        maximized: false,
        width: INITIAL_WIDTH,
        height: INITIAL_HEIGHT,
        x: initialCenterX,
        y: initialCenterY,
      })),
    [initialCenterX, initialCenterY],
  );

  const handleModalClose = useCallback(() => {
    setState((_state) => ({ ..._state, open: false }));

    onCallEnd?.();
  }, [setState, onCallEnd]);

  const value = useMemo(
    () => ({
      open: state.open,
      initialProps: {
        width: INITIAL_WIDTH,
        height: INITIAL_HEIGHT,
        x: initialCenterX,
        y: initialCenterY,
      },
      bounds: 'parent',
      onResizeStop: () => null,
      onDragStop: () => null,
      minWidth: MIN_WIDTH,
      minHeight: MIN_HEIGHT,
      modalSize: { width: state.width, height: state.height },
      modalPosition: { x: state.x, y: state.y },
      handleMaximize,
      handleMinimize,
      handleModalClose,
      maximized: state.maximized,
    }),
    [
      state.open,
      state.width,
      state.height,
      state.x,
      state.y,
      state.maximized,
      initialCenterX,
      initialCenterY,
      handleMaximize,
      handleMinimize,
      handleModalClose,
    ],
  );

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
