import { FC, useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import z from 'zod';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Stack, Theme, useMediaQuery } from '@mui/material';
import { damageSchema } from 'api/domain/entities/documents/PreWorkQuestionnaire';

import { Service } from 'domain/entities/Service';
import { InspectionArea } from 'domain/entities/InspectionArea';
import { useCaseSubmitPreWorkQuestionnaire } from 'application/jobs/useCases/useCaseSubmitPreWorkQuestionnaire';
import { useCaseGetJobDetails } from 'application/jobs/useCases/useCaseGetJobDetails';
import { useCaseUploadFile } from 'application/attachments/useCases/useCaseUploadFile';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { stringLimited } from 'infrastructure/utils/stringLimited';
import { Button, ConfirmationModal, Loader, Modal } from 'targets/web/components';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';

import { PreWorkQuestionnaireForm } from './PreWorkQuestionnaireForm';

export const damageFoundSchema = z.object({
  damage: damageSchema.or(z.literal('')),
  description: stringLimited(500).min(5).optional(),
  imageFile: z.instanceof(File).optional(),
});

export const PreWorkQuestionnaireFormSchema = z.object({
  availabilityVerified: z.literal<boolean>(true),
  walkaroundConducted: z.literal<boolean>(true),
  hangarChecked: z.literal<boolean>(true),
  inspectionAreas: z.array(InspectionArea).min(1),
  includeCommunication: z.literal<boolean>(true),
  toolsUsed: z.object({
    bottles: z.number().min(0).max(999),
    microfiber: z.number().min(0).max(999),
    terrycloth: z.number().min(0).max(999),
    cleanKit: z.number().min(0).max(999),
    other: z.number().min(0).max(999),
  }),
  employeeCount: z.number().gte(0),
  damagesFound: z.array(damageFoundSchema),
  customerContacted: z.boolean(),
  clientDamageCommunication: stringLimited(500).min(5).optional(),
});

export type PreWorkQuestionnaireFormData = z.infer<typeof PreWorkQuestionnaireFormSchema>;

export interface PreWorkQuestionnaireDialogProps {
  onSubmit: (values: PreWorkQuestionnaireFormData) => void | Promise<void>;
  onClose: () => void;
  open: boolean;
  service: Pick<
    Service,
    'id' | 'jobId' | 'name' | 'status' | 'workLogs' | 'toolsUsed' | 'employeeCount'
  >;
}

export const PreWorkQuestionnaireDialog: FC<PreWorkQuestionnaireDialogProps> = ({
  onSubmit,
  onClose,
  open,
  service,
}) => {
  const t = useTranslationPrefix('jobs.pre_work_questionnaire');
  const notify = useSnackbar();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { submitPreWorkQuestionnaire } = useCaseSubmitPreWorkQuestionnaire();
  const { uploadFile } = useCaseUploadFile();
  const { data, isLoading } = useCaseGetJobDetails({ id: open ? service.jobId : undefined });

  const initialValues: PreWorkQuestionnaireFormData = useMemo(
    () => ({
      inspectionAreas: [],
      availabilityVerified: false,
      walkaroundConducted: false,
      hangarChecked: false,
      includeCommunication: false,
      toolsUsed: service?.toolsUsed ?? {
        bottles: 0,
        microfiber: 0,
        terrycloth: 0,
        cleanKit: 0,
        other: 0,
      },
      customerContacted: false,
      damagesFound: [],
      employeeCount: service?.employeeCount ?? 0,
    }),
    [service?.employeeCount, service?.toolsUsed],
  );

  const details = useMemo(
    () => ({
      job: data?.name ?? '',
      service: service.name,
      customer: data?.customer.name ?? '',
      aircraft: data?.aircraft.type.code ?? '',
    }),
    [data?.aircraft.type.code, data?.customer.name, data?.name, service],
  );

  const handleSubmit = useCallback(
    async (values: PreWorkQuestionnaireFormData) => {
      const result = await submitPreWorkQuestionnaire({
        serviceId: service.id,
        // @ts-expect-error TS2322
        damagesFound: values.damagesFound.map(({ damage, description }) => ({
          damage,
          description,
        })),
        includeCommunication: values.includeCommunication,
        clientDamageCommunication:
          values.customerContacted && values.clientDamageCommunication
            ? { notes: values.clientDamageCommunication }
            : undefined,
        toolsUsed: values.toolsUsed,
        employeeCount: values.employeeCount,
        inspectionAreas: values.inspectionAreas,
      });

      await Promise.allSettled(
        values.damagesFound.map(({ imageFile }) => {
          if (!imageFile) return;

          return uploadFile({
            jobId: service.jobId,
            file: imageFile,
            refersTo: result.questionnaireId,
          }).catch((e) => {
            notify(t('snackbar.uploading_damage_image_error'), {
              variant: 'error',
              preventDuplicate: true,
            });

            throw e;
          });
        }),
      );

      onSubmit(values);
      onClose();
    },
    [
      notify,
      onClose,
      onSubmit,
      service.jobId,
      service.id,
      submitPreWorkQuestionnaire,
      t,
      uploadFile,
    ],
  );

  const didToolsChange = (
    ref: { [key: string]: number } | undefined,
    current: { [key: string]: number },
  ) => {
    if (!ref) {
      return false;
    }

    // fast and simple
    return JSON.stringify(ref) !== JSON.stringify(current);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
      validationSchema={toFormikValidationSchema(PreWorkQuestionnaireFormSchema)}
    >
      {({ isValid, isSubmitting, submitForm, values }) => (
        <Form>
          <Modal
            disableBackdropClick
            onClose={onClose}
            open={open}
            title={t('title')}
            maxWidth="md"
            fullWidth
            fullScreen={isMobile}
            onClick={(e) => e.stopPropagation()}
            content={
              isLoading ? (
                <Stack height={500} width={1} alignItems="center" justifyContent="center">
                  <Loader />
                </Stack>
              ) : (
                <PreWorkQuestionnaireForm details={details} />
              )
            }
            sx={{
              '& .MuiDialogActions-root': {
                justifyContent: 'space-between',
              },
            }}
            actions={{
              primary: didToolsChange(service?.toolsUsed, values.toolsUsed)
                ? {
                    onClick: submitForm,
                    element: (
                      <ConfirmationModal
                        title={t('confirm_modal.title')}
                        subtitle={t('confirm_modal.subtitle')}
                        confirmText={t('buttons.yes')}
                        cancelText={t('buttons.no')}
                        confirmButtonColor="primary"
                        triggerButton={(onClick: () => void) => (
                          <Button
                            startIcon={<PlayArrowIcon />}
                            variant="contained"
                            color="primary"
                            onClick={onClick}
                            disabled={!isValid || isSubmitting}
                            size="large"
                            type="submit"
                          >
                            {service.workLogs.length === 0
                              ? t('buttons.start')
                              : t('buttons.resume')}
                          </Button>
                        )}
                        onConfirm={submitForm}
                      />
                    ),
                  }
                : {
                    size: 'large',
                    type: 'submit',
                    startIcon: <PlayArrowIcon />,
                    text: service.workLogs.length === 0 ? t('buttons.start') : t('buttons.resume'),
                    disabled: !isValid || isSubmitting,
                    onClick: submitForm,
                    loading: isSubmitting,
                  },
              secondary: {
                size: 'large',
                text: t('buttons.cancel'),
              },
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
