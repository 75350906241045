import { Navigate, Route } from 'react-router-dom';

import {
  ShowConfiguration,
  ShowOperations,
  ViewApprovalsDashboard,
  ViewBillingDashboard,
} from 'domain/types/Roles';
import { ScheduleView } from 'targets/web/modules/schedule/views/Schedule.view';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { operationsRoutes } from 'targets/web/modules/operations/navigation/operationsRoutes';
import JobsRoutes from 'targets/web/modules/jobs/routes/JobsRoutes';
import OperationsRoutes from 'targets/web/modules/operations/routes/OperationsRoutes';
import { DashboardView, PageNotFoundView } from 'targets/web/modules/dashboard/views';
import { approvalsRoutesConfig } from 'targets/web/modules/approvals/navigation/approvalsRoutesConfig';
import { ApprovalsRoutes } from 'targets/web/modules/approvals/navigation/ApprovalsRoutes';
import { configurationRoutes } from 'targets/web/modules/configuration/navigation/configurationRoutes';
import ConfigurationRoutes from 'targets/web/modules/configuration/routes/ConfigurationRoutes';
import { billingRoutesConfig } from 'targets/web/modules/billing/navigation/billingRoutesConfig';
import { BillingRoutes } from 'targets/web/modules/billing/navigation/BillingRoutes';
import { scheduleRoutes } from 'targets/web/modules/schedule/navigation/scheduleRoutes';
import { ScrollToTopProvider } from 'targets/web/contexts/ScrollToTopProvider';

import ProtectedRoute from './ProtectedRoute';
import { RoleLimitedRoute } from './RoleLimitedRoute';

const DEFAULT_ROUTE = jobsRoutes.jobs;

const AuthorizedRoutes = (
  <Route
    element={
      <ProtectedRoute>
        <ScrollToTopProvider>
          <DashboardView />
        </ScrollToTopProvider>
      </ProtectedRoute>
    }
  >
    <Route
      path={`${approvalsRoutesConfig.main}/*`}
      element={
        <RoleLimitedRoute role={ViewApprovalsDashboard}>
          <ApprovalsRoutes />
        </RoleLimitedRoute>
      }
    />
    <Route path={`${jobsRoutes.jobs}/*`} element={<JobsRoutes />} />
    <Route
      path={`${configurationRoutes.configuration}/*`}
      element={
        <RoleLimitedRoute role={ShowConfiguration}>
          <ConfigurationRoutes />
        </RoleLimitedRoute>
      }
    />
    <Route
      path={`${billingRoutesConfig.main}/*`}
      element={
        <RoleLimitedRoute role={ViewBillingDashboard}>
          <BillingRoutes />
        </RoleLimitedRoute>
      }
    />

    <Route path={`${scheduleRoutes.schedule}/*`} element={<ScheduleView />} />
    <Route
      path={`${operationsRoutes.operations}/*`}
      element={
        <RoleLimitedRoute role={ShowOperations}>
          <OperationsRoutes />
        </RoleLimitedRoute>
      }
    />

    <Route path="/" element={<Navigate to={DEFAULT_ROUTE} />} />

    <Route path="*" element={<PageNotFoundView />} />
  </Route>
);

export default AuthorizedRoutes;
