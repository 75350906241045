import { styled } from '@mui/material';

const Dot = styled('div')<{
  size?: number;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}>(({ theme, size, color }) => ({
  flex: 'none',
  backgroundColor: theme.palette[color ?? 'primary'].main,
  width: theme.spacing(size ?? 3),
  borderRadius: '100%',
  height: theme.spacing(size ?? 3),
}));

export default Dot;
