import { IconButton, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';

import { isEntityLocked } from 'domain/entities/BillingStatus';
import { useCaseGetJobDetails } from 'application/jobs/useCases/useCaseGetJobDetails';
import { useCaseUpdateJob } from 'application/jobs/useCases/useCaseUpdateJob';
import { useCaseGetServiceTypes } from 'application/services/useCases/useCaseGetServiceTypes';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Breadcrumbs, Loader } from 'targets/web/components';
import {
  FormikCancelSaveFooter,
  PageTitle,
  PersistFormikValues,
} from 'targets/web/modules/dashboard/components';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { ConfirmationModal } from 'targets/web/components/ConfirmationModal';
import { JobDetailsData, JobDetailsForm } from 'targets/web/modules/jobs/components';
import { usePersistedValues } from 'targets/web/modules/dashboard/hooks/usePersistedValues';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

const EditJobView = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();

  const tBreadcrumbs = useTranslationPrefix('jobs.breadcrumbs');
  const t = useTranslationPrefix('jobs.edit_job_details');
  const { isOffline } = useNetworkStatus();

  const persistFormKey = `edit-job-${jobId}`;
  const { getPersistedValues, delPersistedValues } = usePersistedValues(persistFormKey);

  const { isLoading: servicesLoading } = useCaseGetServiceTypes();
  const { data, isLoading: detailsLoading } = useCaseGetJobDetails({ id: jobId });
  const { updateJob } = useCaseUpdateJob();

  useEffect(() => {
    if (data && isEntityLocked(data)) {
      navigate(`/jobs/${data.id}/details`, { replace: true });
    }
  }, [data, data?.billingStatus, data?.id, navigate]);

  const handleSubmit = useCallback(
    async (values: JobDetailsData) => {
      if (!data) return;

      await updateJob({
        jobId: data.id,
        data: {
          aircraftId: values.aircraft.id,
          customerId: values.customer.id,
          scheduledStart: values.scheduled ? new Date(values.scheduled) : new Date(),
          due: values.deliverBy ? new Date(values.deliverBy) : new Date(),
          etd: values.etd ? new Date(values.etd) : new Date(),
          eta: values.eta ? new Date(values.eta) : new Date(),
          notes: values.notes,
          services: values.services.map((s, order) => ({
            serviceTypeId: s.serviceType.id,
            priceListId: s.priceList.id,
            quantity: s.quantity,
            estimatedTime: s.estimatedTime,
            price: s.price,
            discountPercentage: s.discountPercentage,
            order,
            notes: s.notes ?? '',
          })),
          poNumber: values.payment.poNumber,
          woNumber: values.payment.woNumber,
          squawkNumber: values.payment.squawkNumber,
          recurringType: values.recurringType,
          recurringStartsAt: values.recurringStartsAt,
          recurringEndsAt: values.recurringEndsAt,
          flatRate: values.flatRate,
        },
      });

      navigate(`/jobs/${data.id}/details`, {
        replace: true,
        state: { from: `/jobs/${data.id}/edit` },
      });
      delPersistedValues();
    },
    [data, delPersistedValues, navigate, updateJob],
  );

  const navigateBack = useCallback(
    () =>
      navigate(`/jobs/${data?.id}/details`, {
        replace: true,
        state: { from: `/jobs/${data?.id}/edit` },
      }),
    [data?.id, navigate],
  );

  const [effectiveInitialValues, setEffectiveInitialValues] = useState<JobDetailsData>();

  useEffect(() => {
    getPersistedValues().then((values) => {
      if (values) {
        setEffectiveInitialValues(values);
      }
    });
  }, [getPersistedValues]);

  useEffect(() => {
    if (effectiveInitialValues) return;
    if (!data) return;

    setEffectiveInitialValues({
      station: data.station,
      customer: data.customer,
      aircraft: data.aircraft,
      payment: {
        poNumber: data.poNumber,
        woNumber: data.woNumber,
        squawkNumber: data.squawkNumber,
      },
      eta: data.eta,
      scheduled: data.scheduledStart,
      deliverBy: data.due,
      etd: data.etd,
      notes: data.notes ?? '',
      services: data.services
        .map(
          ({
            serviceType,
            serviceTypeId,
            priceList,
            estimatedTime,
            quantity,
            rate,
            discountPercentage,
            order,
            notes,
          }) => ({
            serviceType: {
              id: serviceTypeId,
              name: serviceType.name,
            },
            priceList: {
              id: priceList.id,
              name: priceList.name,
            },
            price: rate,
            discountPercentage: discountPercentage ?? null,
            name: serviceType.name,
            estimatedTime,
            quantity,
            order,
            notes: notes ?? '',
          }),
        )
        .sort((a, b) => (a.order || 0) - (b.order || 0)),
      recurringType: data.recurringType,
      recurringStartsAt: data.recurringStartsAt,
      recurringEndsAt: data.recurringEndsAt,
      flatRate: data.flatRate ?? undefined,
    });
  }, [data, effectiveInitialValues]);

  if (!data || !effectiveInitialValues || detailsLoading || servicesLoading) {
    return (
      <Stack height={1} justifyContent="center">
        <Loader />
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <ConfirmationModal
          title={t('back_modal.title')}
          subtitle={t('back_modal.subtitle')}
          confirmText={t('back_modal.confirm')}
          cancelText={t('back_modal.cancel')}
          onConfirm={navigateBack}
          triggerButton={(onClick: () => void) => (
            <IconButton onClick={isOffline ? navigateBack : onClick} color="primary">
              <ArrowBackIcon />
            </IconButton>
          )}
        />

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('jobs_and_services'),
                url: jobsRoutes.jobs,
              },
              {
                label: tBreadcrumbs('job_details'),
              },
            ]}
          />

          <PageTitle title={t('title', { job: data.name })} />
        </Stack>
      </Stack>

      <JobDetailsForm
        job={data}
        initialValues={effectiveInitialValues}
        onSubmit={handleSubmit}
        footer={
          <>
            {jobId && isOffline && <PersistFormikValues persistInvalid name={persistFormKey} />}
            <FormikCancelSaveFooter
              onCancel={() =>
                navigate(`/jobs/${data.id}/details`, {
                  replace: true,
                  state: { from: `/jobs/${data.id}/edit` },
                })
              }
            />
          </>
        }
      />
    </>
  );
};

export default EditJobView;
