import { ErrorOutline, MoneyOff, RunningWithErrorsOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { JobSummaryCount } from 'domain/entities/JobsSummary';
import { ShowOperations } from 'domain/types/Roles';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { componentShadows } from 'targets/web/theme/shadows';
import Button from 'targets/web/components/Button';
import { InfoTile } from 'targets/web/modules/jobs/components/InfoTile';
import { operationsRoutes } from 'targets/web/modules/operations/navigation/operationsRoutes';

interface SummaryProps {
  items: JobSummaryCount;
}

export const Summary: FC<SummaryProps> = ({ items }) => {
  const t = useTranslationPrefix('components.summary_container');
  const navigate = useNavigate();
  const { hasRole } = useCaseCheckAccess();

  const onClickHandler = () => {
    navigate(operationsRoutes.operations);
  };

  return (
    <Stack
      width={1}
      gap={4}
      sx={{
        padding: 4,
        borderRadius: 1,
        backgroundColor: 'background.default',
        boxShadow: componentShadows.card,
      }}
    >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant="h5">{t('header')}</Typography>
        {hasRole(ShowOperations) && (
          <Button
            size="small"
            variant="text"
            startIcon={<RemoveRedEyeOutlinedIcon />}
            onClick={onClickHandler}
          >
            {t('view')}
          </Button>
        )}
      </Stack>

      <Stack direction="row" alignItems="center" gap={4}>
        <InfoTile
          type="error"
          icon={<ErrorOutline sx={{ fontSize: '32px' }} />}
          numberOfItems={items.totalOverdue}
          title={t('overdue')}
        />
        <InfoTile
          type="warning"
          icon={<RunningWithErrorsOutlined sx={{ fontSize: '32px' }} />}
          numberOfItems={items.totalAtRisk}
          title={t('at_risk')}
        />
        <InfoTile
          type="info"
          icon={<MoneyOff sx={{ fontSize: '32px' }} />}
          numberOfItems={items.totalOverbudget}
          title={t('over_budget')}
        />
      </Stack>
    </Stack>
  );
};
