import { atom, WritableAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export function atomWithToggleAndStorage(
  key: string,
  initialValue?: boolean,
): WritableAtom<boolean, [boolean | void], void> {
  const anAtom = atomWithStorage(key, initialValue);

  return atom(
    (get) => get(anAtom) ?? false,
    (get, set, nextValue?: boolean | void) => {
      const update = nextValue ?? !get(anAtom);
      set(anAtom, update);
    },
  );
}
