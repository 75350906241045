/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { Stack } from '@mui/material';
import { Part } from 'api/domain/entities/documents/PostWorkQuestionnaire';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';

import { InspectionArea } from 'domain/entities/InspectionArea';
import PerformedByModal from 'targets/web/modules/jobs/components/PostWorkQuestionnaire/PerformedByModal';
import {
  FormikCancelSaveFooter,
  PersistFormikValues,
} from 'targets/web/modules/dashboard/components';
import { usePersistedValues } from 'targets/web/modules/dashboard/hooks/usePersistedValues';
import { usePostWorkQuestionnaireSteps } from 'targets/web/modules/jobs/hooks/usePostWorkQuestionnaireSteps';
import ConfirmedByModal from 'targets/web/modules/jobs/components/PostWorkQuestionnaire/ConfirmedByModal';

import { PartField } from './PartSigned';
import { Form900Schema } from './schema';
import { InspectionQuestionnaireSection } from './InspectionQuestionnaireSection';
import { sectionsMap } from './sectionsMap';

interface Props {
  inspectionAreas: InspectionArea[];
  persistFormKey: string;
  onSubmit: (values: Record<Part, PartField>) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const Step1PostWorkQuestionnaire: FC<Props> = ({
  onSubmit,
  onCancel,
  isLoading,
  persistFormKey,
  inspectionAreas,
}) => {
  const { step, previousStep, nextStep } = usePostWorkQuestionnaireSteps();
  const [selectedPerformedByPart, setSelectedPerformedByPart] = useState<Part[]>([]);
  const [selectedConfirmedByPart, setSelectedConfirmedByPart] = useState<Part[]>([]);

  const inspectionParts = useMemo(
    () => inspectionAreas.map((part) => sectionsMap[part]).flat(2),
    [inspectionAreas],
  );

  const initialValues = useMemo(
    () =>
      inspectionParts.reduce((acc: Record<Part, PartField>, part: Part) => {
        acc[part] = {};
        return acc;
      }, {} as Record<Part, PartField>),
    [inspectionParts],
  );

  const schema = useMemo(() => {
    const baseSchema = Form900Schema(inspectionParts);

    if (step === 'form900-1') {
      return baseSchema.superRefine((values, ctx) => {
        if (Object.values(values).every((part) => !part.performedBy)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [0],
            message: 'At least one field needs to be filled up',
          });
        }
      });
    }

    return baseSchema.superRefine((values, ctx) => {
      const allConfirmed = !Object.values(values).some(
        ({ checkedBy, performedBy }) => !!checkedBy !== !!performedBy,
      );

      if (!allConfirmed) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [0],
          message: 'All fields need to be confirmed',
        });
      }
    });
  }, [inspectionParts, step]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values, { setSubmitting }) => {
      switch (step) {
        case 'form900-1':
          nextStep();
          window.scrollTo({ top: 0, behavior: 'instant' });
          setSubmitting(false);
          return;
        case 'form900-2':
          window.scrollTo({ top: 0, behavior: 'instant' });
          return onSubmit(values);
        default:
          break;
      }
    },
  });

  const { getPersistedValues } = usePersistedValues(persistFormKey);
  useEffect(() => {
    getPersistedValues().then((values) => {
      if (values && Object.keys(values).length > 0 && !formik.dirty) {
        formik.setValues(values, true);
      }
    });
  }, [formik.dirty, formik.setValues, getPersistedValues]);

  useEffect(() => {
    formik.validateForm();
  }, [formik.validateForm, step]);

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <PersistFormikValues persistInvalid name={persistFormKey} />

        <Stack direction="column" gap={4}>
          {inspectionAreas.map((inspectionPart) => (
            <InspectionQuestionnaireSection
              key={inspectionPart}
              inspectionPart={inspectionPart}
              onPerformedByClick={setSelectedPerformedByPart}
              onConfirmedByClick={setSelectedConfirmedByPart}
            />
          ))}

          <FormikCancelSaveFooter
            submitMode={'continue'}
            isLoading={isLoading}
            disabled={isLoading}
            onBack={() => {
              if (step !== 'form900-1') {
                previousStep();
                window.scrollTo({ top: 0, behavior: 'instant' });
                return;
              }

              onCancel();
            }}
            onCancel={onCancel}
          />
        </Stack>

        <PerformedByModal
          onClose={() => setSelectedPerformedByPart([])}
          onSave={(name, performedBy) => {
            Promise.any(name.map((n) => formik.setFieldValue(n, { performedBy }))).then(() =>
              formik.validateForm(),
            );

            setSelectedPerformedByPart([]);
          }}
          part={selectedPerformedByPart}
        />
        <ConfirmedByModal
          onClose={() => setSelectedConfirmedByPart([])}
          onSave={(name, checkedBy) => {
            Promise.any(
              name.map((n) => formik.setFieldValue(n, { ...formik.values[n], checkedBy })),
            ).then(() => formik.validateForm());

            setSelectedConfirmedByPart([]);
          }}
          part={selectedConfirmedByPart}
        />
      </Form>
    </FormikContext.Provider>
  );
};
