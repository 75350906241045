import { FC, PropsWithChildren } from 'react';

import { ButtonProps } from 'targets/web/components';

import { StyledButton } from './style';

export const IconButton: FC<PropsWithChildren<ButtonProps>> = ({ ...props }) => (
  <StyledButton {...props} />
);

export default IconButton;
