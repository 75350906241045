import { useQuery, useQueryClient } from '@tanstack/react-query';
import Pagination from 'common/types/Pagination';
import { useCallback } from 'react';

import { SearchServicesParams, ServiceRepository } from 'domain/repositories/IServiceRepository';
import { ServiceStatusCount } from 'domain/entities/ServiceStatusCount';
import { useHookInjection } from 'application/hooks';

const initialData: ServiceStatusCount = {
  all: 0,
  completed: 0,
  inProgress: 0,
  notStarted: 0,
  canceled: 0,
};

const QUERY_PREFIX = 'SERVICES_STATUS_COUNT';

export const useCaseGetServicesCountByStatus = (
  params: Omit<SearchServicesParams, keyof Pagination> | null,
) => {
  const qClient = useQueryClient();

  const { getCountByStatus } = useHookInjection<ServiceRepository>(ServiceRepository);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_PREFIX, params && { ...params, status: undefined }] as const,
    queryFn: ({ queryKey }) => getCountByStatus(queryKey[1] ?? {}),
    enabled: !!params,
    initialData,
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    data: {
      ...data,
      all: data.all - data.canceled,
    },
    isLoading,
    isFetching,
    refetch,
  };
};
