import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import SortingOptions, { SortingDirection } from 'common/types/SortingOptions';

import { JobStatus } from 'domain/entities/JobStatus';
import { ServiceStatus } from 'domain/entities/ServiceStatus';
import { Filters } from 'targets/web/modules/dashboard/components/FiltersContainer/FiltersContainer';

export type ViewType = 'grid-view' | 'table-view';
export type LogType = 'job-log' | 'service-log';

const viewTypeAtom = atomWithStorage<ViewType>('ViewType', 'grid-view');
const logTypeAtom = atomWithStorage<LogType>('LogType', 'job-log');
const filtersAtom = atom<Partial<Filters & { limit: number }>>({
  dateStart: undefined,
  dateEnd: undefined,
});

export type StatusTab = 'all' | 'scheduled' | 'in_progress' | 'completed' | 'canceled';

const statusTabAtom = atom<StatusTab>('all');

const getJobStatus = (status: StatusTab): JobStatus[] => {
  switch (status) {
    case 'all':
      return ['scheduled', 'completed', 'in_progress'];
    default:
      return [status];
  }
};

const getServiceStatus = (status: StatusTab): ServiceStatus[] => {
  switch (status) {
    case 'all':
      return ['pending', 'in_progress', 'completed'];
    case 'scheduled':
      return ['pending'];
    default:
      return [status];
  }
};

const servicesTableSortAtom = atom<SortingOptions>({
  sortBy: 'status',
  direction: SortingDirection.Ascending,
});

const jobsTableSortAtom = atom<SortingOptions>({
  sortBy: 'status',
  direction: SortingDirection.Ascending,
});

export const useJobViewFilters = () => {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [viewType, setViewType] = useAtom(viewTypeAtom);
  const [logType, setLogType] = useAtom(logTypeAtom);
  const [statusTab, setStatusTab] = useAtom(statusTabAtom);
  const [servicesTableSort, setServicesTableSort] = useAtom(servicesTableSortAtom);
  const [jobsTableSort, setJobsTableSort] = useAtom(jobsTableSortAtom);

  const jobFilters = useMemo(
    () => ({
      ...filters,
      ...jobsTableSort,
      status: getJobStatus(statusTab),
    }),
    [filters, statusTab, jobsTableSort],
  );
  const serviceFilters = useMemo(
    () => ({
      ...filters,
      ...servicesTableSort,
      status: getServiceStatus(statusTab),
    }),
    [statusTab, filters, servicesTableSort],
  );
  const setSort = useCallback(
    (params: SortingOptions) =>
      logType === 'job-log' ? setJobsTableSort(params) : setServicesTableSort(params),
    [setServicesTableSort, setJobsTableSort, logType],
  );

  return {
    setSort,
    filters,
    setFilters,
    jobFilters,
    serviceFilters,
    statusTab,
    setStatusTab,
    viewType,
    setViewType,
    logType,
    setLogType,
  };
};
