import { Grid, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { z } from 'zod';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { CardStack } from 'targets/web/components/CardStack';
import { DatePicker, TextField } from 'targets/web/components';
import { localTimeToUTC, utcToLocalTime } from 'targets/web/modules/jobs/utils';

export const BasicServiceDetailsSchema = z.object({
  service: z.string(),
  serviceLocation: z.string(),
  scheduled: z.string(),
  dueDate: z.string(),
  estimatedTime: z.number(),
  quantity: z.number(),
  recurringType: z.string(),
  employeeCount: z.number().min(0),
  contractType: z.string(),
});

export type BasicServiceDetailsData = z.infer<typeof BasicServiceDetailsSchema>;

export interface BasicServiceDetailsProps {
  disabled: boolean;
}

const BasicServiceDetails: FC<BasicServiceDetailsProps> = ({ disabled }) => {
  const { values, setFieldValue, handleChange } = useFormikContext<BasicServiceDetailsData>();
  const t = useTranslationPrefix('jobs.service_details_form.basic_details');
  return (
    <CardStack gap={4}>
      <Typography variant="h6">{t('title')}</Typography>
      <Grid container spacing={6}>
        <Grid item md={6}>
          <Stack gap={4}>
            <TextField
              label={t('service_label')}
              name="service"
              value={values.service}
              onChange={handleChange('service')}
              fullWidth
              readOnly={true}
              disabled={true}
            />
            <DatePicker
              label={t('scheduled_label')}
              value={values.scheduled ? utcToLocalTime(new Date(values.scheduled)) : null}
              disabled={disabled}
              onChange={(e) =>
                setFieldValue('scheduled', e ? localTimeToUTC(e).toUTCString() : undefined)
              }
            />
            <DatePicker
              label={t('due_date_label')}
              value={values.dueDate ? utcToLocalTime(new Date(values.dueDate)) : null}
              disabled={disabled}
              onChange={(e) =>
                setFieldValue('dueDate', e ? localTimeToUTC(e).toUTCString() : undefined)
              }
            />
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Stack gap={4}>
            <TextField
              label={t('service_location_label')}
              name="service_location"
              readOnly={true}
              value={values.serviceLocation}
              onChange={handleChange('serviceLocation')}
              fullWidth
              disabled={true}
            />
            <TextField
              type="number"
              label={t('estimated_time_label')}
              name="estimated_time"
              value={values.estimatedTime}
              onChange={(e) => setFieldValue('estimatedTime', parseInt(e.target.value))}
              fullWidth
            />
            <TextField
              type="number"
              label={t('employee_count_label')}
              name="employee_count"
              value={values.employeeCount}
              onChange={(e) => setFieldValue('employeeCount', parseInt(e.target.value))}
              fullWidth
              InputProps={{
                inputProps: {
                  min: 0,
                  onWheel: (e) => {
                    // Prevent the input value change
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    e.target?.blur();
                  },
                },
                endAdornment: <Typography variant="body2">{t('employee_count_suffix')}</Typography>,
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </CardStack>
  );
};

export default BasicServiceDetails;
