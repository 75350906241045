import { ContainerModule } from 'inversify';
import 'reflect-metadata';

import { JobRepository } from 'domain/repositories/IJobRepository';
import { ServiceRepository } from 'domain/repositories/IServiceRepository';
import { AircraftRepository } from 'domain/repositories/IAircraftRepository';
import { CustomerRepository } from 'domain/repositories/ICustomerRepository';
import { AttachmentsRepository } from 'domain/repositories/IAttachmentsRepository';
import { StationRepository } from 'domain/repositories/IStationRepository';
import { JobRequestRepository } from 'domain/repositories/IJobRequestRepository';
import { ReportRepository } from 'domain/repositories/IReportRepository';
import { PricesRepository } from 'domain/repositories/IPricesRepository';
import { BillingItemRepository } from 'domain/repositories/IBillingItemRepository';

import { useTsRestJobRepository } from './providers/jobs/useTsRestJobRepository';
import { useTsRestServiceRepository } from './providers/services/useTsRestServiceRepository';
import { useTsRestAircraftRepository } from './providers/aircraft/useTsRestAircraftRepository';
import { useTsRestCustomerRepository } from './providers/customers/useTsRestCustomerRepository';
import { useTsRestAttachmentsRepository } from './providers/attachments/useTsRestAttachmentsRepository';
import { useTsRestStationRepository } from './providers/stations/useTsRestStationRepository';
import { useTsRestJobRequestRepository } from './providers/approvals/useTsRestJobRequestRepository';
import { useTsRestReportRepository } from './providers/reports/useTsRestReportRepository';
import { useTsRestPricesRepository } from './providers/prices/useTsRestPricesRepository';
import { useTsRestBillingItemRepository } from './providers/billing/useTsRestBillingItemRepository';

const infrastructureModule = new ContainerModule((bind) => {
  bind(JobRepository).toFunction(useTsRestJobRepository);
  bind(JobRequestRepository).toFunction(useTsRestJobRequestRepository);
  bind(ServiceRepository).toFunction(useTsRestServiceRepository);
  bind(AttachmentsRepository).toFunction(useTsRestAttachmentsRepository);
  bind(AircraftRepository).toFunction(useTsRestAircraftRepository);
  bind(BillingItemRepository).toFunction(useTsRestBillingItemRepository);
  bind(PricesRepository).toFunction(useTsRestPricesRepository);
  bind(CustomerRepository).toFunction(useTsRestCustomerRepository);
  bind(StationRepository).toFunction(useTsRestStationRepository);
  bind(ReportRepository).toFunction(useTsRestReportRepository);
});

export default infrastructureModule;
