import { Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import { IconContainer } from 'targets/web/components';
import { Colors } from 'targets/web/types/theme';

interface InfoTileProps {
  type: Colors;
  icon: ReactNode;
  numberOfItems: number;
  title: string;
}

export const InfoTile: FC<InfoTileProps> = ({ type, numberOfItems, icon, title }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: '100%',
        backgroundColor: `${type}.action.alertBackground`,
        borderRadius: 1,
        gap: {
          xs: 2,
          sm: 4,
        },
        padding: {
          xs: 2,
          sm: 4,
        },
      }}
    >
      <IconContainer padding={2} color={type} iconColorVariant="alertContent">
        {icon}
      </IconContainer>

      <Stack direction="column" alignItems={'flex-start'}>
        <Typography color={`${type}.action.alertContent`}>{title}</Typography>
        <Typography color={`${type}.action.alertContent`} variant="h4">
          {numberOfItems}
        </Typography>
      </Stack>
    </Stack>
  );
};
