import { useCallback } from 'react';
import contract from 'api/infrastructure/contract/stations.route';

import { StationRepository } from 'domain/repositories/IStationRepository';
import useTsRestClient from 'infrastructure/providers/useTsRestClient';

type RepositoryFunctions = ReturnType<StationRepository>;

export const useTsRestStationRepository: StationRepository = () => {
  const client = useTsRestClient(contract);

  const searchStation: RepositoryFunctions['searchStation'] = useCallback(
    async (params) => {
      const res = await client.searchStations({
        query: {
          ...params,
        },
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const getStation: RepositoryFunctions['getStation'] = useCallback(
    async (params) => {
      const res = await client.getById({ params });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  return {
    searchStation,
    getStation,
  };
};
