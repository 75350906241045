import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { CustomerRepository, SearchCustomerParams } from 'domain/repositories/ICustomerRepository';
import { Entity } from 'domain/types/Entity';
import { Customer } from 'domain/entities/Customer';
import { useHookInjection } from 'application/hooks';
import { useSortingAndPagination } from 'application/common';

const QUERY_PREFIX = 'CUSTOMER';

export const useCaseSearchCustomer = (params: SearchCustomerParams | null) => {
  const qClient = useQueryClient();
  const { searchCustomer } = useHookInjection<CustomerRepository>(CustomerRepository);

  const { paging, sorting, ...utils } = useSortingAndPagination<Entity<Customer>>('name');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_PREFIX, { ...params, ...paging, ...sorting }] as const,
    queryFn: ({ queryKey }) => searchCustomer(queryKey[1]),
    placeholderData: { items: [], total: 0 },
    enabled: !!params,
    staleTime: 10_000,
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    ...data,
    refetch,

    paging,
    sorting,
    ...utils,

    isLoading,
    isFetching,
  };
};
