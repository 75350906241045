import { Chip, InputAdornment, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import GridViewIcon from '@mui/icons-material/GridViewOutlined';
import ListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import DollarIcon from '@mui/icons-material/AttachMoneyOutlined';
import { addDays } from 'date-fns';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import ToggleButton from 'targets/web/components/ToggleButton';
import TextField from 'targets/web/components/TextField';
import { StatusChip } from 'targets/web/modules/jobs/components/StatusChips';
import OnboardingModal from 'targets/web/modules/jobs/components/OnboardingModal';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export const OnboardingJobAndServicesModal: FC<ModalProps> = ({ onClose, open }) => {
  const t = useTranslationPrefix('jobs.jobs_view.job_and_services_onboarding_modal');

  const options: { main: ReactNode; description: ReactNode }[] = [
    {
      main: (
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={t('search.placeholder')}
          disabled
        />
      ),
      description: (
        <>
          <Typography variant="body2">{t('search.description_p1')}</Typography>
          <Typography variant="body2">{t('search.description_p2')}</Typography>
        </>
      ),
    },
    {
      main: (
        <Stack gap={4} direction="row" justifyContent="center">
          <ToggleButton
            color="primary"
            size="medium"
            value="auto"
            options={[
              { value: 'auto', label: 'Job Log' },
              { value: 'high', label: 'Service Log' },
            ]}
          ></ToggleButton>
          <ToggleButton
            color="primary"
            size="medium"
            value="auto"
            options={[
              { value: 'auto', icon: <GridViewIcon /> },
              { value: 'low', icon: <ListBulletedIcon /> },
            ]}
          />
        </Stack>
      ),
      description: <Typography variant="body2">{t('organize.description_p1')}</Typography>,
    },
    {
      main: (
        <>
          <Stack direction="row" justifyContent="space-between">
            <StatusChip
              status="scheduled"
              dueDate={addDays(Date.now(), 10)}
              editable={false}
            ></StatusChip>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <StatusChip
              status="scheduled"
              dueDate={addDays(Date.now(), -5)}
              editable={false}
            ></StatusChip>
            <Typography variant="body2" color="secondary">
              {t('job_status.notstarted_past_due_description')}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <StatusChip
              status="scheduled"
              dueDate={addDays(Date.now(), 5)}
              editable={false}
            ></StatusChip>
            <Typography variant="body2" color="secondary">
              {t('job_status.notstarted_close_due_description')}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <StatusChip
              status="in_progress"
              dueDate={addDays(Date.now(), 10)}
              editable={false}
            ></StatusChip>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <StatusChip
              status="in_progress"
              dueDate={addDays(Date.now(), 5)}
              editable={false}
            ></StatusChip>
            <Typography variant="body2" color="secondary">
              {t('job_status.inprogress_left_due_description')}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <StatusChip
              status="in_progress"
              dueDate={addDays(Date.now(), -1)}
              editable={false}
            ></StatusChip>
            <Typography variant="body2" color="secondary">
              {t('job_status.inprogress_past_due_description')}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <StatusChip
              status="completed"
              dueDate={addDays(Date.now(), 10)}
              editable={false}
            ></StatusChip>
          </Stack>
        </>
      ),
      description: <Typography variant="body2">{t('job_status.description_p1')}</Typography>,
    },
    {
      main: (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Chip
              label={'Not Ready'}
              color={'default'}
              size="small"
              icon={<DollarIcon />}
              clickable={false}
              deleteIcon={<ArrowDropDownIcon />}
            />
            <Typography variant="body2" color="secondary">
              {t('billing_status.non_billable_description')}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Chip
              label={'Ready'}
              color={'success'}
              size="small"
              icon={<DollarIcon />}
              clickable={false}
              deleteIcon={<ArrowDropDownIcon />}
            />
            <Typography variant="body2" color="secondary">
              {t('billing_status.billable_description')}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Chip
              label={'Sent to Sage'}
              color={'primary'}
              size="small"
              icon={<DollarIcon />}
              clickable={false}
              deleteIcon={<ArrowDropDownIcon />}
            />
            <Typography variant="body2" color="secondary">
              {t('billing_status.sent_to_sage_description')}
            </Typography>
          </Stack>
        </>
      ),
      description: <Typography variant="body2">{t('billing_status.description_p1')}</Typography>,
    },
  ];

  return <OnboardingModal title={t('title')} options={options} open={open} onClose={onClose} />;
};
