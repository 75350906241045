export const downloadFile = (url: string | URL, filename: string) => {
  const anchor = document.createElement('a');
  anchor.rel = 'noopener';
  anchor.target = '_blank';
  anchor.href = url.toString();
  anchor.download = filename;

  anchor.click();
  anchor.remove();
};

// By default, browsers open images in a new tab instead of downloading them
export const downloadImage = (url: string | URL, name?: string) =>
  fetch(url.toString())
    .then((response) => response.blob())
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobURL;
      a.style.display = 'none';

      if (name) {
        a.download = name;
      }

      a.click();

      return blobURL;
    })
    .then((blobURL) => URL.revokeObjectURL(blobURL));
