import { Stack, Typography, styled } from '@mui/material';
import { useAuth } from 'react-oidc-context';

import { Button, Card } from 'targets/web/components';
import BackgroundImage from 'targets/web/assets/background2x.jpg';

const Image = styled('img')(() => ({
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  zIndex: -1,
  pointerEvents: 'none',
}));

export const WelcomePage = () => {
  const auth = useAuth();

  const login = () => {
    auth
      .signinRedirect({
        redirect_uri: location.origin,
      })
      .catch(console.error);
  };

  return (
    <Stack width={1} height="100vh" justifyContent="stretch" position="relative">
      <Image src={BackgroundImage} alt="" />

      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
        HeaderProps={{ title: 'Welcome to Primeflight' }}
      >
        <Stack gap={6}>
          <Typography>Click the button below to proceed</Typography>

          <Stack direction="row" justifyContent="center">
            <Button onClick={login}>Get Started</Button>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};
