import Pagination from 'common/types/Pagination';
import SortingOptions from 'common/types/SortingOptions';

import { InjectableHook } from 'domain/types/InjectableHook';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { BillingItem } from 'domain/entities/BillingItem';
import { ID } from 'domain/types/ID';

export type SearchBillingItemsParams = Partial<Pagination & SortingOptions>;

export const BillingItemRepository = Symbol('BillingItemRepository');
export type BillingItemRepository = InjectableHook<{
  searchBillingItems(params: SearchBillingItemsParams): Promise<PaginatedResult<BillingItem>>;
  confirmBillingItem(params: { id: ID }): Promise<void>;
}>;
