import { FC, ReactNode, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Stack } from '@mui/material';

import {
  CustomerData,
  CustomerFormFields,
  CustomerSchema,
} from 'targets/web/modules/configuration/components/index';
import { componentShadows } from 'targets/web/theme/shadows';

import { Mode } from './BaseFields';

export const CustomerDetailsForm: FC<{
  initialValues: CustomerData;
  onSubmit?: (values: CustomerData) => Promise<void>;
  disabled?: boolean;
  footer?: ReactNode;
  mode?: Mode;
}> = ({ initialValues, disabled, footer, onSubmit, mode }) => {
  const submitHandler = useCallback(
    (values: CustomerData) => {
      return onSubmit?.(values);
    },
    [onSubmit],
  );

  return (
    <Formik<CustomerData>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={toFormikValidationSchema(CustomerSchema)}
      onSubmit={submitHandler}
    >
      <Form autoComplete="off">
        <Stack gap={4}>
          <Stack
            sx={{
              width: 1,
              padding: 4,
              borderRadius: 1,
              backgroundColor: 'background.default',
              boxShadow: componentShadows.card,
            }}
          >
            <CustomerFormFields disabled={disabled} mode={mode} />
          </Stack>

          {footer}
        </Stack>
      </Form>
    </Formik>
  );
};
