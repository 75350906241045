import { useCallback } from 'react';
import contract from 'api/infrastructure/contract/reports.route';

import { ReportRepository } from 'domain/repositories/IReportRepository';
import { RequestReportParams } from 'domain/entities/Report';
import useTsRestClient from 'infrastructure/providers/useTsRestClient';

type RepositoryFunctions = ReturnType<ReportRepository>;

export const useTsRestReportRepository: ReportRepository = () => {
  const client = useTsRestClient(contract);

  const getReports: RepositoryFunctions['getReports'] = useCallback(
    async (params: RequestReportParams) => {
      const res = await client.getReports({
        query: params,
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  return {
    getReports,
  };
};
