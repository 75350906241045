import SortingOptions, { SortingDirection } from 'common/types/SortingOptions';
import { useMemo, useState } from 'react';

import { ID } from 'domain/types/ID';

export const usePriceListFilters = () => {
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [sort, setSort] = useState<SortingOptions>({
    sortBy: 'serviceType.name',
    direction: SortingDirection.Ascending,
  });
  const [filters, setFilters] = useState<{
    aircraftTypeId?: ID;
    serviceTypeId?: ID;
    limit?: number;
  }>({});
  const combinedFilters = useMemo(
    () => ({
      phrase: searchPhrase.length < 2 ? undefined : searchPhrase,
      ...filters,
    }),
    [filters, searchPhrase],
  );
  return {
    setSearchPhrase,
    sort,
    setSort,
    filters: combinedFilters,
    setFilters,
  };
};
