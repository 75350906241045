import { useInjection } from 'inversify-react';
import { useMemo } from 'react';

import { AsyncHookResult } from 'domain/types/AsyncHookResult';
import { InjectableHook } from 'domain/types/InjectableHook';

export enum LogoutError {
  Unknown = 'Unknown',
}

export const LogoutAdapter = Symbol('LogoutAdapter');
export const LogoutRedirectHook = Symbol('LogoutRedirectHook');

export interface ILogoutAdapter {
  useLogout(): AsyncHookResult<{ success: boolean }, LogoutError> & {
    logout: () => void;
  };
}

export type ILogoutRedirectHook = InjectableHook<{
  goToLogin(): void;
}>;

const useCaseLogout = () => {
  const adapter = useInjection<ILogoutAdapter>(LogoutAdapter);

  const { logout, error, isLoading } = adapter.useLogout();

  return useMemo(
    () => ({
      isLoading,
      error,
      logout,
    }),
    [isLoading, error, logout],
  );
};

export default useCaseLogout;
