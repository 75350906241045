import { Stack, styled } from '@mui/material';

import { componentShadows } from 'targets/web/theme/shadows';

export const StyledCalendarContainer = styled(Stack)(({ theme }) => {
  return {
    borderRadius: 8,
    boxShadow: componentShadows.card,
    background: theme.palette.background.paper,
    '--fc-button-active-bg-color': theme.palette.primary.main,
    '--fc-button-active-border-color': theme.palette.primary.main,
    '--fc-button-bg-color': theme.palette.primary.main,
    '--fc-button-border-color': theme.palette.primary.main,
    '--fc-button-hover-bg-color': theme.palette.primary.dark,
    '--fc-button-hover-border-color': theme.palette.primary.dark,
    '.fc-toolbar-chunk': {
      display: 'flex',
      flexDirection: 'row',
    },
    '.fc-header-toolbar': {
      padding: theme.spacing(6, 6, 0, 6),
    },
    'h2.fc-toolbar-title': {
      fontWeight: theme.typography.h5.fontWeight,
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
      alignSelf: 'center',
    },
    '.fc-today-button.fc-button-primary': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      borderColor: theme.palette.text.primary,
      fontSize: theme.typography.buttonMedium.fontSize,
      fontWeight: theme.typography.buttonMedium.fontWeight,
      borderRadius: 8,
      '&:focus, &:disabled, &:active': {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.primary,
      },
    },
    '.fc-next-button, .fc-prev-button': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      border: 'none',
      '&:hover, &:focus, &:focus-visible, &:active, &:active:not(:disabled)': {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        boxShadow: 'none',
        border: 'none',
      },
    },
    '.fc-toolbar-chunk:last-child': {
      '.fc-button-group': {
        borderRadius: 8,
        overflow: 'hidden',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.action.focus,
      },
      '.fc-button': {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        border: 'none',
        minWidth: 125,
        ...theme.typography.body2,
        padding: theme.spacing(1),
        '&:hover, &:focus, &:focus-visible, &:active, &:active:not(:disabled)': {
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
          border: 'none',
          boxShadow: 'none',
        },
        '&.fc-button-active': {
          fontSize: theme.typography.buttonMedium.fontSize,
          fontWeight: theme.typography.buttonMedium.fontWeight,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          borderRadius: 8,
          border: 'none',
        },
      },
    },
    '.fc-daygrid-day.fc-day-today': {
      backgroundColor: theme.palette.warning.action.hover,
    },
    '.fc-daygrid-day a.fc-daygrid-day-number': {
      width: '100%',
      svg: {
        width: '16px',
        color: theme.palette.secondary.light,
      },
    },
    '.header-service-count svg': {
      width: '16px',
      color: theme.palette.secondary.light,
    },
    '.fc-daygrid': {
      '&.fc-dayGridWeek-view': {
        '.day-service-count': {
          display: 'none',
        },
      },
    },
    'a.fc-col-header-cell-cushion': {
      width: '100%',
    },
    '.fc-event': {
      padding: 0,
      margin: theme.spacing(1),
      borderRadius: 8,
      fontSize: 12,
      fontWeight: 600,
      verticalAlign: 'center',
      '&:focus': {
        boxShadow: 'none',
      },
      '&:focus::after': {
        display: 'none',
      },
      '.fc-event-main': {
        lineHeight: '16px',
      },
      '.service-container': {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
        '.service-icon': {
          flex: 'none',
          borderRadius: 12,
          width: 24,
          height: 24,
          position: 'relative',
          svg: {
            position: 'absolute',
            left: 4,
            top: 4,
            width: 16,
            height: 16,
            color: theme.palette.background.paper,
          },
          '&.warning': {
            backgroundColor: theme.palette.warning.main,
          },
          '&.error': {
            backgroundColor: theme.palette.error.main,
          },
        },
      },
      '.service-label': {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        '&, & > span': {
          overflow: 'hidden',
        },
        '&.warning > svg': {
          fill: theme.palette.warning.main,
        },
        '&.error > svg': {
          fill: theme.palette.error.main,
        },
        span: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        svg: {
          width: 16,
          height: 16,
          marginRight: theme.spacing(1),
        },
      },
    },
    '.event-color-primary': {
      backgroundColor: theme.palette.primary.action.hover,
      borderColor: theme.palette.primary.action.hover,
      '.fc-event-main': {
        color: theme.palette.primary.main,
      },
    },
    '.event-color-success': {
      backgroundColor: theme.palette.success.action.hover,
      borderColor: theme.palette.success.action.hover,
      '.fc-event-main': {
        color: theme.palette.success.main,
      },
    },
    '.event-color-warning': {
      backgroundColor: theme.palette.warning.action.hover,
      borderColor: theme.palette.warning.action.hover,
      '.fc-event-main': {
        color: theme.palette.warning.main,
      },
    },
    '.event-color-error': {
      backgroundColor: theme.palette.error.action.hover,
      borderColor: theme.palette.error.action.hover,
      '.fc-event-main': {
        color: theme.palette.error.main,
      },
    },
    '.event-color-secondary': {
      backgroundColor: theme.palette.secondary.action.hover,
      borderColor: theme.palette.secondary.action.hover,
      '.fc-event-main': {
        color: theme.palette.secondary.main,
      },
    },
    '.event-color-default': {
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.action.hover,
      '.fc-event-main': {
        color: theme.palette.text.primary,
      },
    },
    '.fc-more-link': {
      padding: theme.spacing(2),
      margin: theme.spacing(1, 0, 0, 0),
      fontWeight: 600,
      width: '100%',
      display: 'block',
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.action.hover,
    },
  };
});
