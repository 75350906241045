import { FC, useCallback, useState } from 'react';
import { ExcludedServiceCodesFromPostWorkQuestionnaire } from 'common/data/service';

import { Service } from 'domain/entities/Service';
import { Entity } from 'domain/types/Entity';
import { useCaseStartService } from 'application/services/useCases/useCaseStartService';
import { useCaseStopService } from 'application/services/useCases/useCaseStopService';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import {
  BeforeYouStartModal,
  PreWorkQuestionnaireDialog,
  ResumeServiceDialog,
  TimerButton,
} from 'targets/web/modules/jobs/components';
import { isWorkLogExceedTTL, getLastWorkLog } from 'targets/web/modules/jobs/utils';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export interface TimerProps {
  service: Entity<Service>;
  refresh?: () => void;
}

export const Timer: FC<TimerProps> = ({ service, refresh }) => {
  const notify = useSnackbar();
  const timerT = useTranslationPrefix('jobs.timers');
  const { isOffline } = useNetworkStatus();

  const { startService, isPending: startIsPending } = useCaseStartService((e) => {
    if (
      'status' in e &&
      e.status === 409 &&
      e.body?.message === 'pre_work_questionnaire_required'
    ) {
      return;
    }

    notify(
      timerT('error_start', { error: ('body' in e ? e.body?.message : e.message) || 'unknown' }),
      {
        variant: 'error',
        autoHideDuration: 4000,
      },
    );
  });
  const { stopService, isPending: stopIsPending } = useCaseStopService();

  const [serviceStartConfirmationVisible, setServiceStartConfirmationVisible] = useState(false);
  const [isPreWorkQuestionnaireModalOpen, setIsPreWorkQuestionnaireModalOpen] = useState(false);
  const [isResumeServiceModalOpen, setIsResumeServiceModalOpen] = useState(false);
  const [chosenBillableValue, setChosenBillableValue] = useState(false);

  const onStop = useCallback(() => {
    stopService({ serviceId: service.id })
      .then(() => refresh?.())
      .catch((e) => {
        notify(timerT('error_stop', { error: e.data?.message || e.message || 'unknown' }), {
          variant: 'error',
        });
      });
  }, [notify, refresh, service.id, stopService, timerT]);

  const onStart = useCallback(
    (billable: boolean) => {
      startService({ serviceId: service.id, billable })
        .then(() => refresh?.())
        .catch((e) => {
          if (e.status === 409 && e.body?.message === 'pre_work_questionnaire_required') {
            setIsPreWorkQuestionnaireModalOpen(true);
            return;
          }
        });
    },
    [refresh, service.id, startService],
  );

  const onConfirm = (billable: boolean) => {
    setChosenBillableValue(billable);
    setServiceStartConfirmationVisible(false);

    const lastLog = getLastWorkLog(service.workLogs);

    if (
      !lastLog ||
      isWorkLogExceedTTL(lastLog) ||
      ExcludedServiceCodesFromPostWorkQuestionnaire.includes(service.code)
    ) {
      return onStart(billable);
    }

    setIsResumeServiceModalOpen(true);
  };

  return (
    <>
      <TimerButton
        inProgress={stopIsPending || startIsPending}
        onStart={() => setServiceStartConfirmationVisible(true)}
        onResume={() => setServiceStartConfirmationVisible(true)}
        onStop={onStop}
        workLogs={service.workLogs}
        disabled={isOffline}
      />

      <BeforeYouStartModal
        open={serviceStartConfirmationVisible}
        onClose={() => setServiceStartConfirmationVisible(false)}
        onConfirm={onConfirm}
      />

      <PreWorkQuestionnaireDialog
        open={isPreWorkQuestionnaireModalOpen}
        service={service}
        onSubmit={() => onStart(chosenBillableValue)}
        onClose={() => setIsPreWorkQuestionnaireModalOpen(false)}
      />

      <ResumeServiceDialog
        open={isResumeServiceModalOpen}
        service={service}
        onSubmit={() => onStart(chosenBillableValue)}
        onClose={() => setIsResumeServiceModalOpen(false)}
      />
    </>
  );
};
