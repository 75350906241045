import { FC, PropsWithChildren, ReactNode } from 'react';
import {
  Tab as MuiTab,
  TabProps as MuiTabProps,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
  Typography,
} from '@mui/material';

import { Chip } from 'targets/web/components';

export type TabProps = MuiTabProps;

export interface TabsProps extends MuiTabsProps {
  /**
   * Array of `TabProps` to generate the `Tab` elements.
   *
   * **Note:** either `items` or `children` needs to be provided.
   */
  items?: TabProps[];
}

export const Tab: FC<TabProps> = ({ ...props }) => <MuiTab {...props} />;

export const getLabelWithValue = (text: string, value: ReactNode) => (
  <>
    <Typography color="inherit" variant="labelMedium" mr={2}>
      {text}
    </Typography>
    <Chip size="small" label={value} />
  </>
);

export const Tabs: FC<PropsWithChildren<TabsProps>> = ({ items, children, ...props }) => (
  <MuiTabs scrollButtons allowScrollButtonsMobile {...props}>
    {children || items?.map((item, index) => <Tab key={index} {...item} />)}
  </MuiTabs>
);

export default Tabs;
