import { Divider, Grid, Stack, Typography, styled } from '@mui/material';
import { FC, useState } from 'react';
import { useParams } from 'react-router';
import { z } from 'zod';
import { useFormikContext } from 'formik';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { ID } from 'domain/types/ID';
import usePermission from 'application/auth/hooks/usePermission';
import { JobResourceType } from 'application/auth/utils/resources';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import formatPrice from 'infrastructure/utils/formatPrice';
import { CardStack } from 'targets/web/components/CardStack';
import { Select, TextField } from 'targets/web/components';
import Switch from 'targets/web/components/Switch';

export const BillingServiceDetailsSchema = z.object({
  priceList: z.string().optional().nullable(),
  type: z.string().optional().nullable(),
  amount: z.string().nullable(),
  discountPercentage: z.number().nullable(),
});

export type BillingServiceDetailsData = z.infer<typeof BillingServiceDetailsSchema>;

export interface BillingServiceDetailsProps {
  disabled: boolean;
  types: {
    name: string;
    id: string;
  }[];
  discountPercentages: {
    name: string;
    value: number;
  }[];
  quantity: number;
}

const AttachmentIconWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.action.alertBackground,
  color: theme.palette.primary.action.alertContent,
  borderRadius: '100%',
  padding: theme.spacing(2),
  height: 'fit-content',
}));

const BillingServiceDetails: FC<BillingServiceDetailsProps> = ({
  disabled,
  discountPercentages,
}) => {
  const t = useTranslationPrefix('jobs.service_details_form.billing');
  const { values, setFieldValue } = useFormikContext<BillingServiceDetailsData>();
  const [formattedAmount, setFormattedAmount] = useState<string | null>(values.amount || null);
  const { jobId } = useParams();

  const { granted: updateBillingAmount } = usePermission(
    jobId
      ? {
          resource: `${JobResourceType}:${ID(jobId)}`,
          scope: 'update-billing-amount',
        }
      : undefined,
  );
  return (
    <CardStack gap={4}>
      <Typography variant="h6">{t('title')}</Typography>
      <Grid container spacing={6}>
        <Grid item md={6}>
          <Stack gap={4}>
            <TextField
              label={t('price_list_label')}
              name="price_list"
              value={values.priceList}
              fullWidth
              disabled={disabled}
              readOnly
            />
            <TextField
              type="text"
              label={t('quantity_label')}
              name="quantity"
              value={1}
              fullWidth
              readOnly
            />
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Stack gap={4}>
            <TextField
              type="text"
              label={t('amount_label')}
              name="amount"
              disabled={!updateBillingAmount}
              value={formattedAmount}
              InputProps={{
                startAdornment: '$',
              }}
              onChange={updateBillingAmount ? (e) => setFormattedAmount(e.target.value) : undefined}
              onBlur={() => {
                if (updateBillingAmount) {
                  const numberValue = Number(formattedAmount);
                  const decimalValue = isNaN(numberValue) ? '0.00' : numberValue.toFixed(2);
                  setFieldValue('amount', decimalValue);
                  setFormattedAmount(decimalValue);
                }
              }}
              fullWidth
            />
            <Switch
              label={t('apply_discount_label')}
              name="apply_discount"
              checked={values.discountPercentage !== null}
              value={values.discountPercentage}
              onChange={(_, val) => setFieldValue('discountPercentage', val ? 0 : null)}
            />
            {values.discountPercentage !== null && values.amount !== null && (
              <Stack direction="row" width={1} gap={4}>
                <Select
                  label={t('discount_percentage_label')}
                  name="type"
                  value={values.discountPercentage.toString()}
                  fullWidth
                  disabled={disabled}
                  onChange={(e) => setFieldValue('discountPercentage', Number(e.target.value))}
                  items={discountPercentages.map(({ value, name }) => ({
                    label: name,
                    value: value.toString(),
                  }))}
                />
                <TextField
                  type="text"
                  label={t('discount_amount_label')}
                  name="discount_amount"
                  value={formatPrice(Number(values.amount) * values.discountPercentage)}
                  fullWidth
                  readOnly
                />
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Typography variant="h6">{t('attachments_title')}</Typography>
      <Stack alignItems="center" justifyContent="center" direction="column" gap={2}>
        <AttachmentIconWrapper>
          <AttachFileIcon />
        </AttachmentIconWrapper>
        <Typography variant="body1">{t('no_attachments')}</Typography>
      </Stack>
    </CardStack>
  );
};

export default BillingServiceDetails;
