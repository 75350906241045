import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { PriceListSummary } from 'api/domain/entities/PriceList';
import { Entity } from 'api/domain/types';

import { useCaseSearchPriceLists } from 'application/prices/useCases/useCaseSearchPriceLists';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { TableColumn } from 'targets/web/components';
import ReactRouterLink from 'targets/web/components/ReactRouterLink';
import { TableWithSearch } from 'targets/web/components/TableWithSearch';
import { usePriceListsFilters } from 'targets/web/modules/configuration/hooks/usePriceListsFilters';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { OfflinePlaceholder } from 'targets/web/modules/dashboard/components';

function getSort(sort: string): string {
  switch (sort) {
    case 'name':
      return 'name';
    default:
      return sort;
  }
}

export const PriceListsTable: FC = () => {
  const t = useTranslationPrefix('configuration.price_lists.table');
  const { isOffline } = useNetworkStatus();

  const { setSort, filters, setFilters, setSearchPhrase } = usePriceListsFilters();
  const [currentPage, setCurrentPage] = useState(0);
  const { pages, total, isLoading, isFetching, fetchNextPage } = useCaseSearchPriceLists(filters);

  const columns: TableColumn<Entity<PriceListSummary>>[] = [
    {
      headerName: t('price_list_name_column'),
      field: 'name',
      sortable: true,
      renderCell: ({ id, name }) => (
        <ReactRouterLink
          to={`/configuration/price-lists/${id}/details`}
          variant="body1"
          color="primary"
        >
          {name}
        </ReactRouterLink>
      ),
    },
    {
      headerName: t('stations_column'),
      field: 'stationNames',
      sortable: false,
      renderCell: ({ stationNames }) => (
        <>
          {stationNames.map((name, i) => (
            <Typography key={i}>{name}</Typography>
          ))}
        </>
      ),
    },
    {
      headerName: t('entries_column'),
      field: 'pricesCount',
      sortable: false,
      renderCell: ({ pricesCount }) => pricesCount,
    },
  ];

  return (
    <>
      {isOffline && !pages[currentPage]?.items.length ? (
        <OfflinePlaceholder />
      ) : (
        <TableWithSearch
          onSearchPhraseChange={setSearchPhrase}
          columns={columns}
          rows={pages[currentPage]?.items ?? []}
          rowsPerPageOptions={[10, 50, 100]}
          initialRowsPerPage={filters?.limit ?? 10}
          count={total}
          onRowsPerPageChange={(rowsPerPage) => {
            setFilters((current) => ({ ...current, limit: rowsPerPage }));
            setCurrentPage(0);
          }}
          onPageChange={async (pageNumber) => {
            if (!pages[pageNumber]) {
              await fetchNextPage();
            }

            setCurrentPage(pageNumber);
          }}
          onSortChange={(sortBy, direction) => {
            setSort({
              sortBy: getSort(sortBy),
              direction,
            });
            setCurrentPage(0);
          }}
          initialPage={currentPage}
          isLoading={isLoading || isFetching}
          searchPhrasePlaceholder={t('search_placeholder')}
        />
      )}
    </>
  );
};
