import { Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { Colors } from 'targets/web/types/theme';

export const IconContainer: FC<
  PropsWithChildren<{
    color?: Colors;
    padding?: number;
    iconColorVariant?: 'main' | 'alertContent';
  }>
> = ({ children, color = 'primary', padding = 2, iconColorVariant = 'main' }) => {
  return (
    <Stack
      sx={{
        height: 'fit-content',
        width: 'fit-content',
        padding: padding,
        borderRadius: '100%',
        backgroundColor: `${color}.action.selected`,
        '& > svg': {
          color: `${color}.${
            iconColorVariant === 'alertContent' ? 'action.alertContent' : iconColorVariant
          }`,
        },
      }}
    >
      {children}
    </Stack>
  );
};
