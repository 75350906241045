import { useCallback } from 'react';
import contract from 'api/infrastructure/contract/jobs.route';
import axios from 'axios';

import {
  AttachmentsParams,
  AttachmentsRepository,
} from 'domain/repositories/IAttachmentsRepository';
import {
  CreateAttachmentParams,
  DeleteAttachmentParams,
  RequestUploadUrlParams,
  UploadFileParams,
} from 'domain/entities/Attachment';
import useTsRestClient from 'infrastructure/providers/useTsRestClient';

type RepositoryFunctions = ReturnType<AttachmentsRepository>;

const fileClient = axios.create();

export const useTsRestAttachmentsRepository: AttachmentsRepository = () => {
  const client = useTsRestClient(contract);

  const searchAttachments: RepositoryFunctions['searchAttachments'] = useCallback(
    async (params: AttachmentsParams) => {
      const res = await client.searchAttachments({
        params: { jobId: params.jobId },
        query: {
          ...params,
        },
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const requestUploadUrl: RepositoryFunctions['requestUploadUrl'] = useCallback(
    async (params: RequestUploadUrlParams) => {
      const res = await client.getUploadAttachmentUrl({
        params: { jobId: params.jobId },
        body: {
          name: params.name,
          contentType: params.contentType,
        },
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const createAttachment: RepositoryFunctions['createAttachment'] = useCallback(
    async (params: CreateAttachmentParams) => {
      const res = await client.createAttachment({
        params: { jobId: params.jobId },
        body: {
          name: params.data.name,
          contentType: params.data.contentType,
          uri: params.data.uri,
          type: params.data.type,
          refersToId: params.data.refersToId,
        },
      });

      if (res.status === 201) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const uploadFile: RepositoryFunctions['uploadFile'] = useCallback(
    async (params: UploadFileParams) => {
      const res = await fileClient.put(params.url, params.file, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
      });

      if (res.status === 201) {
        return;
      }

      throw res;
    },
    [],
  );

  const deleteAttachment: RepositoryFunctions['deleteAttachment'] = useCallback(
    async (params: DeleteAttachmentParams) => {
      const res = await client.deleteAttachment({
        params: { jobId: params.jobId, attachmentId: params.attachmentId },
      });

      if (res.status === 204) {
        return;
      }

      throw res;
    },
    [client],
  );

  return {
    searchAttachments,
    requestUploadUrl,
    createAttachment,
    uploadFile,
    deleteAttachment,
  };
};
