import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/CloseOutlined';

import TextField from 'targets/web/components/TextField';

export const StyledLabel = styled('label')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  cursor: 'pointer',
  zIndex: 1,
}));

export const StyledInputAdornment = styled(InputAdornment)(() => ({
  margin: 0,
}));

export const StyledCloseIcon = styled(CloseIcon)(() => ({
  zIndex: 2,
  cursor: 'pointer',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  'input::placeholder': {
    color: theme.palette.text.secondary,
    opacity: 1,
  },
}));
