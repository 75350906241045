import { useMutation } from '@tanstack/react-query';

import { UpdateJobRequest } from 'domain/entities/UpdateJobRequest';
import { JobRepository } from 'domain/repositories/IJobRepository';
import { ID } from 'domain/types/ID';
import { BillingStatus } from 'domain/entities/BillingStatus';
import { useHookInjection } from 'application/hooks';
import { useCaseGetJobDetails } from 'application/jobs/useCases/useCaseGetJobDetails';

import { useCaseSearchJobs } from './useCaseSearchJobs';
import { useCaseGetJobCountByStatus } from './useCaseGetJobCountByStatus';

export const useCaseUpdateJob = () => {
  const { updateJob, setBillingStatus } = useHookInjection<JobRepository>(JobRepository);

  const { refetch: refetchJobList } = useCaseSearchJobs(null);
  const { refetch: refetchJobCount } = useCaseGetJobCountByStatus(null);
  const { refetch: refetchJob } = useCaseGetJobDetails({});

  const { mutateAsync, isPending: isUpdateJobPending } = useMutation<void, Error, UpdateJobRequest>(
    {
      mutationFn: updateJob,
      onSuccess: () => {
        refetchJob();
        refetchJobList();
        refetchJobCount();
      },
    },
  );

  const { mutateAsync: updateJobBillingStatus, isPending: isUpdateJobBillingStatusPending } =
    useMutation<void, Error, { jobId: ID; billingStatus: BillingStatus }>({
      mutationFn: setBillingStatus,
      onSuccess: () => {
        refetchJob();
        refetchJobList();
      },
    });

  return {
    updateJob: mutateAsync,
    updateJobBillingStatus,
    isPending: isUpdateJobPending || isUpdateJobBillingStatusPending,
  };
};
