import {
  FormControlLabel,
  Stack,
  useTheme,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Typography,
} from '@mui/material';

export type CheckboxCardProps = MuiCheckboxProps & {
  label: string;
  description?: string;
};

export const CheckboxCard = ({ label, description, ...rest }: CheckboxCardProps) => {
  const theme = useTheme();

  return (
    <Stack
      spacing={1}
      sx={{
        padding: theme.spacing(2, 4, 4, 4),
        borderRadius: 2,
        backgroundColor: 'background.neutral',
      }}
    >
      <FormControlLabel
        control={<MuiCheckbox {...rest} />}
        label={<Typography variant="labelLarge">{label}</Typography>}
      />
      {description && (
        <Typography color="secondary" sx={{ paddingLeft: 10.5 }}>
          {description}
        </Typography>
      )}
    </Stack>
  );
};
