import { FC } from 'react';
import { Stack } from '@mui/material';

import { useCaseGetOperationsSummary } from 'application/operations/useCaseGetOperationsSummary';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { OfflinePlaceholder, PageTitle } from 'targets/web/modules/dashboard/components';
import { Loader } from 'targets/web/components';
import {
  OperationFiltersContainer,
  OperationViewTabs,
} from 'targets/web/modules/operations/components';
import { useOperationViewFilters } from 'targets/web/modules/operations/hooks/useOperationViewFilters';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export const OperationsView: FC = () => {
  const t = useTranslationPrefix('operations.operations_view');
  const { isOffline } = useNetworkStatus();

  const { viewTab: id, setFilters, filters } = useOperationViewFilters();

  const { data, isLoading } = useCaseGetOperationsSummary({
    id: id,
    dateStart: filters.dateStart,
    dateEnd: filters.dateEnd,
    customerId: filters.customerId,
    stationIds: filters.stationIds,
  });

  return (
    <>
      <Stack width={1} direction="row" alignItems="center" justifyContent="space-between">
        <PageTitle title={t('title')} />
      </Stack>

      <OperationFiltersContainer
        initialValues={filters}
        onFiltersChange={(f) => setFilters({ ...filters, ...f })}
      />
      <OperationViewTabs />

      {isLoading ? (
        <Stack height={1} width={1} alignItems="center" justifyContent="center">
          <Loader size={48} />
        </Stack>
      ) : (
        <Stack height={1} width={1} flex={1} alignItems="center" justifyContent="center">
          {isOffline && !data ? (
            <OfflinePlaceholder />
          ) : (
            <iframe
              name={id}
              src={data?.url}
              style={{ border: 0, height: '100%', width: '100%' }}
            />
          )}
        </Stack>
      )}
    </>
  );
};
