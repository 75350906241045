import { createTheme } from '@mui/material';

import { breakpoints } from './breakpoints';
import { components } from './components';
import { shape } from './shape';
import { spacing } from './spacing';
import { shadows } from './shadows';
import { typography } from './typography';

export const baseTheme = createTheme({
  breakpoints,
  components,
  shape,
  spacing,
  shadows,
  typography,
});
