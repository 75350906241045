import { IconButton, Stack } from '@mui/material';
import { FC, useState } from 'react';
import EditIcon from '@mui/icons-material/EditOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router';

import { EditCustomer } from 'domain/types/Roles';
import { useCaseGetCustomer } from 'application/customers/useCases/useCaseGetCustomer';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Breadcrumbs, Button, Loader, Tabs } from 'targets/web/components';
import { OfflinePlaceholder, PageTitle } from 'targets/web/modules/dashboard/components';
import { configurationRoutes } from 'targets/web/modules/configuration/navigation/configurationRoutes';
import {
  AssociationsTable,
  CustomerDetailsGeneralTab,
} from 'targets/web/modules/configuration/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export const CustomerDetailsView: FC = () => {
  const tGlobal = useTranslationPrefix('global');
  const tBreadcrumbs = useTranslationPrefix('configuration.breadcrumbs');
  const t = useTranslationPrefix('configuration.customer_details');
  const { isOffline } = useNetworkStatus();

  const navigate = useNavigate();
  const { hasRole } = useCaseCheckAccess();

  const { customerId } = useParams();
  const { data, isLoading } = useCaseGetCustomer({ customerId });

  const [isEditMode, setIsEditMode] = useState(false);
  const [tab, setTab] = useState<'general' | 'associated'>('general');

  if (isLoading)
    return (
      <Stack height={1} justifyContent="center">
        <Loader />
      </Stack>
    );
  if (!data) {
    if (isOffline) {
      return <OfflinePlaceholder />;
    }

    return null;
  }

  const isEditButtonVisible =
    !isEditMode && tab === 'general' && hasRole(EditCustomer) && !isOffline;

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <IconButton onClick={() => navigate('/configuration/customers')} color="primary">
          <ArrowBackIcon />
        </IconButton>

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('configuration'),
                url: configurationRoutes.configuration,
              },
              {
                label: tBreadcrumbs('customers'),
                url: `${configurationRoutes.configuration}/${configurationRoutes.internalRoutes.customers}`,
              },
            ]}
          />

          <PageTitle title={data?.name} />
        </Stack>

        {isEditButtonVisible && (
          <Button size="large" startIcon={<EditIcon />} onClick={() => setIsEditMode(true)}>
            {tGlobal('edit')}
          </Button>
        )}
      </Stack>

      <Stack direction="row" justifyContent="flex-start" width={1}>
        <Tabs
          items={[
            {
              label: t('tabs.general'),
              value: 'general',
            },
            {
              label: t('tabs.associated'),
              value: 'associated',
            },
          ]}
          value={tab}
          onChange={(_, value) => setTab(value)}
        />
      </Stack>

      {tab === 'general' && (
        <CustomerDetailsGeneralTab
          editMode={isEditMode}
          onExitEditMode={() => setIsEditMode(false)}
          customerId={data.id}
        />
      )}
      {tab === 'associated' && (
        <Stack width={1}>
          <AssociationsTable customerId={data.id} />
        </Stack>
      )}
    </>
  );
};
