import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { PricesRepository, SearchAssociationsParams } from 'domain/repositories/IPricesRepository';
import { Entity } from 'domain/types/Entity';
import { PopulatedPriceListAssociation } from 'domain/entities/PopulatedPriceListAssociation';
import { useHookInjection } from 'application/hooks';
import { useSortingAndPagination } from 'application/common';

const QUERY_PREFIX = 'ASSOCIATIONS';

export const useCaseSearchAssociations = (
  params?: Omit<SearchAssociationsParams, 'limit' | 'offset' | 'sortBy' | 'direction'>,
) => {
  const qClient = useQueryClient();

  const { searchAssociations } = useHookInjection<PricesRepository>(PricesRepository);

  const { paging, sorting, ...utils } =
    useSortingAndPagination<Entity<PopulatedPriceListAssociation>>('priceList');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_PREFIX, { ...params, ...paging, ...sorting }] as const,
    queryFn: ({ queryKey }) => searchAssociations(queryKey[1]),
    enabled: !!params,
    placeholderData: { items: [], total: 0 },
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    ...data,
    refetch,

    paging,
    sorting,
    ...utils,

    isLoading,
    isFetching,
  };
};
