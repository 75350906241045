import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_API_BASE_URL,
});
axiosInstance.interceptors.request.use(() => {
  throw new Error('token not set');
});

export const rawAxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_API_BASE_URL,
});

export default axiosInstance;
