import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FC, useState } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button } from 'targets/web/components/Button';
import Modal from 'targets/web/components/Modal';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (namePreference: NamePreferenceType) => void;
}

export type NamePreferenceType = 'registration_number' | 'serial_number';

export const JobNameSelectModal: FC<ModalProps> = ({ onClose, onSubmit, open }) => {
  const t = useTranslationPrefix('jobs.jobs_view.job_name_select_modal');

  const [isSubmitting, setIsSubmitting] = useState<NamePreferenceType>();

  const onClick = (type: NamePreferenceType) => {
    return () => {
      setIsSubmitting(type);
      onSubmit(type);
    };
  };

  return (
    <Modal
      title={t('title')}
      content={
        <Stack gap={8} sx={{ maxWidth: '480px' }}>
          <Stack>
            <Typography variant="body1" color="text.secondary">
              {t('description')}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Button variant="outlined" color="secondary" onClick={onClose}>
                {t('cancel_btn')}
              </Button>
            </Stack>
            <Stack direction="row" gap={2}>
              <LoadingButton
                variant="contained"
                onClick={onClick('registration_number')}
                loading={isSubmitting === 'registration_number'}
                disabled={isSubmitting && isSubmitting !== 'registration_number'}
              >
                {t('registration_number_btn')}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                onClick={onClick('serial_number')}
                loading={isSubmitting === 'serial_number'}
                disabled={isSubmitting && isSubmitting !== 'serial_number'}
              >
                {t('serial_number_btn')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      }
      open={open}
      onClose={onClose}
    />
  );
};
