import { FC, useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { AttachFileOutlined } from '@mui/icons-material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';

import { Attachment } from 'domain/entities/Attachment';
import { useCaseDeleteAttachment } from 'application/attachments/useCases/useCaseDeleteAttachment';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { downloadImage } from 'infrastructure/utils/downloadFile';
import { ConfirmationModal, IconButton, TrashCanIcon } from 'targets/web/components';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';

interface AttachmentCardProps {
  attachment: Attachment & { url: string };
}

export const AttachmentCard: FC<AttachmentCardProps> = ({ attachment }) => {
  const t = useTranslationPrefix('jobs.job_details.attachments');
  const notify = useSnackbar();
  const { deleteAttachment } = useCaseDeleteAttachment();

  const onPreview = useCallback(() => {
    window.open(attachment.url, '_blank');
  }, [attachment.url]);

  const onRemove = useCallback(async () => {
    await deleteAttachment({ jobId: attachment.jobId, attachmentId: attachment.id });

    notify(t('snackbar.removed'), {
      variant: 'success',
    });
  }, [attachment.id, attachment.jobId, deleteAttachment, notify, t]);

  const downloadImageHandler = useCallback(
    () => downloadImage(attachment.url, attachment.name),
    [attachment.name, attachment.url],
  );

  return (
    <Stack direction="row" justifyContent="space-between" gap={2}>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        sx={{
          paddingInline: 2,
          paddingBlock: 1,
          borderRadius: 1,
          borderColor: 'outline',
          borderStyle: 'solid',
          borderWidth: 1,
          width: '100%',
          cursor: 'default',
        }}
      >
        <Stack
          sx={{
            padding: 1.5,
            borderRadius: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '32px',
            backgroundColor: 'primary.action.selected',
          }}
        >
          <AttachFileOutlined sx={{ color: 'primary.main', fontSize: '20px' }} />
        </Stack>

        <Stack>
          <Typography variant="labelSmall">{attachment.name}</Typography>
          <Typography variant="caption" color="secondary">
            {attachment.contentType}
          </Typography>
        </Stack>
      </Stack>

      <IconButton variant="outlined" color="primary" onClick={onPreview}>
        <RemoveRedEyeOutlinedIcon />
      </IconButton>

      <IconButton variant="outlined" color="secondary" onClick={downloadImageHandler}>
        <GetAppOutlinedIcon />
      </IconButton>

      <ConfirmationModal
        title={t('remove_modal.title')}
        subtitle={t('remove_modal.description')}
        confirmText={t('remove_modal.confirm')}
        cancelText={t('remove_modal.cancel')}
        onConfirm={onRemove}
        triggerButton={(onClick: () => void) => (
          <IconButton variant="outlined" color="error" onClick={onClick}>
            <TrashCanIcon width="24px" height="24px" />
          </IconButton>
        )}
      />
    </Stack>
  );
};
