import { FC, memo, useEffect, useMemo } from 'react';
import { FormikContextType, useFormik, useFormikContext } from 'formik';
import { debounce, omit } from 'lodash';

import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { usePersistedValues } from 'targets/web/modules/dashboard/hooks/usePersistedValues';

type FormikType = FormikContextType<Record<string, unknown>> | ReturnType<typeof useFormik>;

export interface PersistFormikValuesProps {
  name: string;
  debounceTime?: number; // Debounce in ms
  persistInvalid?: boolean;
  ignoreValues?: string[];
}

export const usePersistFormikValues = (
  { values, isValid }: Pick<FormikType, 'values' | 'isValid'>,
  { persistInvalid, ignoreValues, name, debounceTime }: PersistFormikValuesProps,
) => {
  const { setPersistedValues } = usePersistedValues(name);

  const handlePersist = useMemo(
    () =>
      debounce((values: FormikType['values'], isValid: FormikType['isValid']) => {
        if (isValid || persistInvalid) {
          const valuesToPersist = ignoreValues ? omit(values, ignoreValues) : values;
          setPersistedValues(valuesToPersist);
        }
      }, debounceTime ?? DEFAULT_DEBOUNCE_TIME),
    [debounceTime, persistInvalid, ignoreValues, setPersistedValues],
  );

  useEffect(() => {
    handlePersist(values, isValid);
  }, [values, isValid, handlePersist]);
};

export const PersistFormikValuesMemo: FC<PersistFormikValuesProps> = (props) => {
  const formik = useFormikContext<Record<string, unknown>>();

  usePersistFormikValues(formik, props);

  return null;
};

export const PersistFormikValues = memo(PersistFormikValuesMemo);
