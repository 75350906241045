import { useMutation } from '@tanstack/react-query';

import { ServiceRepository } from 'domain/repositories/IServiceRepository';
import { UpdateServiceRequest } from 'domain/entities/UpdateServiceRequest';
import { ID } from 'domain/types/ID';
import { BillingStatus } from 'domain/entities/BillingStatus';
import { useHookInjection } from 'application/hooks';

import { useCaseSearchServices } from './useCaseSearchServices';
import { useCaseGetService } from './useCaseGetService';
import { useCaseGetServicesCountByStatus } from './useCaseGetServicesCountByStatus';

export const useCaseUpdateService = () => {
  const { updateService, setBillingStatus } =
    useHookInjection<ServiceRepository>(ServiceRepository);

  const { refetch: refetchServiceList } = useCaseSearchServices(null);
  const { refetch: refetchServicesCount } = useCaseGetServicesCountByStatus(null);
  const { refetch: refetchService } = useCaseGetService();

  const { mutateAsync } = useMutation<void, Error, UpdateServiceRequest>({
    mutationFn: updateService,
    onSuccess: () => {
      refetchService();
      refetchServiceList();
      refetchServicesCount();
    },
  });

  const { mutateAsync: updateServiceBillingStatus } = useMutation<
    void,
    Error,
    { serviceId: ID; billingStatus: BillingStatus }
  >({
    mutationFn: setBillingStatus,
    onSuccess: () => {
      refetchService();
      refetchServiceList();
    },
  });

  return {
    updateService: mutateAsync,
    updateServiceBillingStatus,
  };
};
