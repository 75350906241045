import { Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import { Pause } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import OnboardingModal from 'targets/web/modules/jobs/components/OnboardingModal';
import Switch from 'targets/web/components/Switch';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export const OnboardingJobSummaryModal: FC<ModalProps> = ({ onClose, open }) => {
  const t = useTranslationPrefix('jobs.jobs_view.job_summary_onboarding_modal');

  const options: { main: ReactNode; description: ReactNode }[] = [
    {
      main: (
        <Stack flexDirection="row" justifyContent="center">
          <Switch checked={false} label={'Track Non-Billable'} disabled={true} />
        </Stack>
      ),
      description: <Typography variant="body2">{t('toggle.description')}</Typography>,
    },
    {
      main: (
        <Stack flexDirection="row" justifyContent="center">
          <LoadingButton
            variant="contained"
            loading={false}
            color={'primary'}
            startIcon={<Pause />}
            disabled={false}
          >
            {'1:05:15'}
          </LoadingButton>
        </Stack>
      ),
      description: (
        <>
          <Typography variant="body2">{t('inprogress.description_p1')}</Typography>
          <Typography variant="body2">{t('inprogress.description_p2')}</Typography>
        </>
      ),
    },
    {
      main: (
        <Stack flexDirection="row" justifyContent="center">
          <LoadingButton
            variant="contained"
            loading={false}
            startIcon={<CheckIcon />}
            disabled={false}
            color="success"
            size="large"
          >
            {'Done'}
          </LoadingButton>
        </Stack>
      ),
      description: (
        <>
          <Typography variant="body2">{t('done.description_p1')}</Typography>
          <Typography variant="body2">{t('done.description_p2')}</Typography>
        </>
      ),
    },
  ];

  return <OnboardingModal title={t('title')} options={options} open={open} onClose={onClose} />;
};
