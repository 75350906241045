import { BreakpointsOptions } from '@mui/material/styles';

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 650,
    md: 1024,
    lg: 1280,
    xl: 1536,
    xxl: 1920,
    xxxl: 2560,
    x4k: 3840,
  },
};
