import { FC, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { CircularProgress } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button } from 'targets/web/components';

import { CancelJobModalProps } from './CancelJobModal';

export const CancelJobModalActions: FC<Pick<CancelJobModalProps, 'onClose'>> = ({ onClose }) => {
  const t = useTranslationPrefix('jobs.job_details.cancel_job_modal');
  const { isValid, dirty, isSubmitting, submitForm, resetForm } = useFormikContext();

  const handleCloseClick = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        disabled={isSubmitting}
        onClick={handleCloseClick}
      >
        {t('cancel')}
      </Button>
      <Button
        disabled={!isValid || !dirty || isSubmitting}
        onClick={submitForm}
        color="error"
        type="submit"
        endIcon={isSubmitting ? <CircularProgress size={14} color="inherit" /> : null}
      >
        {t('cancel')}
      </Button>
    </>
  );
};
