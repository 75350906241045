import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import { Customer } from 'domain/entities/Customer';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Modal } from 'targets/web/components';

export const CustomerExistsModal: FC<{
  customer: Pick<Customer, 'name' | 'code' | 'externalId'>;
  open: boolean;
  onClose: () => void;
}> = ({ customer, open, onClose }) => {
  const t = useTranslationPrefix('configuration.customers.customer_exists_modal');
  const tGlobal = useTranslationPrefix('global');

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={t('title')}
      description={t('description')}
      maxWidth={false}
      content={
        <Stack gap={4}>
          {[
            { label: t('name'), value: customer.name },
            { label: t('acronym'), value: customer.code ?? '-' },
            { label: t('externalId'), value: customer.externalId ?? '-' },
          ].map(({ label, value }) => (
            <Stack
              gap={1}
              key={label}
              sx={{
                backgroundColor: 'background.neutral',
                borderRadius: 1,
                padding: 4,
              }}
            >
              <Typography variant="inputLabel" color="text.secondary">
                {label}
              </Typography>
              <Typography variant="labelMedium" fontWeight="bold">
                {value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      }
      actions={{
        primary: {
          text: tGlobal('close'),
        },
      }}
    />
  );
};
