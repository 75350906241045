import { Stack, Typography } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { ReactComponent as OfflineImage } from 'targets/web/assets/offline-image.svg';

export const OfflinePlaceholder = () => {
  const t = useTranslationPrefix('global.offline_placeholder');

  return (
    <Stack
      gap={6}
      alignItems="center"
      justifyContent="center"
      sx={{
        padding: 4,
        width: 1,
        height: 1,
        borderRadius: 1,
        backgroundColor: 'background.default',
      }}
    >
      <OfflineImage />

      <Stack gap={2} textAlign="center">
        <Typography variant="h4">{t('title')}</Typography>

        <Typography variant="body1" color="text.secondary">
          {t('subtitle')}
        </Typography>
      </Stack>

      <Stack gap={2}>
        {[t('tip_1'), t('tip_2'), t('tip_3')].map((text, index) => (
          <Stack
            key={index}
            gap={2}
            direction="row"
            sx={{
              padding: 4,
              borderRadius: 1,
              maxWidth: 540,
              backgroundColor: 'primary.action.selected',
            }}
          >
            <Typography variant="menuItem" fontWeight="bold" color="primary.main">
              {index + 1}
            </Typography>
            <Typography variant="body1">{text}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
