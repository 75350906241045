import { FC, useCallback, useMemo, useState } from 'react';
import { Chip, Stack, Typography, styled } from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';

import { BillingItem } from 'domain/entities/BillingItem';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import formatPrice from 'infrastructure/utils/formatPrice';
import Modal from 'targets/web/components/Modal';
import { configurationRoutes } from 'targets/web/modules/configuration/navigation/configurationRoutes';

interface ConfirmBillingItemModalProps {
  open: boolean;
  invoiceHdrId: string;
  services: BillingItem['services'];
  onConfirm: () => Promise<void>;
  onClose: () => void;
}

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

export const ConfirmBillingItemModal: FC<ConfirmBillingItemModalProps> = ({
  open,
  invoiceHdrId,
  services,
  onClose,
  onConfirm,
}) => {
  const t = useTranslationPrefix('billing.confirm_billing_item_modal');
  const tGlobal = useTranslationPrefix('global');

  const navigate = useNavigate();

  const totalCost = useMemo(
    () =>
      services
        .map((el) => (el.price ? Number(el.price) * el.quantity : 0))
        .reduce((acc, curr) => acc + curr, 0),
    [services],
  );

  const hasMissingPrice = useMemo(() => !!services.find((s) => s.price === null), [services]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onConfirmClick = useCallback(() => {
    setIsSubmitting(true);
    onConfirm().finally(() => {
      setIsSubmitting(false);
      onClose();
    });
  }, [onClose, onConfirm]);

  return (
    <Modal
      open={open}
      title={t('title')}
      description={
        <Typography color="text.secondary">
          <Trans
            i18nKey="billing.confirm_billing_item_modal.description"
            components={{ bold: <Typography variant="labelLarge" color="text.primary" /> }}
          />
          <br />
          <Typography mt={4} variant="caption">
            {invoiceHdrId ? t('job_invoice_hdr') + ' - ' + invoiceHdrId : null}
          </Typography>
        </Typography>
      }
      maxWidth={'xs'}
      onClose={onClose}
      content={
        <Stack gap={4}>
          <Stack gap={2}>
            {services.map(({ service, price, quantity }) => (
              <Stack key={service.id} gap={1}>
                <Container direction="row" data-testname={`service-${service.id}`}>
                  <Typography variant="body2" color="text.secondary" data-testname="serviceCode">
                    {service.code} (quantity: {quantity})
                  </Typography>

                  {price ? (
                    <Typography variant="body2" fontWeight="bold" data-testname="servicePrice">
                      {formatPrice(Number(price) * quantity)}
                    </Typography>
                  ) : (
                    <Chip
                      size="small"
                      color="error"
                      onClick={() =>
                        navigate(
                          `${configurationRoutes.configuration}/${configurationRoutes.internalRoutes.priceLists}`,
                        )
                      }
                      icon={<OpenInNewOutlinedIcon />}
                      label={t('no_price_set_label')}
                    />
                  )}
                </Container>
                {!price && (
                  <Typography variant="inputHelper" color="error">
                    {t('no_price_set_helper', { service: service.code })}
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack>

          <Container direction="row">
            <Typography variant="body2" color="text.secondary">
              {t('total_label')}
            </Typography>

            <Typography variant="body2" fontWeight="bold" data-testname="totalCost">
              {formatPrice(totalCost)}
            </Typography>
          </Container>
        </Stack>
      }
      actions={{
        primary: {
          type: 'submit',
          text: tGlobal('confirm'),
          onClick: onConfirmClick,
          disabled: hasMissingPrice,
          loading: isSubmitting,
        },
        secondary: {
          text: tGlobal('cancel'),
        },
      }}
    />
  );
};
