import { Link, LinkProps } from '@mui/material';
import { FC } from 'react';
import {
  Link as ReactRouterLinkComponent,
  LinkProps as ReactRouterLinkComponentProps,
} from 'react-router-dom';

interface ReactRouterLinkProps
  extends LinkProps,
    Pick<ReactRouterLinkComponentProps, 'replace' | 'state' | 'relative' | 'to'> {}

export const ReactRouterLink: FC<ReactRouterLinkProps> = (props) => {
  return (
    <Link
      variant="body2"
      color="text.secondary"
      underline="none"
      component={ReactRouterLinkComponent}
      {...props}
    />
  );
};

export default ReactRouterLink;
