import { FC } from 'react';
import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';

export type SwitchProps = MuiSwitchProps & {
  /**
   * Text to be used in an enclosing label element.
   * If not defined, no label element will be added.
   */
  label?: string;
  /**
   * Props applied to the [`MuiFormControlLabel`](https://mui.com/material-ui/api/form-control-label/) element.
   */
  labelProps?: Omit<FormControlLabelProps, 'label' | 'control'>;
  /**
   * If `true`, the `FormControlLabel` element will not have a CSS margin.
   */
  noLabelMargin?: boolean;
};

export const Switch: FC<SwitchProps> = ({ label, labelProps, noLabelMargin, ...rest }) =>
  label ? (
    <FormControlLabel
      {...labelProps}
      control={<MuiSwitch {...rest} />}
      label={label}
      sx={{ margin: noLabelMargin ? 0 : undefined }}
    />
  ) : (
    <MuiSwitch {...rest} />
  );

export default Switch;
