import { FC } from 'react';
import { LinkProps as MuiLinkProps } from '@mui/material/Link';

import { StyledLink } from './style';

export type LinkProps = MuiLinkProps;

const Link: FC<LinkProps> = ({ ...props }) => (
  <StyledLink variant="body1" color="primary" {...props} />
);

export default Link;
