import { Stack } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

import { Aircraft } from 'domain/entities/Aircraft';
import { useCaseSearchAircraft } from 'application/aircraft/useCases/useCaseSearchAircraft';
import { useCaseCreateAircraft } from 'application/aircraft/useCases/useCaseCreateAircraft';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Breadcrumbs, Button, TableColumn, TableWithSearch } from 'targets/web/components';
import { OfflinePlaceholder, PageTitle } from 'targets/web/modules/dashboard/components';
import { configurationRoutes } from 'targets/web/modules/configuration/navigation/configurationRoutes';
import { CreateAircraftModal } from 'targets/web/modules/configuration/components';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

export const AircraftConfigurationView: FC = () => {
  const notify = useSnackbar();
  const t = useTranslationPrefix('configuration.aircraft');
  const tBreadcrumbs = useTranslationPrefix('configuration.breadcrumbs');
  const { isOffline } = useNetworkStatus();

  const [aircraftPhrase, setAircraftPhrase] = useState('');
  const [isCreateAircraftModalOpen, setIsCreateAircraftModalOpen] = useState(false);

  const {
    items = [],
    total,
    isLoading,
    isFetching,

    paging,
    changePage,
    changePageSize,

    sorting,
    changeSort,
  } = useCaseSearchAircraft({ phrase: aircraftPhrase });
  const { createAircraft } = useCaseCreateAircraft();

  const columns: TableColumn<Aircraft>[] = useMemo(
    () => [
      {
        headerName: t('column_header.aircraft_type'),
        field: 'type',
        sortable: true,
        width: '25%',
        renderCell: ({ type }) => type.name,
      },
      {
        headerName: t('column_header.reg_number'),
        field: 'code',
        sortable: true,
        width: '25%',
        renderCell: ({ code }) => code,
      },
      {
        headerName: t('column_header.serial_number'),
        field: 'serialNumber',
        sortable: true,
        width: '25%',
        renderCell: ({ serialNumber }) => serialNumber,
      },
    ],
    [t],
  );

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('configuration'),
                url: configurationRoutes.configuration,
              },
            ]}
          />

          <PageTitle title={t('title')} />
        </Stack>

        <Button
          size="large"
          startIcon={<AddIcon />}
          onClick={() => setIsCreateAircraftModalOpen(true)}
        >
          {t('create_button')}
        </Button>
      </Stack>

      <CreateAircraftModal
        open={isCreateAircraftModalOpen}
        onClose={() => setIsCreateAircraftModalOpen(false)}
        onSubmit={async (values) =>
          await createAircraft({
            typeId: values.type.id,
            serialNumber: values.serialNumber,
            regNumber: values.regNumber,
          }).then(
            () => {
              notify(t('snackbar.create_success'), {
                variant: 'success',
              });
            },
            () => {
              notify(t('snackbar.create_error'), {
                variant: 'error',
              });
            },
          )
        }
      />

      <Stack width={1}>
        {isOffline && !items.length ? (
          <OfflinePlaceholder />
        ) : (
          <TableWithSearch
            onSearchPhraseChange={setAircraftPhrase}
            columns={columns}
            rows={items}
            count={total}
            rowsPerPageOptions={[10, 50, 100]}
            initialRowsPerPage={10}
            orderBy={sorting.sortBy}
            orderDirection={sorting.direction}
            initialPage={Math.round(paging.offset / paging.limit)}
            isLoading={isLoading || isFetching}
            searchPhrasePlaceholder={t('placeholder')}
            onRowsPerPageChange={changePageSize}
            onPageChange={changePage}
            onSortChange={changeSort}
          />
        )}
      </Stack>
    </>
  );
};
