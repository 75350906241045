import { injectable } from 'inversify';
import { useAuth } from 'react-oidc-context';
import * as Sentry from '@sentry/react';

import { AsyncHookResult } from 'domain/types/AsyncHookResult';
import { ILogoutAdapter, LogoutError } from 'application/auth/useCases/useCaseLogout';

@injectable()
export default class OIDCLogoutAdapter implements ILogoutAdapter {
  useLogout(): AsyncHookResult<{ success: boolean }, LogoutError> & { logout: () => void } {
    const auth = useAuth();
    return {
      isLoading: auth.isLoading,
      error: auth.error ? LogoutError.Unknown : undefined,
      logout: () => {
        Sentry.setUser(null);

        auth
          .signoutRedirect({
            post_logout_redirect_uri: window.location.href,
          })
          .catch(console.error);
      },
    };
  }
}
