import { FC } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

export type ButtonProps = MuiButtonProps;

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {}
}

export const Button: FC<ButtonProps> = ({ ...props }) => <MuiButton {...props} />;

export default Button;
