import { FC } from 'react';
import { Route, Routes } from 'react-router';

import { OperationsView } from 'targets/web/modules/operations/views/Operations.view';
import PageNotFoundView from 'targets/web/modules/dashboard/views/PageNotFound.view';

const OperationsRoutes: FC = () => (
  <Routes>
    <Route index element={<OperationsView />} />
    <Route path="*" element={<PageNotFoundView />} />
  </Routes>
);

export default OperationsRoutes;
