import { GridItemProps, VirtuosoGrid, VirtuosoGridProps } from 'react-virtuoso';
import { Box, BoxProps } from '@mui/material';
import { forwardRef, useMemo } from 'react';

interface VirtualizedContainerProps<T> extends VirtuosoGridProps<T> {
  spacing?: number;
  minColumnWidth?: number;
}

export const VirtualizedGrid = <T,>({
  spacing,
  minColumnWidth,
  components,
  overscan,
  ...props
}: VirtualizedContainerProps<T>) => {
  const memoizedComponents = useMemo(() => {
    return {
      ...(components ?? {}),
      // eslint-disable-next-line react/display-name
      Item: forwardRef<HTMLDivElement, GridItemProps>((props, ref) => (
        <Box
          {...props}
          ref={ref}
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            '& > *': { flex: 1 },
          }}
        />
      )),
      // eslint-disable-next-line react/display-name
      List: forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
        <Box
          {...props}
          ref={ref}
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fill, minmax(${minColumnWidth ?? 300}px, 1fr))`,
            gap: spacing,
          }}
        />
      )),
    };
  }, [components, minColumnWidth, spacing]);

  return <VirtuosoGrid {...props} overscan={overscan ?? 300} components={memoizedComponents} />;
};
