import { atom, WritableAtom } from 'jotai';

export function atomWithToggle(
  initialValue = false,
): WritableAtom<boolean, [boolean | undefined], void> {
  const anAtom = atom<boolean, [boolean | undefined], void>(
    initialValue,
    (get, set, nextValue?: boolean) => {
      const update = nextValue ?? !get(anAtom);
      set(anAtom, update);
    },
  );

  return anAtom;
}
