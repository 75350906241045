import { useCallback } from 'react';
import contract from 'api/infrastructure/contract/billing.route';

import {
  BillingItemRepository,
  SearchBillingItemsParams,
} from 'domain/repositories/IBillingItemRepository';
import useTsRestClient from 'infrastructure/providers/useTsRestClient';

type RepositoryFunctions = ReturnType<BillingItemRepository>;

export const useTsRestBillingItemRepository: BillingItemRepository = () => {
  const client = useTsRestClient(contract);

  const searchBillingItems: RepositoryFunctions['searchBillingItems'] = useCallback(
    async (params: SearchBillingItemsParams) => {
      const result = await client.searchBilling({
        query: {
          direction: params.direction,
          limit: params.limit,
          offset: params.offset,
          sortBy: `${params.sortBy}`,
        },
      });
      if (result.status === 200) {
        const { total, items } = result.body;
        return {
          total,
          items: items.map((item) => ({
            ...item,
            submittedAt: new Date(item.submittedAt),
            id: item.job.id,
          })),
        };
      }
      throw result;
    },
    [client],
  );

  const confirmBillingItem: RepositoryFunctions['confirmBillingItem'] = useCallback(
    async (params) => {
      const response = await client.sealBillingItem({
        params: { jobId: params.id },
      });

      if (response.status !== 204) {
        throw response;
      }

      return;
    },
    [client],
  );

  return {
    searchBillingItems,
    confirmBillingItem,
  };
};
