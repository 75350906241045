import { Field, FieldProps } from 'formik';
import { PropsWithChildren } from 'react';
import { get } from 'lodash';

import Select, { SelectProps } from 'targets/web/components/Select';

export const FormikSelectField = <T,>({
  defaultValue,
  ...props
}: PropsWithChildren<SelectProps<T>>) => (
  <Field name={props.name} required={props.required}>
    {({ field, form: { touched, errors } }: FieldProps<T>) => {
      const hasError = !!get(errors, field.name) && !!get(touched, field.name);

      return (
        <Select
          {...field}
          {...props}
          data-testid={props.name}
          value={field.value ?? defaultValue}
          error={hasError}
          helperText={hasError ? get(errors, field.name)?.toString() : undefined}
        />
      );
    }}
  </Field>
);
