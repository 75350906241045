import { FC } from 'react';
import { format } from 'date-fns';
import { Grid, Typography } from '@mui/material';

import { JobRequest } from 'domain/entities/JobRequest';
import { ServiceType } from 'domain/entities/ServiceType';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { capitalizeString } from 'infrastructure/utils/capitalizeString';
import { Container } from 'targets/web/modules/jobs/components/JobDetailsForm/common';
import { PreviewBox } from 'targets/web/components';

export const JobRequestFormContainer: FC<{ jobRequest: JobRequest }> = ({ jobRequest }) => {
  const t = useTranslationPrefix('approvals.confirm_job_request_view.job_request_form_container');

  return (
    <Container width={1} gap={4} sx={{ padding: 4 }} data-testname="jobRequestContainer">
      <Typography variant="h6">{t('title')}</Typography>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <PreviewBox
            data-testname="company"
            header={t('header.company')}
            subheader={jobRequest?.company}
            size="regular"
          />
        </Grid>
        <Grid item xs={6}>
          <PreviewBox
            data-testname="tailNumber"
            header={t('header.tail_number')}
            subheader={jobRequest?.tailNumber}
            size="regular"
          />
        </Grid>

        <Grid item xs={4}>
          <PreviewBox
            data-testname="contactName"
            header={t('header.contact_name')}
            subheader={[jobRequest?.contactFirstName, jobRequest?.contactLastName]
              .filter(Boolean)
              .join(' ')}
            size="regular"
          />
        </Grid>
        <Grid item xs={4}>
          <PreviewBox
            data-testname="email"
            header={t('header.email')}
            subheader={jobRequest?.email}
            size="regular"
          />
        </Grid>
        <Grid item xs={4}>
          <PreviewBox
            data-testname="phoneNumber"
            header={t('header.phone_number')}
            subheader={jobRequest?.phoneNumber}
            size="regular"
          />
        </Grid>

        <Grid item xs={6}>
          <PreviewBox
            header={t('header.arrival_date')}
            subheader={
              jobRequest?.arrivalDate ? format(jobRequest?.arrivalDate, 'MM/dd/yyyy h:mmaa') : ''
            }
            size="regular"
          />
        </Grid>
        <Grid item xs={6}>
          <PreviewBox
            header={t('header.departure_date')}
            subheader={
              jobRequest?.departureDate
                ? format(jobRequest?.departureDate, 'MM/dd/yyyy h:mmaa')
                : ''
            }
            size="regular"
          />
        </Grid>

        <Grid item xs={6}>
          <PreviewBox
            direction="column"
            alignItems="start"
            spacing={1}
            header={t('header.notes')}
            size="regular"
          >
            <Typography noWrap={false} variant="labelMedium">
              {jobRequest?.serviceNeeds ?? 'N/A'}
            </Typography>
          </PreviewBox>
        </Grid>
        <Grid item xs={6}>
          <PreviewBox
            direction="column"
            alignItems="start"
            spacing={1}
            header={t('header.services')}
            size="regular"
          >
            <Typography noWrap={false} sx={{ breakWord: 'break-all' }} variant="labelMedium">
              {jobRequest?.requestedServices
                .map((s: ServiceType) => capitalizeString(s.name))
                .join(', ')}
            </Typography>
          </PreviewBox>
        </Grid>
      </Grid>
    </Container>
  );
};
