import { Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { z } from 'zod';
import { useFormikContext } from 'formik';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { CardStack } from 'targets/web/components/CardStack';
import { TextField } from 'targets/web/components';
import {
  secondsToStringTime,
  stringTimeToSeconds,
  timePattern,
} from 'targets/web/modules/jobs/utils/secondsToStringTime';
import {
  ADJUSTED_LOGGED_TIME_MIN,
  ADJUSTED_LOGGED_TIME_MAX,
} from 'targets/web/modules/jobs/components/ServiceDetailsForm/constants';

const TIME_TRACKING_RANGE = {
  min: ADJUSTED_LOGGED_TIME_MIN,
  max: ADJUSTED_LOGGED_TIME_MAX,
};

export const TimeTrackingServiceDetailsSchema = z.object({
  adjustedLoggedTimeBillable: z
    .number()
    .min(TIME_TRACKING_RANGE.min)
    .max(TIME_TRACKING_RANGE.max)
    .optional(),
  adjustedLoggedTimeNonBillable: z
    .number()
    .min(TIME_TRACKING_RANGE.min)
    .max(TIME_TRACKING_RANGE.max)
    .optional(),
  loggedTimeBillable: z.string(),
  loggedTimeNonBillable: z.string(),
});

export type TimeTrackingServiceDetailsData = z.infer<typeof TimeTrackingServiceDetailsSchema>;

export interface TimeTrackingServiceDetailsProps {
  disabled: boolean;
}

const TimeTrackingServiceDetails: FC<TimeTrackingServiceDetailsProps> = () => {
  const t = useTranslationPrefix('jobs.service_details_form.time_tracking');
  const { values, errors, setFieldValue, setFieldError } =
    useFormikContext<TimeTrackingServiceDetailsData>();
  return (
    <CardStack gap={4}>
      <Typography variant="h6">{t('title')}</Typography>
      <Grid container spacing={6}>
        <Grid item md={6}>
          <Stack gap={4}>
            <TextField
              fullWidth
              helperText={errors.adjustedLoggedTimeBillable}
              error={Boolean(errors.adjustedLoggedTimeBillable)}
              type="string"
              label={t('adjusted_logged_time_billable_label')}
              name="adjusted_logged_time_billable"
              defaultValue={secondsToStringTime(values.adjustedLoggedTimeBillable || 0)}
              onBlur={(e) => {
                if (!timePattern.test(e.target.value)) {
                  setFieldError('adjustedLoggedTimeBillable', t('adjusted_time_format_error'));
                  return;
                }

                setFieldError('adjustedLoggedTimeBillable', undefined);
                setFieldValue('adjustedLoggedTimeBillable', stringTimeToSeconds(e.target.value));
              }}
              InputProps={{
                inputProps: { min: TIME_TRACKING_RANGE.min, max: TIME_TRACKING_RANGE.max },
              }}
            />
            <TextField
              fullWidth
              disabled
              label={t('logged_time_billable_label')}
              name="logged_time_billable"
              value={values.loggedTimeBillable}
              onChange={(e) =>
                setFieldValue('loggedTimeBillable', parseInt(e.target.value) || undefined)
              }
            />
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Stack gap={4}>
            <TextField
              fullWidth
              type="string"
              error={Boolean(errors.adjustedLoggedTimeNonBillable)}
              helperText={errors.adjustedLoggedTimeNonBillable}
              label={t('adjusted_logged_time_nonbillable_label')}
              name="adjusted_logged_time_nonbillable"
              defaultValue={secondsToStringTime(values.adjustedLoggedTimeNonBillable || 0)}
              onBlur={(e) => {
                if (!timePattern.test(e.target.value)) {
                  setFieldError('adjustedLoggedTimeNonBillable', t('adjusted_time_format_error'));
                  return;
                }

                setFieldError('adjustedLoggedTimeNonBillable', undefined);
                setFieldValue('adjustedLoggedTimeNonBillable', stringTimeToSeconds(e.target.value));
              }}
              InputProps={{
                inputProps: { min: TIME_TRACKING_RANGE.min, max: TIME_TRACKING_RANGE.max },
              }}
            />
            <TextField
              fullWidth
              disabled
              label={t('logged_time_nonbillable_label')}
              name="logged_time_nonbillable"
              value={values.loggedTimeNonBillable}
              onChange={(e) => setFieldValue('loggedTimeNonBillable', parseInt(e.target.value))}
            />
          </Stack>
        </Grid>
      </Grid>
    </CardStack>
  );
};

export default TimeTrackingServiceDetails;
