import { FC } from 'react';
import { Part } from 'api/domain/entities/documents/PostWorkQuestionnaire';
import { useFormikContext } from 'formik';
import { Grid, Stack } from '@mui/material';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button, CardStack } from 'targets/web/components';
import { usePostWorkQuestionnaireSteps } from 'targets/web/modules/jobs/hooks/usePostWorkQuestionnaireSteps';

import { PartField } from './PartSigned';
import PostWorkPart from './PostWorkPart';

export const QuestionnairePart: FC<{
  parts: Part[];
  onPerformedByClick: (parts: Part[]) => void;
  onConfirmedByClick: (parts: Part[]) => void;
}> = ({ parts, onPerformedByClick, onConfirmedByClick }) => {
  const t = useTranslationPrefix('jobs.post_work_questionnaire');

  const { step } = usePostWorkQuestionnaireSteps();

  const { values, setFieldValue, validateForm } = useFormikContext<Record<Part, PartField>>();

  const hasAllSelected = !parts.some((name) => !values[name].performedBy);

  const hasSomeSelectedUnchecked = parts
    .filter((name) => !!values[name].performedBy)
    .some((n) => !values[n].checkedBy);

  return (
    <Grid item xs={6}>
      <CardStack padding={{ xs: 4, md: 6 }} height="100%">
        <Stack direction="column" gap={4}>
          {step === 'form900-1' && (
            <Button
              data-testname="anwserAllInSection"
              size="large"
              color="primary"
              variant={hasAllSelected ? 'outlined' : 'contained'}
              onClick={() => {
                if (hasAllSelected) {
                  // deselect
                  Promise.any(
                    parts.map((name) =>
                      setFieldValue(name, {
                        performedBy: undefined,
                        checkedBy: undefined,
                      }),
                    ),
                  ).then(validateForm);
                }
                if (!hasAllSelected) {
                  // select
                  onPerformedByClick(parts);
                }
              }}
            >
              {hasAllSelected ? t('cancel_selection_to_yes_btn') : t('select_yes_to_section_btn')}
            </Button>
          )}
          {step === 'form900-2' && hasSomeSelectedUnchecked && (
            <Button
              data-testname="confirmAllInSection"
              size="large"
              color="primary"
              variant="contained"
              onClick={() => {
                onConfirmedByClick(parts.filter((name) => !!values[name].performedBy));
              }}
            >
              {t('select_confirm_for_section')}
            </Button>
          )}

          {parts.map((part) => (
            <PostWorkPart
              key={part}
              name={part}
              showConfirmationCheck={step === 'form900-2'}
              onPerformedClicked={(name, isPerformed) => {
                if (isPerformed) {
                  onPerformedByClick([name]);
                } else {
                  return setFieldValue(
                    name,
                    {
                      performedBy: undefined,
                      checkedBy: undefined,
                    },
                    true,
                  );
                }
              }}
              onConfirmClicked={(name) => {
                onConfirmedByClick([name]);
              }}
            />
          ))}
        </Stack>
      </CardStack>
    </Grid>
  );
};
