import { FC } from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

import { AbsoluteLoaderContainer } from './style';

export interface LoaderProps extends CircularProgressProps {
  /**
   * If `true`, the loader is wrapped in an absolute positioned container.
   *
   * The container takes the whole width and height of its closest relative positioned parent,
   * and the loader is centered horizontally and vertically within that container.
   *
   * @default false
   */
  absolute?: boolean;
}

export const Loader: FC<LoaderProps> = ({ absolute, ...args }) => {
  if (absolute) {
    return (
      <AbsoluteLoaderContainer>
        <CircularProgress {...args} />
      </AbsoluteLoaderContainer>
    );
  }
  return <CircularProgress {...args} />;
};

export default Loader;
