import { useCallback } from 'react';
import { Job } from 'api/domain/entities/Job';

import { Service } from 'domain/entities/Service';
import { BillingStatus, isEntityLocked } from 'domain/entities/BillingStatus';
import { JobStatus } from 'domain/entities/JobStatus';
import { ServiceStatus } from 'domain/entities/ServiceStatus';
import { useCaseUpdateService } from 'application/services/useCases/useCaseUpdateService';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';

import { BillingStatusChip } from './BillingStatusChip';

type ServiceBillingStatusChipProps = Pick<Service, 'id' | 'billingStatus' | 'status' | 'jobId'> & {
  jobBillingStatus: Job['billingStatus'];
  onChange?: (status: BillingStatus) => void;
  optimisticPermissions?: boolean;
};

export const ServiceBillingStatusChip = ({
  id,
  jobId,
  status,
  billingStatus,
  jobBillingStatus,
  onChange,
  optimisticPermissions,
}: ServiceBillingStatusChipProps) => {
  const notify = useSnackbar();

  const { updateServiceBillingStatus } = useCaseUpdateService();

  const handleStatusChipChange = useCallback(
    async (status: BillingStatus) => {
      await updateServiceBillingStatus(
        {
          serviceId: id,
          billingStatus: status,
        },
        {
          onError: () => {
            notify("Error occurred while updating the Service's billing status", {
              variant: 'error',
            });
          },
        },
      );

      onChange?.(status);
    },
    [id, notify, onChange, updateServiceBillingStatus],
  );

  return (
    <BillingStatusChip
      jobId={jobId}
      billingStatus={billingStatus}
      editable={
        status !== ServiceStatus.Values.pending &&
        status !== JobStatus.Values.in_progress &&
        !isEntityLocked({ billingStatus: jobBillingStatus, status })
      }
      onSelect={handleStatusChipChange}
      optimisticPermissions={optimisticPermissions}
    />
  );
};
