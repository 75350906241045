import { Stack, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useFormikContext } from 'formik';
import CheckIcon from '@mui/icons-material/Check';
import { Part } from 'api/domain/entities/documents/PostWorkQuestionnaire';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { CardStack, IconButton, ToggleButton } from 'targets/web/components';

import { PartField } from './PartSigned';

const StyledCard = styled(CardStack)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.neutral,
}));

interface Props {
  name: Part;
  showConfirmationCheck: boolean;
  onPerformedClicked: (name: Part, isPerformed: boolean) => void;
  onConfirmClicked: (name: Part) => void;
}

const PostWorkPart: React.FC<Props> = ({
  name,
  showConfirmationCheck,
  onConfirmClicked,
  onPerformedClicked,
}) => {
  const t = useTranslationPrefix('jobs.post_work_questionnaire');
  const formik = useFormikContext<Record<Part, PartField>>();

  const field = formik.values[name];

  return (
    <StyledCard>
      <Stack direction="column" gap={4}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          flexWrap="wrap"
          gap={2}
          alignItems={{ xs: 'left', md: 'center' }}
          justifyContent="space-between"
        >
          <Typography
            variant="labelLarge"
            color={!field.performedBy && showConfirmationCheck ? 'text.disabled' : 'text.primary'}
          >
            {t(`parts.${name}`)}
          </Typography>

          <ToggleButton
            data-testname={`${name}`}
            color="primary"
            disabled={showConfirmationCheck}
            value={field.performedBy ? 'yes' : 'na'}
            onClick={({ value }) => onPerformedClicked(name, value === 'yes')}
            options={[
              {
                label: t('yes'),
                value: 'yes',
              },
              {
                label: t('na'),
                value: 'na',
              },
            ]}
          />
        </Stack>

        {showConfirmationCheck && field.performedBy && (
          <IconButton
            data-testname={`${name}-confirm`}
            fullWidth
            startIcon={field.checkedBy ? <CheckIcon /> : undefined}
            onClick={() => onConfirmClicked(name)}
            variant="contained"
            color={field.checkedBy ? 'success' : 'primary'}
          >
            {t(field.checkedBy ? 'confirmed' : 'confirm')}
          </IconButton>
        )}
      </Stack>
    </StyledCard>
  );
};

export default PostWorkPart;
