import { Field, FieldProps } from 'formik';
import { get } from 'lodash';
import { ChangeEvent, useEffect } from 'react';

import PasswordTextField from 'targets/web/components/PasswordTextField';
import TextField from 'targets/web/components/TextField';

export const FormikTextField = ({
  resetOnUnmount = false,
  ...props
}: {
  resetOnUnmount?: boolean;
  onChange?: (value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  [x: string]: unknown;
}) => {
  const Component = props.type === 'password' ? PasswordTextField : TextField;

  return (
    <Field name={props.name} required={props.required}>
      {({ field, form: { touched, errors, setFieldValue } }: FieldProps) => {
        // To support dynamic fields
        // https://github.com/jaredpalmer/formik/issues/1173
        useEffect(() => {
          if (typeof resetOnUnmount === 'boolean' && !resetOnUnmount) {
            return;
          }

          return () => {
            setFieldValue(field.name, undefined, false);
          };
        }, [field.name, setFieldValue]);

        const hasError = !!get(errors, field.name) && !!get(touched, field.name);

        return (
          <Component
            {...field}
            data-testid={props.name}
            error={hasError}
            helperText={hasError ? get(errors, field.name)?.toString() : undefined}
            {...props}
          />
        );
      }}
    </Field>
  );
};
