import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { AircraftRepository, SearchAircraftParams } from 'domain/repositories/IAircraftRepository';
import { Aircraft } from 'domain/entities/Aircraft';
import { Entity } from 'domain/types/Entity';
import { useHookInjection } from 'application/hooks';
import { useSortingAndPagination } from 'application/common';

export const QUERY_PREFIX = 'AIRCRAFT';

export const useCaseSearchAircraft = (
  params: Omit<SearchAircraftParams, 'limit' | 'offset' | 'sortBy' | 'direction'>,
) => {
  const qClient = useQueryClient();
  const { searchAircraft } = useHookInjection<AircraftRepository>(AircraftRepository);

  const { paging, sorting, ...utils } = useSortingAndPagination<Entity<Aircraft>>('type');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_PREFIX, { ...params, ...paging, ...sorting }] as const,
    queryFn: ({ queryKey }) => searchAircraft(queryKey[1]),
    placeholderData: { items: [], total: 0 },
    staleTime: 10_000,
  });

  const refetch = useCallback(
    () => qClient.invalidateQueries({ queryKey: [QUERY_PREFIX] }),
    [qClient],
  );

  return {
    ...data,
    refetch,

    paging,
    sorting,
    ...utils,

    isLoading,
    isFetching,
  };
};
