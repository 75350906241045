export const toUTC = (date: Date | string | number): Date => {
  let internalDate: Date;

  if (typeof date === 'string' || typeof date === 'number') {
    internalDate = new Date(date);
  } else {
    internalDate = date;
  }

  return new Date(
    internalDate.getUTCFullYear(),
    internalDate.getUTCMonth(),
    internalDate.getUTCDate(),
    0,
    0,
    0,
    0,
  );
};
