import { InjectableHook } from 'domain/types/InjectableHook';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { JobApprovalItem } from 'domain/entities/JobApprovalItem';
import { UnapprovedJobStatusCount } from 'domain/entities/UnapprovedJobStatusCount';
import { DeclineJobRequestParams, ReviewJobParams } from 'domain/entities/DeclineJobRequestParams';
import { SearchJobRequestsParams } from 'domain/entities/SearchJobRequestsParams';
import { JobRequest } from 'domain/entities/JobRequest';
import { Entity } from 'domain/types/Entity';

export const JobRequestRepository = Symbol('JobRequestRepository');

export type JobRequestRepository = InjectableHook<{
  searchJobRequests(
    params: SearchJobRequestsParams,
    abortSignal?: AbortSignal,
  ): Promise<PaginatedResult<JobApprovalItem>>;
  getJobRequestsCountByStatus(params: SearchJobRequestsParams): Promise<UnapprovedJobStatusCount>;

  getJobRequest(params: { id?: string }): Promise<Entity<JobRequest>>;
  reviewJob(params: ReviewJobParams): Promise<void>;
  declineJobRequest(params: DeclineJobRequestParams | ReviewJobParams): Promise<void>;
}>;
