import { CustomerData } from './CustomerData';

export const initialValues: CustomerData = {
  name: '',
  code: '',
  externalId: '',
  paymentTerm: 'net30',

  address: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',

  mailingAddressSameAsPhysical: true,

  operationsContact: {
    name: '',
    phoneNumber: '',
    email: '',
  },

  billingContact: {
    name: '',
    phoneNumber: '',
    email: '',
  },
};
