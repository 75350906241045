import { Stack } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Location, useLocation, useNavigate } from 'react-router';

import { Customer } from 'domain/entities/Customer';
import { CreateCustomer } from 'domain/types/Roles';
import { useCaseSearchCustomer } from 'application/customers/useCases/useCaseSearchCustomer';
import { useCaseCreateCustomer } from 'application/customers/useCases/useCaseCreateCustomer';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import {
  Breadcrumbs,
  Button,
  ReactRouterLink,
  TableColumn,
  TableWithSearch,
} from 'targets/web/components';
import { OfflinePlaceholder, PageTitle } from 'targets/web/modules/dashboard/components';
import { configurationRoutes } from 'targets/web/modules/configuration/navigation/configurationRoutes';
import {
  CreateCustomerModal,
  CustomerExistsModal,
} from 'targets/web/modules/configuration/components';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { CustomerModalType } from 'targets/web/modules/configuration/components/CreateCustomerModal/CreateCustomerModal';

const emptyCustomer = { name: '', code: '', externalId: '' };

export const CustomersView: FC = () => {
  const notify = useSnackbar();
  const t = useTranslationPrefix('configuration.customers');
  const tBreadcrumbs = useTranslationPrefix('configuration.breadcrumbs');
  const { isOffline } = useNetworkStatus();
  const navigate = useNavigate();
  const location = useLocation() as Location<{ shouldOpenCreateCustomerModal?: boolean } | null>;

  const [isCreateCustomerModalOpen, setIsCreateCustomerModalOpen] = useState(false);

  const [existingCustomer, setExistingCustomer] = useState<Pick<
    Customer,
    'name' | 'code' | 'externalId'
  > | null>(null);

  const [phrase, setPhrase] = useState('');
  const {
    items = [],
    total,
    isLoading,
    isFetching,

    paging,
    changePage,
    changePageSize,

    sorting,
    changeSort,
  } = useCaseSearchCustomer({ phrase });
  const { createCustomer } = useCaseCreateCustomer();
  const { hasRole } = useCaseCheckAccess();

  useEffect(() => {
    if (location.state?.shouldOpenCreateCustomerModal) {
      setIsCreateCustomerModalOpen(location.state.shouldOpenCreateCustomerModal);

      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, location.state, navigate]);

  const columns: TableColumn<Customer>[] = useMemo(
    () => [
      {
        headerName: t('column_header.name'),
        field: 'name',
        sortable: true,
        width: '50%',
        renderCell: ({ id, name }) => (
          <ReactRouterLink to={`${id}`} variant="body1" color="primary">
            {name}
          </ReactRouterLink>
        ),
      },
      {
        headerName: t('column_header.acronym'),
        field: 'code',
        sortable: true,
        width: '50%',
        renderCell: ({ code }) => code,
      },
    ],
    [t],
  );

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('configuration'),
                url: configurationRoutes.configuration,
              },
            ]}
          />

          <PageTitle title={t('title')} />
        </Stack>

        {hasRole(CreateCustomer) && !isOffline && (
          <Button
            size="large"
            startIcon={<AddIcon />}
            onClick={() => setIsCreateCustomerModalOpen(true)}
          >
            {t('create_button')}
          </Button>
        )}
      </Stack>

      <CustomerExistsModal
        customer={existingCustomer ?? emptyCustomer}
        open={!!existingCustomer}
        onClose={() => setExistingCustomer(null)}
      />
      <CreateCustomerModal
        onSubmit={async (values) =>
          createCustomer(
            values.mailingAddressSameAsPhysical
              ? {
                  ...values,
                  mailingAddress: {
                    address: values.address,
                    city: values.city,
                    state: values.state,
                    postalCode: values.postalCode,
                    country: values.country,
                  },
                }
              : values,
          ).then(
            (res) => {
              if (res.created) {
                notify(t('snackbar.create_success'), {
                  variant: 'success',
                });

                return;
              }

              setExistingCustomer({
                code: res.code ?? '',
                name: res.name,
                externalId: res.externalId,
              });
            },
            () => {
              notify(t('snackbar.create_error'), {
                variant: 'error',
              });
            },
          )
        }
        type={CustomerModalType.Create}
        onClose={() => setIsCreateCustomerModalOpen(false)}
        open={isCreateCustomerModalOpen}
      />

      <Stack width={1}>
        {isOffline && !items.length ? (
          <OfflinePlaceholder />
        ) : (
          <TableWithSearch
            onSearchPhraseChange={setPhrase}
            columns={columns}
            rows={items}
            count={total}
            rowsPerPageOptions={[10, 50, 100]}
            initialRowsPerPage={10}
            orderBy={sorting.sortBy}
            orderDirection={sorting.direction}
            initialPage={Math.round(paging.offset / paging.limit)}
            isLoading={isLoading || isFetching}
            searchPhrasePlaceholder={t('placeholder')}
            onRowsPerPageChange={changePageSize}
            onPageChange={changePage}
            onSortChange={changeSort}
          />
        )}
      </Stack>
    </>
  );
};
