import { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CheckIcon from '@mui/icons-material/CheckOutlined';

import { Damage } from 'domain/entities/DamageFound';
import { IconButton, Select, TextField, TrashCanIcon } from 'targets/web/components';

import { areas, inspectionItems } from './Areas';

export type Area =
  | 'interior-flight_deck'
  | 'interior-entrance'
  | 'interior-crew_rest'
  | 'interior-forward'
  | 'interior-lavatory'
  | 'interior-cabin'
  | 'interior-baggage_area'
  | 'exterior';

interface DamageFound {
  damage: Damage | '';
  description?: string;
  imageFile?: File;
}

const acceptedFiles = ['.jpg', '.jpeg', '.png', '.gif'].join(', ');

export const DamageLine: FC<{
  damageFound: DamageFound;
  error?: Partial<Record<keyof DamageFound, string>>;
  onDelete: () => void;
  onChange: (value: DamageFound) => void;
}> = ({ damageFound, error, onDelete, onChange }) => {
  const splitDamageFound = damageFound.damage.split('-');
  const chosenArea = (
    splitDamageFound.length === 2 ? splitDamageFound[0] : splitDamageFound.slice(0, 2).join('-')
  ) as Area | '';
  const chosenItem = splitDamageFound.length === 2 ? splitDamageFound[1] : splitDamageFound[2];

  const [selectedArea, setSelectedArea] = useState<Area | ''>(chosenArea ?? '');
  const [selectedInspectionItem, setSelectedInspectionItem] = useState(chosenItem ?? '');
  const ref = useRef<HTMLInputElement | null>(null);

  const handleInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const file = event.target.files[0];
      onChange({ ...damageFound, imageFile: file });
    },
    [damageFound, onChange],
  );

  const clickInput = useCallback(() => ref?.current?.click(), []);

  return (
    <Stack
      width={1}
      gap={{ xs: 2, md: 4 }}
      direction="row"
      flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      justifyContent={{ xs: 'flex-end', md: 'center' }}
      alignItems="center"
    >
      <Select
        label={'Area'}
        value={selectedArea}
        sx={{ backgroundColor: 'background.default' }}
        onChange={(value) => {
          setSelectedArea(value.target.value as Area);
          setSelectedInspectionItem('');
        }}
        items={areas}
      />

      <Select
        label={'Inspection Item'}
        disabled={!selectedArea}
        value={selectedInspectionItem}
        sx={{ backgroundColor: 'background.default' }}
        onChange={(value) => {
          setSelectedInspectionItem(value.target.value);
          onChange({ ...damageFound, damage: `${selectedArea}-${value.target.value}` as Damage });
        }}
        items={selectedArea ? inspectionItems[selectedArea] : []}
      />

      <TextField
        error={!!error?.description}
        helperText={error?.description}
        disabled={!selectedArea || !selectedInspectionItem}
        defaultValue={damageFound.description}
        label={'Additional Info'}
        InputProps={{ sx: { backgroundColor: 'background.default' } }}
        onChange={({ target }) => onChange({ ...damageFound, description: target.value })}
        multiline
        maxRows={4}
      />

      <IconButton variant="text" size="large" onClick={clickInput}>
        <input ref={ref} type="file" accept={acceptedFiles} hidden onChange={handleInputChange} />
        {damageFound.imageFile ? <CheckIcon /> : <AddPhotoAlternateIcon />}
      </IconButton>

      <IconButton variant="text" size="large" color="error" onClick={onDelete}>
        <TrashCanIcon width="24px" height="24px" />
      </IconButton>
    </Stack>
  );
};
