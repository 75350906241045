import { useQuery } from '@tanstack/react-query';

import { RequestReportParams } from 'domain/entities/Report';
import { ReportRepository } from 'domain/repositories/IReportRepository';
import { useHookInjection } from 'application/hooks';

export const useCaseGetOperationsSummary = (params: RequestReportParams) => {
  const { getReports } = useHookInjection<ReportRepository>(ReportRepository);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['REPORTS', params] as const,
    queryFn: async ({ queryKey }) => getReports(queryKey[1]),
    staleTime: 10_000,
  });

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};
