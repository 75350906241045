import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import Modal from 'targets/web/components/Modal';

export interface ConfirmationModalProps {
  title: string;
  subtitle: string;
  triggerButton: (onClick: () => void) => ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  open?: boolean;
  confirmText?: string;
  cancelText?: string;
  confirmButtonColor?: 'error' | 'primary';
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  subtitle,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  triggerButton,
  confirmButtonColor = 'error',
  open: openProp,
}) => {
  const tGlobal = useTranslationPrefix('global');

  const [open, setOpen] = useState(openProp ?? false);

  useEffect(() => {
    if (typeof openProp !== 'undefined') {
      setOpen(openProp);
    }
  }, [openProp]);

  const handleClose = useCallback(() => {
    setOpen(false);
    onCancel?.();
  }, [onCancel]);

  return (
    <>
      {triggerButton(() => setOpen(true))}
      <Modal
        onClose={handleClose}
        open={open}
        title={title}
        content={subtitle}
        actions={{
          primary: {
            text: confirmText ?? tGlobal('confirm'),
            onClick: () => {
              setOpen(false);
              onConfirm();
            },
            color: confirmButtonColor,
          },
          secondary: {
            text: cancelText ?? tGlobal('cancel'),
            onClick: handleClose,
          },
        }}
      />
    </>
  );
};
